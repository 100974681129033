import React from 'react';
import { SvgIcon } from '@mui/material';

const Facebook = (props) => {
  return (
    <SvgIcon {...props} height='100' viewBox='0 0 100 100' width='100'>
      <path d='m43.0913242 90h12.4200913v-29.3339533h9.2607306l1.7621005-11.796902h-11.0228311v-7.6553923c0-3.2274092 1.5397808-6.373356 6.4769224-6.373356h5.0116621v-10.0433084s-4.5482374-.797088-8.8966667-.797088c-9.0784657 0-15.0120091 5.6497595-15.0120091 15.8779924v8.9911523h-10.0913242v11.796902h10.0913242z' />
    </SvgIcon>
  );
};

export default Facebook;
