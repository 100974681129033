import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import { authorQueries } from '../../../graphql/queries';
import AuthorForm from './AuthorForm.js';
import EnumValues from '../../../Components/Queries/EnumValues.js';

const AddAuthor = (props) => {
  return (
    <Mutation
      mutation={mutations.UpsertAuthor}
      update={(cache, { data: { upsertAuthor } }) => {
        const { authors } = cache.readQuery({ query: authorQueries.Authors });
        cache.writeQuery({
          query: authorQueries.Authors,
          data: { authors: authors.concat([upsertAuthor]) }
        });
      }}
    >
      {upsertAuthor => (
        <EnumValues name='LanguageEnum'>
          {languageEnum => (
            <AuthorForm
              upsertAuthor={upsertAuthor}
              author={{
                firstName: '',
                lastName: '',
                bio: '',
                authorLinks: [],
                language: 'enGB'
              }}
              languageEnum={languageEnum}
              {...props}
            />
          )}
        </EnumValues>
      )}
    </Mutation>
  );
};

AddAuthor.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default AddAuthor;
