import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const styles = {
  component: {
    position: 'relative',
    fontSize: 10,
    userSelect: 'none',
    fontWeight: 700,
    width: '17em',
    overflow: 'hidden',
    cursor: 'default'
  },
  heading: {
    float: 'left',
    fontSize: '1.4em',
    lineHeight: '2.58em'
  },
  ul: {
    float: 'right',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      float: 'left',
      marginLeft: -1,
      '& span': {
        position: 'relative',
        display: 'block',
        width: '2.25em',
        height: '2.25em',
        lineHeight: '2.2em',
        textAlign: 'center',
        fontSize: '1.6em',
        textDecoration: 'none',
        color: '#4a4a4a',
        fontWeight: 700,
        background: '#fff',
        border: '1px solid #ccc',
        borderRadius: '0.15em',
        cursor: 'pointer',
        '&:hover': {
          background: '#f5f5f5',
          borderColor: '#999',
          color: '#000',
          zIndex: 1
        },
        '&.active': {
          zIndex: 1,
          color: '#fff'
        },
        '&.not-vulnerable': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          '&.active': {
            background: '#9bc172',
            borderColor: '#749055'
          }
        },
        '&.vulnerable': {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          '&.active': {
            background: '#ae4646',
            borderColor: '#823434'
          }
        }
      }
    }
  }
};

class Vulnerability extends React.Component {

  returnHeading = (pair) => {
    let heading = "North/South";
    if (pair === "EW") {
      heading = "East/West";
    }
    return heading;
  }

  render () {
    const { classes, pair, vulnerable, onClick } = this.props;

    return (
      <div className={classes.component}>
        <div className={classes.heading}>{this.returnHeading(pair)}</div>
        <ul className={classes.ul}>
          <li>
            <span
              className={ clsx('not-vulnerable', !vulnerable ? 'active' : null) }
              onClick={() => {
                if (vulnerable) {
                  onClick(pair);
                }
              }}
            >
              NV
            </span>
          </li>
          <li>
            <span
              className={ clsx('vulnerable', vulnerable ? 'active' : null) }
              onClick={() => {
                if (!vulnerable) {
                  onClick(pair);
                }
              }}
            >
              V
            </span>
          </li>
        </ul>
      </div>
    );
  };
};

Vulnerability.propTypes = {
  classes: PropTypes.object.isRequired,
  pair: PropTypes.string.isRequired,
  vulnerable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(Vulnerability);
