import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

class TagManager extends React.Component {
  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    const options = props.options.map(option => {
      return { label: option.name, value: option.id };
    });
    const selected = props.selected.map(tag => {
      if (tag.tagGroup === undefined || tag.tagGroup === null) {
        return { label: tag.name, value: tag.id };
      }
      return null;
    });
    this.state = {
      options,
      selected,
      newTag: ''
    };
  }

  handleChange (newValue, actionMeta) {
    switch (actionMeta.action) {
      case 'select-option':
      case 'remove-value':
      case 'create-option':
        this.props.onUpdate(newValue);
        break;
      default:
        console.log('no switch condition found');
    }
    console.groupEnd();
  }

  render () {
    const { options, selected } = this.state;
    const { createable } = this.props;
    if (createable) {
      return (
        <CreatableSelect
          defaultValue={selected}
          isMulti
          onChange={this.handleChange}
          options={options}
          placeholder='Select tags...'
        />
      );
    } else {
      return (
        <Select
          defaultValue={selected}
          isMulti
          onChange={this.handleChange}
          options={options}
          placeholder='Select tags...'
        />
      );
    }
  }
}

TagManager.propTypes = {
  selected: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  createable: PropTypes.bool
};

export default TagManager;
