import gql from 'graphql-tag';
import { userFragments } from '../fragments';

const userQueries = {
  // USERS
  Users: gql`
    query {
      users {
        id
        firstName
        lastName
        fullName
        email
        type
        dateCreated
      }
    }
  `,
  UserSearch: (options) => {
    return gql`
      query {
        userSearch (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          users {
            id
            firstName
            lastName
            fullNameAdmin
            email
            type
            dateCreated
          }
        }
      }
    `;
  },
  UserById: (id) => {
    return gql`
      query {
        userById (id: ${id}) {
          id
          firstName
          lastName
          fullName
          email
          type
          dateCreated
          marketingPrefs
          filterSettings
          stripeCustomerId
          stripePaymentMethodId
          currency
          language
          tags {
            id
            name
            tagGroup {
              id
              name
            }
          }
        }
      }
    `;
  },
  UserByGiftedToId: gql`
    query (
      $id: Int!
    ) {
      userByGiftedToId (
        id: $id
      ) {
        id
        firstName
        lastName
        email
      }
    }`,
  UserByToken: (token) => {
    return gql`
      query {
        userByToken (token: "${token}") {
          ...FullUser
          token
        }
      }
      ${userFragments.fullUser}
    `;
  },
  // uses the jwt to get the user details
  CurrentUser: gql`
    query {
      currentUser {
        ...FullUser
      }
    }
    ${userFragments.fullUser}
  `,
  PurchasesByUserId: gql`
    query (
      $id: Int
    ) {
      purchasesByUserId (
        id: $id
      ) {
        ...FullUser
        purchases {
          id
          status
          purchaseDate
          totalPrice
          totalPriceFormatted
          currency
          paymentType
          purchaseItems {
            id
            price
            discount
            giftedToUserId
            product {
              id
              type
              title
              language
              images
              tagsCache
              priceLocalFormatted
              author {
                id
                fullName
              }
            }
          }
        }
      }
    }
    ${userFragments.fullUser}
  `
};

export default userQueries;
