import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { endpoint } from '../helpers/env.js';
import { getJwt } from '../helpers/auth.js';
import { parseError } from '../helpers/error.js';

const httpLink = createHttpLink({
  uri: endpoint,
  preserveHeaderCase: true // allows us to use mixed case headers like Authorization!
});

const errorLink = onError((err) => {
  const parsedError = parseError(err);
  if (parsedError.code === 'UNAUTHENTICATED') {
    err.networkError.message = '401 UNAUTHENTICATED';
  }
});

const authLink = setContext((_, { headers }) => {
  const token = getJwt();

  if (!token) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  };
});

const client = new ApolloClient({
  uri: endpoint,
  cache: new InMemoryCache(),
  link: authLink
    .concat(errorLink)
    .concat(httpLink)
});

export default client;
