import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import BBTranslate from '../Locale/BBTranslate.js';

const useStyles = ({ zIndex }) => makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    background: theme.palette.styleguide.green,
    color: '#fff',
    textAlign: 'center',
    zIndex: zIndex ? 3 + zIndex : 3
  },
  container: {
    position: 'relative',
    maxWidth: '140rem',
    margin: '0 auto',
    lineHeight: '4rem'
  },
  link: {
    marginLeft: '1rem',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const FooterMessage = (props) => {
  const { message, onLinkClick, linkText } = props;
  const classes = useStyles(props)();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <BBTranslate textId={message} />
        <br />
        {
          onLinkClick
            ? <span onClick={onLinkClick} className={classes.link}><BBTranslate textId={linkText} /></span>
            : null
        }
      </div>
    </div>
  );
};

FooterMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func,
  linkText: PropTypes.string,
  zIndex: PropTypes.number
};

export default FooterMessage;
