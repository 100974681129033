import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import PurchaseStats from '../../../Components/Queries/PurchaseStats.js';

const SimpleChart = (props) => {
  const getTotalsForPeriod = (data) => {
    const totals = {
      sales: 0,
      count: 0
    };
    data.forEach((item) => {
      totals.sales += parseFloat(item.totalSales);
      totals.count += item.count;
    });
    return totals;
  };
  const { dateFrom, dateTo } = props;
  return (
    <PurchaseStats dateFrom={dateFrom} dateTo={dateTo}>
      {
        (data) => {
          const totals = getTotalsForPeriod(data);
          return (
            <>
              <div style={{ margin: 20 }}>
                <Typography variant='h6'>
                  Total Sales for the selected period: ${totals.sales.toFixed(2)}
                </Typography>
                <Typography variant='h6'>
                  Total purchase count for the selected period: {totals.count}
                </Typography>
              </div>
              <ResponsiveContainer width='99%' height={320}>
                <LineChart data={data}>
                  <XAxis dataKey='date' />
                  <YAxis />
                  <CartesianGrid vertical={false} strokeDasharray='3 3' />
                  <Tooltip />
                  <Legend />
                  <Line name='Purchase count' type='monotone' dataKey='count' stroke='#80BCB2' />
                  <Line name='Total sales in $' type='monotone' dataKey='totalSales' stroke='#683044' activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </>
          );
        }
      }
    </PurchaseStats>
  );
};

SimpleChart.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string
};

export default SimpleChart;
