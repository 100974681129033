import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { Select, FormControl, MenuItem } from '@mui/material';
import BBTranslate from '../../Locale/BBTranslate';

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: '#fff',
    borderRadius: 2,
    fontSize: 16
  },
  item: {
    fontSize: 16
  }
}));

const StoreSelect = (props) => {
  const { value, valueField, keyField, options, onChange } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <FormControl size='small'>
      <Select
        value={value}
        inputProps={{ 'aria-label': 'Order by' }}
        onChange={onChange}
        className={classes.select}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: theme.boxShadow
            }
          }
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={valueField ? option[valueField] : option.id}
            className={classes.item}
          >
            <BBTranslate textId={keyField ? option[keyField] : option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

StoreSelect.propTypes = {
  value: PropTypes.string,
  valueField: PropTypes.string,
  keyField: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default StoreSelect;
