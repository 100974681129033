import React from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Box
} from '@mui/material';
import PasswordForm from '../../../Components/Forms/PasswordForm.js';

const Password = () => {
  const { user, handleSubmitError, handleSubmitSuccess } = useOutletContext();
  return (
    <Box sx={{ maxWidth: '38rem', marginLeft: 'auto', marginRight: 'auto' }}>
      <PasswordForm
        user={user}
        onSubmitError={handleSubmitError}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </Box>
  );
};

export default Password;
