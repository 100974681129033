import gql from 'graphql-tag';

const voucherQueries = {
  VoucherSearch: (options) => {
    return gql`
      query {
        voucherSearch (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          vouchers {
            id
            code
            discountAmount
            dateCreated
          }
        }
      }
    `;
  },
  VoucherById: (id) => {
    return gql`
      query {
        voucherById (
          id: ${id}
        ) {
          id
          code
          discountAmount
          discountType
          activationDate
          expiryDate
          totalRedemptions
          remainingRedemptions
          product {
            id
            title
          }
        }
      }
    `;
  }
};

export default voucherQueries;
