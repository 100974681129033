import React, { useContext } from 'react';
import {
  Link,
  Box,
  Typography
} from '@mui/material';
// import { ReactTitle } from 'react-meta-tags';
import LoginForm from './LoginForm.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import { DrawerContext } from '../../../Context';
import { useNavigate } from 'react-router-dom';

const LoginDrawer = () => {
  const navigate = useNavigate();
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const handleForgottenPasswordClick = () => {
    openDrawer('forgotten');
  };
  const handleRegisterClick = () => {
    closeDrawer();
    navigate('/register');
  };

  const handleLoginComplete = () => {
    closeDrawer();
  };

  return (
    <>
      <Box sx={{ marginBottom: '2rem', display: 'flex' }}>
        <Typography variant='body2'>
          <BBTranslate textId='dont-have-an-account' />&nbsp;
        </Typography>
        <Link
          component='button'
          variant='body2'
          onClick={handleRegisterClick}
        >
          <BBTranslate textId='register-here' />
        </Link>
      </Box>
      <LoginForm onLoginComplete={handleLoginComplete} />
      <Link
        component='button'
        variant='body1'
        onClick={handleForgottenPasswordClick}
      >
        <BBTranslate textId='forgotten-password' />
      </Link>
    </>
  );
};

export default LoginDrawer;
