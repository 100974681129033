import React, { useState } from 'react';

const GiftContext = React.createContext();

const GiftProvider = ({ children }) => {
  const [gift, setGift] = useState(null);

  const initGift = (product) => {
    setGift({
      product
    });
  };

  return (
    <GiftContext.Provider value={{ gift, initGift, setGift }}>
      {children}
    </GiftContext.Provider>
  );
};

export { GiftContext, GiftProvider };
