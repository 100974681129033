import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import BBTranslate from '../../Locale/BBTranslate.js';

const styles = {
  header: {
    overflow: 'hidden',
    padding: '0.8em',
    margin: '0.1em',
    borderRadius: '0.2em',
    background: '#9bc172',
    color: '#fff',
    textShadow: '0 0.1em 0 rgba(0,0,0,0.15)',
    '&.isVulnerable': {
      background: '#ae4646'
    }
  },
  h3: {
    margin: 0,
    padding: 0,
    fontSize: '1.5em'
  },
  ul: {
    margin: 0,
    float: 'right',
    textTransform: 'uppercase',
    fontSize: '1.2em'
  },
  li: {
    display: 'inline',
    padding: '0 0 0 0.5em',
    cursor: 'help'
  }
};

const DealDirectionHeader = (props) => {
  const { classes, direction, dealer, vulnerable, onSeatClick } = props;
  return (
    <div
      className={clsx(classes.header, vulnerable ? 'isVulnerable' : null)}
      onClick={() => {
        if (onSeatClick) {
          onSeatClick(direction);
        }
      }}
    >
      <ul className={classes.ul}>
        {
          dealer
            ? <Tooltip title={<BBTranslate textId='dealer' />} placement='top'><li className={classes.li}><BBTranslate textId='d-dealer' /></li></Tooltip>
            : null
        }
        {
          vulnerable
            ? <Tooltip title={<BBTranslate textId='vulnerable' />} placement='top'><li className={classes.li}><BBTranslate textId='v-vulnerable' /></li></Tooltip>
            : <Tooltip title={<BBTranslate textId='not-vulnerable' />} placement='top'><li className={classes.li}><BBTranslate textId='nv-not-vulnerable' /></li></Tooltip>
        }
      </ul>
      <h3 className={classes.h3}><BBTranslate textId={direction.toLowerCase()} /></h3>
    </div>
  );
};

DealDirectionHeader.propTypes = {
  direction: PropTypes.string.isRequired,
  dealer: PropTypes.bool.isRequired,
  vulnerable: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired,
  onSeatClick: PropTypes.func
};

export default withStyles(styles)(DealDirectionHeader);
