import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import BBTranslate from '../../Locale/BBTranslate.js';

const RevealMessage = (props) => {
  const { children, linkText } = props;
  const [visible, setVisible] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  return (
    <div>
      {
        visible
          ? children
          : <a href='/reveal' onClick={handleClick}><BBTranslate textId={_.kebabCase(linkText)} /></a>
      }
    </div>
  );
};

RevealMessage.propTypes = {
  children: PropTypes.node.isRequired,
  linkText: PropTypes.string.isRequired
};

export default RevealMessage;
