import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block'
  },
  logoIcon: {
    float: 'left',
    display: 'block',
    height: '3.3rem',
    width: '3.3rem'
  },
  logoWord: {
    float: 'left',
    display: 'block',
    height: '3.3rem',
    width: '16rem',
    marginLeft: '1rem',
    background: 'transparent url("/img/components/header/logo_word_maroon.svg") no-repeat right top',
    textIndent: '-100rem',
    overflow: 'hidden'
  }
}));

const Logo = (props) => {
  const { iconOnly, wordOnly } = props;

  const classes = useStyles();
  return (
    <a href='/' className={classes.logo}>
      {!wordOnly && (
        <img
          src='/img/components/header/logo_hive.svg'
          className={classes.logoIcon}
          alt=''
        />
      )}
      {!iconOnly && (
        <span className={classes.logoWord}>
          bridgebee
        </span>
      )}
    </a>
  );
};

Logo.propTypes = {
  iconOnly: PropTypes.bool,
  wordOnly: PropTypes.bool
};

export default Logo;
