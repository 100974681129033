import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { InputAdornment, TextField } from '@mui/material';
import BBTranslate from '../Locale/BBTranslate.js';
// import { Visibility, VisibilityOff } from '@mui/icons-material';

const styles = theme => ({
  eye: {
    cursor: 'pointer',
    color: theme.palette.error
  },
  hideShow: {
    textDecoration: 'underline',
    color: 'grey',
    '&:hover': {
      cursor: 'pointer'
    },
  }
});

class BBInput extends Component {
  state = {
    value: this.props.value,
    error: '',
    passwordIsMasked: true
  };

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }))
  };

  onChange = e => {
    const name = this.props.name;
    const value = this.props.type === 'number' ? parseInt(e.target.value) : e.target.value;
    const error = this.props.validate ? this.props.validate(value) : '';
    this.setState({
      value,
      error
    });
    this.props.onChange({
      name,
      value,
      error
    });
  };

  showEye = () => {
    const { classes, type } = this.props;
    if (type === 'password') {
      if (this.state.passwordIsMasked) {
        return (
          <InputAdornment position='end'>
            <span
              className={classes.hideShow}
              onClick={this.togglePasswordMask}
            >
              <BBTranslate textId='show' />
            </span>
          {/*
            <VisibilityOff
            className={classes.eye}
            color={'action'}
            onClick={this.togglePasswordMask}
            />
            */}
          </InputAdornment>
          )
      } else {
        return (
          <InputAdornment position='end'>
            <span
              className={classes.hideShow}
              onClick={this.togglePasswordMask}
            >
              <BBTranslate textId='hide' />
            </span>
            {/*
              <Visibility
                className={classes.eye}
                color={'action'}
                onClick={this.togglePasswordMask}
              />
            */}
          </InputAdornment>
        )
      };
    };
    return null
  };

  getInputProps = () => {
    let { type, inputProps, className } = this.props;
    if (!inputProps) {
      inputProps = {}
    };
    if (type === 'password') {
      inputProps.endAdornment = this.showEye()
    };
    if (className) {
      inputProps.classes = {
        input: this.props.className
      }
    };
    inputProps.sx = { fontSize: '16px !important' };
    return inputProps
  };

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
      })
    }
  };

  render() {
    // work out what the type should be
    let type = 'text';
    if (this.props.type) {
      if (this.props.type === 'password') {
        type = this.state.passwordIsMasked ? 'password' : 'text'
      } else {
        type = this.props.type
      }
    };
    const translatedLabel = <BBTranslate textId={this.props.label} />
    return (
      <TextField        
        sx={{ backgroundColor: 'white' }}
        name={this.props.name}
        type={type}
        label={translatedLabel}
        id={this.props.id}
        ref={this.setRef ? this.setRef : 'input'}
        value={this.state.value}
        autoComplete={this.props.autoComplete}
        autoFocus={this.props.autoFocus}
        required={this.props.required}
        error={this.state.error !== ''}
        helperText={this.state.error}
        onChange={this.onChange}
        onBlur={this.onChange}
        onKeyDown={this.onChange}
        onKeyUp={this.props.onKeyUp}
        variant={this.props.variant ? this.props.variant : 'outlined'}
        inputProps={{
          maxLength: this.props.maxLength
        }}
        InputProps={this.getInputProps()}
        InputLabelProps={{
          classes: {
            root: this.props.className ? this.props.className : null
          }
        }}
        fullWidth={this.props.fullWidth === undefined ? true : false}
        multiline={this.props.rows && this.props.rows > 1}
        minRows={this.props.rows ? this.props.rows : 1}
        size={this.props.size || 'medium'}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
      />
    );
  }
}

BBInput.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  translatedLabel: PropTypes.func,
  onChange: PropTypes.func,
  setRef: PropTypes.func,
  value: PropTypes.any,
  maxlength: PropTypes.number,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  validate: PropTypes.func,
  onKeyUp: PropTypes.func,
  inputProps: PropTypes.object,
  rows: PropTypes.number,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  onAutoFillStart: PropTypes.func,
  disabled: PropTypes.bool
};

export default withStyles(styles)(BBInput);
