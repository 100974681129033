import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  Box
} from '@mui/material';
import EnumValues from '../../../../Components/Queries/EnumValues.js';
import StoreSelect from '../../../../Components/Forms/Store/StoreSelect.js';
import StoreButton from '../../../../Components/Forms/Store/StoreButton.js';

const CurrencyForm = ({ user, onSubmit, onSubmitSuccess, isLoading }) => {
  const [currency, setCurrency] = useState(user.currency);
  const { t } = useTranslation();
  const handleChange = (e) => {
    setCurrency(e.target.value);
  };
  const handleSubmit = () => {
    onSubmit(currency);
    onSubmitSuccess(t('currency-has-been-set'));
  };
  return (
    <EnumValues name='CurrencyEnum'>
      {currencies => (
        <>
          <FormControl variant='standard' margin='normal' required fullWidth>
            <StoreSelect
              id='currency'
              required
              options={currencies}
              valueField='name'
              keyField='name'
              value={currency}
              onChange={handleChange}
            />
          </FormControl>
          <Box sx={{ textAlign: 'right' }}>
            <FormControl variant='standard' margin='normal' required>
              <StoreButton
                label='save-currency'
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isLoading}
              />
            </FormControl>
          </Box>
        </>
      )}
    </EnumValues>
  );
};

CurrencyForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired
};

export default CurrencyForm;
