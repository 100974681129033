import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { purchaseQueries } from '../../../../graphql/queries';
import {
  CircularProgress
} from '@mui/material';
import MainContainer from '../../../../Components/Layout/MainContainer.js';
import SuccessStep from '../../../../Components/SuccessStep.js';
import Failed from './Components/Failed.js';
import CheckoutAppBar from './Components/CheckoutAppBar';
import { CartContext } from '../../../../Context';

const IdealPaymentLanding = () => {
  const [redirectedPurchase, setRedirectedPurchase] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const { cart, clearCart } = useContext(CartContext);

  const { t } = useTranslation();

  const redirectedPurchaseParams = useRef(
    (location.search && location.search.includes('payment_intent'))
      ? new URLSearchParams(location.search)
      : null
  );

  const [getPurchase] = useLazyQuery(purchaseQueries.PurchaseByStripeId, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const purchase = data.purchaseByStripeId;
      // if (purchase.status === 'succeeded') {
      //   send stats to google
      //   recordGoogleAnalyticsPurchase(purchase.id, purchase.product, purchase.currency);
      // }
      setRedirectedPurchase(purchase);
    }
  });

  useEffect(() => {
    if (redirectedPurchaseParams.current) {
      const paymentIntent = redirectedPurchaseParams.current.get('payment_intent');
      getPurchase({ variables: { stripeId: paymentIntent } });
    }
  }, [redirectedPurchaseParams, getPurchase, location.pathname]);

  if (!redirectedPurchase) {
    return null;
  }

  let inner;

  const handleRedirect = async () => {
    // if cart still populated, perform actions
    if (cart) {
      // set giftsOnly before clearing cart
      const giftsOnly = cart && cart.giftsOnly;
      clearCart();
      if (giftsOnly) {
        navigate('/products');
      } else {
        navigate('/library');
      }
    }
  };

  switch (redirectedPurchase.status) {
    case 'Succeeded':
      inner = (
        <SuccessStep
          heading='Success!'
          text='your-purchase-is-complete'
          onSuccess={handleRedirect}
        />
      );
      break;
    case 'RequiresAction':
      // call getPurchase again (poll)
      setTimeout(() => {
        getPurchase({ variables: { stripeId: redirectedPurchase.stripeId } });
      }, 2000);
      inner = (
        <>
          {t('waiting-for-confirmation')}
          <CircularProgress color='inherit' />
        </>
      );
      break;
    default:
      inner = <Failed purchase={redirectedPurchase} />;
      break;
  }

  return (
    <>
      <CheckoutAppBar />
      <MainContainer>
        {inner}
      </MainContainer>
    </>
  );
};

export default IdealPaymentLanding;
