import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import BBSelect from './Forms/BBSelect.js';

const BBDateRange = (props) => {
  const { selectedRange, onChange, dateFilterOptions } = props;

  /* future ranges!
  const ranges = [
    { range: 'Custom' },
    { range: 'Today' },
    { range: 'Yesterday' },
    { range: 'This week' },
    { range: 'Last week' },
    { range: 'This month' },
    { range: 'Last month' },
    { range: 'This year' },
    { range: 'Last year' }
  ];
  */

  /* current ranges -->
    (default)
    ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days', 'Custom']
    OR
    (financial quarters)
    ['Current Quarter', 'Backdated 1', 'Backdated 2', 'Backdated 3', 'Custom']
  */
  const ranges = dateFilterOptions.map(option => ({ range: option.label }));

  return (
    <Box sx={{ marginTop: '8px' }}>
      <BBSelect
        id='dateRange'
        name='dateRange'
        label='Date range'
        required={false}
        onChange={onChange}
        value={selectedRange}
        valueField='range'
        keyField='range'
        items={ranges}
        variant='standard'
      />
    </Box>
  );
};

BBDateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedRange: PropTypes.string.isRequired,
  dateFilterOptions: PropTypes.array.isRequired
};

export default BBDateRange;
