import React from 'react';
import BBAccordion from '../../Components/BBAccordion.js';
import StoreSectionContainer from '../../Components/Store/StoreSectionContainer.js';
import { useTranslation } from 'react-i18next';

const FAQ = ({ extraWhitespace }) => {
  const { t } = useTranslation();

  return (
    <StoreSectionContainer
      heading={t('faq')}
      extraWhitespace={extraWhitespace}
    >
      <BBAccordion
        items={[
          {
            key: 'bridgebee',
            summary: t('what-is-bridgebee'),
            details: t('bridgebee-offers-an-immersive-way')
          },
          {
            key: 'purchases',
            summary: t('how-long-will-my-purchases-available'),
            details: t('when-you-acquire-hands-from-bridgebee', { link: '<a href="/library" target="_blank">https://bridgebee.app/library</a>' })
          },
          {
            key: 'account',
            summary: t('how-can-i-manage-my-account'),
            details: t('by-accessing-your-settings', { link: '<a href="/account" target="_blank">https://bridgebee.app/account</a>' })
          },
          {
            key: 'password',
            summary: t('what-should-i-do-password'),
            details: t('in-the-event-of-forgotten-password', { link: '<a href="/forgottenpassword" target="_blank">https://bridgebee.app/forgottenpassword</a>' })
          },
          {
            key: 'gifts',
            summary: t('is-it-possible-gift'),
            details: t('by-selecting-send-gift')
          }
        ]}
      />
    </StoreSectionContainer>
  );
};

export default FAQ;
