import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import SuitPattern from '../../Components/SuitPattern.js';
import StoreButton from '../../Components/Forms/Store/StoreButton.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E3E4DF',
    paddingTop: '12rem',
    paddingBottom: '14rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8rem'
    }
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    maxWidth: '118rem',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  textContainer: {
    width: '522px',
    maxWidth: '50%',
    padding: '2rem',
    zIndex: 1,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: '0 auto',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'normal',
      justifyContent: 'normal',
      textAlign: 'left'
    }
  },
  image: {
    position: 'absolute',
    right: '-10%',
    bottom: 0,
    top: 0,
    width: '60%',
    // this allows us to fade out the right-hand side of the image
    // so there's no clean edge
    maskImage: 'linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 1) 10%)',
    [theme.breakpoints.down('md')]: {
      right: 0,
      width: '75%',
      position: 'relative',
      flexShrink: 1,
      margin: '0 auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  heading: {
    fontSize: 48,
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginBottom: '1.6rem'
    }
  },
  body: {
    color: '#683044',
    marginBottom: '3rem'
  },
  button: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      '& button': {
        width: '100%'
      }
    }
  },
  suitPattern: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const Hero = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleTryClick = () => {
    // const elem = document.querySelector('#hands');
    // elem && elem.scrollIntoView();
    navigate('/products/1037/bridgebee-free-sample-hands');
  };

  return (
    <div className={classes.container}>
      <SuitPattern className={classes.suitPattern} />
      <div className={classes.contentContainer}>
        <div className={classes.textContainer}>
          <Typography
            variant='h1'
            className={classes.heading}
          >
            {t('the-best-players-are-always-learning')}
          </Typography>
          <Typography
            variant='body4'
            className={classes.body}
          >
            {t('play-and-practice-interactive-bridge-hands')}
          </Typography>
          <div
            className={classes.button}
          >
            <StoreButton
              onClick={handleTryClick}
              label='practice-a-free-hand'
            />
          </div>
        </div>
        <img
          alt=''
          className={classes.image}
          src='img/components/sales/tablet-cup.jpg'
        />
      </div>
    </div>
  );
};

export default Hero;
