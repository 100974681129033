import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import {
  convertCodeToCardObject,
  isCardInArray,
  allCardsBySuit,
  getSuitFromCardCode,
  getRankFromCardCode
} from '../../helpers/card.js';

const styles = {
  component: {
    position: 'relative',
    fontSize: 10,
    width: '50em',
    userSelect: 'none'
  },
  rootUL: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  rootLI: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'left center',
    paddingLeft: '4.5em',
    margin: '1em 0',
    '&.suitS': {
      backgroundImage: 'url("/img/components/full_deck/bg_icon_suit_spade.svg")'
    },
    '&.suitH': {
      backgroundImage: 'url("/img/components/full_deck/bg_icon_suit_heart.svg")'
    },
    '&.suitD': {
      backgroundImage: 'url("/img/components/full_deck/bg_icon_suit_diamond.svg")'
    },
    '&.suitC': {
      backgroundImage: 'url("/img/components/full_deck/bg_icon_suit_club.svg")'
    }
  },
  innerUL: {
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '&.red li a': {
      color: '#e32522'
    }
  },
  innerLI: {
    float: 'left',
    marginLeft: '0.2em'
  },
  cardLink: {
    cursor: 'pointer',
    display: 'block',
    width: '1.3em',
    lineHeight: '1.8em',
    fontSize: '2.5em',
    fontWeight: '700',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '0.1em',
    letterSpacing: '-0.1em',
    textIndent: '-0.08em',
    color: '#000',
    textShadow: '0 1px #fff',
    background: '#fff',
    '&:hover': {
      borderColor: '#999',
      background: '#f5f5f5',
    }
  },
  isSelected: {
    cursor: 'default',
    borderColor: 'transparent',
    color: '#999 !important',
    background: 'none',
    '&:hover': {
      background: 'none',
      borderColor: 'transparent'
    }
  }
};

class FullDeck extends React.Component {
  isDealt = (card) => {
    // is the card in the list of deat cards?
    const cardObj = convertCodeToCardObject(card);
    return isCardInArray(this.props.dealtCards, cardObj);
  }

  onClick = (value) => {
    if (!this.isDealt(value)) {
      const card = convertCodeToCardObject(value);
      this.props.onCardClick(card);
    }
  }

  render () {
    const { classes } = this.props;
    return (
      <div className={classes.component}>
        {
          allCardsBySuit.map((cardsForSuit, index) => {
            const suit = getSuitFromCardCode(cardsForSuit[index]);
            return(
              <ul key={index} className={classes.rootUL}>
                <li className={clsx(classes.rootLI, 'suit' + suit)}>
                  <ul className={clsx(classes.innerUL, suit === 'H' || suit === 'D' ? 'red' : null)}>
                    {
                      cardsForSuit.map((card, index) => {
                        return(
                          <li key={index} className={classes.innerLI}>
                            <span
                              onClick={() => { this.onClick(card) }}
                              className={
                                clsx(
                                  classes.cardLink,
                                  this.isDealt(card) === true
                                    ? classes.isSelected
                                    : null
                                )}
                              >
                                {getRankFromCardCode(card)}
                              </span>
                          </li>
                        )
                      })
                    }
                  </ul>
                </li>
              </ul>
            )
          })
        }

      </div>
    )
  }
}

FullDeck.propTypes = {
  classes: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  dealtCards: PropTypes.array
}

export default withStyles(styles)(FullDeck);
