import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Tabs,
  Tab
} from '@mui/material';
import { Link } from 'react-router-dom';
import BBTranslate from '../../Locale/BBTranslate.js';

const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: '1.6rem',
    color: theme.palette.styleguide.maroon,
    fontWeight: 700,
    width: 'auto !important',
    minWidth: 'auto !important',
    padding: 0,
    marginLeft: 24
  }
}));

const Nav = ({ tabs, activeNavTab, onNavClick }) => {
  const handleTabChange = (e, newValue) => {
    onNavClick(tabs[newValue], newValue);
  };
  const classes = useStyles();

  // passing "false" as a value prevents Material from shouting
  // if no tab is selected
  return (
    <Tabs
      onChange={handleTabChange}
      textColor='primary'
      indicatorColor='primary'
      value={activeNavTab === -1 ? false : activeNavTab}
    >
      {tabs.map((tab, i) => (
        <Tab
          key={tab.label}
          label={<BBTranslate textId={tab.label} />}
          component={Link}
          to={tab.path}
          className={classes.tab}
        />
      ))}
    </Tabs>
  );
};

export default Nav;
