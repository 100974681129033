import React from 'react';
import Grid from '@mui/material/Grid';
import AuthorListItem from './Components/AuthorListItem.js';
import Authors from '../../../Components/Queries/Authors.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import StoreSectionContainer from '../../../Components/Store/StoreSectionContainer.js';

const AuthorsPage = () => {
  return (
    <StoreSectionContainer
      heading={<BBTranslate textId='our-authors' />}
    >
      <Authors>
        {
          data =>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              {
                data.map((author, index) => author.products.length > 0 &&
                  <AuthorListItem key={index} author={author} />
                )
              }
            </Grid>
        }
      </Authors>
    </StoreSectionContainer>
  );
};

export default AuthorsPage;
