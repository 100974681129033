import { Box } from '@mui/material';
import { styled } from '@mui/styles';

const GreyLine = styled(Box)(({ theme }) => ({
  height: '1px',
  borderTop: 'solid 1px',
  borderColor: theme.palette.common.grey,
  margin: '3rem 0'
}));

export default GreyLine;
