import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Card } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: theme.boxShadow,
    borderRadius: '12px',
    padding: '4rem',
    textAlign: 'center'
  },
  heading: {
    marginBottom: '1rem'
  },
  body: {
    marginBottom: '1rem'
  }
}));

const Testimonial = (props) => {
  const { heading, body, byline } = props;
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      {heading && (
        <Typography variant='h3' className={classes.heading}>{heading}</Typography>
      )}
      <Typography variant='body4' className={classes.body}>{body}</Typography>
      <Typography variant='body1'>{byline}</Typography>
    </Card>
  );
};

export default Testimonial;
