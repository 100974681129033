import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  getCookieVal,
  persistCookie,
  getMobileOperatingSystem
} from '../helpers/pwa.js';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    background: '#eee',
    fontSize: '1.4rem',
    zIndex: 2
  },
  header: {
    background: '#fff',
    padding: '1rem 4rem 1rem 8rem'
  },
  icon: {
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    width: '6rem',
    height: '6rem',
    borderRadius: '1rem',
    background: '#683044 url("/img/components/header/logo_icon.svg") no-repeat center center',
    backgroundSize: 'contain'
  },
  closeButton: {
    position: 'absolute',
    right: '0.6rem',
    top: '0.6rem',
    zIndex: 2
  },
  shareIcon: {
    padding: '0 1rem',
    background: 'transparent url("/img/components/add_to_homescreen/icon_ios.svg") no-repeat center center',
    backgroundSize: 'contain',
    margin: '0 0.5rem'
  },
  android: {
    backgroundImage: 'url("/img/components/add_to_homescreen/icon_android.svg")'
  }
});

const AddToHomescreen = () => {
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  const handleClose = () => {
    setShow(false);
    persistCookie(true);
  };

  useEffect(() => {
    // decide if we should show the AddToHomescreen compont
    const mobileOS = getMobileOperatingSystem();
    if (mobileOS) {
      const qsValues = queryString.parse(location.search);
      const pwaCookieVal = getCookieVal();
      if ((qsValues.method === undefined || qsValues.method !== 'homescreen') && pwaCookieVal !== 'true') {
        // the customer has not yet dismissed the homescreen component
        // and is not coming from a device already, so show the component
        let isUIWebView = false;
        const isSafari = /safari/.test(window.navigator.userAgent.toLowerCase());
        if (mobileOS === 'iOS' && !isSafari) {
          isUIWebView = true;
        }
        if (!isUIWebView) {
          setShow(true);
        }
      }
      if (qsValues.method !== undefined && qsValues.method === 'homescreen') {
        // persist the cookie so pages that are accessed without the QS still
        // don't show the component
        persistCookie(true);
      }
    }
  }, [setShow, location]);

  const opSys = getMobileOperatingSystem();

  if (show) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <IconButton
            aria-label='Close'
            className={classes.closeButton}
            onClick={handleClose}
            size='large'
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.icon} />
          <strong>Add BridgeBee to your homescreen</strong><br />
          To add BridgeBee to your device's homescreen, simply tap
          {
            opSys === 'iOS'
              ? <span className={clsx(classes.shareIcon, classes.ios)} />
              : <span className={clsx(classes.shareIcon, classes.android)} />
          }
          located at the top right of your screen and then find <strong>Add to Home Screen</strong>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default AddToHomescreen;
