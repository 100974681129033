import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import DealDirectionCard from './DealDirectionCard.js';

const styles = {
  icon_c: {
    backgroundImage: 'url("/img/hand/bg_icon_suit_club.svg")'
  },
  icon_d: {
    backgroundImage: 'url("/img/hand/bg_icon_suit_diamond.svg")'
  },
  icon_h: {
    backgroundImage: 'url("/img/hand/bg_icon_suit_heart.svg")'
  },
  icon_s: {
    backgroundImage: 'url("/img/hand/bg_icon_suit_spade.svg")'
  },
  row: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    backgroundSize: 'contain',
    paddingLeft: '1.8em',
    marginBottom: '0.1em',
    overflow: 'hidden',
    height: '1.8em'
  },
  innerUL: {
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    margin: 0
  }
};

const DealDirectionRow = (props) => {
  const { classes, suit, cards, ...other } = props;
  return (
    <li className={clsx(classes.row, classes.selectable, classes[`icon_${suit.toLowerCase()}`])}>
      <ul className={classes.innerUL}>
        {
          cards.map((card, index) => {
            return (
              <DealDirectionCard
                key={index}
                card={card}
                {...other}
              />
            );
          })
        }
      </ul>
    </li>
  );
};

DealDirectionRow.propTypes = {
  classes: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  suit: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
  allPlayed: PropTypes.bool,
  onCardClick: PropTypes.func
};

export default withStyles(styles)(DealDirectionRow);
