import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import UpArrowIcon from '@mui/icons-material/ArrowDropUp';
import DownArrowIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import { formatURLTitle } from '../../../../helpers/http.js';

const useStyles = makeStyles(() => ({
  reorderButton: {
    padding: '0 !important'
  },
  cell: {
    width: 5,
    paddingRight: 0
  },
  rowHighlightedForDelete: {
    // textDecoration: 'line-through',
    color: '#a94442',
    '& td': {
      background: '#f2dede'
    }
  },
  deletePopover: {
    paddingLeft: '2rem'
  },
  title: {
    color: '#000'
  }
}));

const CollectionSets = (props) => {
  const { sets, onDeleteClick, onReorderClick } = props;
  const classes = useStyles();
  const [deleteId, setDeleteId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCancelClick = () => {
    setDeleteId(null);
    setAnchorEl(null);
  };

  const handleDeleteClick = (id, event) => {
    setDeleteId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteConfirmClick = () => {
    onDeleteClick(deleteId);
    setDeleteId(null);
    setAnchorEl(null);
  };

  return (
    <Table aria-labelledby='tableTitle' align='left'>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}>&nbsp;</TableCell>
          <TableCell>Title</TableCell>
          <TableCell align='right' style={{ width: 180 }}>Author</TableCell>
          <TableCell align='center' style={{ width: 50 }}>On Sale</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          sets.map(set => {
            return (
              <TableRow
                key={set.id}
                className={deleteId === set.id ? classes.rowHighlightedForDelete : null}
              >
                <TableCell className={classes.cell}>
                  <div>
                    <IconButton
                      aria-label='Yes'
                      onClick={onReorderClick(set.id, 'up')}
                      className={classes.reorderButton}
                      size='large'
                    >
                      <UpArrowIcon />
                    </IconButton>
                  </div>
                  <div>
                    <IconButton
                      aria-label='Yes'
                      onClick={onReorderClick(set.id, 'down')}
                      className={classes.reorderButton}
                      size='large'
                    >
                      <DownArrowIcon />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell>
                  <Link
                    className={classes.title}
                    to={`/admin/products/set/${set.id}/${formatURLTitle(set.title)}`}
                  >
                    {set.title}
                  </Link>
                </TableCell>
                <TableCell>
                  {set.author.fullName}
                </TableCell>
                <TableCell>
                  {
                    set.onSale
                      ? <DoneIcon />
                      : null
                  }
                </TableCell>
                <TableCell align='right'>
                  <Tooltip title='Delete' aria-label='delete'>
                    <IconButton
                      aria-label='Delete'
                      onClick={(event) => {
                        handleDeleteClick(set.id, event);
                      }}
                      size='large'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={set.id}
                    open={deleteId === set.id}
                    anchorEl={anchorEl}
                    onClose={handleCancelClick}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <div className={classes.deletePopover}>
                      Are you sure you want to remove this set?
                      <Tooltip title={<Typography>Yes</Typography>} aria-label='yes'>
                        <IconButton aria-label='Yes' onClick={handleDeleteConfirmClick} size='large'>
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={<Typography>Cancel</Typography>} aria-label='cancel'>
                        <IconButton aria-label='Cancel' onClick={handleCancelClick} size='large'>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Popover>
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
    </Table>
  );
};

CollectionSets.propTypes = {
  sets: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onReorderClick: PropTypes.func.isRequired
};

export default CollectionSets;
