import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import striptags from 'striptags';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import RichText from '../../../../Components/Forms/RichText/RichText.js';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2)
  }
}));

const Step3 = ({ hand, onChange }) => {
  const classes = useStyles();

  const handleInputChange = (input) => {
    onChange({
      summary: input.value
    });
  };

  const { summary } = hand;
  return (
    <Paper className={classes.paper}>
      <FormControl variant='standard' margin='normal' fullWidth>
        <RichText
          name='summary'
          value={summary}
          label='Summary'
          onChange={handleInputChange}
          invalid={striptags(summary).length === 0}
        />
      </FormControl>
    </Paper>
  );
};

Step3.propTypes = {
  hand: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Step3;
