import React from 'react';
import { useLocation } from 'react-router-dom';

import ErrorPage from '../../Components/Errors/ErrorPage.js';

const Error404 = () => {
  const location = useLocation();
  return (
    <ErrorPage
      heading={['404', 'error']}
      text={['no-route-found-matching', `: ${location.pathname}`]}
    />
  );
};

export default Error404;
