import React from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import { pbnQueries } from '../../../../graphql/queries';
import PBNUploader from './PBNUploader.js';

class PbnManager extends React.Component {
  state = {
    isLoading: false,
    pollCount: 0,
    filename: null
  };

  onDataFetched = (data, client) => {
    const { pollCount } = this.state;
    const { onProcessingComplete } = this.props;
    if (pollCount === 30) {
      this.setState({
        pollCount: 0,
        isLoading: false
      });
      onProcessingComplete('The upload has timed out');
    } else {
      this.setState({
        pollCount: pollCount + 1
      });

      // should we keep polling ?
      if (data.pbnByFilename === null || data.pbnByFilename.status === 'processing') {
        // wait 1 seconds and try again
        setTimeout(() => {
          this.poll(client);
        }, 1000);
      } else {
        // check status of most recent pbn
        const mostRecentPbn = data.pbnByFilename;
        if (mostRecentPbn.status === 'complete') {
          onProcessingComplete();
        } else {
          // it failed
          onProcessingComplete(`Converting PBN failed: ${mostRecentPbn.error}`);
        }
        this.setState({
          isLoading: false,
          pollCount: 0
        });
      }
    }
  };

  poll = async (client) => {
    const { filename } = this.state;
    const { data } = await client.query({
      query: pbnQueries.PbnByFilename,
      variables: { filename: filename },
      fetchPolicy: 'no-cache'
    });
    this.onDataFetched(data, client);
  };

  handleUploadError = (message) => {
    const { onProcessingComplete } = this.props;
    onProcessingComplete(message);
  };

  render () {
    const { setId } = this.props;
    const { isLoading } = this.state;
    return (
      <ApolloConsumer>
        {client => (
          <div>
            <PBNUploader
              onUploadComplete={() => {
                this.poll(client)
              }}
              onUploadFilenameGenerated={async (filename) => {
                this.setState({
                  isLoading: true,
                  filename: filename
                });
              }}
              onUploadError={this.handleUploadError}
              setId={setId}
              isLoading={isLoading}
            />
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

PbnManager.propTypes = {
  setId: PropTypes.number.isRequired,
  onProcessingComplete: PropTypes.func.isRequired
};

export default PbnManager;
