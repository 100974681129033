import React from 'react';
import {
  Container,
  Typography
} from '@mui/material';
import GridViewSkeleton from '../Components/Skeletons/GridViewSkeleton.js';

const TestGridSkeleton = () => {
  return (
    <>
      <Typography>NO WRAP</Typography>
      <Container sx={{ maxWidth: 1180 }}>
        <GridViewSkeleton
          nowrap
        />
      </Container>
      <Typography>WRAP</Typography>
      <Container sx={{ maxWidth: 1180 }}>
        <GridViewSkeleton />
      </Container>
    </>
  );
};

export default TestGridSkeleton;
