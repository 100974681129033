import _ from 'lodash';
import { getEnvVar } from './env.js';

export const getFileExtension = (filename) => {
  const dotIndex = filename.lastIndexOf('.');
  const ext = filename.substring(dotIndex + 1);
  return ext;
};

export const isValidFileExtension = (filename, supportedFileTypes) => {
  const ext = getFileExtension(filename).toLowerCase();
  const fileType = _.find(supportedFileTypes, { extension: ext });
  return fileType !== undefined;
};

export const getContentType = (filename, supportedFileTypes) => {
  const ext = getFileExtension(filename);
  const fileType = _.find(supportedFileTypes, { extension: ext });
  if (fileType !== undefined) {
    return fileType.contentType;
  }
  return '';
};

export const formatS3FilePath = (filename, folder) => {
  // return a path in the format - https://s3.us-east-2.amazonaws.com/dev-uploads.bridgebee.app/z3y_vhAi6.png
  const REGION = getEnvVar('REGION');
  const UPLOAD_BUCKET = getEnvVar('UPLOAD_BUCKET');
  const bucketUrl = _.join(['s3', REGION, 'amazonaws', 'com'], '.');
  const path = _.join(['https:/', bucketUrl, UPLOAD_BUCKET, folder, filename], '/');
  return path;
};
