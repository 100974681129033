import React from 'react';
import { makeStyles } from '@mui/styles';
import { Outlet } from 'react-router-dom';
// import AdminBreadcrumbs from './AdminBreadcrumbs.js';
import { AdminSearchProvider } from '../../Context';
import CurrentUser from '../../Components/Queries/CurrentUser.js';
import {
  AdminNav
} from './';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(4),
    maxWidth: 1450,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const Admin = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CurrentUser>
        {currentUser => {
          // Force admin to use enGB language. If we don't duplicate currentUser first we get
          // "Uncaught TypeError: Cannot assign to read only property"
          // lookup Object.defineProperty()
          const adminUser = { ...currentUser };
          adminUser.language = 'enGB';
          return (
            <AdminSearchProvider>
              <AdminNav currentUser={adminUser} />
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Outlet />
              </main>
            </AdminSearchProvider>
          );
        }}
      </CurrentUser>
    </div>
  );
};

export default Admin;
