import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FullDeck from '../../../../Components/Hands/FullDeck.js';
import Deal from '../../../../Components/Hands/Deal/Deal.js';
import Dealer from '../../../../Components/Hands/Dealer/Dealer.js';
import Vulnerability from '../../../../Components/Hands/Vulnerability/Vulnerability.js';
import Viewpoint from '../../../../Components/Hands/Viewpoint.js';
import SectionLabel from '../../../../Components/Hands/SectionLabel.js';

import {
  removeCardFromArray,
  getNextSeatForDeal,
  randomDeal,
  allCards,
  completeFinalSeatsDeal,
  convertCardObjectToCode,
  shiftViewPointInDirection
} from '../../../../helpers/card.js';

const styles = theme => ({
  deck: {
    marginTop: theme.spacing(3)
  },
  switches: {
    marginTop: theme.spacing(3)
  },
  switch: {
    paddingRight: theme.spacing(7)
  },
  vulnerability: {
    marginTop: theme.spacing(3)
  },
  dealer: {
    marginTop: theme.spacing(3)
  },
  viewpoint: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: theme.spacing(21),
  },
  paper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
    height: 522,
    textAlign: 'center'
  },
  gridContent: {
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  fulldeck: {
    width: 500
  },
  deal: {
    fontSize: 10
  }
});

class Step0 extends React.Component {
  // component state... doesn't make it to the hand
  state = {
    activeSeat: 'N',
    remainingCards: this.props.hand.deal.length === 52 ? [] :  _.cloneDeep(allCards)
  }

  handleSeatClick = (seat) => {
    this.setState({
      activeSeat: seat.substring(0, 1)
    });
  }

  handleDealtCardClick = (card) => {
    let { deal } = this.props.hand;
    let { activeSeat, remainingCards } = this.state;
    // remove card from dealt array
    deal = removeCardFromArray(deal, card);

    this.props.onChange({
      deal: deal,
      activeSeat: activeSeat
    });
    // set active seat to seat just clicked
    activeSeat = card.seat;

    // add card back to remaining cards
    // but first check its not already been added
    const convertedCard = convertCardObjectToCode(card);
    if (remainingCards.indexOf(convertedCard) < 0) {
      remainingCards.push(convertedCard);
    }

    this.setState ({
      activeSeat: activeSeat,
      remainingCards: remainingCards
    });
  }


  handleCardClick = (card) => {
    let { deal } = this.props.hand;
    let { activeSeat, remainingCards } = this.state;

    // add card to dealt array
    card.seat = activeSeat;
    deal.push(card);

    // remove card from remainingCards
    remainingCards = removeCardFromArray(remainingCards, convertCardObjectToCode(card));

    // see if we can populate remaining seat
    deal = completeFinalSeatsDeal(deal, remainingCards);

    // set active seat
    if (deal.length < 52) {
      activeSeat = getNextSeatForDeal(deal, activeSeat);
    } else {
      remainingCards = [];
    }

    this.props.onChange({
      deal: deal
    });

    this.setState ({
      activeSeat: activeSeat,
      remainingCards: remainingCards
    });
  }

  handleDealerClick = (direction) => {
    this.props.onChange({
      dealer: direction
    });
  }

  handleViewPointClick = () => {
    const { viewpoint } = this.props.hand;
    const newViewpoint = shiftViewPointInDirection(viewpoint);
    this.props.onChange({
      viewpoint: newViewpoint
    });
  }

  handleRandomDealClick = () => {
    const deal = randomDeal();
    this.props.onChange({
      deal: deal
    });
    this.setState({
      remainingCards: []
    });
  }

  handleVulnerableClick = (value) => {
    let vulnerable = this.props.hand.vulnerable;
    if (value === 'EW' && vulnerable === 'NS') {
      vulnerable = 'Both';
    } else if (value === 'NS' && vulnerable === 'EW') {
      vulnerable = 'Both';
    } else if (vulnerable === 'None') {
      vulnerable = value;
    } else if (vulnerable === 'Both' && value === 'NS') {
      vulnerable = 'EW';
    } else if (vulnerable === 'Both' && value === 'EW') {
      vulnerable = 'NS';
    } else if (vulnerable === 'Both' && value === 'NS') {
      vulnerable = 'EW';
    } else if (vulnerable === value) {
      vulnerable = 'None';
    }
    this.props.onChange({
      vulnerable: vulnerable
    });
  }

  render () {
    const { deal, dealer, vulnerable, viewpoint } = this.props.hand;
    const { activeSeat } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
        >
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <div className={clsx(classes.fulldeck, classes.gridContent)}>
                <SectionLabel
                  text='Build the hand'
                >
                  <Button
                    onClick={this.handleRandomDealClick}
                    color='secondary'
                  >
                    Random Deal
                  </Button>
                </SectionLabel>
                <div className={classes.deck}>
                  <FullDeck
                    onCardClick={this.handleCardClick}
                    dealtCards={deal}
                  />
                </div>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  className={classes.switches}
                >
                  <Grid item className={classes.switch}>
                    <SectionLabel
                      text='Dealer'
                    />
                    <div className={classes.dealer}>
                      <Dealer
                        dealer={dealer}
                        onClick={this.handleDealerClick}
                      />
                    </div>
                  </Grid>
                  <Grid item className={classes.switch}>
                    <SectionLabel
                      text='Vulnerability'
                    />
                    <div className={classes.vulnerability}>
                      <Vulnerability
                        pair='NS'
                        vulnerable={vulnerable === 'Both' || vulnerable === 'NS' ? true : false}
                        onClick={this.handleVulnerableClick}
                      />
                    </div>
                    <div className={classes.vulnerability}>
                      <Vulnerability
                        pair='EW'
                        vulnerable={vulnerable === 'Both' || vulnerable === 'EW' ? true : false}
                        onClick={this.handleVulnerableClick}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <div className={clsx(classes.gridContent, classes.deal)}>
                <SectionLabel
                  text='Deal'
                />
                <Deal
                  onSeatClick={this.handleSeatClick}
                  onCardClick={this.handleDealtCardClick}
                  selectable={true}
                  cards={deal}
                  activeDirection={activeSeat}
                  dealer={dealer}
                  vulnerable={vulnerable}
                  viewpoint={viewpoint}
                  scale={10}
                />
                <div className={classes.viewpoint}>
                  <Viewpoint
                    onClick={this.handleViewPointClick}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>

      </React.Fragment>
    );
  };
}

Step0.propTypes = {
  hand: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default withStyles(styles)(Step0);
