import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import { productQueries } from '../../../graphql/queries';
import AdminPage from '../../../Components/AdminPage.js';
import AddButton from '../../../Components/Forms/AddButton.js';
import { formatURLTitle } from '../../../helpers/http.js';
import { UserContext } from '../../../Context';

const AddButtons = ({ selected, addSetsToCollection, user }) => {
  const naviagte = useNavigate();

  const handleAddSetClick = () => {
    naviagte('/admin/products/set/add');
  };
  const handleAddCollectionClick = () => {
    naviagte('/admin/products/collection/add');
  };
  const handleNewCollectionFromSetsClick = () => {
    addSetsToCollection({
      variables: {
        setIds: JSON.stringify(selected)
      }
    }).then(response => {
      naviagte(`/admin/products/collection/${response.data.addSetsToCollection.id}/New+collection+from+selected+sets`);
    });
  };

  if (selected.length > 0) {
    return (
      <div>
        <AddButton
          onClick={handleNewCollectionFromSetsClick}
          text='New collection from sets'
        />
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: 'right', width: 350 }}>
        <AddButton
          onClick={handleAddSetClick}
          text='Add Set'
        />
        {
          user.type === 'Admin' || user.type === 'Editor'
            ? <AddButton
                onClick={handleAddCollectionClick}
                text='Add Collection'
              />
            : null
        }
      </div>
    );
  }
};

const Products = () => {
  const naviagte = useNavigate();
  const { user } = useContext(UserContext);
  const [selected, setSelected] = useState([]);

  const handleRowClick = item => {
    const title = formatURLTitle(item.title);
    naviagte(`/admin/products/${item.type.toLowerCase()}/${item.id}/${title}`);
  };

  const handleCheckboxClick = id => (e) => {
    e.stopPropagation();
    const thisSelected = _.cloneDeep(selected);
    const selectedIndex = selected.indexOf(id);
    if (selectedIndex === -1) {
      // add to selected
      thisSelected.push(id);
    } else {
      // remove from selected
      // selected.slice(0, selectedIndex);
      thisSelected.splice(selectedIndex, 1);
    }
    setSelected(thisSelected);
  };

  const columns = [
    { id: 'title', label: 'Title', sortColumn: 'title' },
    { id: 'type', label: 'Type', sortColumn: 'type' },
    { id: 'inCcollection', label: 'In Collection', type: 'boolean', condition: (item) => { return item.collectionSets[0] && item.collectionSets[0].collectionId !== null; }, sortColumn: 'collectionSets.collection_id' },
    { id: 'author.fullName', label: 'Author', sortColumn: 'author.last_name' },
    { id: 'dateCreated', label: 'Created', sortColumn: 'createdAt' },
    { id: 'onSale', label: 'On sale', sortColumn: 'onSale', type: 'boolean', width: '8rem' },
    { id: 'promoted', label: 'Promoted', sortColumn: 'promoted', type: 'boolean', width: '8rem' }
  ];
  if (user.type === 'Admin' || user.type === 'Editor') {
    columns.unshift({ id: 'checkbox', type: 'checkbox', onClick: handleCheckboxClick, condition: (item) => { return item.type === 'Set'; } });
  }
  return (
    <>
      <AdminPage
        columns={columns}
        query={productQueries.ProductSearchAdmin}
        rowsDataPath='productSearchAdmin.products'
        countDataPath='productSearchAdmin.count'
        onRowClick={handleRowClick}
        buttons={
          <Mutation mutation={mutations.AddSetsToCollection}>
            {addSetsToCollection => (
              <AddButtons
                selected={selected}
                addSetsToCollection={addSetsToCollection}
                user={user}
              />
            )}
          </Mutation>
        }
        initialOrderBy='id'
        initialOrderByDirection='desc'
        selected={selected}
        sectionName='products'
      />
    </>
  );
};

export default Products;
