import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import striptags from 'striptags';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Deal from '../../../../Components/Hands/Deal/Deal.js';
import PlayTable from '../../../../Components/Hands/PlayTable.js';
import TrickCounts from '../../../../Components/Hands/TrickCounts.js';
import UndoRedo from '../../../../Components/Hands/UndoRedo.js';
import Trick from '../../../../Components/Hands/Deal/Trick.js';
import Auction from '../../../../Components/Hands/Auction.js';
import Contract from '../../../../Components/Hands/Contract/Contract.js';
import SectionLabel from '../../../../Components/Hands/SectionLabel.js';
import CommentsModal from '../../../../Components/Hands/Comments/CommentsModal.js';
import RichText from '../../../../Components/Forms/RichText/RichText.js';
import PreviewTrickButton from './PreviewTrickButton.js';
import { getNextSeatForPlay } from '../../../../helpers/card.js';
import { noOfRowsInTable } from '../../../../helpers/auction.js';
import {
  firstToPlay,
  winnerOfHand,
  addWinnerToTrickCount,
  removeWinnerFromTrickCount,
  validSuitToPlay,
  flagWinningCard,
  markDealCardsAsPlayed,
  setCardToNotPlayedInDeal
} from '../../../../helpers/play.js';
import {
  getCommentsForCardOrBid,
  upsertPlayComments,
  getHeadingForPlay,
  isCommentForViewpointOrDummy
} from '../../../../helpers/comment.js';

const styles = theme => ({
  introPaper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bottomRow: {
    marginTop: theme.spacing(5)
  },
  auction: {
    marginRight: theme.spacing(3),
    fontSize: '.6em'
  },
  contract: {
    marginRight: theme.spacing(3)
  },
  tricks: {
    position: 'relative',
    left: 370,
    top: -42,
    fontSize: '.8rem'
  },
  buttons: {
    textAlign: 'center',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-2)
  },
  paper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  gridContent: {
    width: 520,
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  deal: {
    fontSize: 10
  },
  play: {
    width: 290
  }
});

class PlayGrid extends React.Component {
  state = {
    // slice() to copy array - https://stackoverflow.com/a/7486130
    localDeal: markDealCardsAsPlayed(
      this.props.hand.deal.slice(),
      this.props.hand.play.slice()
    ),
    redo: [],
    activeSeat: firstToPlay(this.props.hand.declarer),
    currentHand: [],
    selectable: true,
    currentComments: undefined,
    currentStep: undefined,
    currentRow: undefined,
    commentsForViewpointOrDummy: false,
    heading: '',
    playItem: null,
    dirtyComment: false,
    commentsOpen: false
  }

  handleDealtCardClick = (card) => {
    let { localDeal, activeSeat, currentHand, redo } = this.state;
    let { play, tricks, contract } = this.props.hand;

    // duplicate things to avoid updating them by reference
    localDeal = localDeal.slice();
    card = { ...card };
    currentHand = currentHand.slice();
    redo = redo.slice();

    // update card object in the localDeal array
    // so any subsequent updates to it will be stored in localDeal
    for (let i = 0; i < localDeal.length; i++) {
      const oldCard = localDeal[i];
      if (oldCard.suit === card.suit && oldCard.rank === card.rank) {
        localDeal[i] = card;
        break;
      }
    }

    // add step number to card object
    card.step = (play.length * 4) + (currentHand.length);

    // add value (suit + rank)
    card.value = `${card.suit}${card.rank}`;

    // check the card is from the activeSeat
    if (card.seat === activeSeat
       && validSuitToPlay(card, activeSeat, currentHand, localDeal)) {

      // mark card as played so it's grayed out
      card.played = true;

      // add card to current hand
      currentHand.push(card);

      if (redo.length > 0) {
        redo = [];
      }

      // work out the hand winner if there are 4 cards in the hand
      if (currentHand.length === 4) {
        const winner = winnerOfHand(currentHand, contract.denomination);
        // set winner flag for winning card
        currentHand = flagWinningCard(currentHand, winner);
        // set the active seat to the winner so they can play first
        activeSeat = winner;
        // update the tricks count
        tricks = addWinnerToTrickCount(tricks, winner);

        // DELAY THE MOVING OF THE TRICK TO PLAY
        setTimeout(() => {
          // add the trick to the play
          play.push(currentHand);
          // reset current hand
          currentHand = [];
          // pass the play and trick count out to parent
          this.props.onChange({
            play: play,
            tricks: tricks
          });
          // set the state for hand and seat
          this.setState({
            activeSeat: activeSeat,
            currentHand: currentHand,
            selectable: true
          });
        }, 1000);

        // call this so we see the winning hand before the delay
        this.setState({
          localDeal: localDeal,
          currentHand: currentHand,
          selectable: false,
          redo: redo
        });

      } else {
        // get next activeSeat
        activeSeat = getNextSeatForPlay(activeSeat);
        this.props.onChange({
          play: play
        });
        this.setState({
          localDeal: localDeal,
          activeSeat: activeSeat,
          currentHand: currentHand,
          redo: redo
        });
      }
    }
  }

  handleUndoClick = () => {
    let { redo, localDeal, currentHand } = this.state;
    let { play, contract, tricks } = this.props.hand;
    let activeSeat = null;
    
    // if current hand is empty
    // put last trick back in
    if (currentHand.length < 1) {
      const trick = play.pop();

      // remove winner from tricks count
      tricks = removeWinnerFromTrickCount(tricks, trick, contract.denomination);

      trick.forEach((play, index) => {
        // dont show last card
        if (index < 3) {
          play.winner = false;
          currentHand.push(play);
        } else {
          activeSeat = play.seat;
          
          // mark the card as not played so it can be selectable again 
          localDeal = setCardToNotPlayedInDeal(localDeal, play);

          redo.push(play);
        }
      });
    } else {
      // else remove last item from currenthand
      const lastPlay = currentHand.pop();
      
      // add last item to redo array
      redo.push(lastPlay);
      
      // mark the card as not played so it can be selectable again 
      localDeal = setCardToNotPlayedInDeal(localDeal, lastPlay);
      
      activeSeat = lastPlay.seat;
    }

    this.setState({
      redo: redo,
      localDeal: localDeal,
      activeSeat: activeSeat,
      currentHand: currentHand
    });

    this.props.onChange({
      play: play,
      tricks: tricks
    });
  }

  handleRedoClick = () => {
    let { redo } = this.state;
    // remove last item from redo list
    const lastPlay = redo.pop();
    lastPlay.winner = false;
    this.handleDealtCardClick(lastPlay);
    this.setState({
      redo: redo
    });
  };

  handlePlayItemClick = (playItem, row) => {
    const { viewpoint, dummy, declarer } = this.props.hand;
    const { dirtyComment } = this.state;
    const { onUnavedComment } = this.props;

    if (dirtyComment) {
      onUnavedComment();
    } else {
      // we need to know the seat of the bid so we can compare it against the
      // viewpoint, or dummy if viewpoint is declarer
      // if seat === viewpoint or viewpoint === d show all 3 comment types
      const commentsForViewpointOrDummy = isCommentForViewpointOrDummy(
        playItem.seat, viewpoint, dummy, declarer
      );
      const currentComments = getCommentsForCardOrBid(playItem);
      this.setState({
        playItem: playItem,
        currentStep: playItem.step,
        currentRow: row,
        commentsForViewpointOrDummy: commentsForViewpointOrDummy,
        currentComments: currentComments,
        heading: getHeadingForPlay(playItem, row),
        commentsOpen: true
      });
    }
  };

  handleInputChange = (input) => {
    let { playIntro } = this.props.hand;
    playIntro = input.value;
    this.props.onChange({
      playIntro: playIntro
    });
  };

  handleCommentChange = (input) => {
    const currentComments = this.state.currentComments;
    currentComments[input.name] = input.value;
    this.setState({ currentComments, dirtyComment: true  });
  };

  handleAddCommentClick = () => {
    const { onChange, onCommentsSaved } = this.props;
    let { play } = this.props.hand;
    const { currentComments, currentStep, commentsForViewpointOrDummy } = this.state;

    // add stepComments to play
    play = upsertPlayComments(play, currentStep, currentComments);

    onChange({
      play: play
    });
    onCommentsSaved(commentsForViewpointOrDummy);
    this.setState({ dirtyComment: false, playItem: null, commentsOpen: false });
  };

  handleDeleteCommentClick = () => {
    const { onChange } = this.props;
    let { play } = this.props.hand;
    const { currentStep } = this.state;

    // delete comments from auction
    play = upsertPlayComments(play, currentStep, undefined);

    onChange({
      play: play
    });
    this.setState({ dirtyComment: false, currentBid: undefined, commentsOpen: false });
  };

  handleCancelCommentClick = () => {
    this.setState({
      currentComments: undefined,
      dirtyComment: false,
      playItem: null,
      commentsOpen: false
    });
  };

  handleIntroInputChange = (input) => {
    let { playIntro } = this.props.hand;
    playIntro = input.value;
    this.props.onChange({
      playIntro: playIntro
    });
  };

  render () {
    const {
      playOnly,
      auctionOnly,
      auction,
      contract,
      declarer,
      dealer,
      vulnerable,
      viewpoint,
      play,
      tricks,
      playIntro
    } = this.props.hand;
    const {
      classes,
      showPreview,
      onPreviewClick
    } = this.props;
    const {
      localDeal,
      activeSeat,
      redo,
      currentHand,
      selectable,
      heading,
      commentsForViewpointOrDummy,
      currentComments,
      playItem,
      commentsOpen
    } = this.state;
    const paperHeight = 700 + (noOfRowsInTable(auction) * 45);
    if (auctionOnly) {
      return null;
    } else {
      return (
        <>
          <Paper className={classes.introPaper}>
            <RichText
              name='introduction'
              value={playIntro}
              label='Pre-play introduction'
              onChange={this.handleIntroInputChange}
              invalid={striptags(playIntro).length === 0}
            />
          </Paper>
          {
            playItem !== null
            ?
            <CommentsModal
              onCancelClick={this.handleCancelCommentClick}
              onSubmitClick={this.handleAddCommentClick}
              onDeleteClick={this.handleDeleteCommentClick}
              onCommentChange={this.handleCommentChange}
              heading={heading}
              commentsForViewpointOrDummy={commentsForViewpointOrDummy}
              comments={currentComments}
              card={playItem}
              open={commentsOpen}
            />
            :
            null
          }
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs={6}>
              <Paper className={classes.paper} style={{height: paperHeight}}>
                <div className={classes.gridContent}>
                  <SectionLabel
                    text='Deal'
                  />
                  <div className={classes.deal}>
                    <Deal
                      onCardClick={this.handleDealtCardClick}
                      cards={localDeal}
                      activeDirection={activeSeat}
                      dealer={dealer}
                      vulnerable={vulnerable}
                      viewpoint={viewpoint}
                      expanded={true}
                      currentHand={currentHand}
                      selectable={selectable}
                      allPlayed={play.length === 13}
                    >
                      <Trick
                        scale={10}
                        trick={currentHand}
                        viewpoint={viewpoint}
                      />
                    </Deal>
                  </div>
                  <span className={classes.tricks}>
                    <TrickCounts
                      tricks={tricks}
                    />
                  </span>
                  <div className={classes.buttons}>
                    <UndoRedo
                      undoDisabled={play.length === 0 && currentHand.length === 0}
                      redoDisabled={redo.length === 0 || currentHand.length === 4}
                      onUndoClick={this.handleUndoClick}
                      onRedoClick={this.handleRedoClick}
                    />
                  </div>
                  <Grid
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='flex-start'
                      className={classes.bottomRow}
                    >
                      {
                        !playOnly
                        ?
                        <Grid className={classes.auction}>
                          <SectionLabel
                            text='Auction'
                          />
                          <Auction
                            dealer={dealer}
                            auction={auction}
                          />
                        </Grid>
                        :
                        null
                      }
                      <Grid className={classes.contract}>
                        <SectionLabel
                          text='Contract'
                        />
                        <Contract
                          contract={contract}
                          declarer={declarer}
                        />
                      </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper} style={{height: paperHeight}}>
                <div className={clsx(classes.play, classes.gridContent)}>
                  <SectionLabel
                    text='Play (click a card to add a comment)'
                  />
                  <PlayTable
                    play={play}
                    dealer={dealer}
                    declarer={declarer}
                    rowAction={(rowIndex) => {
                      if (!showPreview) {
                        return null;
                      }

                      return (
                        <PreviewTrickButton
                          rowIndex={rowIndex}
                          onClick={onPreviewClick}
                        />
                      );
                    }}
                    onPlayItemClick={this.handlePlayItemClick}
                    size='small'
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </>
      );
    }
  }
}

PlayGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  hand: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onUnavedComment: PropTypes.func.isRequired,
  onCommentsSaved: PropTypes.func.isRequired,
  showPreview: PropTypes.bool,
  onPreviewClick: PropTypes.func
}

export default withStyles(styles)(PlayGrid);
