import React from 'react';
import {
  Drawer,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import GreyLine from '../Store/GreyLine';
import { useTextTranslation } from '../../Hooks';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 375,
    maxWidth: '100%',
    backgroundColor: '#FAFAFA',
    [theme.breakpoints.down(480)]: {
      width: '100%'
    }
  }
}));

const BBDrawer = (props) => {
  const { open, onClose, heading, children } = props;

  const classes = useStyles();
  const translatedHeading = useTextTranslation(heading);

  return (
    <Drawer
      open={open}
      anchor='right'
      onClose={onClose}
      classes={{
        paper: classes.paper
      }}
    >
      <IconButton
        aria-label='Close'
        onClick={onClose}
        style={{ position: 'fixed', top: 10, right: 10, zIndex: 3 }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ padding: '2rem' }}>
        <Typography variant='h2'>{translatedHeading}</Typography>
        <GreyLine />
        {children}
      </Box>
    </Drawer>
  );
};

export default BBDrawer;
