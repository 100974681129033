import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BBTableHeadCell from './BBTableHeadCell.js';

const BBTableHead = (props) => {
  const { columns } = props;
  return (
    <TableHead>
      <TableRow>
        {columns.map(
          column => (
            <BBTableHeadCell key={column.id} column={column} {...props} />
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
};

BBTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onColumnSort: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired
};

export default BBTableHead;
