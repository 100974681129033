import React, { Component } from 'react';
import { logout } from '../../../helpers/auth.js';
import BBLinearProgress from '../../../Components/BBLinearProgress.js';

class Logout extends Component {
  constructor (props) {
    super(props);
    logout();
    window.location = '/';
  }

  render () {
    return <BBLinearProgress />;
  }
}

export default Logout;
