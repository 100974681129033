import _ from 'lodash';
import { fetchData } from './global.js';
import {
  writeCookie,
  readCookie,
  deleteCookie
} from '../helpers/cookie.js';

const env = process.env.REACT_APP_ENV;
const localeCookieName = `bb-locale-${env}`;

const manageLocaleCookie = (action, locale) => {
  switch (action) {
    case 'write':
      writeCookie(localeCookieName, locale);
      break;
    case 'read':
      return readCookie(localeCookieName);
    case 'delete':
      deleteCookie(localeCookieName);
      break;
    default:
      throw new Error('cookie action not found');
  }
};

const convertLanguageCodeToFullWord = (langCode) => {
  if (langCode === 'nlNL') {
    return 'Dutch';
  } else {
    return 'English';
  }
};

const convertFullWordToLanguageCode = (language) => {
  if (language === 'Dutch') {
    return 'nlNL';
  } else {
    return 'enGB';
  }
};

const convertLangCodeToGraphqlEnum = (language) => {
  if (language === 'en-gb' || language === 'nl-nl') {
    const sections = language.split('-');
    return `${sections[0]}${sections[1].toUpperCase()}`;
  }
};

const convertGraphQLLanguageName = (language) => {
  const fileNamePart1 = language.substring(0, 2);

  const fileNamePart2 = language
    .substring(2)
    .toLowerCase();

  return `${fileNamePart1}-${fileNamePart2}`;
};

const selectLanguageBasedOnLocation = countryCode => {
  if (countryCode === 'NL') {
    return 'nl-nl';
  } else {
    return 'en-gb';
  }
};

const getTranslatedTextFromFile = (languageFile, textId) => {
  const textItem = _.find(languageFile, ['id', textId]);
  if (textItem) {
    return textItem.text;
  } else {
    return textId;
  }
};

const fetchLocaleDataBasedOnIP = async () => {
  try {
    // set memoized function as proprty on function (functions are objects)
    // so it becomes static and maintains its value between function calls
    if (!fetchLocaleDataBasedOnIP.memoized) {
      fetchLocaleDataBasedOnIP.memoized = _.memoize(fetchData);
    }
    return await fetchLocaleDataBasedOnIP.memoized('https://ipapi.co/json/');
  } catch (err) {
    console.log('err', err);
  }
};

const selectCurrencyBasedOnLocation = (countryCode, continentCode) => {
  switch (continentCode) {
    case 'EU':
      if (countryCode === 'GB') {
        return 'GBP';
      }
      return 'EUR';
    case 'NA':
      if (countryCode === 'CA') {
        return 'CAD';
      }
      return 'USD';
    case 'OC':
      if (countryCode === 'NZ') {
        return 'NZD';
      }
      return 'AUD';
    default:
      return 'USD';
  }
};

export {
  manageLocaleCookie,
  convertLanguageCodeToFullWord,
  convertFullWordToLanguageCode,
  convertLangCodeToGraphqlEnum,
  convertGraphQLLanguageName,
  selectLanguageBasedOnLocation,
  getTranslatedTextFromFile,
  fetchLocaleDataBasedOnIP,
  selectCurrencyBasedOnLocation
};
