import gql from 'graphql-tag';
import { userFragments } from './fragments';

const mutations = {
  // AUTHORS
  UpsertAuthor: gql`
    mutation(
      $id: Int,
      $firstName: String!,
      $lastName: String!,
      $bio: String!,
      $avatarPath: String!,
      $backgroundImagePath: String,
      $links: [AuthorLinkInput]
      $royaltyPercentage: Int,
      $language: LanguageEnum
    ) {
      upsertAuthor(
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        bio: $bio,
        avatarPath: $avatarPath,
        backgroundImagePath: $backgroundImagePath,
        links: $links,
        royaltyPercentage: $royaltyPercentage,
        language: $language
      ) {
        id,
        firstName,
        lastName,
        fullName,
        fullNameAdmin,
        avatarPath,
        backgroundImagePath,
        language
      }
    }
    `,
  // COLLECTIONS
  AddSetsToCollection: gql`
    mutation(
      $setIds: JSON!
    ) {
      addSetsToCollection(
        setIds: $setIds
      ) {
        id
      }
    }`,
  AddSetToCollection: gql`
    mutation(
      $collectionId: Int!,
      $setId: Int!
    ) {
      addSetToCollection(
        collectionId: $collectionId,
        setId: $setId
      ) {
        id
      }
    }`,
  DeleteSetFromCollection: gql`
    mutation(
      $collectionId: Int!,
      $setId: Int!
    ) {
      deleteSetFromCollection(
        collectionId: $collectionId,
        setId: $setId
      ) {
        id
      }
    }`,
  ReorderCollection: gql`
    mutation(
      $collectionId: Int!,
      $setId: Int!,
      $direction: String!
    ) {
      reorderCollection(
        collectionId: $collectionId,
        setId: $setId,
        direction: $direction
      ) {
        id
        collectionSets {
          id
          product {
            id
            title
            author {
              id
              fullName
            }
          }
        }
      }
    }
    `,
  // GIFTS
  CreateGift: gql`
    mutation(
      $purchaseId: Int!,
      $message: String
    ) {
      createGift (
        purchaseId: $purchaseId,
        message: $message,
      ) {
        id
      }
    }
    `,
  CreateGiftRecipient: gql`
    mutation(
      $productId: Int,
      $toEmail: String!,
      $toFirstName: String,
      $toLastName: String
    ) {
      createGiftRecipient (
        productId: $productId,
        toEmail: $toEmail,
        toFirstName: $toFirstName,
        toLastName: $toLastName
      ) {
        toId,
        toFirstName,
        toEmail
      }
    }
    `,
  CreateGiftPurchaser: gql`
    mutation(
      $fromEmail: String,
      $fromFirstName: String,
      $fromLastName: String
    ) {
      createGiftPurchaser (
        fromEmail: $fromEmail,
        fromFirstName: $fromFirstName,
        fromLastName: $fromLastName
      ) {
        fromId
        fromEmail,
        fromFirstName,
        fromLastName,
        fromStripeCustomerId
      }
    }
    `,
  ResendGiftEmail: gql`
    mutation(
      $purchaseItemId: Int
    ) {
      resendGiftEmail (
        purchaseItemId: $purchaseItemId
      ) {
        fromId
      }
    }
    `,
  // HANDS
  UpsertHand: gql`
    mutation(
      $id: Int,
      $productId: Int,
      $title: String,
      $status: HandStatusEnum,
      $freeToPlay: Boolean,
      $handJSON: JSON
    ) {
      upsertHand(
        id: $id,
        productId: $productId,
        title: $title,
        status: $status,
        freeToPlay: $freeToPlay,
        handJSON: $handJSON
      ) {
        id
        title
      }
    }
    `,
  DeleteHand: gql`
    mutation(
      $id: Int
    ) {
      deleteHand(id: $id) {
        id
      }
    }
    `,
  ReorderHand: gql`
    mutation(
      $id: Int,
      $direction: String
    ) {
      reorderHand(id: $id, direction: $direction) {
        id
        pbnId
        title
        status
        orderBy
      }
    }
    `,
  DuplicateHands: gql`
    mutation(
      $setId: Int!,
      $hands: [HandInput]!
    ) {
      duplicateHands(setId: $setId, hands: $hands) {
        id
      }
    }
  `,
  // PAYMENT METHODS
  AttachPaymentMethod: gql`
    mutation (
      $id: String!,
      $stripeCustomerId: String!
    ) {
      attachPaymentMethod (
        id: $id,
        stripeCustomerId: $stripeCustomerId
      ) {
        id
      }
    } 
  `,
  DetachPaymentMethod: gql`
    mutation (
      $id: String!
    ) {
      detachPaymentMethod (
        id: $id
      ) {
        id
      }
    } 
  `,
  // PURCHASES
  CreatePurchase: gql`
    mutation (
      $currency: CurrencyEnum,
      $status: PurchaseStatusEnum,
      $cart: [CartInput],
      $voucherId: Int,
      $user: UserInput,
      $savePaymentMethod: Boolean,
      $paymentType: PaymentTypeEnum
    ) {
      createPurchase (
        currency: $currency,
        status: $status,
        cart: $cart,
        voucherId: $voucherId,
        user: $user,
        savePaymentMethod: $savePaymentMethod,
        paymentType: $paymentType
      ) {
        id
        status
        paymentIntent
      }
    }`,
  CompletePurchase: gql`
    mutation (
      $id: Int!
    ) {
      completePurchase (
        id:$id
      ) {
        id
        status
      }
    }`,
  FailPurchase: gql`
    mutation (
      $id: Int!,
      $failureMessage: String
    ) {
      failPurchase (
        id:$id,
        failureMessage:$failureMessage
      ) {
        id
        status
      }
    }`,
  RefundPurchase: gql`
    mutation(
      $id: Int!
    ) {
      refundPurchase (
        id: $id
      ) {
        id
        stripeId
        currency
        totalPrice
        purchaseDate
        refundDate
        status
      }
    }`,
  // PRODUCTS
  UpsertProduct: gql`
    mutation(
      $id: Int
      $type: ProductTypeEnum!
      $authorId: Int
      $title: String!
      $description: String!
      $language: LanguageEnum
      $onSale: Boolean!
      $productLinksEnabled: Boolean
      $promoted: Boolean!
      $tags: [TagInput]
      $priceId: Int!
      $images: JSON
    ) {
      upsertProduct(
        id: $id
        type: $type
        authorId: $authorId
        title: $title
        description: $description
        language: $language
        onSale: $onSale
        productLinksEnabled: $productLinksEnabled
        promoted: $promoted
        tags: $tags
        priceId: $priceId
        images: $images
      ) {
        id
        type
        authorId
        title
        description
        onSale
        productLinksEnabled
        promoted
        images
        priceId
        dateCreated
      }
    }
    `,
  DeleteProduct: gql`
    mutation(
      $id: Int
    ) {
      deleteProduct(id: $id) {
        id
      }
    }
    `,
  DuplicateProduct: gql`
    mutation(
      $id: Int
    ) {
      duplicateProduct(id: $id) {
        id
      }
    }
    `,
  // PRODUCT LINKS
  CreateProductLink: gql`
    mutation(
      $productId: Int!
    ) {
      createProductLink(productId: $productId) {
        id
      }
    }
  `,
  DeleteProductLink: gql`
    mutation(
      $id: Int!
    ) {
      deleteProductLink(id: $id) {
        id
      }
    }
  `,
  // TAGS
  UpsertTag: gql`
    mutation(
      $id: Int
      $name: String!
      $tagGroupId: Int
    ) {
      upsertTag(
        id: $id
        name: $name
        tagGroupId: $tagGroupId
      ) {
        id
      }
    }
    `,
  DeleteTag: gql`
    mutation(
      $id: Int
    ) {
      deleteTag(id: $id) {
        id
      }
    }
    `,
  // USERS
  UpdateUser: gql`
    mutation(
      $id: Int!,
      $firstName: String!,
      $lastName: String!,
      $email: String!,
      $password: String,
      $type: UserTypeEnum!,
      $stripePaymentMethodId: String,
      $marketingPrefs: JSON,
      $filterSettings: JSON,
      $tags: [TagInput],
      $confirmed: Boolean,
      $currency: CurrencyEnum,
      $language: LanguageEnum
    ) {
      updateUser(
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        password: $password,
        type: $type,
        stripePaymentMethodId: $stripePaymentMethodId,
        marketingPrefs: $marketingPrefs,
        filterSettings: $filterSettings,
        tags: $tags
        confirmed: $confirmed,
        currency: $currency,
        language: $language
      ) {
        ...FullUser
      }
    }
    ${userFragments.fullUser}
    `,
  UpdatePassword: gql`
    mutation(
      $id: Int!,
      $firstName: String,
      $email: String,
      $password: String
    ) {
      updatePassword(
        id: $id,
        firstName: $firstName,
        email: $email,
        password: $password
      ) {
        id
      }
    }
    `,
  UpsertUserHand: gql`
    mutation(
      $handId: Int!,
      $status: UserHandStatusEnum,
      $step: String
    ) {
      upsertUserHand(
        handId: $handId,
        status: $status,
        step: $step
      ) {
        id
      }
    }
    `,
  LoginUser: gql`
    mutation($email: String!, $password: String!) {
      loginUser(email: $email, password: $password) {
        ...FullUser,
        token
      }
    }
    ${userFragments.fullUser}
    `,
  ImpersonateUser: gql`
    mutation($userId: Int!) {
      impersonateUser(userId: $userId) {
        id
        token
      }
    }
    `,
  DontImpersonateUser: gql`
    mutation {
      dontImpersonateUser {
        id
        token
        language
        currency
      }
    }
    `,
  RegisterUser: gql`
    mutation(
      $firstName: String!,
      $lastName: String!,
      $email: String!,
      $password: String!,
      $currency: CurrencyEnum
      $language: LanguageEnum,
      $captchaVerificationKey: String!
    ) {
      registerUser(
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        password: $password
        currency: $currency
        language: $language,
        captchaVerificationKey: $captchaVerificationKey
      ) {
        ...FullUser,
        token
      }
    }
    ${userFragments.fullUser}
    `,
  ActivateUser: gql`
    mutation(
      $id: Int!,
      $password: String!,
      $currency: CurrencyEnum
    ) {
      activateUser(
        id: $id,
        password: $password,
        currency: $currency
      ) {
        id
        fullName
        token
      }
    }
    `,
  ResetPassword: gql`
    mutation(
      $email: String!
    ) {
      resetPassword(
        email: $email
      ) {
        id
      }
    }
    `,
  UpdateUserTags: gql`
    mutation(
      $id: Int!,
      $tags: [TagInput]
    ) {
      updateUserTags(
        id: $id,
        tags: $tags
      ) {
        id
        tags
      }
    }`,
  ContactUs: gql`
    mutation(
      $firstName: String!,
      $lastName: String!,
      $email: String!,
      $phone: String,
      $message: String!
    ) {
      contactUs(
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        phone: $phone,
        message: $message
      ) {
        id
      }
    }
  `,
  // VOUCHERS
  CreateVoucher: gql`
    mutation(
      $code: String!,
      $discountAmount: Int!,
      $activationDate: String,
      $expiryDate: String,
      $productId: Int,
      $totalRedemptions: Int
    ) {
      createVoucher(
        code: $code,
        discountAmount: $discountAmount,
        activationDate: $activationDate,
        expiryDate: $expiryDate,
        productId: $productId,
        totalRedemptions: $totalRedemptions
      ) {
        id
      }
    }
  `,
  UpdateVoucher: gql`
    mutation(
      $id: Int!,
      $discountAmount: Int!,
      $activationDate: String,
      $expiryDate: String,
      $productId: Int
    ) {
      updateVoucher(
        id: $id,
        discountAmount: $discountAmount,
        activationDate: $activationDate,
        expiryDate: $expiryDate,
        productId: $productId
      ) {
        id
      }
    }
  `,
  RedeemVoucher: gql`
    mutation(
      $code: String!
    ) {
      redeemVoucher(
        code: $code
      ) {
        id
        product {
          id
        }
        code
        discountAmount
      }
    }`
};

// HOW TO - Example write to cache after Mutation update

// const [updateUser] = useMutation(mutations.UpdateUser, {
//   update: (cache, { data: { updateUser } }) => {
//     const { todos } = cache.readQuery({ query: GET_TODOS });
//     console.log('updateUser - update', updateUser);
//     cache.writeQuery({
//       query: queries.CurrentUser,
//       data: { currentUser: updateUser }
//     });
//     onLanguageSelected(updateUser);
//   }
// });

export default mutations;
