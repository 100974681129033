import React from 'react';
import { authorQueries } from '../../../graphql/queries';
import AdminPage from '../../../Components/AdminPage.js';
import AuthorDetails from './AuthorDetails.js';
import AddAuthor from './AddAuthor.js';

const Authors = () => {
  const columns = [
    { id: 'fullNameAdmin', label: 'Name', sortColumn: 'lastName', isTitle: true },
    { id: 'dateCreated', label: 'Created', sortColumn: 'createdAt' }
  ];
  return (
    <>
      <AdminPage
        columns={columns}
        query={authorQueries.AuthorSearch}
        initialOrderBy='lastName'
        rowsDataPath='authorSearch.authors'
        countDataPath='authorSearch.count'
        showAddButton
        sectionName='authors'
      >
        {
          (id, onSubmitSuccess, onCancelClick, refetch, openSnackbar) => (
            <>
              {
              id && id > 0
                ? <AuthorDetails
                    id={id}
                    onCancelClick={onCancelClick}
                    onSubmitSuccess={(message) => {
                      openSnackbar(message, 'success');
                      onSubmitSuccess(refetch);
                    }}
                    onSubmitError={(message) => {
                      openSnackbar(message, 'error');
                    }}
                  />
                : <AddAuthor
                    onCancelClick={onCancelClick}
                    onSubmitSuccess={(message) => {
                      openSnackbar(message, 'success');
                      onSubmitSuccess(refetch);
                    }}
                    onSubmitError={(message) => {
                      openSnackbar(message, 'error');
                    }}
                  />
            }
            </>
          )
        }
      </AdminPage>
    </>
  );
};

export default Authors;
