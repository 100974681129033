import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import PasswordForm from '../../../Components/Forms/PasswordForm.js';
import UserByToken from '../../../Components/Queries/UserByToken.js';
import SuccessStep from '../../../Components/SuccessStep.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import TwoColumn from '../../../Components/Layout/TwoColumn';
import NiceError from '../../../Components/Errors/NiceError';

const ResetPassword = () => {
  const { token } = useParams();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const handleSubmitSuccess = () => {
    setMessage('youre-password-has-been-updated');
  };

  const handleSubmitError = err => {
    setError(err);
  };

  // get the user by the token
  return (
    <TwoColumn
      title='reset-password'
      description={
        <>
          <p><BBTranslate textId='please-choose-a-new-password' /></p>
        </>
      }
      form={
        <UserByToken token={token}>
          {user => (
            <>
              {
                message !== ''
                  ? (
                    <div style={{ width: 460 }}>
                      <SuccessStep
                        heading='Success!'
                        text={message}
                      />
                    </div>
                    )
                  : (
                    <>
                      <NiceError err={error} defaultText='resetting-your-password' />
                      <Typography variant='body1' sx={{ marginBottom: '2rem' }}>
                        {user.firstName}, <BBTranslate textId='you-requested-to-reset-password' />
                      </Typography>
                      <Mutation
                        mutation={mutations.UpdatePassword}
                      >
                        {updateUser => (
                          <>
                            <PasswordForm
                              onSubmitSuccess={handleSubmitSuccess}
                              onSubmitError={handleSubmitError}
                              user={user}
                            />
                          </>
                        )}
                      </Mutation>
                    </>
                    )
              }
            </>
          )}
        </UserByToken>
    }
    />
  );
};

export default ResetPassword;
