// https://itnext.io/understanding-the-react-context-api-through-building-a-shared-snackbar-for-in-app-notifications-6c199446b80c

import React from 'react';
import BBSnackbar from './BBSnackbar.js';

const BBSnackbarContext = React.createContext();

export class BBSnackbarProvider extends React.Component {

  state = {
    isOpen: false,
    message: '',
    type: ''
  };

  openSnackbar = (message, type) => {
    if (this.state.message !== message) {
      this.setState({
        message,
        type,
        isOpen: true,
      });
    }
  };

  closeSnackbar = () => {
    this.setState({
      message: '',
      type: '',
      isOpen: false,
    });
  };

  render() {
    const { children } = this.props;
    const { isOpen, message, type } = this.state;
    return (
      <BBSnackbarContext.Provider
        value={{
          openSnackbar: this.openSnackbar,
          closeSnackbar: this.closeSnackbar,
          isOpen: isOpen,
          message: message,
          type: type
        }}
      >
        <BBSnackbar />
        {children}
      </BBSnackbarContext.Provider>
    );
  }
}

const BBSnackbarConsumer = BBSnackbarContext.Consumer

export { BBSnackbarContext, BBSnackbarConsumer }
