import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { mutations } from '../../../../graphql';
import Hand from './Hand.js';
import HooksWrapper from '../../../../Components/HooksWrapper.js';
import {
  UserContext,
  CartContext,
  DrawerContext
} from '../../../../Context';

const HandDetails = (props) => {
  const {
    collection,
    set,
    hand,
    ...other
  } = props;

  const { user } = useContext(UserContext);
  const { addToCart, removeFromCart, checkIfInCart } = useContext(CartContext);
  const { openDrawer } = useContext(DrawerContext);

  // we need to pass navigate into Hand.js to save us from turning Hand.js into a functional component
  const navigate = useNavigate();

  const [upsertUserHand] = useMutation(mutations.UpsertUserHand, {
    ignoreResults: true
  });

  const handleStepTaken = (type, step) => {
    if (user) {
      upsertUserHand({
        variables: {
          handId: hand.id,
          step: `${type}_${step}`,
          status: 'PartPlayed'
        }
      });
    }
  };

  const handleHandComplete = () => {
    if (user) {
      upsertUserHand({
        variables: {
          userId: user.id,
          handId: hand.id,
          step: 'auction_0',
          status: 'Played'
        }
      });
    }
  };

  const product = collection || set;
  const isInCart = checkIfInCart(product);

  const handleRemoveFromCartClick = () => {
    removeFromCart(product.id);
    openDrawer('cart');
  };

  const handlePurchaseClick = () => {
    addToCart(product);
    openDrawer('cart');
  };

  return (
    <HooksWrapper>
      {({ isPortrait, componentScale, screenDimensions, aspectRatio }) => (
        <>
          <Hand
            isPortrait={isPortrait}
            componentScale={componentScale}
            screenDimensions={screenDimensions}
            aspectRatio={aspectRatio}
            key={hand.id} // required to remount
            collection={collection}
            set={set}
            hand={hand}
            user={user}
            isPreview={false}
            onStepTaken={handleStepTaken}
            onHandComplete={handleHandComplete}
            onRemoveFromCartClick={handleRemoveFromCartClick}
            onPurchaseClick={handlePurchaseClick}
            isInCart={isInCart}
            {...other}
            startStep={
              hand.userHands && hand.userHands.length > 0
                ? hand.userHands[0].step
                : null
            }
            navigate={navigate}
          />
        </>
      )}
    </HooksWrapper>
  );
};

HandDetails.propTypes = {
  collection: PropTypes.object,
  set: PropTypes.object.isRequired,
  hand: PropTypes.object.isRequired,
  linkId: PropTypes.string,
  isFinalHandInProduct: PropTypes.bool,
  onPlayNextClick: PropTypes.func.isRequired
};

export default HandDetails;
