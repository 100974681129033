import React from 'react';
import PropTypes from 'prop-types';
import { priceQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class Prices extends React.Component {
  render () {
    const { children } = this.props;
    return (
      <BBQuery query={priceQueries.Prices}>
        {data => {
          return children(data.prices);
        }}
      </BBQuery>
    );
  }
}

Prices.propTypes = {
  children: PropTypes.func.isRequired
};

export default Prices;
