import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch, Backdrop, Slide, BottomNavigation, BottomNavigationAction } from '@mui/material';
import ConfirmModal from './ConfirmModal.js';
import BackToSetIcon from '@mui/icons-material/ListRounded';
import RestartHandIcon from '@mui/icons-material/ReplayRounded';
import SummaryIcon from '@mui/icons-material/SkipNextRounded';
import GuidanceIsOnIcon from '@mui/icons-material/SpeakerNotesRounded';
import GuidanceIsOffIcon from '@mui/icons-material/SpeakerNotesOffRounded';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    top: '5rem',
    zIndex: 1000,
    textAlign: 'center',
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)'
    // background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 2%, rgba(0,0,0,0.25) 100%)'
    // background: 'transparent'
  },
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    background: '#232a2c',
    boxShadow: '0 0 2rem rgba(0,0,0,0.5)'
  },
  button: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '3rem',
    cursor: 'pointer',
    background: '#232a2c',
    color: '#fff'
  },
  buttonOpen: {
    boxShadow: '0 0 2rem rgba(0,0,0,0.5)'
  },
  buttonClose: {
    background: theme.palette.styleguide.yellow,
    color: '#232a2c'
  },
  bottomNav: {
    background: '#232a2c'
  },
  bottomNavButton: {
    color: '#fff'
  }
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MobileMenu = (props) => {
  const {
    onReturnToProductClick,
    onRestartClick,
    onSkipToSummaryClick,
    onShowPopupGuidanceClick,
    showPopupGuidance,
    onMenuCloseClick,
    onMenuOpenClick,
    open
  } = props;
  const classes = useStyles();

  const handleModalClose = () => {
    setReturnToSetModalOpen(false);
    setRestartHandModalOpen(false);
    setSkipToSummaryModalOpen(false);
    setGuidanceModalOpen(false);
  };

  const [returnToSetModalOpen, setReturnToSetModalOpen] = React.useState(false);
  const handleReturnToSetModalOpen = () => {
    setReturnToSetModalOpen(true);
  };
  const handleReturnToProductClick = () => {
    onReturnToProductClick();
    handleModalClose();
  };

  const [restartHandModalOpen, setRestartHandModalOpen] = React.useState(false);
  const handleRestartHandModalOpen = () => {
    setRestartHandModalOpen(true);
  };
  const handleRestartHandClick = () => {
    onRestartClick();
    handleModalClose();
  };

  const [skipToSummaryModalOpen, setSkipToSummaryModalOpen] = React.useState(false);
  const handleSkipToSummaryModalOpen = () => {
    setSkipToSummaryModalOpen(true);
  };
  const handleSkipToSummaryClick = () => {
    onSkipToSummaryClick();
    handleModalClose();
  };

  const [guidanceModalOpen, setGuidanceModalOpen] = React.useState(false);
  const handleGuidanceModalOpen = () => {
    setGuidanceModalOpen(true);
  };

  return (
    <>
      <div className={clsx(classes.button, classes.buttonOpen)} onClick={onMenuOpenClick}>
        Show Menu
      </div>
      <Backdrop
        open={open}
        TransitionComponent={Transition}
        className={classes.backdrop}
        onClick={onMenuCloseClick}
      >
        <div className={classes.root}>
          <div className={clsx(classes.button, classes.buttonClose)} onClick={onMenuCloseClick}>
            Hide Menu
          </div>
          <BottomNavigation
            className={classes.bottomNav}
            showLabels
          >
            <BottomNavigationAction
              className={classes.bottomNavButton}
              icon={<BackToSetIcon />}
              label='Back to Set'
              onClick={handleReturnToSetModalOpen}
              showLabel
            />
            <BottomNavigationAction
              className={classes.bottomNavButton}
              icon={<RestartHandIcon />}
              label='Restart'
              onClick={handleRestartHandModalOpen}
              showLabel
            />
            <BottomNavigationAction
              className={classes.bottomNavButton}
              icon={<SummaryIcon />}
              label='Summary'
              onClick={handleSkipToSummaryModalOpen}
              showLabel
            />
            <BottomNavigationAction
              className={classes.bottomNavButton}
              icon={showPopupGuidance ? <GuidanceIsOnIcon /> : <GuidanceIsOffIcon />}
              label='Guidance'
              onClick={handleGuidanceModalOpen}
              showLabel
            />
          </BottomNavigation>
        </div>
      </Backdrop>
      <ConfirmModal
        title='Back to Set'
        onConfirmClick={handleReturnToProductClick}
        onCancelClick={handleModalClose}
        open={returnToSetModalOpen}
      >
        Do you want to return to the set? You will be directed away from this hand.
      </ConfirmModal>
      <ConfirmModal
        title='Restart Hand'
        onConfirmClick={handleRestartHandClick}
        onCancelClick={handleModalClose}
        open={restartHandModalOpen}
      >
        Do you want to restart this hand? All progress will be lost.
      </ConfirmModal>
      <ConfirmModal
        title='Skip to Summary'
        onConfirmClick={handleSkipToSummaryClick}
        onCancelClick={handleModalClose}
        open={skipToSummaryModalOpen}
      >
        Do you want to skip to the summary?
      </ConfirmModal>
      <ConfirmModal
        title='Show or Hide Guidance'
        onConfirmClick={handleModalClose}
        onCancelClick={handleModalClose}
        open={guidanceModalOpen}
      >
        <FormControlLabel
          label='Show Popup Guidance'
          control={
            <Switch
              checked={showPopupGuidance}
              onChange={onShowPopupGuidanceClick}
            />
          }
        />
      </ConfirmModal>
    </>
  );
};

MobileMenu.propTypes = {
  onReturnToProductClick: PropTypes.func.isRequired,
  onRestartClick: PropTypes.func.isRequired,
  onSkipToSummaryClick: PropTypes.func.isRequired,
  onShowPopupGuidanceClick: PropTypes.func.isRequired,
  showPopupGuidance: PropTypes.bool.isRequired,
  onMenuOpenClick: PropTypes.func.isRequired,
  onMenuCloseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default MobileMenu;
