import gql from 'graphql-tag';

const handQueries = {
  Hands: gql`
    query {
      hands {
        id
        title
        status
      }
    }
  `,
  HandsByProductId: gql`
    query ($productId: Int!, $status: HandStatusEnum) {
      handsByProductId (productId: $productId, status: $status) {
        id
        pbnId
        title
        status
        orderBy
        freeToPlay
      }
    }
  `,
  HandsByProductIdAndUserId: gql`
    query ($productId: Int!) {
      handsByProductIdAndUserId (productId: $productId) {
        id
        title
        freeToPlay
        userHands {
          id
          status
          step
        }
      }
    }
  `, // the userId is taken from the cookie (context)
  HandById: gql`
    query ($id: Int!) {
      handById (id: $id) {
        id
        title
        status
        freeToPlay
        handJSON
        userHands {
          id
          status
          step
        }
        product {
          id
          title
          author {
            id
            fullName
            avatarPath
          }
        }
      }
    }
  `
};

export default handQueries;
