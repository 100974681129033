import React, { useRef, useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    height: '4rem'
  }
}));

// like a load more button, but starts loading automatically
// when the user scrolls to the given point
// adapted from:
//
// https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
const LoadMoreScrollAnchor = (props) => {
  const { loadMore } = props;
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleScroll = useCallback(async () => {
    if (!ref.current) {
      return;
    }

    const top = ref.current.getBoundingClientRect().top;
    if (top < window.innerHeight && !isLoading) {
      setIsLoading(true);
      await loadMore();
      setIsLoading(false);
    }
  }, [loadMore, isLoading, setIsLoading]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <div ref={ref} className={classes.container}>
      {isLoading && (
        <CircularProgress size={24} />
      )}
    </div>
  );
};

LoadMoreScrollAnchor.propTypes = {
  loadMore: PropTypes.func.isRequired
};

export default LoadMoreScrollAnchor;
