import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

// provides up-to-date screen width / height dimensions
// throttled by 100ms so it doesn't flood everything else with redraws

const useScreenDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const listener = throttle(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, 100);

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [setDimensions]);

  return dimensions;
};

export default useScreenDimensions;
