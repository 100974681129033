import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import AdminSearch from './Queries/AdminSearch.js';
import AdminTable from './AdminTable/AdminTable.js';
import { BBSnackbarConsumer } from './BBSnackbar/BBSnackbarContext.js';
import { AdminSearchContext } from '../Context';

const AdminPage = (props) => {
  const {
    children,
    columns,
    query,
    initialDateRange,
    initialOrderBy,
    initialOrderByDirection,
    rowsDataPath,
    countDataPath,
    showAddButton,
    savedSearch,
    queryOptions,
    onRowClick,
    onAddClick,
    sectionName,
    ...other
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [itemId, setItemId] = useState(null);

  const {
    searchParams,
    currentSection,
    setSearchParams,
    initSearchParams,
    setCurrentSection
  } = useContext(AdminSearchContext);

  const handleFiltersChange = (searchState) => {
    const newState = { ...searchState };
    if (searchState.dateFrom) {
      newState.dateFrom = moment(searchState.dateFrom).format();
    }
    if (searchState.dateTo) {
      newState.dateTo = moment(searchState.dateTo).format();
    }
    if (searchState.dateRange) {
      newState.dateRange = searchState.dateRange;
    }
    newState.page = 0;
    handleStateChange(newState);
  };

  const handleOrderChange = (orderBy, orderByDirection) => {
    handleStateChange({ orderBy, orderByDirection });
  };

  const handlePageChange = (page, limit) => {
    const offset = page * limit;
    handleStateChange({ page, limit, offset });
  };

  const handleRowClick = item => () => {
    if (onRowClick) {
      onRowClick(item);
    } else {
      // open modal
      setModalOpen(true);
      setItemId(item.id);
    }
  };

  const handleAddClick = () => {
    if (onAddClick) {
      onAddClick();
    } else {
      // open modal
      setModalOpen(true);
      setItemId(null);
    }
  };

  const handleSubmitSuccess = (refetch) => {
    setModalOpen(false);
    refetch();
  };

  const handleCancelClick = () => {
    setModalOpen(false);
  };

  const handleStateChange = (changes) => {
    const newParams = { ...searchParams, ...changes };
    setSearchParams(newParams);
  };

  useEffect(() => {
    if (sectionName !== currentSection) {
      initSearchParams(initialDateRange, initialOrderBy, initialOrderByDirection);
      setCurrentSection(sectionName);
    }
  }, [
    initialDateRange,
    initialOrderBy,
    initialOrderByDirection,
    initSearchParams,
    sectionName,
    currentSection,
    setCurrentSection
  ]);

  return (
    <AdminSearch
      query={query}
      options={queryOptions ? { ...searchParams, ...queryOptions } : searchParams}
    >
      {
        (data, refetch) => {
          const count = _.get(data, countDataPath);
          const rows = _.get(data, rowsDataPath);

          return (
            <>
              <AdminTable
                columns={columns}
                rows={rows}
                count={count}
                searchParams={searchParams}
                onFilterChange={handleFiltersChange}
                onOrderChange={handleOrderChange}
                onPageChange={handlePageChange}
                onRowClick={handleRowClick}
                onAddClick={showAddButton ? handleAddClick : null}
                {...other}
              />
              <Modal open={modalOpen}>
                <BBSnackbarConsumer>
                  {({ openSnackbar }) => (
                    <span>
                      {
                        children
                          ? children(
                            itemId,
                            handleSubmitSuccess,
                            handleCancelClick,
                            refetch,
                            openSnackbar
                          )
                          : null
                      }
                    </span>
                  )}
                </BBSnackbarConsumer>
              </Modal>
            </>
          );
        }
      }
    </AdminSearch>
  );
};

AdminPage.propTypes = {
  children: PropTypes.func,
  columns: PropTypes.array.isRequired,
  query: PropTypes.func.isRequired,
  queryOptions: PropTypes.object,
  initialOrderBy: PropTypes.string,
  initialOrderByDirection: PropTypes.string,
  initialDateRange: PropTypes.object,
  rowsDataPath: PropTypes.string.isRequired,
  countDataPath: PropTypes.string.isRequired,
  showDates: PropTypes.bool,
  showSearch: PropTypes.bool,
  additionalDropdown: PropTypes.object,
  showAddButton: PropTypes.bool,
  onRowClick: PropTypes.func,
  onAddClick: PropTypes.func,
  buttons: PropTypes.object,
  selected: PropTypes.array,
  savedSearch: PropTypes.object,
  dateFilterOptions: PropTypes.array
};

export default AdminPage;
