import React from 'react';
import { userQueries } from '../../../graphql/queries';
import AdminPage from '../../../Components/AdminPage.js';
import UserDetails from './UserDetails.js';

const Users = () => {
  const columns = [
    { id: 'fullNameAdmin', label: 'Name', sortColumn: 'lastName' },
    { id: 'email', label: 'Email', sortColumn: 'email' },
    { id: 'dateCreated', label: 'Created', sortColumn: 'createdAt' },
    { id: 'type', label: 'Type', sortColumn: 'type' }
  ];
  return (
    <AdminPage
      columns={columns}
      query={userQueries.UserSearch}
      initialOrderBy='id'
      initialOrderByDirection='desc'
      rowsDataPath='userSearch.users'
      countDataPath='userSearch.count'
      sectionName='users'
    >
      {
        (id, onSubmitSuccess, onCancelClick, refetch, openSnackbar) => (
          <>
            {
              id && id > 0
                ? <UserDetails
                    id={id}
                    onCancelClick={onCancelClick}
                    onSubmitSuccess={(message) => {
                      openSnackbar(message, 'success');
                      onSubmitSuccess(refetch);
                    }}
                    onSubmitError={(message) => {
                      openSnackbar(message, 'error');
                    }}
                  />
                : null
            }
          </>
        )
      }
    </AdminPage>
  );
};

export default Users;
