import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    border: 'solid 1px #cccccc',
    backgroundColor: '#ffffff',
    padding: '1em',
    top: '-1em',
    position: 'relative'
  },
  delete: {
    borderColor: '#ff0000',
    color: '#ff0000'
  },
  cancel: {
    marginLeft: '1em',
    marginRight: '1em'
  },
  ok: {
    marginRight: '1em'
  }
}));

const DeleteButton = (props) => {
  const { onDeleteOkClick } = props;
  const [deleteClicked, setDeleteClicked] = useState(false);
  const classes = useStyles();

  const handleDeleteClick = () => {
    setDeleteClicked(true);
  };

  const handleCancelClick = () => {
    setDeleteClicked(false);
  };

  const handleOkClick = () => {
    setDeleteClicked(false);
    onDeleteOkClick();
  };

  if (deleteClicked) {
    return (
      <Paper className={classes.paper}>
        <span>
          Are you sure?
        </span>
        <Button
          onClick={handleCancelClick}
          variant='outlined'
          className={classes.cancel}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOkClick}
          variant='contained'
          color='secondary'
          className={classes.ok}
        >
          Ok
        </Button>
      </Paper>
    );
  } else {
    return (
      <Button
        onClick={handleDeleteClick}
        variant='outlined'
        className={classes.delete}
      >
        Delete
      </Button>
    );
  }
};

DeleteButton.propTypes = {
  onDeleteOkClick: PropTypes.func.isRequired
};

export default DeleteButton;
