import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Deal from '../../../../Components/Hands/Deal/Deal.js';
import Auction from '../../../../Components/Hands/Auction.js';
import Contract from '../../../../Components/Hands/Contract/Contract.js';
import CompleteButtons from './CompleteButtons.js';
import { useTranslation } from 'react-i18next';
import { formatComment } from '../../../../helpers/comment.js';

const useStyles = makeStyles((theme) => ({
  column: {
    padding: '1rem',
    height: 'calc(86vh - 50px)',
    '@media all and (max-height: 667px)': {
      height: 'calc(90vh - 50px)'
    }
  },
  paper: {
    background: 'rgba(0,0,0,0.2)',
    border: '1px solid rgba(255,255,255,0.25)',
    padding: theme.spacing(2),
    maxWidth: 620
  },
  paperPortrait: {
    background: 'transparent',
    border: 'none',
    padding: 0
  },
  dealContainer: {
    fontSize: '0.7rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem'
    }
  },
  auction: {
    color: '#fff',
    '& table': {
      fontSize: '1rem'
    }
  },
  summaryContainer: {
    background: '#fff',
    borderRadius: '1rem',
    margin: '1rem',
    padding: '2rem',
    flexGrow: 1,
    flex: 1,
    overflowY: 'auto',
    minHeight: 0,
    width: '90%'
  },
  summary: {
    overflow: 'hidden',
    overflowY: 'auto',
    maxWidth: '400px',
    '& p': {
      margin: 0
    }
  }
}));

const PlayComplete = (props) => {
  const {
    hand,
    isPortrait,
    ...rest
  } = props;

  const { t } = useTranslation();

  const {
    deal,
    dealer,
    vulnerable,
    viewpoint,
    auction,
    playOnly,
    contract,
    declarer
  } = hand;

  const classes = useStyles();

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              className={classes.column}
            >
              <Grid item>
                <Paper
                  className={clsx(classes.paper, isPortrait && classes.paperPortrait)}
                >
                  <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={isPortrait ? 1 : 4}
                  >
                    <Grid item className={classes.dealContainer}>
                      <Deal
                        selectable={false}
                        cards={deal}
                        dealer={dealer}
                        vulnerable={vulnerable}
                        viewpoint={viewpoint}
                        activeDirection='None'
                        wideLayout={isPortrait}
                        allPlayed
                      />
                    </Grid>
                    <Grid item className={classes.auction}>
                      {
                        playOnly
                          ? <div><Contract contract={contract} declarer={declarer} /></div>
                          : <Auction auction={auction} dealer={dealer} isPortrait={isPortrait} />
                      }
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {
                isPortrait && (
                  <Grid item className={classes.summaryContainer}>
                    <div
                      className={classes.summary}
                      dangerouslySetInnerHTML={
                        { __html: formatComment(hand.summary, t) }
                      }
                    />
                  </Grid>
                )
              }
              <Grid item>
                <CompleteButtons
                  {...rest}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PlayComplete.propTypes = {
  hand: PropTypes.object.isRequired,
  isPortrait: PropTypes.bool.isRequired,
  onReplayClick: PropTypes.func,
  onPlayNextClick: PropTypes.func,
  onReturnToProductClick: PropTypes.func,
  onBrowseStoreClick: PropTypes.func,
  onPurchaseClick: PropTypes.func,
  onRemoveFromCartClick: PropTypes.func,
  isInCart: PropTypes.bool,
  purchased: PropTypes.bool,
  isFinalHandInProduct: PropTypes.bool,
  onSale: PropTypes.bool
};

export default PlayComplete;
