import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Heading from '../Components/Store/Heading.js';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme) => ({
  component: {
    position: 'relative',
    margin: '0 auto',
    textAlign: 'center',
    padding: '5rem 3rem',
    maxWidth: '70rem',
    '& img': {
      maxWidth: '50rem',
      width: '100%',
      marginBottom: '1rem',
      '@media all and (orientation: landscape)': {
        maxWidth: '30rem'
      }
    }
  },
  heading: {
    color: theme.palette.styleguide.maroon
  },
  backButton: {
    position: 'absolute',
    left: '1rem',
    top: '1rem'
  }
}));

const DeviceNotSupported = ({ osVersion }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className={classes.component}>
      <Button
        onClick={handleBackClick}
        size='small'
        startIcon={<ArrowBackIcon />}
        className={classes.backButton}
      >
        Back
      </Button>
      <img src='/img/components/resolution_not_supported/devices.png' alt='devices' />
      <Heading
        variant='h2'
        text='Uh-oh! Your device is not yet supported'
        className={classes.heading}
      />
      <p>
        BridgeBee doesn't currently support your device's Operating System.
        You are currently running version {osVersion}, please check our support pages for versions we support
      </p>
    </div>
  );
};

DeviceNotSupported.propTypes = {
  osVersion: PropTypes.number.isRequired
};

export default DeviceNotSupported;
