import React from 'react';
import PropTypes from 'prop-types';
import { ReactTitle } from 'react-meta-tags';
import _ from 'lodash';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Auction from './Auction.js';
import CardPlay from './CardPlay.js';
import AuctionModal from './AuctionModal.js';
import PlayHeader from './PlayHeader.js';
import MobileMenu from './MobileMenu/MobileMenu.js';
import Rotate from './Rotate.js';
import Drawer from '../../../../Components/Hands/Drawer/Drawer.js';
import PortraitCommentModal from '../../../../Components/Hands/Chat/PortraitCommentModal.js';
import { formatURLTitle } from '../../../../helpers/http.js';
import { stripHtml } from '../../../../helpers/global.js';
import { getCardsForSeat, getNextSeatForPlay, getSeparateAuctionValues } from '../../../../helpers/card.js';
import { convertSeatCodeToFullWord } from '../../../../helpers/auction.js';
import { addNewMessage, getPosition } from '../../../../helpers/conversation.js';
import { formatComment, getRandomWrongChoiceAnswer } from '../../../../helpers/comment.js';
import {
  flagCardAsInvisible,
  flagWinningCard,
  winnerOfHand,
  addWinnerToTrickCount,
  firstToPlay
} from '../../../../helpers/play.js';
import {
  buildProductLink
} from '../../../../helpers/product.js';
import clsx from 'clsx';

const styles = {
  '@global': {
    body: {
      height: '100%'
    }
  },
  play: {
    position: 'relative',
    background: 'radial-gradient(rgb(100,111,115) 0%, rgb(68,76,79) 100%)',
    height: '100%',
    overflow: 'hidden'
  },
  conversation: {
    minHeight: '6rem'
  },
  // for some reason cardplay breaks out without this in Material v5
  maxWidth100: {
    maxWidth: '100% !important'
  }
};

class Hand extends React.Component {
  // ------------------------------------------
  // all the delay settings can be changed here
  waitingMessageDelay = 1000; // original = 1000 quick=500
  auctionDelay = 5000;
  auctionBidDelay = 1000; // original = 3000 quick=1000
  playDelay = 5000;
  cardPlayDelay = 1000; // original = 3000 quick=1000
  updateTrickDelay = 1000; // original = 2000 quick=500
  animationSpeed = 250; // original = 250
  animationStartDelay = 50;
  // ------------------------------------------

  startConversation = () => {
    const { handJSON } = this.props.hand;
    const { playOnly, playIntro, auctionIntro } = handJSON;
    let text = playOnly ? playIntro : auctionIntro;
    text = formatComment(text, this.props.t);
    const conversation = [
      {
        position: 'left',
        messages: [
          {
            text: text,
            type: 'comment'
          }
        ]
      }
    ];
    return conversation;
  };

  initModalMessage = () => {
    if (this.props.isPortrait) {
      return this.startConversation()[0].messages[0];
    }
    return undefined;
  };

  state = {
    auction: [],
    auctionComplete:
      !!(this.props.hand.handJSON.playOnly ||
      (this.props.startStep && this.props.startStep.includes('play'))),
    drawerOpen: true,
    unreadMessages: 1,
    conversation: this.startConversation(),
    modalMessage: this.initModalMessage(),
    numberOfWrongChoices: 0,
    skipToSummary: false,
    skipToSummaryEnabled: false,
    auctionModalOpen: false,
    commentModalOpen: this.props.isPortrait,
    mobileMenuOpen: false,
    playComplete: false,
    showPopupGuidance: true,
    deal: _.cloneDeep(this.props.hand.handJSON.deal),
    tricks: {
      northSouth: 0,
      eastWest: 0
    },
    trick: [],
    trickNumber: 0,
    activeSeat: this.props.hand.handJSON.playOnly ? firstToPlay(this.props.hand.handJSON.declarer) : this.props.hand.handJSON.dealer,
    canPlay: false,
    paused: this.props.isPortrait
  };

  handleReturnToProductClick = () => {
    const { navigate, collection, set, isPreview, linkId, onPreviewCloseClick } = this.props;
    if (isPreview) {
      onPreviewCloseClick();
    } else {
      if (linkId) {
        navigate(`${buildProductLink(set.language, linkId, true)}`);
      } else {
        if (collection) {
          navigate(`/products/${collection.id}/${formatURLTitle(collection.title)}`);
        } else {
          navigate(`/products/${set.id}/${formatURLTitle(set.title)}`);
        }
      }
    }
  };

  handleBrowseStoreClick = () => {
    const { navigate } = this.props;
    navigate('/products');
  };

  // --- AUCTION EVENTS ---

  handleBidToPlay = (bid) => {
    const { skipToSummaryEnabled } = this.state;
    // get pre comment if there is one
    const hasComment = this.handleCommentCheck('pre', bid);
    setTimeout(() => {
      const update = { canPlay: true };
      // Skip to summary button is disabled by default - enable it when all initial comments have been displayed
      if (!skipToSummaryEnabled) {
        update.skipToSummaryEnabled = true;
      }
      this.setState(update);
    }, hasComment ? this.auctionBidDelay : 0);
  };

  handleBidPlayed = (bid) => {
    let { auction, activeSeat } = this.state;
    const { onStepTaken, hand } = this.props;
    const { viewpoint, dummy, declarer } = hand.handJSON;

    // push bid to auction array
    auction.push(bid);

    if (onStepTaken) {
      onStepTaken('auction', auction.length);
    }
    const position = getPosition({
      seat: bid.seat,
      viewpoint,
      dummy,
      declarer,
      isAuction: true
    });
    // create the bid message as an array for BBTranslate
    let whoBids = [convertSeatCodeToFullWord(activeSeat).toLowerCase(), 'bids'];
    if (position === 'right') {
      whoBids = 'i-bid';
    }
    let message = { text: whoBids, type: 'info' };
    message.isBid = true;
    if (bid.value === 'Pass' || bid.value === 'X' || bid.value === 'XX') {
      message.value = bid.value;
    } else {
      message.suit = bid.denomination;
      message.value = bid.level;
    }
    this.handleMessageCreated(message, position);

    // update activeSeat to next player to bid
    activeSeat = getNextSeatForPlay(activeSeat);

    this.setState({
      auction: auction,
      activeSeat: activeSeat,
      canPlay: false,
      numberOfWrongChoices: 0
    });

    // check for post bid comment
    return this.handleCommentCheck(
      'post',
      bid,
      this.auctionBidDelay
    );
  };

  handleAuctionComplete = () => {
    const { onStepTaken, isPortrait } = this.props;
    const { contract, declarer, auctionOnly } = this.props.hand.handJSON;
    const { drawerOpen } = this.state;

    if (onStepTaken && !auctionOnly) {
      onStepTaken('play', 0);
    }

    const { bidValue, doubleValue } = getSeparateAuctionValues(contract.value);

    const message = {
      bidValue,
      doubleValue,
      declarer,
      auctionOnly,
      type: 'info'
    }

    this.handleMessageCreated(message, 'left');

    if (auctionOnly) {
      this.handlePlayComplete();
    } else {
      this.setState({
        activeSeat: firstToPlay(declarer),
        auctionComplete: true,
        drawerOpen: auctionOnly && isPortrait ? true : drawerOpen
      });
    }
  };

  // --- END AUCTION EVENTS ---

  // --- CARD PLAY EVENTS ---
  handlePlayStarted = () => {
    const { playOnly, playIntro } = this.props.hand.handJSON;
    if (!playOnly) {
      // show intro message
      const message = {
        text: formatComment(playIntro, this.props.t),
        type: 'comment'
      }
      this.handleMessageCreated(message, 'left');
    }
  };

  handleCardToPlay = (card) => {
    const { skipToSummaryEnabled } = this.state;
    // get pre comment if there is one
    const hasComment = this.handleCommentCheck('pre', card);
    setTimeout(() => {
      const update = { canPlay: true };
      // Skip to summary button is disabled by default - enable it when all initial comments have been displayed
      if (!skipToSummaryEnabled) {
        update.skipToSummaryEnabled = true;
      }
      this.setState(update);
    }, hasComment ? this.cardPlayDelay : 0);
  };

  handleCardPlaying = (card) => {
    let { deal } = this.state;
    // remove the card from the deal
    setTimeout(() => {
      flagCardAsInvisible(deal, card);
      this.setState({
        deal: deal
      });
    }, 0); // without the set timeout the animation starts from the wrong place (top right)
  }

  handleCardPlayed = (card) => {
    let { trick, tricks, trickNumber, activeSeat } = this.state;
    const { hand } = this.props;
    const { viewpoint, dummy, declarer, contract } = hand.handJSON;
    const position = getPosition({
      seat: card.seat,
      viewpoint,
      dummy,
      declarer,
      isAuction: false
    });
    // add the play message
    let whoPlays = [convertSeatCodeToFullWord(activeSeat).toLowerCase(), 'plays'];
    if (position === 'right') {
      if (activeSeat === dummy) {
        whoPlays = 'dummy-plays';
      } else {
        whoPlays = 'i-play';
      }
    }
    const message = {
      text: whoPlays,
      type: 'info',
      suit: card.suit,
      value: card.rank
    };
    message.isBid = false;
    this.handleMessageCreated(message, position);

    // check for post comment
    const hasComment = this.handleCommentCheck(
      'post',
      card,
      this.cardPlayDelay
    );

    // ----------------------

    // add to trick
    card = { ...card, winner: false };
    trick.push(card);

    // check winner
    if (trick.length === 4) {
      const winner = winnerOfHand(trick, contract.denomination);

      // set winner flag for winning card
      trick = flagWinningCard(trick, winner);

      // set the active seat to the winner so they can play first
      activeSeat = winner;

      // update the tricks count
      tricks = addWinnerToTrickCount(tricks, winner);

      // raise event so trick winner message can be shown
      setTimeout(() => {
        this.handleTrickWon(tricks.eastWest + tricks.northSouth, viewpoint, trick, true);
      }, hasComment ? this.cardPlayDelay : 0);

      setTimeout(() => {
        trickNumber = trickNumber + 1;
        trick = [];
        this.setState({
          trick: trick,
          tricks: tricks,
          trickNumber: trickNumber
        });

        // pass tricks up to Hand component
        this.handleTricksChange(tricks);

      }, this.updateTrickDelay);
    } else {
      activeSeat = getNextSeatForPlay(activeSeat);
    }

    this.setState({
      activeSeat: activeSeat,
      trick: trick,
      trickNumber: trickNumber,
      numberOfWrongChoices: 0,
      canPlay: false
    });

    return hasComment;
  };

  handleWrongChoice = (cardOrBid, section) => {
    let { numberOfWrongChoices } = this.state;
    let message = {
      type: 'action'
    };
    if (numberOfWrongChoices > 1) {
      message.revealLinkText = section === 'play' ? 'click-here-for-correct-card' : 'click-here-for-correct-bid';
      if (section === 'play') {
        message.suit = cardOrBid.suit;
        message.value = cardOrBid.rank;
        message.text = 'try-card';
      } else {
        message.isBid = true;
        message.text = 'try-bid';
        if (cardOrBid.value === 'Pass' || cardOrBid.value === 'X' || cardOrBid.value === 'XX') {
          message.value = cardOrBid.value;
        } else {
          message.suit = cardOrBid.denomination;
          message.value = cardOrBid.level;
        }
      }
      this.handleMessageCreated(message, 'left');
      numberOfWrongChoices = 0;
    } else {
      message = {
        text: getRandomWrongChoiceAnswer(section),
        type: 'feedback',
      };
      this.handleMessageCreated(message, 'left');
      const hint = this.getComment('hint', cardOrBid);
      if (hint) {
        message = {
          text: 'click-here-for-a-hint',
          type: 'hint',
          onClick: () => {
            this.handleCommentCheck('hint', cardOrBid);
          }
        };
        setTimeout(() => {
          this.handleMessageCreated(message, 'left');
        }, this.auctionBidDelay);
      }
      numberOfWrongChoices += 1;
    }
    this.setState({
      numberOfWrongChoices: numberOfWrongChoices
    });
  };

  handleTrickWon = (trickNumber, viewpoint, trick, logStepTaken) => {
    if (trickNumber < 14) {
      const { onStepTaken } = this.props;
      if (onStepTaken && logStepTaken) {
        onStepTaken('play', trickNumber);
      }
      // show trick winner message
      let text = ['trick', trickNumber];
      const message = {
        text: text,
        type: 'info',
        trick: trick,
        viewpoint: viewpoint
      };
      this.handleMessageCreated(message, 'left');
    }
  };

  handlePlayComplete = () => {
    const { drawerOpen } = this.state;
    const { onHandComplete, isPortrait, hand } = this.props;
    const { deal, summary } = hand.handJSON;

    if (onHandComplete) {
      onHandComplete();
    }

    // show hand summary
    const message = {
      text: formatComment(summary, this.props.t),
      type: 'summary'
    }
    this.handleMessageCreated(message, 'left');

    // update user hand status to complete
    this.setState({
      auctionComplete: true,
      playComplete: true,
      drawerOpen: isPortrait ? true : drawerOpen,
      deal: _.cloneDeep(deal),
      skipToSummaryEnabled: false
    });
  };

  handleTouchingCard = (playedCard, authorCard) => {
    const message = {
      text: this.props.t('you-played-weve-played', {
        yourCard: formatComment(`${playedCard.suit}${playedCard.rank}`, this.props.t),
        ourCard: formatComment(`${authorCard.suit}${authorCard.rank}`, this.props.t)
      }),
      type: 'feedback'
    }
    this.handleMessageCreated(message, 'left');
  };

  // --- END CARD PLAY EVENTS ---

  handleMessageCreated = (message, position) => {
    let {
      conversation,
      unreadMessages,
      commentModalOpen,
      modalMessage,
      showPopupGuidance
    } = this.state;
    const { isPortrait,  } = this.props;
    conversation = addNewMessage(conversation, message, position);
    if (position === 'left') {
      unreadMessages += 1;
    }

    // only change the state for the comment modal if its a new message  and the orientation is portait:
    const showMessage = showPopupGuidance &&
      isPortrait &&
      message.type !== 'info' &&
      message.type !== 'summary';

    this.setState({
      conversation: conversation,
      unreadMessages: unreadMessages,
      commentModalOpen: showMessage ? true : commentModalOpen,
      paused: showMessage ? true : commentModalOpen,
      modalMessage: showMessage ? message : modalMessage,
      mobileMenuOpen: false
    });
  };

  handleCommentCheck = (type, cardOrBid, delay) => {
    const comment = this.getComment(type, cardOrBid);
    if (comment) {
      const message = { text: formatComment(comment, this.props.t), type: 'comment' };
      if (delay && delay > 0) {
        setTimeout(() => {
          this.handleMessageCreated(message, 'left');
        }, delay)
      } else {
        this.handleMessageCreated(message, 'left');
      }
      return true;
    }
    return false;
  };

  getComment = (type, cardOrBid) => {
    if (cardOrBid.comments && cardOrBid.comments[type]) {
      const comment = cardOrBid.comments[type];
      // only return the comment if there are actual chars in it (no blanks allowed)
      if (stripHtml(comment).length > 0) {
        return comment;
      }
    }
    return undefined;
  };

  handleToggleDrawer = () => {
    const expanded = this.state.drawerOpen;
    this.setState({
      drawerOpen: !expanded,
      unreadMessages: 0
    });
  };

  handleContractClick = () => {
    this.setState({
      auctionModalOpen: true
    });
  };

  handleCloseAuctionClick = () => {
    this.setState({
      auctionModalOpen: false
    });
  };

  handleSkipToSummaryClick = () => {
    let {
      trick,
      tricks,
      trickNumber,
      auctionComplete
    } = this.state;
    const { hand } = this.props;
    const {
      auction,
      auctionOnly,
      play,
      viewpoint,
      contract,
      playOnly,
      declarer
    } = hand.handJSON;
    // add all the remaining comments and tricks to conversation

    if (!playOnly && !auctionComplete) {
      this.showAllAuctionComments(auction, contract, declarer);
    }

    if (!auctionOnly) {
      // show intro
      if (!auctionComplete) {
        this.handlePlayStarted();
      }
      // first go through remaining cards in current trick
      let currentTrick = play[trickNumber];
      currentTrick.forEach((card, index) => {
        if (index >= trick.length) {
          this.handleCommentCheck('pre', card, 0);
          this.handleCommentCheck('post', card, 0);
        }
      });
      // get the winner
      const winner = winnerOfHand(currentTrick, contract.denomination);
      // update the tricks count
      tricks = addWinnerToTrickCount(tricks, winner);
      // show trick as message (trickNumber zero based so add 1)
      this.handleTrickWon(trickNumber + 1, viewpoint, currentTrick);
      // then go through remaining tricks
      while (trickNumber < 12) {
        trickNumber += 1;
        currentTrick = play[trickNumber];
        currentTrick.forEach(card => {
          this.handleCommentCheck('pre', card, 0);
          this.handleCommentCheck('post', card, 0);
        });
        // get the winner
        const winner = winnerOfHand(currentTrick, contract.denomination);
        // update the tricks count
        tricks = addWinnerToTrickCount(tricks, winner);
        // show trick as message  (trickNumber zero based so add 1)
        this.handleTrickWon(trickNumber + 1, viewpoint, currentTrick);
      }

      this.setState({
        tricks: tricks
      });
    }

    this.setState({
      skipToSummaryEnabled: false,
      auctionComplete: true,
      playComplete: true
    });    

    // update play complete & show summary
    setTimeout(() => {
      this.handlePlayComplete();
    }, 200);
  };

  handleRestartClick = () => {
    const { onHandComplete } = this.props;
    if (onHandComplete) {
      onHandComplete();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  handleModalContinueClick = () => {



    // reset the modalMessage obj and close modal
    this.setState({
      commentModalOpen: false,
      modalMessage: undefined
    });
    setTimeout(() => {
      this.setState({
        paused: false
      });
    }, 500);
  };

  handleTricksChange = (tricks) => {
    this.setState({
      tricks: tricks
    });
  }

  handleShowPopupGuidanceClick = () => {
    this.setState({
      showPopupGuidance: !this.state.showPopupGuidance
    });
  };

  handleMobileMenuOpenClick = () => {
    this.setState({
      mobileMenuOpen: true
    });
  };

  handleMobileMenuCloseClick = () => {
    this.setState({
      mobileMenuOpen: false
    });
  };

  splitStartStep = () => {
    // set auctionComplete if the start step is a play trick
    if (this.props.startStep) {
      const startStepType = this.props.startStep.split('_')[0];
      const startStep = parseInt(this.props.startStep.split('_')[1]);
      return {
        startStepType: startStepType,
        startStep: startStep
      }
    }
    return {
      startStepType: null,
      startStep: null
    };
  };

  isPurchased = () => {
    const { user, hand } = this.props;
    let isPurchased = false;
    if (user) {
      const purchasedHand = _.find(user.products, { id: hand.product.id });
      isPurchased = purchasedHand !== undefined;
    }
    return isPurchased;
  };

  showAllAuctionComments = (auction, contract, declarer) => {
    // get all comments for the remaining bids
    auction.forEach(bid => {
      this.handleCommentCheck('pre', bid, 0);
      this.handleCommentCheck('post', bid, 0);
    });

    // show the contract
    const message = {
      text: this.props.t('all-pass-the-contract-is-by-declarer', {
        contract: formatComment(contract.value, this.props.t),
        declarer: formatComment(`<strong>${declarer}</strong>`, this.props.t)
      }),
      type: 'info'
    };
    this.handleMessageCreated(message, 'left');
  };

  handleStartAtStep = (step) => {
    let { auction, activeSeat } = this.state;
    const { auction: handAuction } = this.props.hand.handJSON;
    let i = 0;
    while (i < step) {

      const bid = handAuction[i];

      // push bid to auciton array
      auction.push(bid);

      this.handleCommentCheck('pre', bid, 0);
      this.handleCommentCheck('post', bid, 0);

      // update activeSeat to next player to bid
      activeSeat = getNextSeatForPlay(activeSeat);

      i += 1;
    }

    this.setState({
      auction: auction,
      activeSeat: activeSeat,
      canPlay: false
    });
  };

  handleStartAtTrick = (trickNumber) => {
    let { activeSeat, tricks, deal } = this.state;
    const {
      auction,
      playOnly,
      contract,
      play,
      viewpoint,
      declarer
    } = this.props.hand.handJSON;

    if (!playOnly) {
      this.showAllAuctionComments(auction, contract, declarer);
    }

    activeSeat = firstToPlay(declarer);

    let i = 0;
    while (i < trickNumber) {
      const currentTrick = play[i];
      currentTrick.forEach(card => {
        this.handleCommentCheck('pre', card, 0);
        this.handleCommentCheck('post', card, 0);
        // remove card from deal
        flagCardAsInvisible(deal, card);
      });
      // get the winner
      const winner = winnerOfHand(currentTrick, contract.denomination);

      // set the active seat to the winner so they can play first
      activeSeat = winner;

      // update the tricks count
      tricks = addWinnerToTrickCount(tricks, winner);

      // show trick as message
      this.handleTrickWon(i + 1, viewpoint, currentTrick);

      i += 1;
    }

    this.setState({
      deal: deal,
      trickNumber: trickNumber,
      activeSeat: activeSeat,
      tricks: tricks,
      auctionComplete: true
    });
  };

  componentDidMount () {
    const { hand, isPreview, linkId } = this.props;

    // check if the hand is playable (either its free or its been purchased)
    if (!isPreview && !linkId) {
      if (!hand.freeToPlay && !this.isPurchased()) {
        window.location = '/error404';
      }
    }

    // check to see if this is a set being resumed
    // has to be in componentDidMount so we can set state
    if (this.props.startStep && this.props.startStep !== '') {
      const {
        startStepType,
        startStep
      } = this.splitStartStep();

      if (startStepType === 'play') {
          this.handleStartAtTrick(startStep);
      } else {
        this.handleStartAtStep(startStep);
      }
    }
  }

  render () {
    const {
      classes,
      collection,
      hand,
      isPreview,
      isPortrait,
      componentScale,
      screenDimensions,
      aspectRatio,
      set,
      linkId,
      embed,
      ...other
    } = this.props;

    const { dealer, viewpoint, auction: handAuction } = hand.handJSON;

    const {
      auction,
      conversation,
      playComplete,
      unreadMessages,
      drawerOpen,
      auctionModalOpen,
      deal,
      trick,
      trickNumber,
      tricks,
      activeSeat,
      modalMessage,
      commentModalOpen,
      paused,
      showPopupGuidance,
      mobileMenuOpen,
      canPlay,
      skipToSummaryEnabled
    } = this.state;
    let {
      auctionComplete,
      skipToSummary
    } = this.state;

    const playersCards = getCardsForSeat(deal, viewpoint);

    return (
      <>
        <ReactTitle title={`${hand.product.title} - ${hand.title}`} />
        <AuctionModal
          open={auctionModalOpen}
          onCloseClick={this.handleCloseAuctionClick}
          dealer={dealer}
          auction={handAuction}
        />
        <Grid
          container
          direction={isPortrait ? 'column' : 'row'}
          justifyContent='space-between'
          alignItems='stretch'
          style={{height:'100%'}}
        >
          <Grid item xs zeroMinWidth className={classes.maxWidth100}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              alignItems='stretch'
              style={{ height:'100%' }}
            >
              <Grid item className={classes.maxWidth100}>
                <PlayHeader
                  collection={collection}
                  hand={hand}
                  auctionComplete={auctionComplete}
                  onContractClick={this.handleContractClick}
                  tricks={tricks}
                  isPortrait={isPortrait}
                  onReturnToProductClick={this.handleReturnToProductClick}
                  embed={embed}
                />
              </Grid>
              <Grid item xs className={clsx(classes.play, classes.maxWidth100)}>
                {
                  auctionComplete
                  ?
                  <CardPlay
                    onPlayStarted={this.handlePlayStarted}
                    onCardToPlay={this.handleCardToPlay}
                    onCardPlaying={this.handleCardPlaying}
                    onCardPlayed={this.handleCardPlayed}
                    onWrongChoice={this.handleWrongChoice}
                    onTouchingCard={this.handleTouchingCard}
                    onTrickWon={this.handleTrickWon}
                    onPlayComplete={this.handlePlayComplete}
                    onCommentCheck={this.handleCommentCheck}
                    hand={hand.handJSON}
                    deal={deal}
                    playComplete={playComplete}
                    trick={trick}
                    tricks={tricks}
                    trickNumber={trickNumber}
                    activeSeat={activeSeat}
                    playDelay={this.playDelay}
                    cardPlayDelay={this.cardPlayDelay}
                    updateTrickDelay={this.updateTrickDelay}
                    animationSpeed={this.animationSpeed}
                    animationStartDelay={this.animationStartDelay}
                    purchased={this.isPurchased()}
                    skipToSummary={skipToSummary}
                    onTricksChange={this.handleTricksChange}
                    onReplayClick={this.handleRestartClick}
                    isPortrait={isPortrait}
                    paused={paused}
                    onReturnToProductClick={this.handleReturnToProductClick}
                    onBrowseStoreClick={linkId ? null : this.handleBrowseStoreClick}
                    canPlay={canPlay}
                    onSale={isPreview ? false : (collection ? collection.onSale : set.onSale)}
                    embed={embed}
                    {...other}
                  />
                  :
                  <Auction
                    onMessageCreated={this.handleMessageCreated}
                    onWrongChoice={this.handleWrongChoice}
                    onBidToPlay={this.handleBidToPlay}
                    onBidPlayed={this.handleBidPlayed}
                    onAuctionComplete={this.handleAuctionComplete}
                    cards={playersCards}
                    dealer={dealer}
                    viewpoint={viewpoint}
                    auction={auction}
                    activeSeat={activeSeat}
                    auctionDelay={this.auctionDelay}
                    bidDelay={this.auctionBidDelay}
                    isPortrait={isPortrait}
                    componentScale={componentScale}
                    screenDimensions={screenDimensions}
                    aspectRatio={aspectRatio}
                    paused={paused}
                    handAuction={handAuction}
                    canPlay={canPlay}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
          {
            !isPortrait && (
              <Grid item zeroMinWidth className={classes.conversation}>
                <Drawer
                  conversation={conversation}
                  hand={hand}
                  waitingMessageDelay={this.waitingMessageDelay}
                  unreadMessages={unreadMessages}
                  onToggleDrawer={this.handleToggleDrawer}
                  showButtons
                  onRestartClick={this.handleRestartClick}
                  onSkipToSummaryClick={this.handleSkipToSummaryClick}
                  open={drawerOpen}
                  isPortrait={isPortrait}
                  playComplete={playComplete}
                  skipToSummaryEnabled={skipToSummaryEnabled}
                  {...other}
                />
              </Grid>
            )
          }
          {
            (!playComplete && isPortrait && !isPreview) && (
              <Grid item zeroMinWidth>
                <MobileMenu
                  onRestartClick={this.handleRestartClick}
                  onSkipToSummaryClick={this.handleSkipToSummaryClick}
                  onReturnToProductClick={this.handleReturnToProductClick}
                  onShowPopupGuidanceClick={this.handleShowPopupGuidanceClick}
                  showPopupGuidance={showPopupGuidance}
                  onMenuOpenClick={this.handleMobileMenuOpenClick}
                  onMenuCloseClick={this.handleMobileMenuCloseClick}
                  open={mobileMenuOpen}
                />
              </Grid>
            )
          }
        </Grid>
        {
          modalMessage
          ?
          <PortraitCommentModal
            onContinueClick={this.handleModalContinueClick}
            message={modalMessage}
            open={commentModalOpen}
            author={hand.product.author}
          />
          :
          null
        }
        <Rotate />
      </>
    );
  }
}

Hand.propTypes = {
  collection: PropTypes.object,
  set: PropTypes.object.isRequired,
  hand: PropTypes.object.isRequired,
  user: PropTypes.object,
  isPreview: PropTypes.bool,
  onPreviewCloseClick: PropTypes.func,
  onPlayNextClick: PropTypes.func,
  onPurchaseClick: PropTypes.func,
  onRemoveFromCartClick: PropTypes.func,
  isInCart: PropTypes.bool,
  isFinalHandInProduct: PropTypes.bool,
  onStepTaken: PropTypes.func,
  onHandComplete: PropTypes.func,
  startStep: PropTypes.string,
  isPortrait: PropTypes.bool,
  componentScale: PropTypes.number,
  screenDimensions: PropTypes.object,
  aspectRatio: PropTypes.string,
  linkId: PropTypes.string
};

export default withTranslation()(withStyles(styles)(Hand));
