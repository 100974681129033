export const getCardTextId = (code) => {
  switch (code.toString().toUpperCase()) {
    case '1':
      return '1-one';
    case '2':
      return '2-two';
    case '3':
      return '3-three';
    case '4':
      return '4-four';
    case '5':
      return '5-five';
    case '6':
      return '6-six';
    case '7':
      return '7-seven';
    case '8':
      return '8-eight';
    case '9':
      return '9-nine';
    case '10':
      return '10-ten';
    case 'T':
      return 't-ten';
    case 'J':
      return 'j-jack';
    case 'Q':
      return 'q-queen';
    case 'K':
      return 'k-king';
    case 'A':
      return 'a-ace';
    default:
      throw new Error('unsupported card code: ' + code);
  }
};

export const getDirectionTextId = (direction) => {
  direction = direction.toLowerCase();

  switch (direction) {
    case 'w':
      return 'w-west';
    case 'n':
      return 'n-north';
    case 'e':
      return 'e-east';
    case 's':
      return 's-south';
    default:
      throw new Error('unknown direction: ' + direction);
  }
};
