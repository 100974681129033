import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploader from './FileUploader.js';

// https://stackoverflow.com/questions/13572129/is-it-possible-to-check-dimensions-of-image-before-uploading

const styles = theme => ({
  container: {
    position: 'relative'
  },
  progress: {
    margin: theme.spacing(2),
    position: 'absolute',
    top: 26,
    left: 26,
    color: '#ffffff'
  },
  overlay: {
    width: 120,
    height: 120,
    backgroundColor: 'black',
    position: 'absolute',
    top: 0,
    opacity: 0.5
  },
  text: {
    color: '#ffffff',
    position: 'absolute',
    fontSize: '1em',
    bottom: 10,
    left: 8
  }
});

class ImageUploader extends React.Component {
  state = {
    isLoading: false,
    progress: 0,
    src: this.props.currentImageSrc ? this.props.currentImageSrc : '/img/admin/avatar.png'
  };

  fileSpecs = {
    supportedFileTypes: [
      {
        extension: 'png',
        contentType: 'image/png'
      },
      {
        extension: 'jpeg',
        contentType: 'image/jpeg'
      },
      {
        extension: 'jpg',
        contentType: 'image/jpeg'
      }
    ],
    maxSizeKb: 100,
    maxXDimension: 120,
    maxYDimension: 120
  };

  handleUploadStart = (file) => {
    const reader = new FileReader();
    this.setFormState({
      isLoading: true
    });
    reader.addEventListener('load', () => {
      this.setFormState({
        src: reader.result
      });
    });
    reader.readAsDataURL(file);
  };

  handleUploadProgress = (progress) => {
    const { isLoading } = this.state;
    this.setFormState({
      progress: isLoading ? progress : 0
    })
  };

  handleUploadComplete = (fileName, folder) => {
    this.props.onUploadComplete(fileName, folder);
    this.setFormState({
      isLoading: false,
      progress: 0
    })
  };

  setFormState = (newState) => {
    let state = this.state;
    state = Object.assign(state, newState);
    this.setState(state);
  };

  render () {
    const { isLoading, progress, src } = this.state;
    const { classes, uploadFolder, onUploadError } = this.props;
    return (
      <div>
        <div className={classes.container}>
          <img
            src={src}
            width={120}
            height={120}
            alt='avatar'
          />
          {isLoading ?
            <React.Fragment>
              <div className={classes.overlay}>&nsp;</div>
              <div className={classes.text}>uploading...</div>
              <CircularProgress
                className={classes.progress}
                variant='determinate'
                value={progress}
              />
            </React.Fragment>
            : null
          }
        </div>
        <FileUploader
          fileSpecs={this.fileSpecs}
          uploadFolder={uploadFolder}
          onUploadStart={this.handleUploadStart}
          onUploadProgress={this.handleUploadProgress}
          onUploadComplete={this.handleUploadComplete}
          onUploadError={onUploadError}
          buttonText='Choose Image'
          buttonDisabled={isLoading}
        />
      </div>
    );
  }
}

ImageUploader.protoTypes = {
  currentImageSrc: PropTypes.string,
  uploadFolder: PropTypes.string,
  onUploadComplete: PropTypes.func.isRequired,
  onUploadError: PropTypes.func.isRequired
}

export default withStyles(styles)(ImageUploader);
