import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { handQueries } from '../../../../graphql/queries';

const HandJsonDownload = () => {
  const {
    productId,
    productTitle,
    handId,
    handTitle
  } = useParams();

  const navigate = useNavigate();

  const [getHand] = useLazyQuery(handQueries.HandById, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const handJson = data.handById.handJSON;
      const json = JSON.stringify(handJson, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = handTitle + '.json';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      navigate(`/admin/products/set/${productId}/${productTitle}/hands/${handId}/${handTitle}`);
    },
    onError: (err) => {
      console.log('getHand err', err);
    }
  });

  useEffect(() => {
    getHand({
      variables: {
        id: parseInt(handId)
      }
    });
  }, [getHand, handId]);

  return null;
};

export default HandJsonDownload;
