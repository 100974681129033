import React from 'react';
import { useOutletContext } from 'react-router-dom';
import validator from 'validator';
import {
  Box
} from '@mui/material';
import StoreForm from '../../../Components/Forms/Store/StoreForm.js';
import StoreInput from '../../../Components/Forms/Store/StoreInput.js';

const Personal = () => {
  const { user, handleSaveUser, handleSubmitError, handleSubmitSuccess } = useOutletContext();

  return (
    <Box sx={{ maxWidth: '38rem', marginLeft: 'auto', marginRight: 'auto' }}>
      <StoreForm
        fields={user}
        onSubmit={handleSaveUser}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitError={handleSubmitError}
      >
        <StoreInput
          id='firstName'
          label='first-name'
          autoComplete='given-name'
          autoFocus
          required
          maxLength={100}
          validate={(val) => (val ? '' : 'First name is required')}
        />
        <StoreInput
          id='lastName'
          label='last-name'
          autoComplete='family-name'
          required
          maxLength={100}
          validate={(val) => (val ? '' : 'Last name is required')}
        />
        <StoreInput
          id='email'
          label='email-address'
          autoComplete='email'
          required
          maxLength={100}
          validate={(val) => (validator.isEmail(val) ? '' : 'Invalid Email')}
        />
      </StoreForm>
    </Box>
  );
};

export default Personal;
