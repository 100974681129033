import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  component: {
    width: '100%',
    height: '14rem',
    borderRadius: '1rem',
    marginTop: '2rem'
  },
  invisible: {
    background: 'none',
    visibility: 'hidden'
  }
}));

const SearchResultsSkeleton = ({ numOfItems, invisible }) => {
  const classes = useStyles();
  const items = [];

  if (!numOfItems) {
    numOfItems = 12;
  }

  for (let i = 0; i < numOfItems; i++) {
    items.push(<Skeleton key={i} variant='rectangular' className={clsx(classes.component, invisible ? classes.invisible : null)} />);
  }
  return (
    <>
      {items}
    </>
  );
};

SearchResultsSkeleton.propTypes = {
  numOfItems: PropTypes.number,
  invisible: PropTypes.bool
};

export default SearchResultsSkeleton;
