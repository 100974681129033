import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Search } from '@mui/icons-material';
import BBInput from '../Forms/BBInput.js';
import BBDateFilters from '../BBDateFilters.js';
import SearchChip from '../SearchChip.js';
import AddButton from '../Forms/AddButton.js';
import BBSelect from '../Forms/BBSelect.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  tableWrapper: {
    paddingLeft: 25,
    paddingTop: 25,
    paddingBottom: 50
  },
  submitButton: {
    marginTop: -3
  },
  chip: {
    marginTop: theme.spacing(3)
  },
  filtersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  }
}));

const AdminTableFilter = (props) => {
  const {
    count,
    showDates,
    showSearch,
    onAddClick,
    buttons,
    additionalDropdown,
    dateFilterOptions,
    onSearchSubmit,
    searchParams
  } = props;

  const {
    dateTo,
    dateFrom,
    dateRange,
    searchState
  } = searchParams;

  const classes = useStyles();

  const [localSearchTerm, setLocalSearchTerm] = useState('');

  const handleDelete = () => {
    onSearchSubmit({ ...searchParams, searchTerm: '', searchState: 'off' });
  };

  const handleInputChange = (input) => {
    setLocalSearchTerm(input.value);
  };

  const handleDateFiltersChange = (changes) => {
    const newSearchParams = { ...searchParams };
    newSearchParams.dateRange = changes.range ? changes.range : 'Custom';
    if (changes.from) {
      newSearchParams.dateFrom = moment(changes.from);
    }
    if (changes.to) {
      newSearchParams.dateTo = moment(changes.to);
    }
    newSearchParams.searchState = 'on';
    onSearchSubmit(newSearchParams);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchSubmit({ ...searchParams, searchState: 'on', searchTerm: localSearchTerm });
  };

  const dateFilters = (
    <BBDateFilters
      onChange={handleDateFiltersChange}
      to={dateTo}
      from={dateFrom}
      range={dateRange}
      dateFilterOptions={dateFilterOptions}
    />
  );

  const dropdown = additionalDropdown &&
    <BBSelect
      {...additionalDropdown}
      required={false}
      valueField='name'
      keyField='name'
      variant='standard'
      customStyles={{ width: '20rem' }}
    />;

  let filters;
  if (showDates && additionalDropdown) {
    filters = (
      <div className={classes.filtersWrapper}>
        {dateFilters}
        {dropdown}
      </div>
    );
  } else if (showDates && !additionalDropdown) {
    filters = dateFilters;
  } else if (additionalDropdown && !showDates) {
    filters = dropdown;
  }

  return (
    <>
      <div className={classes.tableWrapper}>
        <form onSubmit={handleSubmit}>
          {filters}
          <Grid container spacing={3}>
            {
              showSearch !== false &&
              (
                <>
                  <Grid item xs={6}>
                    <BBInput
                      id='search'
                      name='search'
                      type='text'
                      label=''
                      maxLength={100}
                      value={localSearchTerm}
                      onChange={handleInputChange}
                      variant='standard'
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Search color='action' />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className={classes.submitButton}
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={localSearchTerm.length === 0}
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
              )
            }
            <Grid item xs={3}>
              <Grid
                container
                justifyContent='flex-end'
                style={{ paddingRight: 20 }}
              >
                <Grid item>
                  {
                    onAddClick &&
                      <AddButton
                        onClick={onAddClick}
                        text='Add'
                      />
                  }
                  {buttons && buttons}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SearchChip
            label={`${count} search results`}
            name='adminSearch'
            active={searchState === 'on'}
            className={classes.chip}
            onDelete={handleDelete}
          />
        </form>
      </div>
    </>
  );
};

AdminTableFilter.propTypes = {
  searchParams: PropTypes.object,
  onSearchSubmit: PropTypes.func.isRequired,
  count: PropTypes.number,
  showDates: PropTypes.bool,
  showSearch: PropTypes.bool,
  onAddClick: PropTypes.func,
  buttons: PropTypes.object,
  additionalDropdown: PropTypes.object,
  dateFilterOptions: PropTypes.array
};

export default AdminTableFilter;
