import React from 'react';
import {
  createBrowserRouter,
  Outlet
} from 'react-router-dom';
import EnumValues from './Components/Queries/EnumValues.js';
import App from './App.js';
import Error404 from './Views/Error/Error404.js';
import Error401 from './Views/Error/Error401.js';
import Home from './Views/Home/Home.js';
import Customer from './Views/Customer/Customer.js';
import Login from './Views/Customer/Login/Login.js';
import Logout from './Views/Customer/Login/Logout.js';
import Register from './Views/Customer/Register/Register.js';
import Admin from './Views/Admin/Admin.js';
import RequireAuth from './Components/RequireAuth.js';
import ImpersonateUser from './Views/Admin/Users/ImpersonateUser.js';
import ForgottenPassword from './Views/Customer/Login/ForgottenPassword.js';
import ResetPassword from './Views/Customer/Login/ResetPassword.js';
import RegisterPassword from './Views/Customer/Register/RegisterPassword.js';
import SetHandDetails from './Views/Customer/Store/Hand/SetHandDetails.js';
import CollectionHandDetails from './Views/Customer/Store/Hand/CollectionHandDetails.js';

import CheckoutWrapper from './Views/Customer/Store/Checkout/CheckoutWrapper.js';
import IdealPaymentLanding from './Views/Customer/Store/Checkout/IdealPaymentLanding.js';
import StripeWrapper from './Components/Store/Checkout/StripeWrapper.js';
import { getEnvVar } from './helpers/env.js';

import {
  AllProducts,
  Author,
  Authors,
  Help,
  Library,
  LinkedProduct,
  Products,
  ProductWrapper,
  SearchResults
} from './Views/Customer';
import {
  Account,
  Password,
  Personal,
  AccountPurchases,
  Payment
} from './Views/Customer/Account';
import {
  Authors as AdminAuthors,
  AuthorPurchases,
  Dashboard,
  HandDetails,
  HandJsonDownload,
  Purchases as AdminPurchases,
  Products as AdminProducts,
  ProductDetails,
  PurchasePage,
  AddProduct,
  RoyaltiesOverview,
  Tags,
  Users,
  Vouchers
} from './Views/Admin';
import TestGrid from './__tests__/TestGrid.js';
import TestGridSkeleton from './__tests__/TestGridSkeleton.js';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error404 />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'test',
        element: <TestGrid />
      },
      {
        path: 'skeletons',
        element: <TestGridSkeleton />
      },
      {
        path: 'logout',
        element: <Logout />
      },
      {
        path: 'impersonate',
        element: <ImpersonateUser />
      },
      {
        path: '/:language?/products/:linkId?/:setId/:setTitle/hands/:handId/:handTitle/:embed?',
        element: <SetHandDetails />
      },
      {
        path: '/:language?/sets/:setId/:setTitle/hands/:handId/:handTitle',
        element: <SetHandDetails />
      },
      {
        path: '/:language?/collections/:collectionId/sets/:setId/:setTitle/hands/:handId/:handTitle',
        element: <CollectionHandDetails />
      },
      {
        path: '/:language/products/:linkId',
        element: <LinkedProduct />
      },
      {
        path: 'checkout',
        element: (
          <StripeWrapper stripeApiKey={getEnvVar('STRIPE_API_KEY')}>
            <CheckoutWrapper />
          </StripeWrapper>
        )
      },
      {
        path: 'checkout/idealpayment',
        element: (
          <IdealPaymentLanding />
        )
      },
      {
        element: <Customer />,
        children: [
          {
            path: 'search',
            element: <SearchResults />,
            handle: {
              crumb: 'search-results'
            }
          },
          {
            path: 'products',
            element: <Outlet />,
            handle: {
              crumb: 'store'
            },
            children: [
              {
                index: true,
                element: <Products />
              },
              {
                path: 'all',
                element: <AllProducts />,
                handle: {
                  crumb: 'all-products'
                }
              },
              {
                path: ':language?/:id/:title',
                element: <ProductWrapper />,
                handle: {
                  crumb: ':title'
                }
              }
            ]
          },
          {
            path: 'collections',
            element: <Outlet />,
            handle: {
              crumb: 'products'
            },
            children: [
              {
                path: ':id/:title',
                element: <ProductWrapper />,
                handle: {
                  crumb: ':title'
                }
              }
            ]
          },
          {
            path: 'sets',
            element: <Outlet />,
            handle: {
              crumb: 'products'
            },
            children: [
              {
                path: ':id/:title',
                element: <ProductWrapper />,
                handle: {
                  crumb: ':title'
                }
              }
            ]
          },
          {
            path: 'library',
            element: <Library />,
            handle: {
              crumb: 'my-library'
            }
          },
          {
            path: '/authors',
            element: <Outlet />,
            handle: {
              crumb: 'authors'
            },
            children: [
              {
                index: true,
                element: <Authors />
              },
              {
                path: ':authorId/:authorName',
                element: <Author />,
                handle: {
                  crumb: ':authorName',
                  crumbColour: '#fff'
                }
              }
            ]
          },
          {
            path: 'account',
            element: <Account />,
            handle: {
              crumb: 'account'
            },
            children: [
              {
                index: true,
                element: <Personal />,
                handle: {
                  crumb: 'personal'
                }
              },
              {
                path: 'payment',
                element: (
                  <StripeWrapper stripeApiKey={getEnvVar('STRIPE_API_KEY')}>
                    <Payment />
                  </StripeWrapper>
                ),
                handle: {
                  crumb: 'payment'
                }
              },
              {
                path: 'purchases',
                element: <AccountPurchases />,
                handle: {
                  crumb: 'purchases'
                }
              },
              {
                path: 'password',
                element: <Password />,
                handle: {
                  crumb: 'password'
                }
              }
            ]
          },
          {
            path: 'login',
            element: <Login />,
            handle: {
              crumb: 'login'
            }
          },
          {
            path: 'register',
            element: <Register />,
            handle: {
              crumb: 'register'
            }
          },
          {
            path: 'forgottenpassword',
            element: <ForgottenPassword />
          },
          {
            path: 'resetpassword/:token',
            element: <ResetPassword />
          },
          {
            path: 'register/:token',
            element: <RegisterPassword />
          },
          {
            path: 'help',
            element: <Help />
          },
          {
            path: 'error401',
            element: <Error401 />
          }
        ]
      },
      {
        element: <Admin />,
        children: [
          {
            path: 'admin',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: (
                  <RequireAuth authorisedUserTypes={['Admin', 'Publisher', 'Editor']}>
                    <Dashboard />
                  </RequireAuth>
                )
              },
              {
                path: 'authors',
                element: (
                  <RequireAuth authorisedUserTypes={['Admin']}>
                    <AdminAuthors />
                  </RequireAuth>
                )
              },
              {
                path: 'products',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <AdminProducts />
                  },
                  {
                    path: ':type/add',
                    element: <AddProduct />
                  },
                  {
                    path: ':type/:id/:title',
                    element: <ProductDetails />
                  },
                  {
                    path: 'set/:productId/:productTitle/hands/add',
                    element: <HandDetails />
                  },
                  {
                    path: 'set/:productId/:productTitle/hands/:handId/:handTitle',
                    element: <HandDetails />
                  },
                  {
                    path: 'set/:productId/:productTitle/hands/:handId/:handTitle/download',
                    element: <HandJsonDownload />
                  }
                ]
              },
              {
                path: 'royalties',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <RequireAuth authorisedUserTypes={['Admin']}>
                        <EnumValues name='CurrencyEnum'>
                          {currencies => (
                            <RoyaltiesOverview currencies={currencies} />
                          )}
                        </EnumValues>
                      </RequireAuth>
                    )
                  },
                  {
                    path: ':id/:author',
                    element: (
                      <RequireAuth authorisedUserTypes={['Admin']}>
                        <AuthorPurchases />
                      </RequireAuth>
                    )
                  }
                ]
              },
              {
                path: 'sales',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <RequireAuth authorisedUserTypes={['Admin']}>
                        <AdminPurchases />
                      </RequireAuth>
                    )
                  },
                  {
                    path: ':id',
                    element: (
                      <RequireAuth authorisedUserTypes={['Admin']}>
                        <PurchasePage />
                      </RequireAuth>
                    )
                  }
                ]
              },
              {
                path: 'tags',
                element: (
                  <RequireAuth authorisedUserTypes={['Admin']}>
                    <Tags />
                  </RequireAuth>
                )
              },
              {
                path: 'users',
                element: (
                  <RequireAuth authorisedUserTypes={['Admin']}>
                    <Users />
                  </RequireAuth>
                )
              },
              {
                path: 'vouchers',
                element: (
                  <RequireAuth authorisedUserTypes={['Admin']}>
                    <Vouchers />
                  </RequireAuth>
                )
              }
            ]
          }
        ]
      }
    ]
  }
]);

export default router;
