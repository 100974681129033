import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tags from '../../Queries/Tags.js';
import Authors from '../../Queries/Authors.js';
import {
  groupAndSimplifyTags,
  tagGroupsSorted
} from '../../../helpers/productTagFilter.js';
import { getEnvVar } from '../../../helpers/env.js';
import FilterPickerLanguage from './FilterPickerLanguage.js';
import FilterPickerGroup from './FilterPickerGroup.js';
import { convertLangCodeToGraphqlEnum } from '../../../helpers/locale.js';

// featured author ids are hardcoded in the client side env for now
// in the future they may be based e.g. on automatically generated author popularity
const featuredAuthorIds = getEnvVar('FEATURED_AUTHOR_IDS')
  .split(',')
  .map(id => parseInt(id));

const ProductFilterPicker = ({ selectedTagsByGroup, onTagsChange, onContentLanguageChange, contentLanguage, locale }) => {
  return (
    <>
      <FilterPickerLanguage
        onContentLanguageChange={onContentLanguageChange}
        contentLanguage={contentLanguage}
        locale={locale}
      />
      <Tags groups='all'>
        {(tagData) => (
          <Authors>
            {(authorData) => {
              const filteredAuthors = _.filter(authorData, (author) => (
                author.products.length > 0 &&
                author.language === convertLangCodeToGraphqlEnum(contentLanguage)
              ));
              const allTags = groupAndSimplifyTags(tagData, filteredAuthors, featuredAuthorIds, contentLanguage);

              return tagGroupsSorted.map(groupName => (
                <FilterPickerGroup
                  key={groupName}
                  groupName={groupName}
                  tags={allTags[groupName]}
                  selectedTags={selectedTagsByGroup[groupName]}
                  onTagClicked={onTagsChange}
                  translateTags={groupName !== 'Author'}
                />
              ));
            }}
          </Authors>
        )}
      </Tags>
    </>
  );
};

ProductFilterPicker.propTypes = {
  selectedTagsByGroup: PropTypes.object.isRequired,
  onTagsChange: PropTypes.func.isRequired,
  onContentLanguageChange: PropTypes.func.isRequired
};

export default ProductFilterPicker;
