import gql from 'graphql-tag';

const productLinkQueries = {
  ProductLinks: gql`
    query (
      $productId: Int!, 
      $language: LanguageEnum
    ) {
      productLinks (
        productId: $productId,
        language: $language
      ) {
        id
        linkId
      }
    }
  `
};

export default productLinkQueries;
