import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';

const LoggedOut = (props) => {
  const { onLoginClick, onRegisterClick } = props;
  return (
    <div>
      <Paper style={{ width: 550, padding: '3rem 4rem', margin: '70px auto' }}>
        <h1><BBTranslate textId='your-library' /></h1>
        <p>
          <BBTranslate textId='to-view-your-library' />.
        </p>
        <div>
          <StoreButton
            onClick={onLoginClick}
            label='login'
          />
          &nbsp;&nbsp;
          <StoreButton
            onClick={onRegisterClick}
            label='register'
          />
        </div>
      </Paper>
    </div>
  );
};

LoggedOut.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onRegisterClick: PropTypes.func.isRequired
};

export default LoggedOut;
