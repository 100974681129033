import gql from 'graphql-tag';

const purchaseStatQueries = {
  PurchaseStats: gql`
    query ($dateTo: String!, $dateFrom: String!) {
      purchaseStats (dateTo: $dateTo, dateFrom: $dateFrom) {
        date
        count
        totalSales
      }
    }
  `
};

export default purchaseStatQueries;
