import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  FormControl,
  TextField
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useLazyQuery } from '@apollo/client';
import productQueries from '../../../graphql/queries/product.js';
import BBAdminForm from '../../../Components/Forms/BBAdminForm.js';
import BBInput from '../../../Components/Forms/BBInput.js';
import { stripTypeNameFromMutationVars } from '../../../helpers/gql.js';
import BBDatePicker from '../../../Components/BBDatePicker.js';
import moment from 'moment';

const styles = {
  gridItem: {
    marginRight: '1rem'
  },
  chip: {
    margin: '0 1rem 1rem 0'
  }
};

const VoucherForm = (props) => {
  const {
    voucher,
    updateVoucher,
    createVoucher,
    ...other
  } = props;

  const [activationDate, setActivationDate] = useState(voucher && voucher.activationDate && moment(voucher.activationDate));
  const [expiryDate, setExpiryDate] = useState(voucher && voucher.activationDate && moment(voucher.expiryDate));
  const [selectedProduct, setSelectedProduct] = useState(voucher && voucher.product);
  const [products, setProducts] = useState(null);

  const [getProducts] = useLazyQuery(productQueries.OnSaleProducts, {
    onCompleted: (data) => {
      setProducts(data.onSaleProducts);
    }
  });

  const handleSubmit = (formState) => {
    formState.variables.activationDate = activationDate && activationDate.format();
    formState.variables.expiryDate = expiryDate && expiryDate.format();
    formState.variables.productId = selectedProduct && selectedProduct.id;
    if (voucher && voucher.id) {
      return updateVoucher(stripTypeNameFromMutationVars(formState));
    } else {
      return createVoucher(stripTypeNameFromMutationVars(formState));
    }
  };

  const handleActivationDateChange = (date) => {
    setActivationDate(date);
  };

  const handleExpiryDateChange = (date) => {
    setExpiryDate(date);
  };

  const handleProductChange = (e, value) => {
    setSelectedProduct(value);
  };

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  if (!products) {
    return null;
  }

  return (
    <>
      <BBAdminForm
        fields={voucher}
        onSubmit={handleSubmit}
        {...other}
      >
        <BBInput
          key='code'
          id='code'
          name='code'
          type='text'
          label='Code'
          required
          validate={(val) => (val ? '' : 'Code is required')}
        />
        <BBInput
          key='discountAmount'
          id='discountAmount'
          name='discountAmount'
          type='number'
          label='Discount Percentage'
          required
          inputProps={{
            inputProps: {
              min: 0,
              max: 100
            }
          }}
          validate={(val) => (val ? '' : 'Discount Percentage is required')}
        />
        <BBInput
          key='totalRedemptions'
          id='totalRedemptions'
          name='totalRedemptions'
          type='number'
          label='Total Redemptions'
          inputProps={{
            inputProps: {
              min: 0,
              max: 1000000
            }
          }}
        />
        <FormControl>
          <BBDatePicker
            bbFormIgnore
            label='Activation Date'
            date={activationDate}
            onChange={handleActivationDateChange}
          />
        </FormControl>
        <FormControl>
          <BBDatePicker
            bbFormIgnore
            label='Expiry Date'
            date={expiryDate}
            onChange={handleExpiryDateChange}
          />
        </FormControl>
        <FormControl>
          <Autocomplete
            value={selectedProduct}
            disablePortal
            autoComplete
            id='product-combo'
            options={products}
            fullWidth
            renderInput={(params) => <TextField {...params} label='Product' />}
            onChange={handleProductChange}
            getOptionLabel={(option) => option.title}
          />
        </FormControl>
      </BBAdminForm>
    </>
  );
};

VoucherForm.propTypes = {
  updateVoucher: PropTypes.func,
  createVoucher: PropTypes.func,
  onCancelClick: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  voucher: PropTypes.object
};

export default withStyles(styles)(VoucherForm);
