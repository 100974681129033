import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import IconButton from './MobileMenu/IconButton.js';
import BackToSetIcon from '@mui/icons-material/ListRounded';
import ReplayHandIcon from '@mui/icons-material/ReplayRounded';
import PlayNextHandIcon from '@mui/icons-material/ArrowForwardRounded';
import StoreIcon from '@mui/icons-material/StorefrontRounded';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import BBTranslate from '../../../../Components/Locale/BBTranslate.js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.7rem 0.4rem'
  },
  gridItem: {
    padding: '0 0.3rem'
  },
  icon: {
    fontSize: 30
  },
  buyButton: {
    backgroundColor: '#FFB73A'
  },
  removeFromCartButton: {
    backgroundColor: theme.palette.styleguide.red
  }
}));

const CompleteButtons = (props) => {
  const {
    onReplayClick,
    onPlayNextClick,
    onReturnToProductClick,
    onBrowseStoreClick,
    onPurchaseClick,
    isInCart,
    onRemoveFromCartClick,
    isFinalHandInProduct,
    purchased,
    onSale,
    embed
  } = props;

  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
      >
        {
          !embed && onReturnToProductClick
            ? <Grid item xs className={classes.gridItem}>
              <IconButton
                icon={<BackToSetIcon className={classes.icon} />}
                onClick={onReturnToProductClick}
              >
                <BBTranslate textId='return-to-product-details' />
              </IconButton>
            </Grid>
            : null
        }
        {
          purchased
            ? <>
              <Grid item xs className={classes.gridItem}>
                <IconButton
                  icon={<ReplayHandIcon className={classes.icon} />}
                  onClick={onReplayClick}
                  size='large'
                >
                  <BBTranslate textId='replay-hand' />
                </IconButton>
              </Grid>
              {
              !isFinalHandInProduct && (
                <Grid item xs className={classes.gridItem}>
                  <IconButton
                    icon={<PlayNextHandIcon className={classes.icon} />}
                    onClick={onPlayNextClick}
                    size='large'
                  >
                    <BBTranslate textId='play-next-hand' />
                  </IconButton>
                </Grid>
              )
            }
            </>
            : <>
              {
                onBrowseStoreClick
                  ? <Grid item xs className={classes.gridItem}>
                    <IconButton
                      icon={<StoreIcon className={classes.icon} />}
                      onClick={onBrowseStoreClick}
                    >
                      <BBTranslate textId='browse-products' />
                    </IconButton>
                  </Grid>
                  : null
              }              
              {
              onSale
                && (
                  <Grid item xs className={classes.gridItem}>
                    {
                      isInCart
                        ? (
                          <IconButton
                            icon={<RemoveShoppingCartIcon className={classes.icon} />}
                            onClick={onRemoveFromCartClick}
                            className={classes.removeFromCartButton}
                            size='large'
                          >
                            <BBTranslate textId='remove-from-cart' />
                          </IconButton>
                        )
                        : (
                          <IconButton
                            icon={<AddShoppingCartIcon className={classes.icon} />}
                            onClick={onPurchaseClick}
                            className={classes.buyButton}
                            size='large'
                          >
                            <BBTranslate textId='add-to-cart' />
                          </IconButton>
                        )
                    }
                  </Grid>
                )
            }
            </>
        }
      </Grid>
    </div>
  );
};

CompleteButtons.propTypes = {
  onReplayClick: PropTypes.func,
  onPlayNextClick: PropTypes.func,
  onReturnToProductClick: PropTypes.func,
  onBrowseStoreClick: PropTypes.func,
  onPurchaseClick: PropTypes.func,
  onRemoveFromCartClick: PropTypes.func,
  isInCart: PropTypes.bool,
  purchased: PropTypes.bool,
  isFinalHandInProduct: PropTypes.bool,
  onSale: PropTypes.bool
};

export default CompleteButtons;
