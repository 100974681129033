import { useTextTranslation } from '../../Hooks';

// props.textId can accept:

// Single String.
// Use a string if you only have one item to translate which will be an exact match
// for an ID in the languages json (public/lang/file.json).

// Array.
// Use an array if you would like to translate multiple item ID's within the same
// string. useTextTranslation will translate each item in turn and concactenate
// into a single string.

// If no ID match:
// For both string and array, if there is no matching ID found in the language JSON
// it will just return the ID.  This is particularly useful in arrays as you can
// still add items that won't match anything but they'll still be concactenated into
// your return string.

const BBTranslate = (props) => useTextTranslation(props.textId);

export default BBTranslate;
