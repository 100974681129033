import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@mui/styles';
import Trick from '../../../../Components/Hands/Cards/Trick.js';
import CardList from '../../../../Components/Hands/Cards/CardList.js';
import CardPlayAnimation from '../../../../Components/Hands/Cards/CardPlayAnimation.js';
import PlayComplete from './PlayComplete.js';
import clsx from 'clsx';
import {
  getCardsForSeat,
  convertCodeToCardObject,
  arrangeSeatsBasedOnViewPoint,
  isTouchingCard
 } from '../../../../helpers/card.js';
import {
  getSeatForPlay,
  getCardFromTrickBySeat,
  getSuitToPlay
} from '../../../../helpers/play.js';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  flexGrow: {
    flex: 1,
    minWidth: 0,
    '@media (orientation: portrait)': {
      // flex: 'auto'
      // flexGrow: 1
    }
  },
  flexAuto: {
    flex: 'auto'
  },
  rowFirst: {
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem 0',
    '@media (orientation: portrait)': {
      padding: 0
    }
  },
  rowMiddle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowLast: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '2rem 0',
    '@media (orientation: portrait)': {
      padding: 0
    }
  },
  columnFirst: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2rem',
    '@media (orientation: portrait)': {
      padding: 0
    }
  },
  columnMiddle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    '@media (orientation: portrait)': {
      padding: 0
    }
  },
  columnLast: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '2rem',
    '@media (orientation: portrait)': {
      padding: 0
    }
  }
};

class CardPlay extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      cardAnimation: {
        card: {
          suit: 'S',
          rank: 'A'
        },
        startPos: {
          left: 0,
          top: 0,
          width: 100
        },
        endPos: {
          left: 0,
          top: 0,
          width: 100
        },
        speed: 2000
      },
      animate: false
    };
    const { playDelay, onPlayStarted, hand, playComplete } = props;

    if (!hand.auctionOnly && !playComplete) {
      onPlayStarted();
      setTimeout(() => {
        this.autoPlay();
      }, playDelay);
    }
  }

  cardRefs = [];
  trickRefs = [];

  handleCardRefCallback = (el) => {
    if (el) {
      this.cardRefs.push(
        {
          value: el.alt,
          ref: el
        }
      );
    }
  };

  handleTrickRefCallback = (el) => {
    if (el) {
      this.trickRefs.push(
        {
          direction: el.id,
          ref: el
        }
      );
    }
  };

  animateCard = (card) => {
    const { animationSpeed, activeSeat } = this.props;
    // get value from suit and rank (car object)
    const value = `${card.rank}${card.suit}`;
    // get cardProps for this card from cardRefs array
    let cardRef = _.find(this.cardRefs, { value: value}).ref;
    let cardProps = cardRef.getBoundingClientRect();
    /* cardRef.style.visibility = 'hidden';
    cardRef.style.opacity = 0;
    cardRef.style.width = 0;
    cardRef.style.marginRight = 0; */
    // get destination props from trickRefs based on direction
    const destinationRef = _.find(this.trickRefs, { direction: activeSeat }).ref;
    const destinationProps = destinationRef.getBoundingClientRect();
    // calculate the header height because we have to subtract it from the top start and destination position
    const headerHeight = document.getElementById('playHeader') ? document.getElementById('playHeader').clientHeight : 70;
    const newTopStartPos = cardProps.top - headerHeight;
    const newTopDestinationPos = destinationProps.top - headerHeight;
    // setup the card animation props
    let cardAnimation = {
      startPos: {
        top: newTopStartPos,
        left: cardProps.left,
        width: cardProps.width === 0 ? 90 : cardProps.width
      },
      endPos: {
        top: newTopDestinationPos,
        left: destinationProps.left,
        width: destinationProps.width
      },
      card: card,
      speed: animationSpeed
    };
    this.setState({
      cardAnimation: cardAnimation,
      animate: true
    });
  };

  handleAnimationComplete = (card) => {
    const {
      trick,
      onCardPlayed,
      cardPlayDelay
    } = this.props;

    // fire event and check if comment added which affects the delay below
    const hasComment = onCardPlayed(card);

    // bonkers hacking to get the delay right
    let delayMultiplier = 1;
    if (hasComment) {
      delayMultiplier = 2;
    }
    if (trick.length === 4) {
      delayMultiplier += 1;
    }

    this.setState({
      animate: false
    });

    setTimeout(() => {
      this.autoPlay();
    }, hasComment ? cardPlayDelay * delayMultiplier : cardPlayDelay);
  };

  autoPlay = () => {
    const {
      onCardToPlay,
      paused,
      activeSeat,
      trickNumber,
      onPlayComplete
    } = this.props;
    const { viewpoint, play, dummy, declarer } = this.props.hand;

    if (!paused) {
      // check to see if the hand has finished
      if (trickNumber === 13) {
        return onPlayComplete();
      }

      // get trick from play data
      const authorsTrick = play[trickNumber];

      // get the card the author chose for this seat
      const correctCard = getCardFromTrickBySeat(authorsTrick, activeSeat);

      if (
        activeSeat === viewpoint
        || (viewpoint === declarer && activeSeat === dummy)
      ) {
        // !!!!> OUR GO <!!!!
        // raise event so message/comment can be shown
        onCardToPlay(correctCard, trickNumber);
      } else {
        // play the card
        this.playCard(correctCard, 'left');
      }
    }
  };

  playCard = (card) => {
    const { onCardPlaying } = this.props;
    onCardPlaying(card);
    this.animateCard(card);
  };

  handleCardClick = (card) => {
    const { animate } = this.state;
    const {
      onWrongChoice,
      onTouchingCard,
      activeSeat,
      trickNumber,
      hand,
      deal
    } = this.props;
    const { play, declarer, viewpoint } = hand;

    // don't do anything if the card is currently animating as we don't want to
    // do the same stuff twice
    if (!animate) {
      // convert card code to object
      card = convertCodeToCardObject(card);

      // get seat for the card clicked from the play
      card.seat = getSeatForPlay(card, play);

      // get trick from play data
      const authorsTrick = play[trickNumber];

      // get the card the author chose for this seat
      const correctCard = getCardFromTrickBySeat(authorsTrick, activeSeat);

      // cards used for the active seat (viewpoint or dummy)
      const cardsForActiveSeat = getCardsForSeat(deal, activeSeat);

      // if correct card
      if (card.rank === correctCard.rank && card.suit === correctCard.suit) {
        this.playCard(correctCard, 'right');
      } else if (
        viewpoint === declarer && // don't enable touching cards for defense hands
        isTouchingCard(correctCard, card, cardsForActiveSeat)
      ) {
        onTouchingCard(card, correctCard);
        this.playCard(correctCard, 'right');
      } else {
        onWrongChoice(correctCard, 'play');
      }
    }
  };

  getEnabledSuit = (direction) => {
    const { playComplete, activeSeat, trick, deal, canPlay } = this.props;
    if (!playComplete && activeSeat === direction && canPlay) {
      const suitToPlay = getSuitToPlay(activeSeat, trick, deal);
      return suitToPlay;
    } else {
      return '';
    }
  };

  isFlipped = (direction) => {
    const { hand, playComplete, trickNumber, trick } = this.props;
    let flipped = (!playComplete &&
      direction !== hand.viewpoint &&
      direction !== hand.dummy);

    // dummy also needs to be flipped when no cards played yet
    if (direction === hand.dummy && trickNumber === 0 && trick.length === 0) {
      flipped = true;
    }

    return flipped;
  };

  isStack = (direction) => {
    const { dummy } = this.props.hand;
    const { trickNumber, trick } = this.props;
    let stacked = false;
    if (direction === dummy) {
      if (trickNumber === 0 && trick.length === 0) {
        stacked = false;
      } else {
        stacked = true;
      }
    }
    return stacked;
  };

  getCardsForDirection = (direction, index) => {
    const cardsForSeat = getCardsForSeat(this.props.deal, direction.value);
    const flipped = this.isFlipped(direction.value);
    const enabledSuit = this.getEnabledSuit(direction.value);
    const isStack = this.isStack(direction.value)
    const { isPortrait } = this.props;
    return (
      <CardList
        key={index}
        index={index}
        cards={cardsForSeat}
        flipped={flipped}
        enabledSuit={enabledSuit}
        orientation={index === 0 || index === 2 ? 'horizontal' : 'vertical' }
        bySuit={false}
        isStack={isStack}
        onCardClick={this.handleCardClick}
        cardRefCallback={this.handleCardRefCallback}
        contract={this.props.hand.contract}
        isPortrait={isPortrait}
      />
    );
  }

  componentDidUpdate (prevProps) {
    const { paused, canPlay } = this.props;
    // check to see if the play has restarted after a pause
    if (paused !== prevProps.paused && paused === false) {
      // if its not our go (canPlay = false)
      // then continue with the auto play
      if (!canPlay) {
        this.autoPlay();
      }
    }
  }

  render () {
    const {
      classes,
      hand,
      animationStartDelay,
      isPortrait,
      playComplete,
      trick,
      activeSeat,
      ...rest
    } = this.props;
    const {
      cardAnimation,
      animate
    } = this.state;
    const directions = arrangeSeatsBasedOnViewPoint(hand.viewpoint);

    return (
      <div className={classes.container}>
        {
          playComplete || hand.auctionOnly
          ?
          <PlayComplete
            hand={hand}
            isPortrait={isPortrait}
            {...rest}
          />
          :
          <>
            <div className={clsx(classes.rowFirst, classes.flexAuto)}>
              {this.getCardsForDirection(directions[0], 0)}
            </div>
            <div className={clsx(classes.rowMiddle, classes.flexAuto)}>
              <div className={clsx(classes.columnFirst, classes.flexGrow)}>
                {this.getCardsForDirection(directions[3], 3)}
              </div>
              <div className={clsx(classes.columnMiddle, classes.flexGrow)}>
                <Trick
                  trick={trick}
                  viewpoint={hand.viewpoint}
                  trickRefCallback={this.handleTrickRefCallback}
                  activeSeat={activeSeat}
                  isPortrait={isPortrait}
                />
              </div>
              <div className={clsx(classes.columnLast, classes.flexGrow)}>
                {this.getCardsForDirection(directions[1], 1)}
              </div>
            </div>
            <div className={clsx(classes.rowLast, classes.flexAuto)}>
              {this.getCardsForDirection(directions[2], 2)}
            </div>
            <CardPlayAnimation
              card={cardAnimation.card}
              startPos={cardAnimation.startPos}
              endPos={cardAnimation.endPos}
              speed={cardAnimation.speed}
              delay={animationStartDelay}
              onAnimationComplete={this.handleAnimationComplete}
              animate={animate}
            />
          </>
        }
      </div>
    );
  }
}

CardPlay.propTypes = {
  onPlayStarted: PropTypes.func.isRequired,
  onCardToPlay: PropTypes.func.isRequired,
  onCardPlaying: PropTypes.func.isRequired,
  onCardPlayed: PropTypes.func.isRequired,
  onWrongChoice: PropTypes.func.isRequired,
  onTouchingCard: PropTypes.func.isRequired,
  onTrickWon: PropTypes.func.isRequired,
  onPlayComplete: PropTypes.func.isRequired,
  onReturnToProductClick: PropTypes.func,
  onPlayNextClick: PropTypes.func,
  onReplayClick: PropTypes.func,
  onBrowseStoreClick: PropTypes.func,
  onCommentCheck: PropTypes.func,
  onPurchaseClick: PropTypes.func,
  onRemoveFromCartClick: PropTypes.func,
  isInCart: PropTypes.bool,
  hand: PropTypes.object.isRequired,
  deal: PropTypes.array.isRequired,
  playComplete: PropTypes.bool,
  trick: PropTypes.array.isRequired,
  tricks: PropTypes.object.isRequired,
  trickNumber: PropTypes.number.isRequired,
  activeSeat: PropTypes.string.isRequired,
  playDelay: PropTypes.number.isRequired,
  cardPlayDelay: PropTypes.number.isRequired,
  animationSpeed: PropTypes.number.isRequired,
  animationStartDelay: PropTypes.number.isRequired,
  purchased: PropTypes.bool,
  isPreview: PropTypes.bool,
  isFinalHandInProduct: PropTypes.bool,
  startTrick: PropTypes.number,
  skipToSummary: PropTypes.bool.isRequired,
  onTricksChange: PropTypes.func,
  isPortrait: PropTypes.bool,
  paused: PropTypes.bool,
  canPlay: PropTypes.bool,
  onSale: PropTypes.bool
};

export default withStyles(styles)(CardPlay);
