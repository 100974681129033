import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import VoucherForm from './VoucherForm.js';
import VoucherById from '../../../Components/Queries/VoucherById.js';

const VoucherDetails = (props) => {
  const { id, ...other } = props;
  return (
    <>
      <VoucherById id={props.id}>
        {data => (
          <Mutation
            mutation={mutations.UpdateVoucher}
          >
            {updateVoucher => (
              <>
                <VoucherForm
                  updateVoucher={updateVoucher}
                  voucher={data}
                  {...other}
                />
              </>
            )}
          </Mutation>
        )}
      </VoucherById>
    </>
  );
};

VoucherDetails.propTypes = {
  id: PropTypes.number.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default VoucherDetails;
