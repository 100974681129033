import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Hand from '../../../Customer/Store/Hand/Hand.js';
import HooksWrapper from '../../../../Components/HooksWrapper.js';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: theme.spacing(2.5),
    left: theme.spacing(2)
  },
  close: {
    marginRight: theme.spacing(1)
  }
}));

const PreviewModal = (props) => {
  const { open, onCloseClick, ...other } = props;
  const classes = useStyles();
  return (
    <Modal
      open={open}
    >
      <div style={{ height: '100%' }} className={classes.root}>
        <HooksWrapper>
          {({ isPortrait, componentScale, screenDimensions, aspectRatio }) => (
            <Hand
              {...other}
              isPreview
              onPreviewCloseClick={onCloseClick}
              isPortrait={isPortrait}
              componentScale={componentScale}
              screenDimensions={screenDimensions}
              aspectRatio={aspectRatio}
            />
          )}
        </HooksWrapper>
      </div>
    </Modal>
  );
};

PreviewModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hand: PropTypes.object
};

export default PreviewModal;
