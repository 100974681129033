import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { adopt } from 'react-adopt';
import { mutations } from '../../../graphql';
import UserForm from './UserForm.js';
import UserById from '../../../Components/Queries/UserById.js';
import EnumValues from '../../../Components/Queries/EnumValues.js';

const UserDetails = (props) => {
  const { id, ...other } = props;
  const Composed = adopt({
    one: ({ render }) => <UserById id={id}>{render}</UserById>,
    two: ({ render }) => <EnumValues name='UserTypeEnum'>{render}</EnumValues>
  });
  return (
    <>
      <Composed>
        {({ one, two }) => (
          <Mutation
            mutation={mutations.UpdateUser}
          >
            {updateUser => (
              <Mutation
                mutation={mutations.ImpersonateUser}
              >
                {impersonateUser => (
                  <>
                    <UserForm
                      updateUser={updateUser}
                      impersonateUser={impersonateUser}
                      user={one}
                      typeEnum={two}
                      {...other}
                    />
                  </>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Composed>
    </>
  );
};

UserDetails.propTypes = {
  id: PropTypes.number.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default UserDetails;
