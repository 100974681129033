import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles(theme => ({
  button: {
    margin: '1em 1.5em 1em 0'
  },
  buttons: {
    marginTop: '1.5em'
  }
}));

const DummyMessageDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    onMakeAuctionOnlyClick,
    // onChangeViewpointClick,
    onChangeAuctionClick
  } = props;
  return (
    <Dialog
      open={open}
      aria-labelledby='form-dialog-title'
      className={classes.dialog}
    >
      <DialogTitle id='form-dialog-title'>
        Dummy is the same seat as the viewpoint
      </DialogTitle>
      <DialogContent>
        To continue you must choose one of the 2 options below:
        <div className={classes.buttons}>
          <Button
            onClick={onMakeAuctionOnlyClick}
            variant='outlined'
            color='primary'
            className={classes.button}
          >
            Make hand 'Auction Only'
          </Button>
          <Button
            onClick={onChangeAuctionClick}
            variant='outlined'
            color='primary'
            className={classes.button}
          >
            Change auction
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

DummyMessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onMakeAuctionOnlyClick: PropTypes.func.isRequired,
  onChangeViewpointClick: PropTypes.func.isRequired,
  onChangeAuctionClick: PropTypes.func.isRequired
};

export default DummyMessageDialog;
