import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPage from '../../../Components/AdminPage.js';
import { royaltyQueries } from '../../../graphql/queries';
import { getDateFilterOptionsByQuarter } from '../../../helpers/admin.js';

const AuthorPurchases = () => {
  const { id } = useParams();
  const dateFilterOptions = getDateFilterOptionsByQuarter();
  const initialDateRange = dateFilterOptions.find(option => option.label === 'Current Quarter');

  const columns = [
    { id: 'product.title', label: 'Product Title', sortColumn: 'product.title' },
    { id: 'product.price.priceUSD', label: 'Product Value ($)', sortColumn: 'product.price.priceUSD' },
    { id: 'discount', label: 'Discount Applied (%)', sortColumn: 'discount' },
    { id: 'purchase.currency', label: 'Currency', sortColumn: 'currency' },
    { id: 'price', label: 'Amount Paid', sortColumn: 'price' }
  ];

  return (
    <AdminPage
      columns={columns}
      query={royaltyQueries.RoyaltyDetails}
      rowsDataPath='royaltyDetails.purchaseItems'
      countDataPath='royaltyDetails.count'
      showDates
      showSearch={false}
      queryOptions={{ authorId: parseInt(id), currency: 'USD' }}
      initialOrderBy='purchase.purchaseDate'
      initialOrderByDirection='desc'
      initialDateRange={initialDateRange}
      onRowClick={() => {}}
      dateFilterOptions={dateFilterOptions}
      sectionName='royalties'
    />
  );
};

export default AuthorPurchases;
