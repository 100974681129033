import { writeCookie, readCookie } from './cookie.js';
const env = process.env.REACT_APP_ENV;
const pwaCookieName = `bb-pwa-${env}`;

export const persistCookie = dismissed => {
  writeCookie(pwaCookieName, dismissed);
};

export const getCookieVal = () => {
  return readCookie(pwaCookieName);
};

// https://stackoverflow.com/a/19572784
export const getAndroidVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  // eslint-disable-next-line
  var match = ua.match(/android\s([0-9\.]*)/i);
  return match ? match[1] : undefined;
};

// https://stackoverflow.com/a/14223920
export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
};

// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains 'Android'
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  /* if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  } */

  // https://stackoverflow.com/a/58065241
  if ((/iPad|iPhone|iPod/.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream) {
    return 'iOS';
  }

  return undefined;
};
