import React from 'react';
import { tagQueries } from '../../../graphql/queries';
import AdminPage from '../../../Components/AdminPage.js';
import TagDetails from './TagDetails.js';
import AddTag from './AddTag.js';

const Tags = () => {
  const columns = [
    { id: 'name', label: 'Name', sortColumn: 'name' },
    { id: 'tagGroup.name', label: 'Group', sortColumn: 'tagGroup.name' }
  ];
  return (
    <>
      <AdminPage
        columns={columns}
        query={tagQueries.TagSearch}
        initialOrderBy='name'
        rowsDataPath='tagSearch.tags'
        countDataPath='tagSearch.count'
        showAddButton
        sectionName='tags'
      >
        {
          (id, onSubmitSuccess, onCancelClick, refetch, openSnackbar) => (
            <>
              {
              id && id > 0
                ? <TagDetails
                    id={id}
                    onCancelClick={onCancelClick}
                    onSubmitSuccess={(message) => {
                      openSnackbar(message, 'success');
                      onSubmitSuccess(refetch);
                    }}
                    onSubmitError={(message) => {
                      openSnackbar(message, 'error');
                    }}
                  />
                : <AddTag
                    onCancelClick={onCancelClick}
                    onSubmitSuccess={(message) => {
                      openSnackbar(message, 'success');
                      onSubmitSuccess(refetch);
                    }}
                    onSubmitError={(message) => {
                      openSnackbar(message, 'error');
                    }}
                  />
            }
            </>
          )
        }
      </AdminPage>
    </>
  );
};

export default Tags;
