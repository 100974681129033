import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  caption: {
    color: theme.palette.grey[600]
  },
  div: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
});

const SectionLabel = (props) => {
  const { classes, text, children } = props;
  return (
    <div className={classes.div}>
      <Typography variant='caption' className={classes.caption}>
        {text}:&nbsp;&nbsp;
        {
          children || null
        }
      </Typography>
    </div>
  );
};

SectionLabel.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.object
};

export default withStyles(styles)(SectionLabel);
