import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import StoreButton from '../Forms/Store/StoreButton.js';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: ({ extraWhitespace }) => extraWhitespace ? '14rem 2rem' : '0 0 14rem 0',
    maxWidth: '118rem',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: ({ extraWhitespace }) => extraWhitespace ? '4rem 2rem' : '0 0 4rem 0'
    }
  },
  heading: {
  },
  subheading: {
    marginTop: '1rem'
  },
  headingContainer: {
    position: 'relative',
    marginBottom: ({ extraWhitespace }) => extraWhitespace ? '7rem' : '4rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '4rem'
    }
  },
  headerButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  bottomButtonContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: '4rem',
      display: 'block'
    }
  }
}));

const StoreSectionContainer = (props) => {
  const {
    heading,
    subheading,
    ctaButtonLabel,
    onCtaButtonClick,
    backgroundColor,
    extraWhitespace,
    children
  } = props;
  const classes = useStyles({ extraWhitespace });

  return (
    <div style={{ backgroundColor }}>
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <Typography variant='h1' component='h2' className={classes.heading}>
            {heading}
          </Typography>
          {subheading && (
            <Typography variant='body4' className={classes.subheading}>
              {subheading}
            </Typography>
          )}
          {ctaButtonLabel && onCtaButtonClick && (
            <div className={classes.headerButtonContainer}>
              <StoreButton
                label={ctaButtonLabel}
                onClick={onCtaButtonClick}
              />
            </div>
          )}
        </div>
        {children}
        {ctaButtonLabel && (
          <div className={classes.bottomButtonContainer}>
            <StoreButton
              fullWidth
              label={ctaButtonLabel}
              onClick={onCtaButtonClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

StoreSectionContainer.propTypes = {
  heading: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  subheading: PropTypes.node,
  backgroundColor: PropTypes.string,
  ctaButtonLabel: PropTypes.string,
  onCtaButtonClick: PropTypes.func,
  extraWhitespace: PropTypes.bool
};

export default StoreSectionContainer;
