import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const BBLinearProgress = () => {
  return (
    <LinearProgress />
  );
};

export default BBLinearProgress;
