import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';
import { Typography, Paper } from '@mui/material';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 550,
    width: '100%',
    padding: '3rem 4rem',
    margin: '0 auto'
  },
  message: {
    marginBottom: '2rem'
  }
}));

const Empty = (props) => {
  const { onStoreClick } = props;
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.container}>
        <Typography className={classes.message}>
          <BBTranslate textId='this-is-your-library' />
        </Typography>
        <div>
          <StoreButton
            onClick={onStoreClick}
            label='go-to-store'
          />
        </div>
      </Paper>
    </div>
  );
};

Empty.propTypes = {
  onStoreClick: PropTypes.func.isRequired
};

export default Empty;
