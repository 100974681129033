import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'block',
    margin: '1rem 0',
    fontSize: '2rem',
    lineHeight: '2.8rem',
    color: '#4788B1',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    },
    '@media all and (max-width: 480px)': {
      '& > span:nth-child(2)': {
        display: 'none'
      }
    }
  },
  icon: {
    float: 'left',
    width: '3rem',
    height: '3rem',
    background: '#595959',
    borderRadius: '100%',
    marginRight: '1rem',
    '& img': {
      width: '100%'
    }
  }
}));

const AuthorLink = ({ link }) => {
  const classes = useStyles();
  return (
    <a
      className={classes.link}
      href={link.linkType === 'Email' ? `mailto:${link.linkUrl}` : link.linkUrl}
      target='_blank'
      rel='noopener noreferrer'
    >
      <span className={classes.icon}>
        <img
          src={`/img/components/author/icons/icon_${link.linkType.toLowerCase()}.svg`}
          alt={link.linkType === 'BridgeWinners' ? 'Bridge Winners' : link.linkType}
        />
      </span>
      <span>{link.linkType}</span>

    </a>
  );
};

AuthorLink.propTypes = {
  link: PropTypes.object.isRequired
};

export default AuthorLink;
