import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
  IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles((theme) => ({
  menu: {
    border: '1px solid #E6E6E2',
    borderRadius: 4,
    boxShadow: theme.boxShadow,
    marginTop: '7rem',
    width: '16rem'
  },
  icon: {
    marginRight: '1rem',
    fontSize: '1.9rem'
  },
  menuItem: {
    color: theme.palette.styleguide.maroon,
    fontSize: '1.6rem'
  }
}));

const Account = ({ onMenuClick, accountTabs }) => {
  const classes = useStyles();
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = event => {
    setAccountMenuOpen(true);
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAccountMenuOpen(false);
  };

  const handleMenuClick = (tab) => {
    setAccountMenuOpen(false);
    onMenuClick(tab);
  };

  return (
    <>
      <IconButton
        edge='end'
        aria-label='Account settings'
        aria-haspopup='true'
        onClick={handleMenuOpen}
        color='inherit'
      >
        <AccountCircleIcon fontSize='small' />
      </IconButton>
      <Menu
        classes={{
          paper: classes.menu
        }}
        id='account-menu'
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transitionDuration={0}
        open={accountMenuOpen}
        onClose={handleCloseMenu}
      >
        {accountTabs.map((tab, i) => {
          const IconComponent = tab.IconComponent;

          return (
            <MenuItem
              key={i}
              onClick={() => { handleMenuClick(tab); }}
              className={classes.menuItem}
              component={Link}
              to={tab.path}
            >
              <IconComponent fontSize='large' className={classes.icon} />
              {tab.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

Account.propTypes = {
  loggedIn: PropTypes.bool,
  onMenuClick: PropTypes.func.isRequired
};

export default Account;
