import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { productLinkQueries } from '../../../graphql/queries';
import { mutations } from '../../../graphql';
import {
  Modal,
  Box,
  Button,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CopyIcon from '@mui/icons-material/FileCopy';
import BBFormContainer from '../../../Components/Forms/BBFormContainer.js';
import { BBSnackbarContext } from '../../../Components/BBSnackbar/BBSnackbarContext';
import { buildProductLink } from '../../../helpers/product.js';

const ProductLinks = ({ productId, productLanguage, open, onModalClose }) => {
  const { openSnackbar } = useContext(BBSnackbarContext);

  const variables = {
    productId,
    language: productLanguage
  };

  const { data, refetch } = useQuery(productLinkQueries.ProductLinks, {
    variables
  });

  const [addNewLink] = useMutation(mutations.CreateProductLink, {
    variables: {
      productId
    },
    onCompleted: () => {
      refetch();
    }
  });

  const [deleteLink] = useMutation(mutations.DeleteProductLink, {
    onCompleted: () => {
      refetch();
    }
  });

  if (!data) {
    return null;
  }

  const handleAddLinkClick = () => {
    addNewLink();
  };

  const handleDeleteLinkClick = id => () => {
    if (!window.confirm('Are you sure')) return;
    deleteLink({
      variables: { id }
    });
  };

  const handleCopyLinkClick = link => () => {
    navigator.clipboard.writeText(link);
    openSnackbar('Copied!', 'success');
  };

  return (
    <Modal
      open={open}
      onClose={onModalClose}
    >
      <>
        <BBFormContainer>
          <Box sx={{ p: 2, width: '100%' }}>
            {
              data.productLinks.length === 0
                ? <span style={{ display: 'flex', justifyContent: 'center' }}>No links added</span>
                : null
            }
            {
              data.productLinks.map((link, index) => {
                const fullLink = buildProductLink(productLanguage, link.linkId);
                return (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {fullLink}
                    <span>
                      <IconButton onClick={handleCopyLinkClick(fullLink)} variant='contained'>
                        <CopyIcon />
                      </IconButton>
                      <IconButton onClick={handleDeleteLinkClick(link.id)} variant='contained'>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </div>
                );
              })
            }
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
              <Button style={{ marginRight: 20 }} onClick={handleAddLinkClick} variant='contained'>Add new link</Button>
              <Button onClick={onModalClose} variant='contained'>Cancel</Button>
            </div>
          </Box>
        </BBFormContainer>
      </>
    </Modal>
  );
};

ProductLinks.propTypes = {
  productId: PropTypes.number.isRequired,
  productLanguage: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default ProductLinks;
