import gql from 'graphql-tag';

const paymentQueries = {
  PaymentMethodById: (id) => {
    return gql`
      query {
        paymentMethodById (id: "${id}") {
          id
          last4
          cardType
        }
      }
    `;
  },
  PaymentMethodsByCustomerId: gql`
    query ($stripeCustomerId: String!) {
      paymentMethodsByCustomerId (stripeCustomerId: $stripeCustomerId) {
        id
        last4
        cardType
      }
    }
  `
};

export default paymentQueries;
