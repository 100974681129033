import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GridView from '../GridView.js';
import StoreSectionContainer from '../StoreSectionContainer.js';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getTextIdFromSearchableTag } from '../../../helpers/tag.js';

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    marginBottom: '3rem'
  },
  chip: {
    borderRadius: 2,
    marginRight: '2rem',
    fontSize: '1.4rem',
    color: '#333',
    backgroundColor: '#fff',
    border: '1px solid #333'
  },
  selected: {
    backgroundColor: '#333',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#555'
    }
  }
}));

const FilteredProductSection = ({ productsByGroup, title, viewAllPath, extraWhitespace }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const filterGroups = Object.keys(productsByGroup)
    .filter(groupName => productsByGroup[groupName] && productsByGroup[groupName].length);

  const [selectedFilterGroup, setSelectedFilterGroup] = useState(filterGroups[0]);

  const handleFilterGroupClick = (groupName) => {
    setSelectedFilterGroup(groupName);
  };

  // e.g. '/products/all/?Type=' + 'Mixed'
  const fullViewAllPath = viewAllPath + selectedFilterGroup;

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(fullViewAllPath);
  };

  return (
    <StoreSectionContainer
      heading={title}
      ctaButtonLabel='view-all'
      onCtaButtonClick={handleClick}
      extraWhitespace={extraWhitespace}
    >
      <div className={classes.chipContainer}>
        {filterGroups.map(groupName => (
          <Chip
            className={clsx(classes.chip, groupName === selectedFilterGroup && classes.selected)}
            key={groupName}
            label={t(getTextIdFromSearchableTag(groupName))}
            variant={groupName === selectedFilterGroup ? 'filled' : 'outlined'}
            onClick={() => { handleFilterGroupClick(groupName); }}
          />
        ))}
      </div>
      <GridView
        nowrap
        products={productsByGroup[selectedFilterGroup]}
      />
    </StoreSectionContainer>
  );
};

FilteredProductSection.propTypes = {
  productsByGroup: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  viewAllPath: PropTypes.string,
  extraWhitespace: PropTypes.bool
};

export default FilteredProductSection;
