import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Menu from './Menu.js';
import Dealer from '../Dealer/Dealer.js';

const styles = (theme) => ({
  menu: {
    paddingTop: theme.spacing(2)
  }
});

const Declarer = (props) => {
  const { classes, onClick, declarer, ...other } = props;
  return (
    <Menu {...other}>
      <div className={classes.menu}>
        <Dealer
          dealer={declarer}
          onClick={onClick}
        />
      </div>
    </Menu>
  );
};

Declarer.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  declarer: PropTypes.string.isRequired,
  open: PropTypes.bool
};

export default withStyles(styles)(Declarer);
