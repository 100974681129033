import React from 'react';
import { useParams } from 'react-router-dom';
import HandDetailsWrapper from './HandDetailsWrapper.js';
import HandDetails from './HandDetails.js';
import {
  getNextHandInSet,
  formatPathToHandPlay
} from '../../../../helpers/product.js';

const SetHandDetails = () => {
  const {
    setId,
    handId,
    linkId,
    language,
    ...other
  } = useParams();

  const handlePlayNextClick = (set, hand) => () => {
    const nextHand = getNextHandInSet(hand.id, set.hands);
    const pathToHand = formatPathToHandPlay(undefined, set, nextHand);
    window.location = pathToHand;
  };

  return (
    <HandDetailsWrapper
      setId={setId}
      handId={handId}
      linkId={linkId}
      language={language}
    >
      {({
        set,
        hand,
        finalHandInProduct
      }) => {
        return (
          <HandDetails
            set={set}
            hand={hand}
            linkId={linkId}
            isFinalHandInProduct={finalHandInProduct}
            onPlayNextClick={handlePlayNextClick(set, hand)}
            {...other}
          />
        );
      }}
    </HandDetailsWrapper>
  );
};

export default SetHandDetails;
