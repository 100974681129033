import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import * as Sentry from '@sentry/browser';
import Paper from '@mui/material/Paper';
import ComponentError from './ComponentError.js';

const styles = {
  paper: {
    padding: 20,
    width: 400,
    margin: 50
  },
  button: {
    marginTop: 20
  }
};

class ErrorNotification extends React.Component {
  componentDidMount () {
    const { error, errorInfo, user } = this.props;
    // log error to sentry
    // https://docs.sentry.io/platforms/javascript/react/
    // add user specific info (if logged in)
    Sentry.withScope(scope => {
      if (user) {
        // scope.setTag('user.email', user.email);
        scope.setExtra('logged in user', user);
        scope.setUser({ email: user.email });
      }
      // add app version number
      scope.setTag('app.version', global.appVersion);
      // group by url
      scope.setFingerprint([window.location.pathname]);
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render () {
    const { classes, error } = this.props;
    return (
      <Paper className={classes.paper}>
        <ComponentError
          heading='Something went wrong'
          text={error && error.toString()}
        />
      </Paper>
    );
  }
}

ErrorNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  errorInfo: PropTypes.object,
  user: PropTypes.object
};

export default withStyles(styles)(ErrorNotification);
