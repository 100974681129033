import React from 'react';
import {
  Grid,
  Box,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Author from '../../../../../Components/Store/AuthorLink.js';
import Tags from '../../../../../Components/Store/Tags.js';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 0,
    paddingTop: theme.spacing(3),
    width: '100%',
    marginBottom: '1.5rem'
  },
  image: {
    width: 180,
    flexShrink: 0,
    marginRight: '3rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  details: {
    flexGrow: 1
  }
}));

const SectionDetails = ({ set }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid
        container
        wrap='nowrap'
        direction='row'
      >
        <Grid item className={classes.image}>
          <img src={set.images.cover} alt={set.title} width='100%' />
        </Grid>
        <Grid item className={classes.details}>
          <Typography variant='h2'>
            {set.title}
          </Typography>
          <Typography variant='body1' sx={{ margin: '2rem 0' }}>
            {set.description}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'flex-end' }}>
            <Author author={set.author} noLink />
            <Tags tags={set.tagsCache} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SectionDetails;
