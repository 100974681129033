// generally useful helper functions... go

// super useful function - https://stackoverflow.com/a/23368052
export const arrayRotate = (arr, reverse) => {
  if (reverse) {
    arr.push(arr.shift());
  } else {
    arr.unshift(arr.pop());
  }
  return arr;
};

// strip the html from text
export const stripHtml = (text) => {
  let noHtmlText = text.replace(/<\/?[^>]+(>|$)/g, '');
  noHtmlText = noHtmlText.replace(/&nbsp;/g, '');
  return noHtmlText.trim();
};

export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const stripNonAlphaNumeric = (text) => {
  if (!text) return '';
  return text.replace(/[^a-z0-9A-Z]/g, ' ');
};

// fetch json data from public file
export const fetchData = async (url) => {
  let response = await window.fetch(url);
  response = await response.json();

  return response;
};
