import React from 'react';
import PropTypes from 'prop-types';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class AdminSearch extends React.Component {
  render () {
    const { children, query, options } = this.props;
    return (
      <BBQuery query={query(options)} showLoader>
        {(data, refetch) => {
          return children(data, refetch);
        }}
      </BBQuery>
    );
  }
}

AdminSearch.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired
};

export default AdminSearch;
