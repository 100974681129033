import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid
} from '@mui/material';
import GridItemsSkeleton from './GridItemsSkeleton.js';

const useStyles = makeStyles((theme) => ({
  wrapGrid: {
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      paddingRight: '2rem',
      width: '100vw'
    }
  },
  nowrapGrid: {
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '1rem',
      paddingRight: '6rem',
      marginLeft: '-2rem'
    }
  },
  nowrapGridItem: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 280,
      marginRight: '1rem'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 200
    }
  }
}));

const GridViewSkeleton = ({ nowrap }) => {
  const classes = useStyles();

  // can we work out number of items based on width?
  const noOfItems = 12;

  return (
    <Grid
      container
      wrap={nowrap && 'nowrap'}
      spacing={{ xs: 2, sm: 2 }}
      className={nowrap ? classes.nowrapGrid : classes.wrapGrid}
    >
      {
        [...Array(noOfItems).keys()].map(item => (
          <Grid
            key={item}
            item
            xs={6}
            sm={4}
            md={3}
            className={nowrap && classes.nowrapGridItem}
          >
            <GridItemsSkeleton variant='rectangular' />
          </Grid>
        ))
      }
    </Grid>
  );
};

export default GridViewSkeleton;
