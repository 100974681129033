import React from 'react';
import {
  Modal,
  Box
} from '@mui/material';
import SuccessStep from '../../../../../Components/SuccessStep.js';
import BBFormContainer from '../../../../../Components/Forms/BBFormContainer.js';

const SuccessModal = ({ open, onSuccess, text }) => {
  return (
    <Modal open={open}>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <BBFormContainer>
          <Box sx={{ m: 5 }}>
            <SuccessStep
              text={text}
              onSuccess={onSuccess}
            />
          </Box>
        </BBFormContainer>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
