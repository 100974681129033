import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import ReactGA from 'react-ga';
import { mutations } from '../graphql';
import { stripTypeNameFromMutationVars } from '../helpers/gql.js';
import { persistJwt } from '../helpers/auth.js';
import { convertLangCodeToGraphqlEnum } from '../helpers/locale.js';

const UserContext = React.createContext();

const UserProvider = ({ children, currentUser }) => {
  const [userState, setUserState] = useState(currentUser);

  // function to set the state of the user
  const setUser = useCallback(updatedUser => {
    setUserState(updatedUser);
  }, [setUserState]);

  const [updateUser] = useMutation(mutations.UpdateUser);
  const [registerUser] = useMutation(mutations.RegisterUser);

  // function to save the user to the db via a mutation
  const saveUser = useCallback(async (user, onSaveCompleted) => {
    if (user.variables) {
      user = user.variables;
    }
    try {
      await updateUser({
        variables: stripTypeNameFromMutationVars(user),
        // onCompleted doesn't work when calling mutation
        // but update does
        update: (_, data) => {
          if (onSaveCompleted) {
            onSaveCompleted(null, data);
          }
        }
      });
    } catch (err) {
      if (onSaveCompleted) {
        onSaveCompleted(err);
      }
    }
  }, [updateUser]);

  // function to create a new user in the db via a mutation
  const createUser = useCallback(async (user, locale) => {
    const newUser = {
      ...user,
      currency: locale.currency,
      language: convertLangCodeToGraphqlEnum(locale.language)
    };

    let registeredUser;
    try {
      const response = await registerUser({
        variables: newUser
      });
      if (response.data) {
        registeredUser = response.data.registerUser;
      }
    } catch (err) {
      console.log('createUser err: ' + err);
      throw err;
    }

    // sent GA event
    ReactGA.event({
      category: 'Conversion',
      action: 'Register'
    });
    // persist jwt token to cookie
    persistJwt(registeredUser.token);

    // return newly minted user
    return registeredUser;
  }, [registerUser]);

  // *** NOTE: this was commented out becuase we shouldn't be reacting to updates to currentUser changes ***
  // *** but this may cause side effects so lets see what happens! ***
  // make sure context is updated if currentUser gets updated (eg./ refetchQueries is called)
  // useEffect(() => {
  //   setUserState(currentUser);
  // }, [currentUser, setUserState]);

  return (
    <UserContext.Provider value={{ user: userState, saveUser, setUser, createUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
