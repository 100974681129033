import React from 'react';
import { SvgIcon } from '@mui/material';

const Email = (props) => {
  return (
    <SvgIcon {...props} height='100' viewBox='0 0 100 100' width='100'>
      <path d='m75.9215187 37.2484375c1.1552649-.5800781 1.0773819.0902344 1.0773819.6316406v26.4257813c0 1.1859375-1.5187191 2.6941406-2.7908086 2.6941406h-48.1576733c-1.2720895 0-3.0504187-1.4953125-3.0504187-2.6941406l.0004113-26.4925488c.0068677-.5164885.1120864-1.1207813 1.2067757-.5648731 1.0644014.5414062 10.721897 7.2960937 17.043403 11.3566406l-10.6569946 11.9238282c-.2596101.2578125-.3764346.5800781-.2206686.721875.1557661.1546875.5062397.0773437.7658498-.1804688l12.7987779-10.725c1.9340952 1.2375 3.2840677 2.0882813 3.5306973 2.2171875.9994989.5027344 1.7004462.5671875 2.6610035.5671875.9605574 0 1.6615047-.0644531 2.6610036-.5671875.233649-.1289062 1.5966021-.9796875 3.5306973-2.2171875l12.7987779 10.725c.2725906.2449219.6100837.3222656.7658498.1804688.1427855-.1546875.025961-.4640625-.2336491-.721875l-10.6699751-11.9238282c6.3215059-4.0605469 15.8751576-10.8152344 16.939559-11.3566406zm-.9735379-3.2484375c1.4408361 0 1.8042902.3738281 1.4667971 1.3535156-.1947076.5542969-1.0903625 1.0441407-1.6225632 1.3148438-1.4278555.7476562-21.2620671 11.4855469-22.0019558 11.8722656-.7398888.3867188-1.4927581.5671875-2.6610036.5671875-1.1682454 0-1.9211147-.1804687-2.6610035-.5671875-.7398888-.3867187-20.5741003-11.1246094-22.0019559-11.8722656-.5322007-.2707031-1.4278555-.7476563-1.6225631-1.3148438-.3374931-.9667968.025961-1.3535156 1.4667971-1.3535156z' />
    </SvgIcon>
  );
};

export default Email;
