import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import { productQueries } from '../../../../graphql/queries';
import { mutations } from '../../../../graphql';
import AdminSearchBox from '../../../../Components/Forms/AdminSearchBox.js';
import BBSubmit from '../../../../Components/Forms/BBSubmit.js';
import HandsTree from './HandsTree';
import BBFormContainer from '../../../../Components/Forms/BBFormContainer.js';
import { formatProductDataForTree } from '../../../../helpers/product.js';

const useStyles = makeStyles((theme) => ({
  duplicateButton: {
    marginTop: '2rem'
  }
}));

const DuplicateHands = ({ open, setId, onModalClose, onDuplicateSuccess }) => {
  const classes = useStyles();
  const [productData, setProductData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [duplicateHands] = useMutation(mutations.DuplicateHands);

  const [getProducts] = useLazyQuery(productQueries.ProductSearchWithHands, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const formattedData = formatProductDataForTree(
        data.productSearchWithHands.products
      );
      setProductData(formattedData);
      setIsSearchLoading(false);
    },
    onError: (err) => {
      console.log('getProducts err', err);
    }
  });

  const handleSearchSubmit = (searchTerm) => {
    const options = {
      searchTerm,
      limit: 100,
      offset: 0,
      orderBy: 'title',
      orderByDirection: 'desc'
    };
    getProducts({ variables: { input: options } });
    setIsSearchLoading(true);
  };

  const handleDuplicateClick = async () => {
    setIsSubmitLoading(true);
    // for each hand create a duplicate
    const selectedHands = selected.map(id => { return { id }; });
    await duplicateHands({
      variables: {
        setId,
        hands: selectedHands
      },
      // onCompleted doesn't work when calling mutation
      // but update does
      update: (cache, data) => {
        setIsSubmitLoading(false);
        onDuplicateSuccess();
      }
    });
  };

  const handleHandCheckBoxClick = (checked, selectedNode) => {
    let newSelected;
    if (checked) {
      // add node to selected
      newSelected = [...selected, selectedNode.id];
    } else {
      // remove node from selected
      newSelected = selected.filter(node => node !== selectedNode.id);
    }
    setSelected(newSelected);
  };

  // clear the results on render (when 'open' prop changes)
  useEffect(() => {
    setProductData(null);
    setSelected([]);
  }, [setProductData, setSelected, open]);

  return (
    <Modal
      open={open}
      onClose={onModalClose}
    >
      <> {/* adding a fragment here fixes a ref warning - https://stackoverflow.com/a/66743078 */}
        <BBFormContainer>
          <Box sx={{ p: 2, width: '100%' }}>
            <AdminSearchBox
              isLoading={isSearchLoading}
              onSubmit={handleSearchSubmit}
            />
          </Box>
          <HandsTree
            productData={productData}
            onCheckboxClick={handleHandCheckBoxClick}
            selected={selected}
          />
          <BBSubmit
            className={classes.duplicateButton}
            label='Duplicate'
            onClick={handleDuplicateClick}
            disabled={selected.length === 0 || isSubmitLoading}
            isLoading={isSubmitLoading}
          />
        </BBFormContainer>
      </>
    </Modal>
  );
};

DuplicateHands.propTypes = {
  open: PropTypes.bool,
  setId: PropTypes.number.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onDuplicateSuccess: PropTypes.func.isRequired
};

export default DuplicateHands;
