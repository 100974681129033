import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    background: 'rgba(0,0,0,0.25)',
    color: '#fff',
    width: '100%',
    height: '6rem',
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    padding: 0,
    margin: '0 0.1rem',
    '&:hover': {
      background: 'rgba(0,0,0,0.35)'
    },
    '&:disabled': {
      color: '#999'
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0.3rem'
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));

const IconButton = (props) => {
  const {
    children,
    onClick,
    icon,
    disabled,
    className
  } = props;
  const classes = useStyles();
  return (
    <Button className={clsx(classes.button, className || null)} onClick={onClick} disabled={disabled}>
      <span className={classes.icon}>{icon}</span>
      {children}
    </Button>
  );
};

IconButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default IconButton;
