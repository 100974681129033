import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import BBAdminForm from '../../../Components/Forms/BBAdminForm.js';
import BBInput from '../../../Components/Forms/BBInput.js';
import BBSelect from '../../../Components/Forms/BBSelect.js';
import BBSubmit from '../../../Components/Forms/BBSubmit.js';
import ImageUploader from '../../../Components/ImageUploader.js';
import LinkButton from '../../../Components/LinkButton.js';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  BBOIcon,
  BridgeWinnersIcon,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WebsiteIcon,
  YouTubeIcon
} from '../../../Components/CustomIcons';
import { formatS3FilePath } from '../../../helpers/file.js';
import { stripTypeNameFromMutationVars } from '../../../helpers/gql.js';
import { authorQueries } from '../../../graphql/queries';

const styles = {
  gridItem: {
    marginRight: '1rem'
  },
  chip: {
    margin: '0 1rem 1rem 0'
  },

};

class AuthorForm extends React.Component {
  state = {
    avatarPath: this.props.author && this.props.author.avatarPath
      ? this.props.author.avatarPath : '',
    backgroundImagePath: this.props.author && this.props.author.backgroundImagePath
      ? this.props.author.backgroundImagePath : '',
    linkType: '',
    linkUrl: '',
    links: this.props.author.authorLinks,
    expanded: false
  };

  handleAvatarUploadComplete = (filename, folder) => {
    this.setState({ avatarPath: formatS3FilePath(filename, folder) })
  };

  handleBackgroundUploadComplete = (filename, folder) => {
    this.setState({ backgroundImagePath: formatS3FilePath(filename, folder) })
  };

  handleUploadError = (message) => {
    // hijak the existing onSubmitError function for the upload error
    const { onSubmitError } = this.props;
    onSubmitError(message);
  };

  handleSubmit = (formState) => {
    formState.variables.avatarPath = this.state.avatarPath;
    formState.variables.backgroundImagePath = this.state.backgroundImagePath;
    formState.variables.links = this.state.links;
    formState.variables.royaltyPercentage = parseInt(formState.variables.royaltyPercentage);
    return this.props.upsertAuthor(stripTypeNameFromMutationVars(formState));
  };

  handleLinkTypeChange = (input) => {
    this.setState({
      linkType: input.value
    });
  };

  handleLinkUrlChange = (input) => {
    this.setState({
      linkUrl: input.value
    })
  };

  handleLinkSubmitClick = () => {
    let { links, linkType, linkUrl } = this.state;
    if (linkUrl !== '' && linkType !== '') {
      links.push({
        id: undefined,
        linkType: linkType,
        linkUrl: linkUrl
      });
      this.setState({
        links: links,
        linkUrl: ''
      });
    }
  };

  handleLinkDeleteClick = id => () => {
    let { links } = this.state;
    links.forEach(link => {
      if (link.id === id) {
        link.linkUrl = 'deleted';
      }
    });
    this.setState({
      links: links
    });
  };

  handleAccordianChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false);
    this.setState({
      expanded: isExpanded ? panel : false
    });
  };

  getUnusedLinks = () => {
    const { links } = this.state;
    let allLinks = [
      { type: 'Email' },
      { type: 'Facebook' },
      { type: 'Website' },
      { type: 'Twitter' },
      { type: 'YouTube' },
      { type: 'BBO' },
      { type: 'BridgeWinners' }
    ];
    // remove any links that have already been used
    links.forEach(usedLink => {
      allLinks = allLinks.filter(link => {
        return link.type !== usedLink.linkType;
      });
    });
    return allLinks;
  }

  render () {
    const { classes, author, languageEnum, ...other } = this.props;
    const { avatarPath, links, linkType, linkUrl, expanded } = this.state;
    const icons = {
      BBO: BBOIcon,
      BridgeWinners: BridgeWinnersIcon,
      Email: EmailIcon,
      Facebook: FacebookIcon,
      Twitter: TwitterIcon,
      Website: WebsiteIcon,
      YouTube: YouTubeIcon
    };
    return (
      <>
        <BBAdminForm
          fields={author}
          onSubmit={this.handleSubmit}
          refetchQueries={[
            {
              query: authorQueries.Authors
            }
          ]}
          {...other}
          onValidate={() => {
            if (avatarPath === '') {
              return 'Avatar is a required field';
            }
            return '';
          }}
        >
          <BBInput
            key='firstName' // needed as it's a child
            id='firstName'
            name='firstName'
            type='text'
            label='First name'
            required={true}
            validate={(val) => (val ? '' : 'First name is required')}
          />
          <BBInput
            key='lastName' // needed as it's a child
            id='lastName'
            name='lastName'
            type='text'
            label='Last name'
            required={true}
            validate={(val) => (val ? '' : 'Last name is required')}
          />
          <BBSelect
            id='language'
            name='language'
            label='Language'
            required
            valueField='name'
            keyField='name'
            items={languageEnum}
            style={{margin: 0}}
          />
          <BBInput
            key='bio' // needed as it's a child
            id='bio'
            name='bio'
            type='text'
            label='Bio'
            maxLength={1000}
            rows={3}
          />
          <BBInput
            key='royaltyPercentage' // needed as it's a child
            id='royaltyPercentage'
            name='royaltyPercentage'
            type='number'
            label='Royalty Percentage'
            required={false}
            inputProps={{
              inputProps: {
                min: 0,
                max: 100
              }
            }}
          />
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={this.handleAccordianChange('panel1')}
            bbFormIgnore
            style={{backgroundColor: 'eeeeee', marginTop: 20}}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography variant='h6'>Links</Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'block'}}>
              {
                links.map((link, index) => {
                  const SocialIcon = icons[link.linkType];
                  if (link.linkUrl !== 'deleted') {
                    return (
                      <Chip
                        className={classes.chip}
                        key={index}
                        icon={<SocialIcon />}
                        label={link.linkUrl}
                        onDelete={this.handleLinkDeleteClick(link.id)}
                      />
                    )
                  }
                  return null;
                })
              }
              <Grid
                id='grid1'
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <Grid item className={classes.gridItem} style={{width: 160}}>
                  <BBSelect
                    items={this.getUnusedLinks()}
                    valueField='type'
                    keyField='type'
                    id='type'
                    name='type'
                    label='Link type'
                    value={linkType}
                    onChange={this.handleLinkTypeChange}
                    showBlankOption={false}
                    width={100}
                  />
                </Grid>
                <Grid item className={classes.gridItem} style={{width: 260, marginTop: 16}}>
                  <BBInput
                    key='linkUrl' // needed as it's a child
                    id='linkUrl'
                    name='linkUrl'
                    type='text'
                    label='Link URL'
                    maxLength={255}
                    value={linkUrl}
                    onChange={this.handleLinkUrlChange}
                  />
                </Grid>
                <Grid item className={classes.gridItem} style={{marginTop: 24}}>
                  <BBSubmit
                    disabled={false}
                    isLoading={false}
                    label='Add link'
                    onClick={this.handleLinkSubmitClick}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={this.handleAccordianChange('panel2')}
            bbFormIgnore
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
            >
              <Typography  variant='h6'>Images</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                id='grid2'
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <Grid item className={classes.gridItem}>
                  Avatar
                  <ImageUploader
                    key='avatar'
                    id='avatar'
                    uploadFolder='avatars'
                    currentImageSrc={
                      author
                        ? author.avatarPath
                        : ''
                      }
                    onUploadComplete={this.handleAvatarUploadComplete}
                    onUploadError={this.handleUploadError}
                  />
                </Grid>
                <Grid item className={classes.gridItem}>
                  Background
                  <ImageUploader
                    key='background'
                    id='background'
                    uploadFolder='backgrounds'
                    currentImageSrc={
                      author
                        ? author.backgroundImagePath
                        : ''
                      }
                    onUploadComplete={this.handleBackgroundUploadComplete}
                    onUploadError={this.handleUploadError}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <LinkButton
            to={`royalties/${author.id}/${author.lastName}-${author.firstName}`}
          >
            View Sales
          </LinkButton>
        </BBAdminForm>
      </>
    )
  }
}

AuthorForm.propTypes = {
  upsertAuthor: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  author: PropTypes.object
}

export default withStyles(styles)(AuthorForm);
