import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MiniCard from '../MiniCard/MiniCard.js';
import Value from './Value.js';
import Risk from './Risk.js';
import Declarer from './Declarer.js';
import { convertSeatCodeToFullWord, getContractValue } from '../../../helpers/auction.js';

const styles = theme => ({
  grid: {
    marginTop: theme.spacing(2)
  },
  card: {
    margin: theme.spacing(.5)
  },
  declarer: {
    padding: 4
  },
  declarerButton: {
    margin: 5
  },
  button: {
    padding: 2,
    minWidth: 52
  }
});

class Contract extends React.Component {
  getDefaultContract = () => {
    let contract = this.props.contract;
    if (_.isEmpty(contract)) {
      contract = {
        level: '1',
        denomination: 'D',
        risk: ''
      };
    }
    return contract;
  }

  state = {
    anchorEl: null,
    valueOpen: false,
    riskOpen: false,
    declarerOpen: false,
    contract: this.getDefaultContract()
  };

  handleValueClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      valueOpen: true,
      riskOpen: false,
      declarerOpen: false
    });
  };

  handleValueChange = (level, denomination) => {
    let { contract } = this.state;
    let { declarer } = this.props;
    contract.level = level;
    contract.denomination = denomination;
    this.handleChange(contract, declarer);
  };

  handleRiskClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      valueOpen: false,
      riskOpen: true,
      declarerOpen: false
    });
  };

  handleRiskChange = (risk) => {
    let { contract } = this.state;
    let { declarer } = this.props;
    if (risk === '-') {
      risk = '';
    }
    contract.risk = risk;
    this.handleChange(contract, declarer);
  };

  handleDeclarerClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      valueOpen: false,
      riskOpen: false,
      declarerOpen: true
    });
  };

  handleDeclarerChange = (declarer) => {
    let { contract } = this.state;
    this.handleChange(contract, declarer);
  };

  handleChange = (contract, declarer) => {
    contract.value = getContractValue(
      contract.level,
      contract.denomination,
      contract.risk
    );
    this.setState({
      contract: contract
    });
    this.props.onChange(contract, declarer);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      valueOpen: false,
      riskOpen: false,
      declarerOpen: false
    });
  };

  render () {
    let { classes, declarer } = this.props;
    const { valueOpen, riskOpen, declarerOpen, anchorEl, contract } = this.state;
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        className={classes.grid}
      >
        <Grid item className={classes.card}>
          <Button
            className={classes.button}
            onClick={this.handleValueClick}
          >
            <MiniCard
              suit={contract.denomination}
              value={contract.level}
              scale='0.75em'
              isBid={true}
            />
          </Button>
          <Value
            open={valueOpen}
            onClick={this.handleValueChange}
            anchorEl={anchorEl}
            onClose={this.handleClose}
          />
        </Grid>
        <Grid item className={classes.card}>
          <Button
            className={classes.button}
            onClick={this.handleRiskClick}
          >
            <MiniCard
              value={contract.risk === '' ? '-' : contract.risk}
              scale='0.75em'
              isBid={true}
            />
          </Button>
          <Risk
            open={riskOpen}
            onClick={this.handleRiskChange}
            anchorEl={anchorEl}
            onClose={this.handleClose}
          />
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            className={clsx(classes.button, classes.declarerButton)}
            onClick={this.handleDeclarerClick}
          >
            <span className={classes.declarer}>
              by {convertSeatCodeToFullWord(declarer)}
            </span>
          </Button>
          <Declarer
            open={declarerOpen}
            onClick={this.handleDeclarerChange}
            declarer={declarer}
            anchorEl={anchorEl}
            onClose={this.handleClose}
          />
        </Grid>
      </Grid>
    );
  }
};

Contract.propTypes = {
  classes: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  declarer: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default withStyles(styles)(Contract);
