import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import GridItem from './GridItem.js';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { markPurchasedProducts } from '../../helpers/purchase.js';
import { UserContext } from '../../Context/UserContext.js';

const useStyles = makeStyles((theme) => ({
  // noWrapContainer: {
  //   overflow: 'visible'
  // },
  wrapGrid: {
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      paddingRight: '2rem',
      width: '100vw'
    }
  },
  nowrapGrid: {
    overflow: 'visible',
    position: 'relative',
    alignItems: 'stretch',
    [theme.breakpoints.down('lg')]: {
      overflowX: 'scroll',
      width: 'calc(100vw + 4rem)',
      paddingBottom: '1rem',
      paddingRight: '6rem',
      marginLeft: '-2rem'
    }
  },
  nowrapGridItem: {
    [theme.breakpoints.down('lg')]: {
      minWidth: 280,
      marginRight: '1rem'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 200
    }
  }
}));

const GridItemWrapper = ({ children, nowrap, classes }) => (
  <Grid
    item
    xs={6}
    sm={4}
    md={3}
    className={nowrap && classes.nowrapGridItem}
  >
    {children}
  </Grid>
);

const GridView = ({ products, nowrap }) => {
  const classes = useStyles();

  const { user } = useContext(UserContext);

  let markedProducts;
  if (products) {
    markedProducts = markPurchasedProducts(products, user);
  }

  return (
    <Grid
      container
      wrap={nowrap && 'nowrap'}
      spacing={{ xs: 2, sm: 2 }}
      className={nowrap ? classes.nowrapGrid : classes.wrapGrid}
    >
      {
        markedProducts && markedProducts.map((product, index) => (
          <GridItemWrapper key={index} nowrap={nowrap} classes={classes}>
            <GridItem
              product={product}
              nowrap={nowrap}
            />
          </GridItemWrapper>
        ))
      }
    </Grid>
  );
};

GridView.propTypes = {
  products: PropTypes.array.isRequired,
  hidePrice: PropTypes.bool,
  nowrap: PropTypes.bool,
  loading: PropTypes.bool
};

export default GridView;
