import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { authorQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';
import { LocaleContext } from '../../Context';

const AuthorById = ({ children, id, ...other }) => {
  const { locale } = useContext(LocaleContext);

  if (!locale) {
    return null;
  }
  const { currency } = locale;

  return (
    <BBQuery
      query={authorQueries.AuthorById(id, currency)}
      showLoader
      {...other}
    >
      {data => {
        return children(data.authorById);
      }}
    </BBQuery>
  );
};

AuthorById.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  loaderComponent: PropTypes.object
};

export default AuthorById;
