import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SectionDetails from './SectionDetails.js';
import SectionHands from './SectionHands.js';
import ViewHandsFOB from './ViewHandsFOB.js';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    borderTop: '1px solid grey',
    borderColor: theme.palette.styleguide.maroon,
    borderWidth: '2px'
  },
  content: {
    margin: 0
  },
  expanded: {
    margin: '40px 0 0 0 !important'
  },
  accordeonDetails: {
    padding: '1rem 0 4rem 0',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  }
}));

const SectionAccordian = ({ product, ...other }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);

  const handleAccordianChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {
      product.sets.map((set, index) => {
        const thisAccordianExpanded = expanded === `panel${index}`;
        return (
          <Accordion
            expanded={thisAccordianExpanded}
            onChange={handleAccordianChange(`panel${index}`)}
            key={index}
            sx={{ marginTop: '4rem' }}
          >
            <AccordionSummary
              sx={{ width: '100%' }}
              classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded
              }}
            >
              <SectionDetails set={set} />
              {
                !thisAccordianExpanded &&
                <ViewHandsFOB />
              }
            </AccordionSummary>
            <AccordionDetails className={classes.accordeonDetails}>
              <SectionHands
                set={set}
                productId={product.id}
                {...other}
              />
            </AccordionDetails>
          </Accordion>
        )
      })
    }
    </>
  );
};

export default SectionAccordian;
