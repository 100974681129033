import React, { useState } from 'react';
import moment from 'moment';

const AdminSearchContext = React.createContext();

const AdminSearchProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useState({});
  const [currentSection, setCurrentSection] = useState('');

  const initSearchParams = (initDateRange, initOrderBy, initOrderByDirection) => {
    setSearchParams({
      searchTerm: '',
      dateFrom:
        initDateRange
          ? `${moment(initDateRange.dateRange.from).format()}`
          : `${moment().subtract(30, 'days').startOf('day').format()}`,
      dateTo:
        initDateRange
          ? `${moment(initDateRange.dateRange.to).format()}`
          : `${moment().endOf('day').format()}`,
      dateRange: initDateRange ? initDateRange.label : 'Custom',
      searchState: 'off',
      page: 0,
      offset: 0,
      limit: 25,
      orderBy: initOrderBy || 'id',
      orderByDirection: initOrderByDirection || 'asc'
    });
  };

  return (
    <AdminSearchContext.Provider value={{
      searchParams,
      setSearchParams,
      initSearchParams,
      currentSection,
      setCurrentSection
    }}
    >
      {children}
    </AdminSearchContext.Provider>
  );
};

export { AdminSearchContext, AdminSearchProvider };
