import React from 'react';
import PropTypes from 'prop-types';
import { IdealBankElement } from 'react-stripe-elements';
import { makeStyles } from '@mui/styles';
import { TextField, MenuItem } from '@mui/material';
import { useTextTranslation } from '../../../../../Hooks';

const useStyles = makeStyles({
  label: {
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    fontWeight: 'bold',
    color: '#999'
  },
  select: {
    margin: '2rem 0'
  },
  hiddenIdealElement: {
    display: 'none'
  }
});

const IdealPaymentForm = ({ onBankSelected, selectedBank }) => {
  const classes = useStyles();

  // https://stripe.com/docs/payments/ideal/accept-a-payment#bank-reference
  const bankList = [
    { name: 'ABN AMRO', value: 'abn_amro' },
    { name: 'ASN Bank', value: 'asn_bank' },
    { name: 'Bunq', value: 'bunq' },
    { name: 'Handelsbanken', value: 'handelsbanken' },
    { name: 'ING', value: 'ing' },
    { name: 'Knab', value: 'knab' },
    { name: 'Rabobank', value: 'rabobank' },
    { name: 'Revolut', value: 'revolut' },
    { name: 'RegioBank', value: 'regiobank' },
    { name: 'SNS Bank (De Volksbank)', value: 'sns_bank' },
    { name: 'Triodos Bank', value: 'triodos_bank' },
    { name: 'Van Lanschot', value: 'van_lanschot' }
  ];

  const selectYourBankLabel = useTextTranslation('select-your-bank');

  return (
    <>
      <div>
        <TextField
          id='idealBankSelect'
          select
          label={selectYourBankLabel}
          value={selectedBank}
          onChange={onBankSelected}
          variant='outlined'
          fullWidth
          className={classes.select}
          required
        >
          {
            bankList.map(bank =>
              <MenuItem key={bank.value} value={bank.value}>
                {bank.name}
              </MenuItem>
            )
          }
        </TextField>
        <div className={classes.hiddenIdealElement}>
          <IdealBankElement value={selectedBank} />
        </div>
      </div>
    </>
  );
};

IdealPaymentForm.propTypes = {
  onBankSelected: PropTypes.func.isRequired,
  selectedBank: PropTypes.object.isRequired
};

export default IdealPaymentForm;
