import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getCardTextId } from '../../../helpers/translation.js';

const styles = {
  innerLI: {
    fontSize: '1.2em',
    lineHeight: '1.4em',
    float: 'left',
    marginLeft: '0.1em'
  },
  innerA: {
    display: 'block',
    width: '1.4em',
    height: '1.5em',
    lineHeight: 'inherit',
    border: '0.1em solid #fff',
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '0.2em',
    color: '#000',
    cursor: 'default',
    '&:hover': {
      borderColor: '#fff'
    }
  },
  selectable: {
    borderColor: '#ccc',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#666'
    }
  },
  played: {
    opacity: 0.3
  }
};

const DealDirectionCard = (props) => {
  const { classes, selectable, card, onCardClick, allPlayed } = props;
  const canBePlayed = selectable && !card.played;
  const { t } = useTranslation();

  return (
    <li className={classes.innerLI}>
      <span
        onClick={() => {
          if (canBePlayed && onCardClick) {
            onCardClick(card);
          }
        }}
        className={clsx(
          classes.innerA,
          canBePlayed ? classes.selectable : null,
          card.played && !allPlayed ? classes.played : null
        )}
      >
        {t(getCardTextId(card.rank))}
      </span>
    </li>
  );
};

DealDirectionCard.propTypes = {
  classes: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  card: PropTypes.object.isRequired,
  allPlayed: PropTypes.bool,
  onCardClick: PropTypes.func
};

export default withStyles(styles)(DealDirectionCard);
