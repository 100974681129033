import React from 'react';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => ({
  img: {
    // CSS hack - padding % is always based on width
    // so this effectively makes the img skeleton square
    paddingBottom: '100%',
    height: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 280
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200
    }
  },
  text: {
    marginTop: 10,
    minHeight: 70,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 280
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200
    }
  }
}));

const GridItemsSkeleton = (props) => {
  const classes = useStyles();
  return (
    <>
      <Skeleton variant='rectangular' component='div' className={classes.img} />
      <Skeleton variant='rectangular' className={classes.text} />
      <Skeleton variant='rectangular' className={classes.text} />
      <Skeleton variant='rectangular' className={classes.text} />
    </>
  );
};

export default GridItemsSkeleton;
