import _ from 'lodash';
import { convertLangCodeToGraphqlEnum } from './locale';

const difficultySorted = [
  {
    name: 'Beginner'
  },
  {
    name: 'Improver'
  },
  {
    name: 'Intermediate'
  },
  {
    name: 'Advanced'
  }
];

export const addTag = (tags, tag) => {
  tags.push(tag);
  return tags;
};

export const removeTag = (tags, tag) => {
  const index = _.findIndex(tags, { id: tag.id });
  tags.splice(index, 1);
  return tags;
};

export const replaceTag = (tags, tagToRepace, newTag) => {
  const index = _.findIndex(tags, { id: tagToRepace.id });
  tags.splice(index, 1, newTag);
  return tags;
};

export const convertReactSelectTags = (tags) => {
  tags = tags.map(tag => {
    if (tag.__isNew__) {
      return { name: tag.label, id: null, tagGroup: null };
    }
    return { name: tag.label, id: tag.value, tagGroup: null };
  });
  return tags;
};

export const getTagsByGroup = (tags, group, language) => {
  let filteredTags = [];
  if (group) {
    filteredTags = _.filter(tags, tag => {
      if (tag.tagGroup) {
        const matchesGroupName = tag.tagGroup.name.toLowerCase() === group.toLowerCase();
        if (language) {
          const graphqlLanguage = language.includes('-') ? convertLangCodeToGraphqlEnum(language) : language;
          return (
            matchesGroupName &&
            (tag.language === null || tag.language === graphqlLanguage)
          );
        }
        return matchesGroupName;
      }
      return null;
    });
  } else {
    filteredTags = _.filter(tags, { tagGroup: null });
  }
  if (group === 'Difficulty') {
    const difficultyTags = [];
    if (filteredTags.length > 1) {
      difficultyTags.push(_.find(filteredTags, { name: difficultySorted[0].name }));
      difficultyTags.push(_.find(filteredTags, { name: difficultySorted[1].name }));
      difficultyTags.push(_.find(filteredTags, { name: difficultySorted[2].name }));
      difficultyTags.push(_.find(filteredTags, { name: difficultySorted[3].name }));
      return difficultyTags;
    }
    return filteredTags;
  } else {
    return filteredTags.sort();
  }
};

export const getGroupTagNameForProduct = (tags, group) => {
  let groupTag;
  const tagsForGroup = getTagsByGroup(tags, group);
  if (tagsForGroup.length > 0) {
    groupTag = tagsForGroup[0];
  }
  if (groupTag) {
    return groupTag.name;
  }
  return '';
};

// tags are stored in plaintext English but that means they're not translatable
// this converts them to a textId for display purposes
export const getTextIdFromSearchableTag = (tag) => {
  if (tag === 'SA') {
    return 'sa-standard-american';
  }

  return tag.toLowerCase().replace(/ /g, '-');
};

export const getGroupTagNameFromTagsCache = (tagsCache, group) => {
  // tagsCache format:
  // [{
  //    "group":"Difficulty",
  //    "name":"Advanced"
  //  },
  //  {
  //    "group":"Type",
  //    "name":"Declarer bidding and play"
  //  },
  //  {
  //    "group":"System",
  //    "name":"Any system"
  //  }]
  const tag = _.find(tagsCache, { group });
  if (tag) {
    return tag.name;
  }
  return '';
};

export const getGroupTagsFromTagsCache = (tagsCache) => {
  if (tagsCache && tagsCache.length > 0) {
    return _.filter(tagsCache, (tag) => {
      return tag && tag.group !== null;
    });
  }
  return null;
};

// shorten tag names so they don't break the layout
export const getShortenedTagName = (tagName) => {
  switch (tagName) {
    case 'Bidding only':
      return 'Bidding';
    case 'Declarer bidding and play':
      return 'Declarer';
    case 'Defender bidding and play':
      return 'Defender';
    case 'Standard American':
      return 'SA';
    default:
      return tagName;
  }
};
