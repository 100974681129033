import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  avatar: {
    display: 'block',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
  },
  img: {
    width: '100%',
    borderRadius: '100%'
  }
};

const Avatar = (props) => {
  const { classes, avatar, authorName } = props;
  return (
    <div className={classes.avatar}>
      <img className={classes.img} src={avatar} alt={authorName} />
    </div>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired
};

export default withStyles(styles)(Avatar);
