// based on this post https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
// on how to automatically update a site in ios (but needed to add the poll to get working)
import React from 'react'
import FooterMessage from './Layout/FooterMessage.js';
import fetch from 'isomorphic-unfetch';

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class RefreshAfterDeploy extends React.Component {
  state = {
    refresh: false
  };

  checkStatus = (response) => {
    if (response.ok) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      return Promise.reject(error);
    }
  }

  fetchMetaJson = () => {
    setTimeout(() => {
      fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
        .then(this.checkStatus)
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = global.appVersion;
          const shouldRefresh = semverGreaterThan(latestVersion, currentVersion);
          if (shouldRefresh) {
            this.setState({ refresh: true });
          } else {
            this.fetchMetaJson();
          }
        }).catch(err => {
          console.log('fetchMetaJson error', err);
          this.fetchMetaJson();
        });
    }, 60000); // 1 minute
  };

  componentDidMount() {
    this.fetchMetaJson();
  }

  render () {
    const { refresh } = this.state;
    if (refresh) {
      return (
        <FooterMessage
          message='new-version-of-the-app-is-available'
          onLinkClick={() => {
            window.location.reload(true);
          }}
          linkText='refresh-page-and-load-new-version'
          zIndex={2}
        />
      )
    } else {
      return null;
    }
  }
}

export default RefreshAfterDeploy;
