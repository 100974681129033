import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { userQueries } from '../../../graphql/queries';
import PasswordForm from '../../../Components/Forms/PasswordForm.js';
import SuccessStep from '../../../Components/SuccessStep.js';
import TwoColumn from '../../../Components/Layout/TwoColumn';
import NiceError from '../../../Components/Errors/NiceError';
import { UserContext } from '../../../Context';
import { persistJwt } from '../../../helpers/auth';
import BBTranslate from '../../../Components/Locale/BBTranslate';

const RegisterPassword = () => {
  const { token } = useParams();

  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const { data } = useQuery(userQueries.UserByToken(token));

  const handleSubmitSuccess = () => {
    setMessage('youre-password-has-been-set');
    setUser(data.userByToken);
    persistJwt(data.userByToken.token);
  };

  const handleSubmitError = err => {
    setError(err);
  };

  const handleRedirect = () => {
    navigate('/library');
  };

  if (!data) {
    return null;
  }

  // get the user by the token
  return (
    <TwoColumn
      title='complete-registration'
      description={
        <>
          <p>
            <BBTranslate textId='an-account-has-been-set-up' />
          </p>
          <p>
            <BBTranslate textId='once-you-have-completed' />
          </p>
        </>
      }
      form={
        <>
          {
            message !== ''
              ? (
                <div style={{ width: 500 }}>
                  <SuccessStep
                    heading='Success!'
                    text={message}
                    onSuccess={handleRedirect}
                  />
                </div>
                )
              : (
                <>
                  <NiceError err={error} defaultText='resetting-your-password' />
                  <>
                    <PasswordForm
                      onSubmitSuccess={handleSubmitSuccess}
                      onSubmitError={handleSubmitError}
                      user={data.userByToken}
                    />
                  </>
                </>
                )
          }
        </>
      }
    />
  );
};

export default RegisterPassword;
