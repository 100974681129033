import React from 'react';
import { SvgIcon } from '@mui/material';

const BridgeWinners = (props) => {
  return (
    <SvgIcon {...props} height='100' viewBox='0 0 100 100' width='100'>
      <path d='m47.3597992 44.1230077c.9968461 0 2.0030669.1020064 2.9936632.3092068 2.7530453.5737859 4.8873584 2.1771986 5.9998262 4.5137821 1.3624605 2.8561785 1.0874685 6.5060942-.7656028 10.28033l-3.1249095 6.3562723c-1.6687016 3.391712-.6937299 4.0898181-.1687451 4.4627789.1062469.0765048.4406122.2518283 1.2687133.2518283 1.4999565 0 3.2280314-.5865367 3.2467809-.5929121.6999797-.2358897 1.4780822.0573786 1.8530713.7108569.3718642.6566661.240618 1.495031-.3187408 1.9954998-.1624953.1466341-4.0561325 3.5893493-9.837215 3.5893493-.9937212 0-1.999942-.1083818-2.9811636-.3092068-2.759295-.5737859-4.8904833-2.1771986-6.0029511-4.5137821-1.3624605-2.8529908-1.0905934-6.5029065.7656029-10.2803301l3.1249094-6.3594599c1.6655768-3.3853366.6874801-4.0930058.1593704-4.4691543-.103122-.0733171-.4343624-.2486406-1.2562136-.2486406-1.4999565 0-3.2311564.5897244-3.2467809.5960998-.7124794.2486405-1.484332-.0510032-1.8593212-.7076693-.3718642-.656666-.240618-1.4918432.3187408-1.9954997.1624953-.1466341 4.0530076-3.5893493 9.8309652-3.5893493zm6.8279271-19.1230077c4.3145996 0 7.8122737 3.5679539 7.8122737 7.9692481s-3.4976741 7.9692481-7.8122737 7.9692481-7.8122736-3.5679539-7.8122736-7.9692481 3.497674-7.9692481 7.8122736-7.9692481z' />
    </SvgIcon>
  );
};

export default BridgeWinners;
