import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Card from './Card.js';
import { useCardVariant } from '../../../Hooks';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    fontSize: '1em',
    width: '100%',
    maxWidth: '58em'
  },
  card: {
    width: '14em',
    minWidth: 0,
    '& img': {
      width: 'inherit !important',
      border: '1px solid #000',
      borderRadius: '0.6em'
    }
  }
}));

const CardListAuction = (props) => {
  const { cards } = props;
  const classes = useStyles();

  const cardVariant = useCardVariant();

  return (
    <div className={classes.container}>
      {
        cards.map((card, index) => {
          const cardValue = `${card.rank}${card.suit}`;
          return (
            <div className={classes.card} key={index}>
              <Card
                value={cardValue}
                variant={cardVariant}
                enabled
              />
            </div>
          );
        })
      }
    </div>
  );
};

CardListAuction.propTypes = {
  cards: PropTypes.array.isRequired
};

export default CardListAuction;
