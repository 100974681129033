import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import TagForm from './TagForm.js';
import TagGroups from '../../../Components/Queries/TagGroups.js';

const AddTag = (props) => (
  <TagGroups>
    {data => (
      <Mutation
        mutation={mutations.UpsertTag}
      >
        {upsertTag => (
          <>
            <TagForm
              upsertTag={upsertTag}
              tag={{
                name: '',
                tagGroupId: null
              }}
              groups={data}
              {...props}
            />
          </>
        )}
      </Mutation>
    )}
  </TagGroups>
);

AddTag.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default AddTag;
