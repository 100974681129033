import React from 'react';
import { SvgIcon } from '@mui/material';

const Facebook = (props) => {
  return (
    <SvgIcon {...props} height='100' viewBox='0 0 100 100' width='100'>
      <path d='m76.830125 36.0899196c-.644-2.3972812-2.54135-4.2847767-4.95075-4.925491-4.36765-1.1644286-21.879375-1.1644286-21.879375-1.1644286s-17.511725 0-21.879375 1.1644286c-2.408875.6407143-4.30675 2.5282098-4.95075 4.925491-1.169875 4.3448438-1.169875 13.4104286-1.169875 13.4104286s0 9.0652366 1.169875 13.4097322c.644 2.3972812 2.541875 4.2854732 4.95075 4.9260134 4.36765 1.1639062 21.879375 1.1639062 21.879375 1.1639062s17.511725 0 21.879375-1.1639062c2.4094-.6405402 4.30675-2.5287322 4.95075-4.9260134 1.169875-4.3444956 1.169875-13.4097322 1.169875-13.4097322s0-9.0655848-1.169875-13.4104286zm-32.4303 21.7677456v-16.714634l14.548975 8.357317z' />
    </SvgIcon>
  );
};

export default Facebook;
