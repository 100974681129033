import React, { useState, useContext, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Menu,
  MenuItem
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Flags from 'country-flag-icons/react/3x2';
import { LocaleContext } from '../../Context/';

const useStyles = makeStyles((theme) => ({
  menu: {
    border: '1px solid #E6E6E2',
    borderRadius: 4,
    boxShadow: theme.boxShadow,
    marginTop: '7rem',
    width: '14rem',
    padding: '1rem',
    alignItems: 'center'
  },
  button: {
    padding: '1rem 0 1rem 1rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  dropdownIcon: {
    color: 'black'
  },
  flagIcon: {
    width: '4rem'
  },
  splitFlagIcon: {
    width: '3.5rem'
  },
  splitFlagIconWrapper: {
    display: 'flex',
    height: 24
  },
  flagIconInList: {
    padding: '0.15rem 0',
    width: '4.5rem',
    margin: 'auto'
  },
  iconList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    listStyle: 'none',
    height: 'auto',
    padding: 0
  },
  iconListItem: {
    display: 'inline'
  },
  iconListTitle: {
    margin: 0,
    textAlign: 'center',
    overflow: 'hidden',
    width: '10rem'
  },
  iconListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '0.25rem 0.15rem'
  }
}));

const LanguageSelector = () => {
  const classes = useStyles();
  const {
    dropdownIcon,
    flagIcon,
    splitFlagIcon,
    splitFlagIconWrapper,
    flagIconInList,
    button,
    menu
  } = classes;

  // Setup dropdown menu open/close.
  const [anchorElement, setAnchorElement] = useState(null);

  const handleDropdownOpen = useCallback((event) => {
    setAnchorElement(event.currentTarget);
  }, [setAnchorElement]);

  const handleDropdownClose = useCallback(() => {
    setAnchorElement(null);
  }, [setAnchorElement]);

  // get current locale
  const { locale, setLanguage } = useContext(LocaleContext);

  // Check if dropdown is open based on whether or not there is an anchorElement.
  const open = Boolean(anchorElement);

  const id = open ? 'language-selector' : undefined;

  const handleLanguageSelection = useCallback(async (language) => {
    // call these immediately as it feels laggy to call them them after saveUser
    handleDropdownClose();
    // set the language
    setLanguage(language);
  }, [setLanguage, handleDropdownClose]);

  if (!locale) {
    return null;
  }

  const language = locale.language;

  const splitUSGBFlagIcon = (
    <span className={splitFlagIconWrapper}>
      <Flags.US className={splitFlagIcon} />&nbsp;/&nbsp;
      <Flags.GB className={splitFlagIcon} />
    </span>
  );

  return (
    <>
      <Button
        aria-describedby={id}
        color='secondary'
        onClick={handleDropdownOpen}
        className={button}
      >
        {
          language === 'en-gb'
            ? <span style={{ color: 'inherit' }}>{splitUSGBFlagIcon}</span>
            : <Flags.NL className={flagIcon} />
        }
        <ArrowDropDownIcon className={dropdownIcon} />
      </Button>
      <Menu
        classes={{
          paper: menu
        }}
        id='account-menu'
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transitionDuration={0}
        open={open}
        onClose={handleDropdownClose}
      >
        <MenuItem
          onClick={() => {
            handleLanguageSelection('en-gb');
          }}
        >
          {splitUSGBFlagIcon}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLanguageSelection('nl-nl');
          }}
        >
          <Flags.NL className={flagIconInList} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSelector;
