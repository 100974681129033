import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid, Button } from '@mui/material';
import Header from './Header.js';
import Messages from '../Chat/Chat.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import BBTranslate from '../../Locale/BBTranslate.js';

const styles = {
  container: {
    background: '#f5f5f5',
    height: '100%'
  },
  messages: {
    overflow: 'hidden',
    overflowY: 'auto',
    minHeight: '40vh',
    borderTop: '1px solid #e5e5e5'
  },
  messagesDetached: {
    height: 0,
    minHeight: 0,
    transition: 'min-height 0.2s ease-in-out'
  },
  messagesDetachedOpen: {
    minHeight: '65vh'
  },
  footerExpanded: {
    position: 'relative',
    background: '#fff',
    padding: '0 1.5rem',
    width: '100%',
    height: '6.8rem',
    borderTop: '1px solid #e5e5e5',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '-1rem',
      right: 0,
      height: '1rem',
      background: 'radial-gradient(farthest-side at 50% 1rem, rgba(0,0,0,0.1), transparent)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 1rem',
      pointerEvents: 'none'
    }
  },
  footer: {
    height: '7rem',
    width: '40rem',
    transform: 'rotate(-90deg)',
    transformOrigin: '3rem 3rem',
    background: 'transparent',
    whiteSpace: 'nowrap'
  },
  buttonContainer: {
    height: 'inherit',
    marginTop: -8
  }
};

const Drawer = (props) => {
  const {
    classes,
    conversation,
    waitingMessageDelay,
    unreadMessages,
    open,
    hand,
    showButtons,
    onRestartClick,
    onSkipToSummaryClick,
    isPortrait,
    onToggleDrawer,
    playComplete,
    skipToSummaryEnabled,
    ...other
  } = props;

  let width = '100%';
  if (!isPortrait) {
    if (open) {
      width = '45rem';
    } else {
      width = '7rem';
    }
  }

  return (
    <div
      className={classes.container}
    >
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='stretch'
        style={{
          height: '100%',
          width
        }}
      >
        <Grid item>
          <Header
            hand={hand}
            author={hand.product.author}
            unreadMessages={unreadMessages}
            isPortrait={isPortrait}
            onToggleDrawer={onToggleDrawer}
            open={open}
            {...other}
          />
        </Grid>
        <Grid
          item
          xs
          className={classes.messages}
          style={{ display: open ? 'block' : 'none' }}
        >
          <Messages
            author={hand.product.author}
            conversation={conversation}
            waitingMessageDelay={waitingMessageDelay}
          />
        </Grid>
        {
          showButtons && (
            <Grid item>
              <div className={open ? classes.footerExpanded : classes.footer}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={2}
                  className={classes.buttonContainer}
                >
                  <Grid item xs>
                    <Button
                      variant='contained'
                      className={classes.button}
                      startIcon={<RefreshIcon />}
                      onClick={onRestartClick}
                      fullWidth
                    >
                      <BBTranslate textId='restart-hand' />
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      variant='contained'
                      className={classes.button}
                      endIcon={<SkipNextIcon />}
                      onClick={onSkipToSummaryClick}
                      fullWidth
                      disabled={!skipToSummaryEnabled}
                    >
                      <BBTranslate textId='skip-to-summary' />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )
        }
      </Grid>
    </div>
  );
};

Drawer.propTypes = {
  conversation: PropTypes.array.isRequired,
  hand: PropTypes.object.isRequired,
  waitingMessageDelay: PropTypes.number.isRequired,
  unreadMessages: PropTypes.number.isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
  onRestartClick: PropTypes.func.isRequired,
  onSkipToSummaryClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showButtons: PropTypes.bool.isRequired,
  isPortrait: PropTypes.bool.isRequired,
  playComplete: PropTypes.bool.isRequired,
  skipToSummaryEnabled: PropTypes.bool.isRequired
};

export default withStyles(styles)(Drawer);
