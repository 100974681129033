import React from 'react';
import PropTypes from 'prop-types';
import { handQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class HandById extends React.Component {
  render () {
    const { children, id } = this.props;
    return (
      <BBQuery
        query={handQueries.HandById}
        variables={{ id }}
        fetchPolicy='no-cache'
      >
        {data => {
          return children(data.handById);
        }}
      </BBQuery>
    );
  }
}

HandById.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default HandById;
