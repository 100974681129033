import React from 'react';
import PropTypes from 'prop-types';

import TextMessage from './TextMessage.js';
import AttachmentMessage from './AttachmentMessage.js';
import TrickMessage from './TrickMessage.js';
import RevealMessage from './RevealMessage.js';
import ContractMessage from './ContractMessage.js';

const MessageTypeSelector = ({ message }) => {
  if (message.onClick) {
    const handleClick = message.onClick;
    return (
      <AttachmentMessage
        text={message.text}
        onClick={handleClick}
      />
    );
  } else if (message.trick && message.trick.length > 0) {
    return (
      <TrickMessage
        trick={message.trick}
        text={message.text}
        viewpoint={message.viewpoint}
      />
    );
  } else if (message.revealLinkText && message.revealLinkText !== '') {
    return (
      <RevealMessage
        linkText={message.revealLinkText}
      >
        <TextMessage
          text={message.text}
          suit={message.suit ? message.suit : null}
          value={message.value ? message.value : null}
          isBid={message.isBid}
        />
      </RevealMessage>
    );
  } else if (message.bidValue) {
    return (
      <ContractMessage
        bidValue={message.bidValue}
        doubleValue={message.doubleValue}
        declarer={message.declarer}
        isAuctionOnly={message.auctionOnly}
      />
    );
  } else {
    return (
      <TextMessage
        text={message.text}
        suit={message.suit ? message.suit : null}
        value={message.value ? message.value : null}
        isBid={message.isBid}
      />
    );
  }
};

MessageTypeSelector.propTypes = {
  message: PropTypes.object.isRequired
};

export default MessageTypeSelector;
