import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';

const SavingBackdrop = ({ open, text, handleClose }) => {
  return (
    <Backdrop
      style={{ color: '#fff', zIndex: 1000 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color='inherit' />
      <span style={{ paddingLeft: 20 }}>{text}</span>
    </Backdrop>
  );
};

SavingBackdrop.protoTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

export default SavingBackdrop;
