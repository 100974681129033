import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTextTranslation } from '../../../Hooks';
import { stripNonAlphaNumeric } from '../../../helpers/global.js';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.form.input.borderRadius,
    width: '100%',
    border: 'solid 1px #ddd',
    background: (props) => props.background
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#bbb'
  },
  inputRoot: {
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '3rem',
    color: '#222',
    fontSize: 13
  }
}));

const SearchBar = (props) => {
  const { background, onSearchSubmit } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles({ background });
  const searchText = useTextTranslation('search-for');

  const handleSearchChange = (e) => {
    setSearchTerm(stripNonAlphaNumeric(e.target.value));
  };

  const handleSearchSubmit = e => {
    e.preventDefault();
    const trimmed = searchTerm.trim();
    if (trimmed && trimmed !== '') {
      setSearchTerm('');
      onSearchSubmit(trimmed);
    }
  };
  return (
    <form onSubmit={handleSearchSubmit}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon
            onClick={handleSearchSubmit}
          />
        </div>
        <InputBase
          value={searchTerm}
          placeholder={searchText}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearchChange}
        />
      </div>
    </form>
  );
};

SearchBar.propTypes = {
  background: PropTypes.string,
  onSearchSubmit: PropTypes.func.isRequired
};

export default SearchBar;
