import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FormGroup, FormControlLabel, Checkbox, Typography, Link } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getTextIdFromSearchableTag } from '../../../helpers/tag.js';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '1rem',
    // shift the groups so there's so the checkbox's hover state
    // doesn't get cut off on the left hand side
    width: 'calc(100% + 2rem)',
    position: 'relative',
    left: '-1rem'
  },
  groupHeading: {
    fontWeight: 700,
    margin: '0 1rem',
    paddingBottom: '1rem',
    borderBottom: '1px solid #bfbfbf'
  },
  checkboxList: {
    maxHeight: 230,
    flexWrap: 'nowrap',
    transition: 'max-height 0.5s ease-out',
    overflow: 'hidden',
    padding: '0 1rem'
  },
  expanded: ({ numItems }) => ({
    maxHeight: `${numItems * 45.5}px`
  }),
  expandButton: {
    color: '#4788B1',
    display: 'block',
    width: '100%',
    margin: '0 1rem',
    textAlign: 'left',
    fontSize: '1.6rem',
    borderTop: '1px dashed #BFBFBF',
    padding: '13px 0 0 32px',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    }
  }
}));

const FilterPickerGroup = (props) => {
  const { groupName, tags, selectedTags, onTagClicked, translateTags } = props;
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const truncated = tags.length > 5;

  const classes = useStyles({ numItems: tags.length });

  const handleCheckboxChange = (tag, checked) => {
    onTagClicked(groupName, tag, checked);
  };

  const toggleExpanded = () => {
    setExpanded(val => !val);
  };

  return (
    <div className={classes.container}>
      <Typography variant='body1' className={classes.groupHeading}>
        {t(getTextIdFromSearchableTag(groupName))}
      </Typography>
      <FormGroup className={clsx(classes.checkboxList, expanded && classes.expanded)}>
        {tags.map(tag => {
          const checked = (selectedTags && selectedTags.includes(tag));

          return (
            <FormControlLabel
              key={tag}
              componentsProps={{ typography: { variant: 'body1' } }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => {
                    handleCheckboxChange(tag, event.target.checked);
                  }}
                />
            }
              label={
                translateTags
                  ? t(getTextIdFromSearchableTag(tag))
                  : tag
              }
            />
          );
        })}
      </FormGroup>
      {
        truncated
          ? (
            <Link
              component='button'
              className={classes.expandButton}
              onClick={toggleExpanded}
            >
              {expanded ? t('view-less') : t('view-more')}
            </Link>
            )
          : null
      }
    </div>
  );
};

FilterPickerGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array,
  translateTags: PropTypes.bool,
  onTagClicked: PropTypes.func.isRequired
};

export default FilterPickerGroup;
