import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import ComponentError from './ComponentError.js';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '20rem',
    background: '#eee',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  }
}));

const ErrorPage = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ComponentError {...props} />
    </div>
  );
};

ErrorPage.propTypes = {
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  text: PropTypes.string.isRequired
};

export default ErrorPage;
