import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import BBTranslate from '../Locale/BBTranslate.js';

const useStyles = makeStyles({
  component: {
    margin: '6rem',
    zIndex: 100,
    fontSize: '1.6rem !important',
    textAlign: 'center',
    color: '#683044',
    '& span': {
      display: 'block'
    }
  },
  middle: {
    position: 'relative',
    top: '50%',
    marginTop: '6rem'
  },
  icon: {
    width: '8rem',
    height: '8rem',
    margin: '0 auto',
    background: 'transparent url("/img/components/component_error/logo.svg") no-repeat left top',
    backgroundSize: 'contain'
  },
  text: {
    marginTop: '1rem'
  }
});

const ComponentError = ({ heading, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.component}>
      <span className={classes.middle}>
        <span className={classes.icon} />
        <span className={classes.text}>
          <Typography variant='h4'><BBTranslate textId={heading} /></Typography>
          <BBTranslate textId={text} />
        </span>
      </span>
    </div>
  );
};

ComponentError.propTypes = {
  classes: PropTypes.object,
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  text: PropTypes.string.isRequired
};

export default ComponentError;
