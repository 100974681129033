import React from 'react';
import PropTypes from 'prop-types';
import { userQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class UserByToken extends React.Component {
  render () {
    const { children, token } = this.props;
    return (
      <BBQuery
        query={userQueries.UserByToken(token)}
        showLoader
      >
        {data => {
          return children(data.userByToken);
        }}
      </BBQuery>
    );
  }
}

UserByToken.propTypes = {
  children: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

export default UserByToken;
