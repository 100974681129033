import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
});

const AddButton = (props) => {
  const { classes, text, onClick } = props;
  return (
    <span style={{ margin: '1rem' }}>
      <Button
        variant='contained'
        color='secondary'
        onClick={onClick}
      >
        {text}
        <AddIcon className={classes.rightIcon} />
      </Button>
    </span>
  );
};

AddButton.protoTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default withStyles(styles)(AddButton);
