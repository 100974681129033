import React, { useContext } from 'react';
import BBDrawer from './BBDrawer.js';
import { DrawerContext } from '../../Context';
import Cart from '../Store/Cart/Cart.js';
import GiftForm from '../Store/Gift/GiftForm.js';
import Login from '../../Views/Customer/Login/LoginDrawer.js';
import HamburgerMenu from '../Layout/AppBar/HamburgerMenu.js';
import ForgottenPassword from '../../Views/Customer/Login/ForgottenPassword';

const drawers = [
  {
    type: 'cart',
    Component: Cart,
    heading: 'your-basket'
  },
  {
    type: 'gift',
    Component: GiftForm,
    heading: 'send-as-gift'
  },
  {
    type: 'login',
    Component: Login,
    heading: 'login'
  },
  {
    type: 'forgotten',
    Component: ForgottenPassword,
    heading: 'forgotten-password'
  },
  {
    type: 'menu',
    Component: HamburgerMenu,
    heading: 'BridgeBee' // TODO logo
  }
];

const DrawerWrapper = () => {
  const {
    open,
    type,
    componentProps = {},
    closeDrawer
  } = useContext(DrawerContext);

  const handleCloseClick = () => {
    closeDrawer();
  };

  const drawer = drawers.find((drawer) => {
    return drawer.type === type;
  });

  let Component, heading;
  if (drawer) {
    ({ Component, heading } = drawer);
  }

  return (
    <BBDrawer
      open={open}
      onClose={handleCloseClick}
      heading={heading}
    >
      {Component && (
        <Component
          {...componentProps}
        />
      )}
    </BBDrawer>
  );
};

export default DrawerWrapper;
