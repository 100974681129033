import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Link,
  Tooltip,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  IconButton
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ApproveIcon from '@mui/icons-material/Spellcheck';
import BackspaceIcon from '@mui/icons-material/Backspace';
import UpArrowIcon from '@mui/icons-material/ArrowDropUp';
import DownArrowIcon from '@mui/icons-material/ArrowDropDown';
import ShareIcon from '@mui/icons-material/Share';
import PreviewIcon from '@mui/icons-material/Launch';
import DescriptionIcon from '@mui/icons-material/Description';
import BBInput from '../../../../Components/Forms/BBInput.js';
import PreviewModal from './PreviewModal.js';
import HandById from '../../../../Components/Queries/HandById.js';
import ContentShare from '../ContentShare.js';

const useStyles = makeStyles({
  reorderButton: {
    padding: '0 !important'
  },
  cell: {
    width: 5,
    paddingRight: 0
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 90
  }
});

const EditableHandsTable = (props) => {
  const {
    set,
    handsArray,
    onRowClick,
    onDeleteClick,
    onEditSaveClick,
    onApproveRejectClick,
    onFreeCheckboxChange,
    onReorder,
    currentUser
  } = props;

  const classes = useStyles();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [contentShareOpen, setContentShareOpen] = useState(false);
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);
  const [selectedHand, setSelectedHand] = useState(null);

  const handleEditClick = (hand) => {
    setIsBeingEdited(true);
    setSelectedHand(hand);
  };

  const handleTitleChange = (input) => {
    setSelectedHand({ ...selectedHand, title: input.value });
  };

  const handleSaveClick = () => {
    onEditSaveClick(selectedHand.id, selectedHand.title);
    setIsBeingEdited(false);
    setSelectedHand(null);
  };

  const handleCancelClick = () => {
    setSelectedHand(null);
  };

  const handleDeleteClick = (id) => {
    setSelectedHand(handsArray.find(h => h.id === id));
    setIsBeingDeleted(true);
  };

  const handlePreviewClick = (id) => {
    setSelectedHand(handsArray.find(h => h.id === id));
    setPreviewOpen(true);
  };

  const handleClosePreviewClick = () => {
    setPreviewOpen(false);
    setSelectedHand(null);
  };

  const handleDeleteConfirmClick = () => {
    onDeleteClick(selectedHand.id);
    setSelectedHand(null);
  };

  const handleApproveRejectClick = (id, status) => {
    onApproveRejectClick(id, status);
    setSelectedHand(null);
  };

  const handleShareClick = (id) => {
    setSelectedHand(handsArray.find(h => h.id === id));
    setContentShareOpen(true);
  };

  const handleContentShareClose = () => {
    setContentShareOpen(false);
    setSelectedHand(null);
  };

  return (
    <>
      <div>
        {
          previewOpen
            ? (
              <HandById id={selectedHand.id}>
                {handData => (
                  <PreviewModal
                    onCloseClick={handleClosePreviewClick}
                    open
                    hand={handData}
                  />
                )}
              </HandById>
              )
            : null
        }
        <Table aria-labelledby='tableTitle' align='left'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>&nbsp;</TableCell>
              <TableCell>Title</TableCell>
              <TableCell align='right' style={{ width: 180 }}>Status</TableCell>
              <TableCell align='center' style={{ width: 50 }}>Free</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              handsArray.map(hand => {
                return (
                  <TableRow
                    key={hand.id}
                  >
                    <TableCell className={classes.cell}>
                      <div>
                        <IconButton
                          aria-label='Yes'
                          onClick={onReorder(hand.id, 'up')}
                          className={classes.reorderButton}
                          size='large'
                        >
                          <UpArrowIcon />
                        </IconButton>
                      </div>
                      <div>
                        <IconButton
                          aria-label='Yes'
                          onClick={onReorder(hand.id, 'down')}
                          className={classes.reorderButton}
                          size='large'
                        >
                          <DownArrowIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell>
                      {
                        selectedHand && selectedHand.id === hand.id && isBeingEdited
                          ? (
                            <span>
                              <BBInput
                                id='title'
                                name='title'
                                type='text'
                                label=''
                                maxLength={100}
                                value={selectedHand.title}
                                onChange={handleTitleChange}
                                variant='standard'
                              />
                              <Tooltip title='Save' aria-label='save'>
                                <IconButton
                                  aria-label='Save'
                                  onClick={handleSaveClick}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title='Cancel' aria-label='cancel'>
                                <IconButton
                                  aria-label='Cancel'
                                  onClick={handleCancelClick}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            </span>
                            )
                          : null
                      }
                      {
                        selectedHand && selectedHand.id === hand.id && isBeingDeleted
                          ? (
                            <span>
                              <Typography variant='h6'>
                                Are you sure you want to delete this hand?
                              </Typography>
                              <Tooltip title='Yes' aria-label='yes'>
                                <IconButton
                                  aria-label='Yes'
                                  onClick={handleDeleteConfirmClick}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title='Cancel' aria-label='cancel'>
                                <IconButton
                                  aria-label='Cancel'
                                  onClick={handleCancelClick}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            </span>
                            )
                          : null
                      }
                      {
                        !selectedHand || selectedHand.id !== hand.id
                          ? (
                            <span>
                              {
                                currentUser.type !== 'Publisher' || hand.status === 'Draft'
                                  ? (
                                    <>
                                      <Link
                                        component='button'
                                        variant='subtitle1'
                                        color='textPrimary'
                                        onClick={() => {
                                          onRowClick(hand);
                                        }}
                                        underline='hover'
                                      >
                                        {hand.title}
                                      </Link>
                                      <Tooltip title='Edit' aria-label='edit'>
                                        <IconButton
                                          aria-label='Edit'
                                          onClick={() => {
                                            handleEditClick(hand);
                                          }}
                                          size='large'
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                    )
                                  : (
                                    <Typography variant='subtitle1'>
                                      {hand.title}
                                    </Typography>
                                    )
                              }
                            </span>
                            )
                          : null
                      }
                    </TableCell>
                    <TableCell align='right' className={classes.status}>
                      {
                        hand.status === 'Draft'
                          ? (
                            <Tooltip title='Delete' aria-label='delete'>
                              <IconButton
                                aria-label='Delete'
                                onClick={() => {
                                  handleDeleteClick(hand.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            )
                          : null
                      }
                      {
                        currentUser.type !== 'Publisher' && hand.status === 'Pending'
                          ? (
                            <>
                              <Tooltip title='Preview' aria-label='preview'>
                                <IconButton
                                  aria-label='Preview'
                                  onClick={() => {
                                    handlePreviewClick(hand.id);
                                  }}
                                >
                                  <PreviewIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title='Reject' aria-label='reject'>
                                <IconButton
                                  aria-label='Reject'
                                  onClick={() => {
                                    handleApproveRejectClick(hand.id, 'Draft');
                                  }}
                                >
                                  <BackspaceIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title='Approve' aria-label='approve'>
                                <IconButton
                                  aria-label='Approve'
                                  onClick={() => {
                                    handleApproveRejectClick(hand.id, 'Approved');
                                  }}
                                >
                                  <ApproveIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                            )
                          : null
                      }
                      {
                        currentUser.type === 'Admin' &&
                          hand.status === 'Approved'
                          ? (
                            <>
                              {
                                set.productLinksEnabled
                                  ? (
                                    <Tooltip title='Share' aria-label='share'>
                                      <IconButton
                                        aria-label='share'
                                        onClick={() => {
                                          handleShareClick(hand.id);
                                        }}
                                      >
                                        <ShareIcon />
                                      </IconButton>
                                    </Tooltip>
                                    )
                                  : null
                              }
                              <Tooltip title='Make draft' aria-label='make-draft'>
                                <IconButton
                                  aria-label='make-draft'
                                  onClick={() => {
                                    handleApproveRejectClick(hand.id, 'Draft');
                                  }}
                                >
                                  <DescriptionIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                            )
                          : null
                      }
                      {hand.status}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        key={hand.id}
                        checked={hand.freeToPlay}
                        onChange={onFreeCheckboxChange(hand)}
                        value='freeToPlay'
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </div>
      {
        selectedHand
          ? (
            <ContentShare
              product={set}
              hand={selectedHand}
              open={contentShareOpen}
              onModalClose={handleContentShareClose}
            />
            )
          : null
      }
    </>
  );
};

EditableHandsTable.propTypes = {
  set: PropTypes.object.isRequired,
  handsArray: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditSaveClick: PropTypes.func.isRequired,
  onApproveRejectClick: PropTypes.func.isRequired,
  onFreeCheckboxChange: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default EditableHandsTable;
