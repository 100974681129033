import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Card from '../Cards/Card.js';
import {
  getCardsForSeat,
  arrangeSeatsBasedOnViewPoint
} from '../../../helpers/card.js';
import { useTextTranslation, useCardVariant } from '../../../Hooks';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold'
  },
  trick: {
    position: 'relative',
    fontSize: '1.25rem',
    width: '15.8rem',
    height: '14.4rem',
    pointerEvents: 'none'
  },
  nl: {
    width: '14.6rem',
    '& $position0': {
      marginLeft: '-1.8em'
    },
    '& $position2': {
      marginLeft: '-1.8em'
    },
    '& $trickCard': {
      width: '3.6em'
    }
  },
  trickCard: {
    width: '4em',
    height: '5.6em',
    borderRadius: '0.2em',
    overflow: 'hidden'
  },
  trickCardImg: {
    width: '100%'
  },
  position0: {
    position: 'absolute',
    left: '50%',
    top: 0,
    marginLeft: '-2em'
  },
  position1: {
    position: 'absolute',
    right: 0,
    top: '50%',
    marginTop: '-2.8em'
  },
  position2: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    marginLeft: '-2em'
  },
  position3: {
    position: 'absolute',
    left: 0,
    top: '50%',
    marginTop: '-2.8em'
  },
  trickWinnerOverlay: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '2.5em',
    height: '2.5em',
    background: 'transparent url(/img/components/mini_card/bg_overlay_winner.svg) no-repeat right bottom',
    backgroundSize: 'contain'
  }
}));

const TrickMessage = (props) => {
  const classes = useStyles();
  const { trick, text, viewpoint } = props;
  const directions = arrangeSeatsBasedOnViewPoint(viewpoint);
  const cardVariant = useCardVariant();

  return (
    <>
      <span className={classes.label} dangerouslySetInnerHTML={{ __html: useTextTranslation(text) + ':' }} />
      <div className={clsx(classes.trick, classes[cardVariant])}>
        {
          directions.map((direction, index) => {
            const card = getCardsForSeat(trick, direction.value)[0];
            return (
              <div
                key={index}
                className={clsx(classes.trickCard, classes['position' + index])}
              >
                {
                  card &&
                    (
                      <>
                        <Card
                          value={`${card.rank}${card.suit}`}
                          variant={cardVariant}
                          enabled
                        />
                        <div
                          className={card.winner ? classes.trickWinnerOverlay : null}
                        />
                      </>
                    )
                }
              </div>
            );
          })
        }
      </div>
    </>
  );
};

TrickMessage.propTypes = {
  trick: PropTypes.array.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  viewpoint: PropTypes.string.isRequired
};

export default TrickMessage;
