import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import BBTranslate from '../Locale/BBTranslate.js';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2rem 0',
    color: '#666',
    fontWeight: 600,
    border: '0.2rem dashed',
    borderColor: theme.palette.styleguide.beigeDark,
    textAlign: 'center',
    borderRadius: '1rem',
    padding: '2rem 1rem'
  }
}));

const NoResultsMessage = (props) => {
  const { text } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BBTranslate textId={text} />
    </div>
  );
};

NoResultsMessage.propTypes = {
  text: PropTypes.string.isRequired
};

export default NoResultsMessage;
