import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      margin: '0 auto',
      maxWidth: 400
    }
  }
}));

const HowItWorksItem = (props) => {
  const { heading, body, icon } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img
        src={`img/components/sales/how-bridgebee-works-${icon}.svg`}
        alt=''
      />
      <Typography
        variant='h2'
        component='h3'
        sx={{
          marginTop: '3rem',
          marginBottom: '3rem'
        }}
      >
        {heading}
      </Typography>
      <Typography variant='body4'>{body}</Typography>
    </div>
  );
};

export default HowItWorksItem;
