import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Testimonial from './Testimonial.js';
import StoreSectionContainer from '../../../Components/Store/StoreSectionContainer.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  testimonialsWrapper: {
    overflow: 'visible'
  },
  testimonials: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
      // show the bits overflowing the container's padding
      width: 'calc(100% + 4rem)',
      // gives the right hand side a bit of leeway
      // so scrolling to the far right lines item up with container padding
      paddingRight: '2rem',
      // make sure bottom box shadow isn't cut off
      paddingBottom: '1rem',
      // adjust pre-existing grid margin that material adds
      marginLeft: '-2rem'
    }
  },
  testimonial: {
    [theme.breakpoints.down('md')]: {
      minWidth: 260
    }
  }
}));

const Testimonials = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <StoreSectionContainer
      heading={t('reviews')}
      subheading={t('dont-take-our-word')}
      extraWhitespace
    >
      <div className={classes.testimonialsWrapper}>
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          wrap='nowrap'
          className={classes.testimonials}
        >
          <Grid item xs={4} className={classes.testimonial}>
            <Testimonial
              heading={t('fun-and-easy-to-use')}
              body={t('i-love-bidding-and-playing')}
              byline='Millie Grant'
            />
          </Grid>
          <Grid item xs={4} className={classes.testimonial}>
            <Testimonial
              body={t('most-instructive-bridge-teaching-apps')}
              byline='Gerald Clarke'
            />
          </Grid>
          <Grid item xs={4} className={classes.testimonial}>
            <Testimonial
              body={t('i-learn-so-much-more')}
              byline='Sally Nowak'
            />
          </Grid>
        </Grid>
      </div>
    </StoreSectionContainer>
  );
};

export default Testimonials;
