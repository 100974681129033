import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import AdminPage from '../../../Components/AdminPage.js';
import { royaltyQueries } from '../../../graphql/queries';
import { getDateFilterOptionsByQuarter } from '../../../helpers/admin.js';
import { AdminSearchContext } from '../../../Context';

const RoyaltiesOverview = ({ currencies }) => {
  const [currency, setCurrency] = useState('USD');
  const navigate = useNavigate();
  const dateFilterOptions = getDateFilterOptionsByQuarter();
  const initialDateRange = dateFilterOptions.find(option => option.label === 'Current Quarter');

  const { searchParams, setSearchParams } = useContext(AdminSearchContext);

  const columns = [
    { id: 'authorFullName', label: 'Author', sortColumn: 'authorFullName' },
    { id: 'totalSales', label: `Total Sales (${currency})`, sortColumn: 'totalSales' },
    { id: 'royaltyPercentage', label: 'Royalty (%)', sortColumn: 'royaltyPercentage' },
    { id: 'totalRoyalty', label: `Royalties (${currency})`, sortColumn: 'totalRoyalty' }
  ];

  const handleCurrencySelection = (currency) => {
    setCurrency(currency.value);
  };
  const handleRowClick = (royalty) => {
    // reset search params so they don't accidentally carry over
    // e.g. this errors out when the orderby param doesn't exist in target
    setSearchParams({
      ...searchParams,
      page: 0,
      offset: 0,
      orderBy: 'id',
      orderByDirection: 'asc'
    });
    navigate(`/admin/royalties/${royalty.authorId}/${_.kebabCase(royalty.authorFullName)}`);
  };

  const additionalDropdown = {
    id: 'currencySelect',
    name: 'currencySelect',
    label: 'Select Currency',
    onChange: handleCurrencySelection,
    items: currencies,
    value: currency
  };

  return (
    <AdminPage
      columns={columns}
      query={royaltyQueries.RoyaltySearch}
      queryOptions={{ currency }}
      rowsDataPath='royaltySearch.royalties'
      countDataPath='royaltySearch.count'
      initialOrderBy='totalSales'
      initialOrderByDirection='desc'
      initialDateRange={initialDateRange}
      showDates
      additionalDropdown={additionalDropdown}
      onRowClick={handleRowClick}
      dateFilterOptions={dateFilterOptions}
      sectionName='royalties'
    />
  );
};

export default RoyaltiesOverview;
