// one of more messages for a side of the conversion
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Message from './Message.js';
import MessageTypeSelector from './MessageTypeSelector.js';

const styles = {
  message: {
    position: 'relative',
    clear: 'both',
    listStyle: 'none',
    margin: 0,
    padding: '1rem 0'
  }
};

const MessageGroup = (props) => {
  const { classes, messages, ...other } = props;
  return (
    <ul className={classes.message}>
      {
        messages.map((message, index) => {
          return (
            <Message {...other} key={index}>
              <MessageTypeSelector message={message} />
            </Message>
          );
        })
      }
    </ul>
  );
};

MessageGroup.propTypes = {
  messages: PropTypes.array.isRequired,
  position: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
  waitingMessageDelay: PropTypes.number.isRequired
};

export default withStyles(styles)(MessageGroup);
