let theBuild = 'local';
if (process.env.REACT_APP_ENV) {
  theBuild = process.env.REACT_APP_ENV.toUpperCase();
}
export const build = theBuild;

export const getEnvVar = (name) => {
  const ENV = theBuild;
  const fullVarName = `REACT_APP_${name}_${ENV}`;
  const retVar = process.env[fullVarName];
  return retVar;
};

export const getCurrentAppVersion = () => {
  return process.env.REACT_APP_VERSION;
};

export const endpoint = getEnvVar('GRAPHQL_URL');
