const currencies = {
  usd: '$',
  gbp: '£',
  eur: '€',
  cad: '$',
  aud: '$',
  nzd: '$'
};

const getCurrencySymbol = (currency) => {
  return currencies[currency];
};

const twoDecimalPlaces = (value) => {
  value = parseFloat(value);
  value = parseFloat((value * 100) / 100).toFixed(2);
  return value;
};

const formatPrice = (price, currency) => {
  return `${getCurrencySymbol(currency.toLowerCase())}${twoDecimalPlaces(price)}`;
};

module.exports = {
  getCurrencySymbol,
  twoDecimalPlaces,
  formatPrice
};
