import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Menu from './Menu.js';
import MiniCard from '../MiniCard/MiniCard.js';

const styles = {

};

const Risk = (props) => {
  const { classes, onClick, ...other } = props;
  const risks = ['-', 'X', 'XX'];
  return (
    <Menu {...other}>
      <table>
        <tbody>
          <tr>
            {
            risks.map((risk, index) => {
              return (
                <td key={index}>
                  <MiniCard
                    value={risk}
                    onClick={onClick}
                    isBid
                  />
                </td>
              );
            })
          }
          </tr>
        </tbody>
      </table>
    </Menu>
  );
};

Risk.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default withStyles(styles)(Risk);
