import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';

const Menu = (props) => {
  const { children, anchorEl, open, onClose } = props;
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {children}
    </Popover>
  );
};

Menu.propTypes = {
  children: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default Menu;
