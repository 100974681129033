import React from 'react';
import PropTypes from 'prop-types';
import StoreSelect from '../Forms/Store/StoreSelect.js';

const SortByDropDown = (props) => {
  return (
    <StoreSelect
      {...props}
      valueField='value'
      keyField='name'
      options={[
        {
          name: 'published-date-newest-first',
          value: 'product.createdAt'
        },
        {
          name: 'product-title-az',
          value: 'product.title'
        },
        {
          name: 'author-surname-az',
          value: 'product.author.last_name'
        },
        {
          name: 'price-low-to-high',
          value: 'product.price.priceUSD'
        }
      ]}
    />
  );
};

SortByDropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default SortByDropDown;
