import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    maxWidth: '118rem',
    padding: '0 1.5rem 6rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '4rem'
    }
  }
}));

const MainContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <main className={classes.container}>
      {children}
    </main>
  );
};

export default MainContainer;
