import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BBTableHead from './BBTableHead.js';
import BBTableRow from './BBTableRow.js';
import TablePagination from '@mui/material/TablePagination';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  table: {
    minWidth: 700,
    paddingLeft: 100
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

const BBTable = (props) => {
  const {
    columns,
    rows = [],
    count,
    onRowClick,
    onColumnSort,
    onChangeRowsPerPage,
    onChangePage,
    limit,
    page,
    orderByDirection,
    orderBy,
    selected
  } = props;
  const classes = useStyles();

  const isSelected = id => selected.indexOf(id) !== -1;
  const emptyRows = limit - rows.length;
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby='tableTitle' align='left'>
          <BBTableHead
            numSelected={selected ? selected.length : 0}
            order={orderByDirection}
            orderBy={orderBy}
            onColumnSort={onColumnSort}
            rowCount={rows.length}
            columns={columns}
          />
          <TableBody>
            {
              rows.map((item, index) => {
                const isItemSelected = selected ? isSelected(item.id) : false;
                return (
                  <TableRow
                    hover
                    onClick={onRowClick(item)}
                    role='checkbox'
                    tabIndex={-1}
                    key={item.id}
                    className={classes.tableRow}
                  >
                    <BBTableRow
                      columns={columns}
                      item={item}
                      isItemSelected={isItemSelected}
                    />
                  </TableRow>
                );
              })
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component='div'
        count={count}
        rowsPerPage={limit}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page'
        }}
        onPageChange={(_, page) => {
          console.log('+++++++++ onPageChange +++++++++');
          onChangePage(page);
        }}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Paper>
  );
};

BBTable.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onColumnSort: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  limit: PropTypes.number,
  page: PropTypes.number,
  selected: PropTypes.array
};

export default BBTable;
