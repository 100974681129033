import React from 'react';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import {
  Alert
} from '@mui/material';
import { parseError } from '../../helpers/error.js';

// A component for displaying nice error messages
// to customers. It will get the nice bit from API/graphql errors
// if there is anything nice (e.g validation error )
// but return a generic error message if there was nothing nice to find
// and stick the message in the page using an Alert

// so essentially... errors thrown as type "UserInputError" get sanitised
// and passed to the customer, and anything else gets logged!
const NiceError = ({ err, defaultText }) => {
  const { t } = useTranslation();
  if (err) {
    const parsedError = parseError(err);
    let message;
    if (parsedError.code === 'BAD_USER_INPUT' || parsedError.code === 'VALIDATION_ERROR') {
      message = t(parsedError.message);
    } else {
      const translatedDefault = t(defaultText);
      message = t('there-was-a-problem', { defaultText: translatedDefault });
      // as this is a nasty error, we should log it with sentry
      Sentry.captureException(err);
    }
    return (
      <Alert severity='error' sx={{ m: 3, border: 'solid 1px #C03D36', borderRadius: '2px' }}>{message}</Alert>
    );
  }
  return null;
};

export default NiceError;
