import React from 'react';
import FeaturedAuthorsShared from '../../Components/Store/FeaturedAuthors/FeaturedAuthors.js';

const FeaturedAuthors = () => {
  return (
    <FeaturedAuthorsShared
      backgroundColor='#F3ECDE'
      extraWhitespace
    />
  );
};

export default FeaturedAuthors;
