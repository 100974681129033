import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const styles = {
  direction: {
    display: 'block',
    position: 'absolute',
    width: '1.8em',
    height: '1.8em',
    lineHeight: '1.8em',
    textAlign: 'center',
    fontSize: '2em',
    textDecoration: 'none',
    color: '#4a4a4a',
    fontWeight: 700,
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '0.15em',
    cursor: 'pointer',
    '&:hover': {
      background: '#f5f5f5',
      borderColor: '#999',
      color: '#000'
    },
    '&.active': {
      background: '#4a90e2',
      borderColor: '#3e7ac0',
      color: '#fff'
    },
    '&.dealerN': { left: '50%', top: 0, marginLeft: ' -0.9em' },
    '&.dealerE': { right: 0, top: '50%', marginTop: ' -0.9em' },
    '&.dealerS': { left: '50%', bottom: 0, marginLeft: ' -0.9em' },
    '&.dealerW': { left: 0, top: '50%', marginTop: '-0.9em;' }
  }
};

class DealerDirection extends React.Component {
  render () {
    const { classes, direction, dealer, onClick } = this.props;

    return (
      <span
        className={
          clsx(
            classes.direction,
            'dealer' + direction,
            dealer === direction ? 'active' : null
          )
        }
        onClick={() => {
          onClick(direction);
        }}
      >
        {direction}
      </span>
    );
  }
}

DealerDirection.propTypes = {
  classes: PropTypes.object.isRequired,
  direction: PropTypes.string.isRequired,
  dealer: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(DealerDirection);
