import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl
} from '@mui/material';
import RegisterForm from './RegisterForm.js';
import { UserContext, LocaleContext } from '../../../Context';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';
import TwoColumn from '../../../Components/Layout/TwoColumn.js';
import SuccessStep from '../../../Components/SuccessStep.js';
import NiceError from '../../../Components/Errors/NiceError.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';

const Register = () => {
  const { createUser, setUser } = useContext(UserContext);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({});
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);

  if (!locale) {
    return null;
  }

  const handleClick = async () => {
    setLoading(true);
    try {
      const user = await createUser(formState, locale);
      setUser(user);
      setMessage('you-have-been-registered');
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleComplete = (isComplete, fields) => {
    setIsValid(isComplete);
    if (isComplete) {
      setFormState(fields);
    }
  };

  const handleFormError = (err) => {
    setError(err);
  };

  const handleRedirect = () => {
    navigate('/products');
  };

  return (
    <TwoColumn
      title='register'
      description={
        <>
          <p>
            <BBTranslate textId='your-privacy-matters' />
          </p>
          <p>
            <BBTranslate textId='your-information-is-securely-protected' />
          </p>
        </>
      }
      form={
        <div>
          <NiceError err={error} defaultText='registering-your-account' />
          {
            message
              ? (
                <SuccessStep
                  heading='Success!'
                  text={message}
                  onSuccess={handleRedirect}
                />
                )
              : (
                <>
                  <RegisterForm
                    onComplete={handleComplete}
                    onError={handleFormError}
                  />
                  <FormControl variant='standard' margin='normal' required fullWidth>
                    <StoreButton
                      onClick={handleClick}
                      label='register'
                      disabled={!isValid}
                      loading={loading}
                      fullWidth
                    />
                  </FormControl>
                </>
                )
          }
        </div>
      }
    />
  );
};

export default Register;
