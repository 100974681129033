import React from 'react';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import {
  Paper,
  Radio,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid #BFBFBF',
    borderRadius: 0,
    padding: '2rem'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  body: {
    background: '#FAFAFA'
  },
  selected: {
    borderColor: theme.palette.styleguide.maroon
  }
}));

const Section = ({ id, header, body, disabled, selected, onSectionSelected }) => {
  const classes = useStyles();
  return (
    <>
      <Paper className={clsx(classes.paper, classes.header, selected && classes.selected)}>
        {
          onSectionSelected &&
            <Radio
              checked={selected}
              disabled={disabled}
              value={id}
              onChange={() => onSectionSelected(id)}
            />
        }
        <Typography
          variant='h5' sx={{
            width: '100%',
            color: disabled ? 'rgba(0,0,0,0.26)' : undefined
          }}
        >
          {header}
        </Typography>
      </Paper>
      {
        body && !disabled && (selected || id === 'login') &&
          <Paper className={clsx(classes.paper, classes.body, selected && classes.selected)}>
            {body}
          </Paper>
      }
    </>
  );
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.object.isRequired,
  body: PropTypes.object,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onSectionSelected: PropTypes.func
};

export default Section;
