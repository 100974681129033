import React, { useEffect } from 'react';
import { GlobalStyles } from '@mui/material';
import { ReactTitle } from 'react-meta-tags';
import { useNavigate } from 'react-router-dom';
import Drawer from '../../Components/Drawer/Drawer.js';
import AppBar from '../../Components/Layout/AppBar/AppBar.js';
import Footer from '../../Components/Layout/Footer.js';
import AddToHomescreen from '../../Components/AddToHomescreen.js';
import { isAuthenticated } from '../../helpers/auth.js';
import { useTranslation } from 'react-i18next';
import Hero from './Hero.js';
import HowItWorks from './HowItWorks/HowItWorks.js';
import Highlight1 from './Highlight1.js';
import LatestProducts from './LatestProducts';
import FeaturesBrief from './FeaturesBrief.js';
import Testimonials from './Testimonials/Testimonials.js';
import FeaturedAuthors from './FeaturedAuthors.js';
import Faq from './FAQ.js';
import Highlight2 from './Highlight2.js';
import StillNotConvinced from './StillNotConvinced.js';

const globalStyles = {
  html: {
    fontSize: 10,
    lineHeight: 10,
    '@media all and (min-width: 1680px)': {
      fontSize: 11
    }
  },
  body: {
    overflowX: 'hidden'
  }
};

const Home = () => {
  const { t } = useTranslation();
  const loggedIn = isAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = isAuthenticated();
    if (loggedIn) {
      navigate('/library');
    }
  }, [navigate]);

  const translatedTitle = t('engaging-bridge-lessons-every-level');

  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <ReactTitle title={`BridgeBee - ${translatedTitle}`} />
      <Drawer />
      <AppBar
        loggedIn={loggedIn}
        transparent
      />
      <main className='container'>
        <Hero />
        <LatestProducts />
        <HowItWorks />
        <Highlight1 />
        <FeaturesBrief />
        <FeaturedAuthors />
        <Faq extraWhitespace />
        <Highlight2 />
        <Testimonials />
        <StillNotConvinced />
      </main>
      <Footer loggedIn={loggedIn} />
      <AddToHomescreen />
    </>
  );
};

export default Home;
