import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import MiniCardBody from './MiniCardBody.js';

const MiniCard = (props) => {
  const { tooltipText, ...other } = props;

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} placement='top'>
        <MiniCardBody {...other} />
      </Tooltip>
    );
  }
  return <MiniCardBody {...other} />;
};

MiniCard.propTypes = {
  scale: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  tooltipText: PropTypes.string,
  size: PropTypes.string,
  isWinner: PropTypes.bool,
  hasComment: PropTypes.bool,
  suit: PropTypes.string,
  value: PropTypes.string,
  isBid: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.string
};

export default MiniCard;
