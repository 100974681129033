import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';

const StripeWrapper = ({ children, stripeApiKey }) => {
  const [stripe, setStripe] = useState();

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(stripeApiKey));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once stripe.js loads
        setStripe(window.Stripe(stripeApiKey));
      });
    }
  }, [stripeApiKey]);

  if (stripe) {
    return (
      <StripeProvider stripe={stripe}>
        <Elements>
          {children}
        </Elements>
      </StripeProvider>
    );
  }
  return null;
};

StripeWrapper.propTypes = {
  children: PropTypes.element,
  stripeApiKey: PropTypes.string.isRequired
};

export default StripeWrapper;
