import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { LocaleContext } from '../../../Context';

const useStyles = makeStyles(() => ({
  root: {
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    left: '-10rem',
    top: '-10rem'
  },
  span: {
    width: 1
  },
  img: {
    width: 1
  }
}));

const CardSVGPreloader = () => {
  const classes = useStyles();
  const suits = ['S', 'C', 'D', 'H'];
  const values = [2, 3, 4, 5, 6, 7, 8, 9, 'T', 'J', 'Q', 'K', 'A'];

  const { locale } = useContext(LocaleContext);
  const cards = [];

  for (const value of values) {
    for (const suit of suits) {
      cards.push(`${value}${suit}`);
    }
  }

  return (
    <div className={classes.root}>
      {
        cards.map((card, index) => {
          return (
            <span
              className={classes.span}
              key={index}
            >
              <img
                src={`/img/cards/normal/${card}.png`}
                className={classes.img}
                alt={card}
              />
              <img
                src={`/img/cards/simplified/${card}.png`}
                className={classes.img}
                alt={card}
              />
              {
                (locale && locale.language === 'nl-nl') &&
                  (
                    <img
                      src={`/img/cards/nl/${card}.png`}
                      className={classes.img}
                      alt={card}
                    />
                  )
              }
            </span>
          );
        })
      }
      )
    </div>
  );
};

export default CardSVGPreloader;
