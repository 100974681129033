import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import VoucherForm from './VoucherForm.js';

const AddVoucher = (props) => {
  return (
    <Mutation
      mutation={mutations.CreateVoucher}
    >
      {createVoucher => (
        <>
          <VoucherForm
            createVoucher={createVoucher}
            {...props}
          />
        </>
      )}
    </Mutation>
  );
};

AddVoucher.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default AddVoucher;
