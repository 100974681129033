import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getEnvVar } from '../helpers/env.js';

const siteKey = getEnvVar('TURNSTILE_SITE_KEY');
// TESTING SITEKEYS:
// const siteKey = '1x00000000000000000000AA' // Always passes (visible)
// const siteKey = '2x00000000000000000000AB' // Always blocks (visible)
// const siteKey = '1x00000000000000000000BB' // Always passes (invisible)
// const siteKey = '2x00000000000000000000BB' // Always blocks (invisible)
// const siteKey = '3x00000000000000000000FF' // Forces an interactive challenge (visible)

/**
 * Injects a Cloudflare Turnstile widget for the user to complete
 * this requires the CF Turnstile script to be linked in index.html
 *
 * Docs at: https://developers.cloudflare.com/turnstile/get-started/client-side-rendering
 */
const Captcha = (props) => {
  const { onSuccess, onError, action } = props;
  const [isTurnstileLoaded, setIsTurnstileLoaded] = useState(!!(window.turnstile && window.turnstile.render));
  const [widgetId, setWidgetId] = useState(null);
  const containerRef = useRef(null);

  const handleError = useCallback(() => {
    onError('Our anti-spam measures have flagged your registration attempt as suspicious. Please try again later, or contact support@bridgebee.app.');
  }, [onError]);

  const handleUnsupported = useCallback(() => {
    onError('Our anti-spam measures do not support your device. Please try a different device, or contact support@bridgebee.app.');
  }, [onError]);

  // if the turnstile lib hasn't fully loaded yet, wait until it is before doing anything else
  useEffect(() => {
    if (isTurnstileLoaded) {
      return;
    }

    const interval = setInterval(() => {
      if (window.turnstile && window.turnstile.render) {
        setIsTurnstileLoaded(true);
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [isTurnstileLoaded]);

  // render turnstile if the lib is loaded
  useEffect(() => {
    if (!isTurnstileLoaded || widgetId) {
      return;
    }

    const newWidgetId = window.turnstile.render(containerRef.current, {
      sitekey: siteKey,
      callback: onSuccess,
      action,
      // show narrower widget when the screen size is lower
      // this is intentionally not dynamic (e.g. useMediaBreakpoint)
      // because useMediaBreakpoint seems to flip between values
      // and we don't want to rerender this unnecessarily
      size: window.visualViewport.width < 900 ? 'compact' : 'normal',
      theme: 'light',
      'error-callback': handleError,
      'unsupported-callback': handleUnsupported
    });

    setWidgetId(newWidgetId);

    return () => {
      // once the widget is rendered, the only reason this would run
      // is if the user is navigating away from the form - clean it up
      if (widgetId) {
        window.turnstile.remove(widgetId);
        setWidgetId(null);
      }
    };
  }, [
    containerRef,
    isTurnstileLoaded,
    widgetId,
    handleError,
    handleUnsupported,
    action,
    onSuccess
  ]);

  return (
    <div ref={containerRef} />
  );
};

Captcha.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  action: PropTypes.string
};

export default Captcha;
