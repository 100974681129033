import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import striptags from 'striptags';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Deal from '../../../../Components/Hands/Deal/Deal.js';
import BiddingBox from '../../../../Components/Hands/BiddingBox/BiddingBox.js';
import Auction from '../../../../Components/Hands/Auction.js';
import Contract from '../../../../Components/Hands/Contract/EditableContract.js';
import UndoRedo from '../../../../Components/Hands/UndoRedo.js';
import SectionLabel from '../../../../Components/Hands/SectionLabel.js';
import CommentsModal from '../../../../Components/Hands/Comments/CommentsModal.js';
import RichText from '../../../../Components/Forms/RichText/RichText.js';
import DummyMessageDialog from './DummyMessageDialog.js';
import {
  whoIsDeclarer,
  whoIsDummy,
  getContract,
  hasEnded,
  getSeatForStep
} from '../../../../helpers/auction.js';
import {
  getCommentsForCardOrBid,
  upsertAuctionComments,
  getHeadingForAuction
} from '../../../../helpers/comment.js';

const styles = theme => ({
  introPaper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
    height: 422,
    textAlign: 'center'
  },
  deal: {
    fontSize: 10
  },
  biddingBox: {
    width: 400,
    fontSize: '.6em'
  },
  auction: {
    width: 232,
    fontSize: '.6em'
  },
  gridContent: {
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  buttons: {
    textAlign: 'center'
  }
});

class AuctionGrid extends React.Component {
  state = {
    redo: [],
    currentBid: undefined,
    currentStep: undefined,
    dummyMessageOpen: false,
    currentComments: undefined,
    commentsForViewpointOrDummy: false,
    heading: '',
    dirtyComment: false,
    commentsOpen: false
  };

  checkDummy = (dummy) => {
    let { viewpoint } = this.props.hand;
    if (viewpoint === dummy) {
      this.setState({
        dummyMessageOpen: true
      });
    }
  };

  handleBoxClick = (bid) => {
    let {
      auction,
      dealer,
      declarer,
      dummy,
      contract
    } = this.props.hand;
    let { dummyMessageOpen, redo } = this.state;

    // add the seat to the bid object
    bid.seat = getSeatForStep(
      auction.length,
      dealer
    );

    // add the bid to the auction
    auction.push(bid);

    if (redo.length > 0) {
      this.setState({
        redo: []
      });
    }

    // set the declarer
    declarer = whoIsDeclarer(auction, dealer);

    // only bother carrying on if declarer has been set
    // (it could be undefined if passes are chosen at start of auction)
    if (declarer) {
      // set the dummy
      dummy = whoIsDummy(declarer);

      // set the contract
      contract = getContract(auction, dealer);

      if (hasEnded(auction) && dummyMessageOpen === false) {
        // check dummy isn't viewpoint
        this.checkDummy(dummy);
      }
    }
    this.props.onChange({
      auction: auction,
      declarer: declarer,
      dummy: dummy,
      contract: contract
    });
  };

  handleUndoClick = () => {
    // remove last item
    let { auction, dealer } = this.props.hand;
    const lastBid = auction.pop();

    // add last item to redo array
    let { redo } = this.state;
    redo.push(lastBid);

    // how do we set current bid
    // loop backwards through the bids and find the first bid that has a number
    let currentBid = this.state.currentBid;
    for (var i = auction.length - 1; i >= 0; --i) {
      const bidToCheck = auction[i];
      if (bidToCheck !== 'X' && bidToCheck !== 'XX' && bidToCheck !== 'Pass') {
        currentBid = bidToCheck;
        break;
      }
    };

    const contract = getContract(auction, dealer);

    this.setState({
      redo: redo,
      currentBid: currentBid,
      dummyMessageOpen: false
    });

    this.props.onChange({
      auction: auction,
      contract: contract
    });
  }

  handleRedoClick = () => {
    // remove last item from redo list
    let { redo } = this.state;
    const lastBid = redo.pop();

    // add redo item to auction array
    let { auction, dummy } = this.props.hand;
    auction.push(lastBid);

    // set current bid
    let currentBid = this.state.currentBid;
    if (lastBid !== 'X' && lastBid !== 'XX' && lastBid !== 'Pass') {
      currentBid = lastBid
    }

    if (hasEnded(auction)) {
      // check dummy isn't viewpoint
      this.checkDummy(dummy);
    }

    this.setState({
      redo: redo,
      currentBid: currentBid
    });

    this.props.onChange({
      auction: auction
    });
  }

  handleContractChange = (contract, declarer) => {
    const dummy = whoIsDummy(declarer);
    // check dummy isn't viewpoint
    this.checkDummy(dummy);
    this.props.onChange({
      contract: contract,
      declarer: declarer,
      dummy: dummy
    });
  }

  handleBidClick = (bid, step, row) => {
    const { viewpoint, auction } = this.props.hand;
    const { dirtyComment } = this.state;
    const { onUnavedComment } = this.props;

    if (dirtyComment) {
      onUnavedComment();
    } else {
      this.setState({
        commentsForViewpointOrDummy: viewpoint === bid.seat,
        currentComments: getCommentsForCardOrBid(auction[step]),
        heading: getHeadingForAuction(bid, row),
        currentBid: bid,
        currentStep: step,
        commentsOpen: true
      });
    }
  };

  handleInputChange = (input) => {
    let { auctionIntro } = this.props.hand;
    auctionIntro = input.value;
    this.props.onChange({
      auctionIntro: auctionIntro
    });
  };

  handleCommentChange = (input) => {
    const currentComments = this.state.currentComments;
    currentComments[input.name] = input.value;
    this.setState({ currentComments: currentComments, dirtyComment: true });
  };

  handleAddCommentClick = () => {
    const { onChange, onCommentsSaved } = this.props;
    let { auction } = this.props.hand;
    const { currentComments, commentsForViewpointOrDummy, currentStep } = this.state;

    // add stepComments to list
    auction = upsertAuctionComments(auction, currentStep, currentComments);

    onChange({
      auction: auction
    });
    onCommentsSaved(commentsForViewpointOrDummy);
    this.setState({ dirtyComment: false, currentBid: undefined, commentsOpen: false });
  };

  handleDeleteCommentClick = () => {
    const { onChange } = this.props;
    let { auction } = this.props.hand;
    const { currentStep } = this.state;

    // delete comments from auction
    auction = upsertAuctionComments(auction, currentStep, undefined);

    onChange({
      auction: auction
    });
    this.setState({ dirtyComment: false, currentBid: undefined, commentsOpen: false });
  };

  handleCancelCommentClick = () => {
    this.setState({
      currentComments: undefined,
      dirtyComment: false,
      currentBid: undefined,
      commentsOpen: false
    });
  };

  handleIntroInputChange = (input) => {
    let { auctionIntro } = this.props.hand;
    auctionIntro = input.value;
    this.props.onChange({
      auctionIntro: auctionIntro
    });
  };

  handleMakeAuctionOnlyClick = () => {
    const { onMakeAuctionOnlyClick } = this.props;
    onMakeAuctionOnlyClick();
    this.setState({
      dummyMessageOpen: false
    });
  };

  handleChangeAuctionClick = () => {
    this.setState({
      dummyMessageOpen: false
    });
  };

  render() {
    const { classes, onChangeViewpointClick } = this.props;
    const {
      auction,
      dealer,
      playOnly,
      deal,
      viewpoint,
      vulnerable,
      contract,
      declarer,
      auctionIntro
    } = this.props.hand;
    const {
      redo,
      dummyMessageOpen,
      heading,
      commentsForViewpointOrDummy,
      currentComments,
      currentBid,
      commentsOpen
    } = this.state;
    return (
      <React.Fragment>
        {
          playOnly
          ?
          <React.Fragment>
            <Contract
              contract={contract}
              declarer={declarer}
              onChange={this.handleContractChange}
            />
          </React.Fragment>
          :
          <React.Fragment>
            {
              currentBid !== undefined
                ?
                <CommentsModal
                  onCancelClick={this.handleCancelCommentClick}
                  onSubmitClick={this.handleAddCommentClick}
                  onDeleteClick={this.handleDeleteCommentClick}
                  onCommentChange={this.handleCommentChange}
                  heading={heading}
                  commentsForViewpointOrDummy={commentsForViewpointOrDummy}
                  comments={currentComments}
                  bid={currentBid}
                  open={commentsOpen}
                />
                :
                null
            }
            <Paper className={classes.introPaper}>
              <RichText
                name='introduction'
                value={auctionIntro}
                label='Pre-bid introduction'
                onChange={this.handleIntroInputChange}
                invalid={striptags(auctionIntro).length === 0}
              />
            </Paper>
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <div className={clsx(classes.deal, classes.gridContent)}>
                    <SectionLabel
                      text='Deal'
                    />
                    <Deal
                      cards={deal}
                      dealer={dealer}
                      vulnerable={vulnerable}
                      viewpoint={viewpoint}
                      activeDirection='None'
                    />
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={5}>
                <Paper className={classes.paper}>
                  <div className={clsx(classes.biddingBox, classes.gridContent)}>
                    <BiddingBox
                      dealer={dealer}
                      onClick={(value) => {
                        this.handleBoxClick(value);
                      }}
                      clickable={true}
                      auction={auction}
                    />
                  </div>
                </Paper>
                <div className={classes.buttons}>
                  <UndoRedo
                    undoDisabled={auction.length === 0}
                    redoDisabled={redo.length === 0}
                    onUndoClick={this.handleUndoClick}
                    onRedoClick={this.handleRedoClick}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>
                  <div className={clsx(classes.auction, classes.gridContent)}>
                    <SectionLabel
                      text='Auction (click a bid to add a comment)'
                    />
                    <Auction
                      auction={auction}
                      dealer={dealer}
                      onBidClick={this.handleBidClick}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <DummyMessageDialog
              open={dummyMessageOpen}
              onMakeAuctionOnlyClick={this.handleMakeAuctionOnlyClick}
              onChangeViewpointClick={onChangeViewpointClick}
              onChangeAuctionClick={this.handleChangeAuctionClick}
            />
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

AuctionGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  hand: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onUnavedComment: PropTypes.func.isRequired,
  onCommentsSaved: PropTypes.func.isRequired,
  onMakeAuctionOnlyClick: PropTypes.func.isRequired,
  onChangeViewpointClick: PropTypes.func.isRequired
}

export default withStyles(styles)(AuctionGrid);
