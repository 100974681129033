import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import AdminTableFilter from './AdminTableFilter.js';
import BBTable from '../BBTable/BBTable.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  table: {
    minWidth: 700,
    paddingLeft: 100
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}));

const AdminTable = (props) => {
  const {
    searchParams,
    onOrderChange,
    onPageChange,
    onFilterChange,
    ...other
  } = props;

  const classes = useStyles();

  const handleColumnSort = (_, property) => {
    const isAsc = searchParams.orderBy === property && searchParams.orderByDirection === 'desc';
    onOrderChange(property, isAsc ? 'asc' : 'desc');
  };

  const handleChangePage = (newPage) => {
    onPageChange(newPage, searchParams.limit);
  };

  const handleChangeRowsPerPage = (event) => {
    // go back to the first page as working out what page we should be on involves HARD MAAAAAAAAATHHHHHHHHSSSSSSSS! (and freeagent do this too)
    onPageChange(0, event.target.value);
  };

  const handleSearchSubmit = (searchState) => {
    onFilterChange(searchState);
  };

  return (
    <Paper className={classes.root}>
      <AdminTableFilter
        searchParams={searchParams}
        onSearchSubmit={handleSearchSubmit}
        {...other}
      />
      <BBTable
        onColumnSort={handleColumnSort}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        limit={searchParams.limit}
        page={searchParams.page}
        {...other}
      />
    </Paper>
  );
};

AdminTable.propTypes = {
  searchParams: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  showDates: PropTypes.bool,
  showSearch: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func,
  buttons: PropTypes.object,
  selected: PropTypes.array,
  additionalDropdown: PropTypes.object,
  dateFilterOptions: PropTypes.array
};

export default AdminTable;
