import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { mutations } from '../../graphql';
import StoreForm from './Store/StoreForm.js';
import StoreInput from './Store/StoreInput.js';
import { stripTypeNameFromMutationVars } from '../../helpers/gql.js';
import { useTextTranslation } from '../../Hooks';

const PasswordForm = ({ user, onSubmitError, onSubmitSuccess }) => {
  const [updatePassword] = useMutation(mutations.UpdatePassword);
  const validationText = useTextTranslation('passwords-dont-match');

  const handleSubmit = async (formState) => {
    const userToSubmit = { ...user, password: formState.variables.password };
    const variables = stripTypeNameFromMutationVars(userToSubmit);
    await updatePassword({ variables });
  };

  const handleValidate = (fields) => {
    if (fields.password !== fields.confirm) {
      return validationText;
    }
    return '';
  };

  return (
    <StoreForm
      fields={{}}
      onSubmit={handleSubmit}
      onSubmitError={onSubmitError}
      onSubmitSuccess={onSubmitSuccess}
      onValidate={handleValidate}
    >
      <StoreInput
        id='password'
        type='password'
        label='new-password'
        autoFocus
        required
        maxLength={100}
        validate={val => (val ? '' : 'New password is required')}
      />
      <StoreInput
        id='confirm'
        type='password'
        label='confirm-password'
        required
        maxLength={100}
        validate={val => (val ? '' : 'Confirm password is required')}
      />
    </StoreForm>
  );
};

PasswordForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onSubmitError: PropTypes.func,
  user: PropTypes.object
};

export default PasswordForm;
