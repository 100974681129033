import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import BBTranslate from '../Locale/BBTranslate.js';

const useStyles = makeStyles(() => ({
  heading: {
    margin: 0,
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600
  },
  h1: {
    fontFamily: 'roc-grotesk, sans-serif',
    margin: '2rem 0'
  },
  h2: {
    fontFamily: 'roc-grotesk, sans-serif',
    color: '#333',
    margin: '2rem 0'
  },
  h3: {
    color: '#666',
    margin: '1rem 0',
    '@media all and (max-width: 770px)': {
    //  marginBottom: '7rem'
    }
  }
}));

const Heading = (props) => {
  const { variant, text, className } = props;
  const classes = useStyles();
  let jsx = null;
  const thisClassName = clsx(classes.heading, classes[variant], className || null);
  switch (variant) {
    case 'h1':
      jsx = <h1 className={thisClassName}><BBTranslate textId={text} /></h1>;
      break;
    case 'h2':
      jsx = <h2 className={thisClassName}><BBTranslate textId={text} /></h2>;
      break;
    case 'h3':
      jsx = <h3 className={thisClassName}><BBTranslate textId={text} /></h3>;
      break;
    default:
      break;
  }
  return jsx;
};

Heading.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Heading;
