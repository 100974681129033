import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTextTranslation } from '../../../Hooks';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#fff',
    fontSize: '1.6rem',
    color: 'rgba(0, 0, 0, 0.87)',
    outline: 0,
    borderColor: '#c4c4c4',
    borderRadius: 2,
    width: '100%',
    whiteSpace: 'nowrap',
    '&:hover, :focus': {
      backgroundColor: '#fff',
      borderColor: 'rgba(0, 0, 0, 0.87)'
    }
  }
}));

const FilterButton = (props) => {
  const { onClick, selectedTagsByGroup } = props;
  const classes = useStyles();

  let numActiveFilters = 0;

  for (const group in selectedTagsByGroup) {
    numActiveFilters += selectedTagsByGroup[group].length;
  }

  let filterText = useTextTranslation('filter');

  if (numActiveFilters) {
    filterText += ` (${numActiveFilters})`;
  }

  return (
    <Button
      variant='outlined'
      className={classes.button}
      endIcon={<FilterAltIcon />}
      onClick={onClick}
    >
      {filterText}
    </Button>
  );
};

export default FilterButton;
