import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { productQueries } from '../../graphql/queries';
import BBErrorBoundary from '../Errors/BBErrorBoundary.js';

const ProductByIdAdmin = (props) => {
  const { children, id } = props;
  return (
    <BBErrorBoundary>
      <Query
        query={productQueries.ProductByIdAdmin(id)}
        notifyOnNetworkStatusChange // https://github.com/apollographql/@apollo/client/issues/1931
        fetchPolicy='no-cache'
      >
        {({ data, error, loading }) => {
          if (error) {
            throw error;
          }
          return children(data, loading);
        }}
      </Query>
    </BBErrorBoundary>
  );
};

ProductByIdAdmin.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default ProductByIdAdmin;
