import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import BBLinearProgress from '../BBLinearProgress';
import BBErrorBoundary from '../Errors/BBErrorBoundary.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

const BBQuery = (props) => {
  const { children, query, variables, showLoader, loaderComponent, fetchPolicy } = props;
  return (
    <BBErrorBoundary>
      <Query
        query={query}
        variables={variables}
        notifyOnNetworkStatusChange // https://github.com/apollographql/@apollo/client/issues/1931
        fetchPolicy={fetchPolicy || 'cache-first'}
      >
        {({ data, error, refetch, loading }) => {
          if (error) {
            throw error;
          }
          if (!loading && data && !error) {
            return children(data, refetch);
          } else {
            if (loaderComponent) {
              return loaderComponent;
            } else if (showLoader) {
              return <BBLinearProgress />;
            } else {
              return null;
            }
          }
        }}
      </Query>
    </BBErrorBoundary>
  );
};

BBQuery.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  showLoader: PropTypes.bool,
  loaderComponent: PropTypes.object,
  fetchPolicy: PropTypes.string
};

export default BBQuery;
