import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Modal,
  Grid
} from '@mui/material';
import StoreButton from '../../../../Components/Forms/Store/StoreButton.js';
import NewCardForm from '../../../../Components/Store/Checkout/NewCardForm.js';
import BBFormContainer from '../../../../Components/Forms/BBFormContainer.js';
import NiceError from '../../../../Components/Errors/NiceError.js';

const CardForm = (props) => {
  const {
    onSubmit,
    onFormComplete,
    onFormError,
    onModalClose,
    open,
    isLoading,
    isValid,
    error
  } = props;
  return (
    <Modal
      open={open}
      onClose={onModalClose}
    >
      <BBFormContainer>
        <Grid item xs={12}>
          <NiceError err={error} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NewCardForm
              onFormComplete={onFormComplete}
              onFormError={onFormError}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <FormControl variant='standard' margin='normal'>
              <StoreButton
                disabled={isLoading || !isValid}
                loading={isLoading}
                onClick={onSubmit}
                label='save-card-details'
              />
            </FormControl>
          </Grid>
        </Grid>
      </BBFormContainer>
    </Modal>
  );
};

CardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CardForm;
