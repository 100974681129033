import { useEffect, useReducer, useContext, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { mutations } from '../graphql';
import {
  paymentQueries
} from '../graphql/queries';
import { UserContext, CartContext } from '../Context';
import {
  createPaymentMethod,
  createPaymentIntent,
  confirmCardPurchase,
  handlePurchaseComplete,
  handlePurchaseFailed
  // recordGoogleAnalyticsPurchase
} from '../helpers/checkout.js';
import {
  getEnvVar
} from '../helpers/env.js';
import { useScrollToTop } from '../Hooks';

const useCheckout = (stripe, elements, locale) => {
  const { user, createUser, setUser } = useContext(UserContext);
  const { cart, clearCart, getTotal, checkIfFreeAfterDiscount, applyDiscount: applyDiscountToCart } = useContext(CartContext);
  const { pathname } = useLocation();
  const { scrollToTop } = useScrollToTop();

  // #region ********** STATE MANAGAMENT **********
  const reducer = (state, action) => {
    switch (action.type) {
      case 'set_discount':
        return {
          ...state,
          discount: action.discount
        };
      case 'set_submit_loading':
        return {
          ...state,
          submitLoading: action.submitLoading
        };
      case 'set_redeem_loading':
        return {
          ...state,
          redeemLoading: action.redeemLoading
        };
      case 'set_success':
        return {
          ...state,
          success: action.success
        };
      case 'set_error':
        return {
          ...state,
          error: action.error
        };
      case 'set_selected_payment_method':
        return {
          ...state,
          selectedPaymentMethodId: action.selectedPaymentMethodId
        };
      case 'set_save_payment_method':
        return {
          ...state,
          savePaymentMethod: action.savePaymentMethod
        };
      case 'set_payment_methods':
        return {
          ...state,
          paymentMethods: action.paymentMethods
        };
      case 'set_register_fields':
        return {
          ...state,
          registerFields: action.registerFields
        };
      case 'set_register_form_complete':
        return {
          ...state,
          registerFormComplete: action.registerFormComplete
        };
      case 'set_card_form_complete':
        return {
          ...state,
          cardFormComplete: action.cardFormComplete
        };
      case 'set_selected_bank':
        return {
          ...state,
          selectedBank: action.selectedBank
        };
      default:
        throw new Error('reducer action type not recognised');
    }
  };

  let selectedPaymentMethodId;

  // if the total after discount is 0.00
  // picking a payment method is irrelevant
  if (checkIfFreeAfterDiscount()) {
    selectedPaymentMethodId = 'none';
  } else if (user && user.stripePaymentMethodId) {
    selectedPaymentMethodId = user.stripePaymentMethodId;
  } else if (locale.currency === 'EUR') {
    selectedPaymentMethodId = 'ideal';
  } else {
    selectedPaymentMethodId = 'new';
  }

  const initialState = {
    discount: 0,
    submitLoading: false,
    redeemLoading: false,
    success: false,
    error: null,
    selectedPaymentMethodId,
    savePaymentMethod: false,
    paymentMethods: null,
    registerFormComplete: false,
    cardFormComplete: false
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   console.log('========== useCheckout state ===========', state);
  // }, [state]);

  const setDiscount = (discount) => {
    dispatch({
      type: 'set_discount',
      discount
    });
  };

  const setSubmitLoading = (submitLoading) => {
    dispatch({
      type: 'set_submit_loading',
      submitLoading
    });
  };

  const setRedeemLoading = (redeemLoading) => {
    dispatch({
      type: 'set_redeem_loading',
      redeemLoading
    });
  };

  const setSucess = () => {
    dispatch({
      type: 'set_success',
      success: true
    });
  };

  const setError = (error) => {
    dispatch({
      type: 'set_error',
      error
    });
  };

  const setSelectedPaymentMethod = (selectedPaymentMethodId) => {
    dispatch({
      type: 'set_selected_payment_method',
      selectedPaymentMethodId
    });
  };

  const setSavePaymentMethod = (savePaymentMethod) => {
    dispatch({
      type: 'set_save_payment_method',
      savePaymentMethod
    });
  };

  const setPaymentMethods = (paymentMethods) => {
    dispatch({
      type: 'set_payment_methods',
      paymentMethods
    });
  };

  const setRegisterFields = (registerFields) => {
    dispatch({
      type: 'set_register_fields',
      registerFields
    });
  };

  const setRegisterFormComplete = (registerFormComplete) => {
    dispatch({
      type: 'set_register_form_complete',
      registerFormComplete
    });
  };

  const setCardFormComplete = (cardFormComplete) => {
    dispatch({
      type: 'set_card_form_complete',
      cardFormComplete
    });
  };

  const setSelectedBank = (selectedBank) => {
    dispatch({
      type: 'set_selected_bank',
      selectedBank
    });
  };

  // #endregion ********** END STATE MANAGEMENT **********

  // #region ********** QUERIES **********

  const [getPaymentMethodsQuery, { called: getPaymentMethodsCalled }] = useLazyQuery(paymentQueries.PaymentMethodsByCustomerId, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setPaymentMethods(data.paymentMethodsByCustomerId);
    },
    onError: (err) => {
      setError(err);
    }
  });

  const getPaymentMethods = useCallback(async () => {
    // only call the lazy query if its not already been called
    if (!getPaymentMethodsCalled) {
      getPaymentMethodsQuery({
        variables: {
          stripeCustomerId: user.stripeCustomerId
        }
      });
    }
  }, [getPaymentMethodsCalled, getPaymentMethodsQuery, user]);

  // #endregion ********** END QUERIES **********

  // #region ********** MUTATIONS **********

  const [createPurchase] = useMutation(mutations.CreatePurchase);
  const [completePurchase] = useMutation(mutations.CompletePurchase);
  const [failPurchase] = useMutation(mutations.FailPurchase);
  const [redeemVoucherMutation] = useMutation(mutations.RedeemVoucher);

  // #endregion ********** END MUTATIONS **********

  // #region ********** EVENT HANDLERS **********
  const validateState = () => {
    // validate the state to decide whether or not we are 'go' for submitting payment
    // if we ARE valid, enable the big yellow button
    // if we ARE NOT, disable it
    if (!user && !state.registerFormComplete) {
      return false;
    }
    if (!state.selectedPaymentMethodId || (state.selectedPaymentMethodId === 'new' && !state.cardFormComplete)) {
      return false;
    }
    return true;
  };

  const redeemVoucher = async (code) => {
    setRedeemLoading(true);
    setError(null);

    try {
      const { data, error } = await redeemVoucherMutation({
        variables: { code }
      });
      if (error) {
        setError(error);
      }
      if (data) {
        const voucher = data.redeemVoucher;
        setDiscount(voucher.discountAmount);
        if (applyDiscountToCart(voucher)) {
          // if the purchase is free now that the discount is applied,
          // choose that - the other options will be greyed out
          if (checkIfFreeAfterDiscount()) {
            setSelectedPaymentMethod('none');
          }
        } else {
          // if the voucher wasn't applied let the customer know why
          setError(new Error('ValidationError: This voucher is not valid for the items in your cart'));
        }
      }
    } catch (err) {
      setError(err);
    }
    setRedeemLoading(false);
  };

  const submitPayment = async () => {
    setSubmitLoading(true);
    setError(null);

    let purchase;
    let customer = user && { ...user };

    try {
      // is the customer already logged in?
      // if not, register them through API

      if (!customer) {
        customer = await createUser(state.registerFields, locale);
        setUser(customer);
      }

      let paymentMethodId = state.selectedPaymentMethodId;

      // const selectedPaymentType = 'card';
      const selectedPaymentType = paymentMethodId === 'ideal' ? paymentMethodId : 'card';

      // do we have an existing payment type selected?
      // if not, create the payment type through stripe js
      if (paymentMethodId === 'new' || paymentMethodId === 'ideal') {
        // const paymentElement = elements.getElement('cardNumber');
        const elementTypeNames = {
          card: 'cardNumber',
          ideal: 'idealBank'
        };
        const paymentElement = elements.getElement(elementTypeNames[selectedPaymentType]);

        paymentMethodId = await createPaymentMethod(
          stripe,
          paymentElement,
          selectedPaymentType,
          customer,
          state.savePaymentMethod
        );
      }

      // create payment intent through API
      purchase = await createPaymentIntent(
        createPurchase,
        locale.currency.toLowerCase(),
        state.savePaymentMethod,
        cart,
        customer,
        paymentMethodId,
        selectedPaymentType
      );

      if (selectedPaymentType === 'card') {
        // confirm CARD payment through stripe js
        const { paymentIntent } = purchase;
        if (purchase.status === 'RequiresAction') {
          // call stripe client side to finish off the payment
          await confirmCardPurchase(
            stripe,
            paymentIntent.client_secret,
            purchase.id
          );
        }
        // complete CARD payment through API
        await handlePurchaseComplete(completePurchase, purchase.id);
      } else {
        // confirm IDEAL payment through stripe API
        const returnUrl = `${getEnvVar('SITE_URL')}${pathname}/idealpayment`;
        await stripe.confirmIdealPayment(
          purchase.paymentIntent.client_secret,
          {
            payment_method: purchase.paymentIntent.payment_method,
            return_url: returnUrl
          }
        );
      }

      // set success state
      setSucess();
    } catch (err) {
      // just in case there was an error after the customer was created
      // we need to setUser so they appear to be logged in otherwise
      // they will try and register again
      setUser(customer);
      setError(err);
      if (purchase) {
        handlePurchaseFailed(failPurchase, purchase.id, err.message);
      }
    } finally {
      setSubmitLoading(false);
      scrollToTop();
    }
  };

  // #endregion ********** END EVENT HANDLERS **********

  useEffect(() => {
    if (user) {
      getPaymentMethods();
    }
  }, [user, getPaymentMethods]);

  return {
    setSelectedPaymentMethod,
    setSavePaymentMethod,
    setRegisterFields,
    setRegisterFormComplete,
    setCardFormComplete,
    setSelectedBank,
    redeemVoucher,
    submitPayment,
    clearCart,
    checkoutState: state,
    user,
    isValid: validateState(),
    cart,
    isFreeAfterDiscount: checkIfFreeAfterDiscount(),
    total: getTotal()
  };
};

export default useCheckout;
