import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTextTranslation } from '../../Hooks';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    border: '0rem solid #FFB73A',
    pointerEvents: 'none !important',
    transition: 'all 0.2s',
    opacity: 0.75,
    '&:after': {
      content: props => props.text,
      background: '#FFB73A',
      borderTopLeftRadius: '0.3rem',
      borderTopRightRadius: '0.3rem',
      position: 'absolute',
      left: '50%',
      right: 0,
      bottom: '-2.4rem',
      height: '2.4rem',
      lineHeight: '2.4rem',
      width: '8rem',
      marginLeft: '-4rem',
      textAlign: 'center',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      transition: 'inherit'
    }
  },
  active: {
    borderWidth: '0.5rem',
    '&:after': {
      bottom: '-0.4rem'
    }
  }
});

const YourTurn = (props) => {
  const { active } = props;
  const classes = useStyles({ text: `"${useTextTranslation('your-turn')}"` });
  return (
    <div className={clsx(classes.container, active ? classes.active : null)} />
  );
};

YourTurn.propTypes = {
  active: PropTypes.bool
};

export default YourTurn;
