// import { build } from './helpers/env.js';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { getCurrentAppVersion } from './helpers/env.js';

// quick and dirty way to do a visual check for strings not wrapped in t()
// uncomment `postProcess` below to change all wrapped strings
// e.g. 'My Library' -> 'AA AAAAAAA'
// const aaaaaPostProcessor = {
//   type: 'postProcessor',
//   name: 'aaaaa',
//   process: (value, key) => {
//     const str = value || key || '';

//     return str.replace(/[a-z]/gi, 'A');
//   }
// };

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  // .use(aaaaaPostProcessor)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'nl'],
    // turns e.g. `en-US` and `en-AU` to `en`
    nonExplicitSupportedLngs: true,
    debug: false, // build !== 'live',
    react: {
      useSuspense: false
    },
    initImmediate: false, // set initImmediate false -> init method finished only when all resources/translation finish loading (async behaviour)
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `/lang/{{lng}}.json?v=${getCurrentAppVersion()}`
    }
    // postProcess: ['aaaaa']
  });
