import React from 'react';
import { useQuery } from '@apollo/client';
import { dynamoProductQueries } from '../../graphql/queries';
import ProductSection from '../../Components/Store/Products/ProductSection.js';
import { convertLangCodeToGraphqlEnum } from '../../helpers/locale.js';
import { useTranslation } from 'react-i18next';

const LatestProducts = () => {
  const language = convertLangCodeToGraphqlEnum('en-gb');
  const currency = 'GBP';
  const { t } = useTranslation();

  const { data: productData } = useQuery(dynamoProductQueries.DynamoPromotedProducts, {
    variables: {
      locale: {
        language,
        currency
      }
    }
  });

  if (!productData) {
    return null;
  }

  return (
    <>
      <span id='hands' style={{ position: 'relative', top: -33 }} />
      <ProductSection
        products={productData.dynamoPromotedProducts.products.slice(0, 4)}
        title={t('latest-products')}
        viewAllPath='/products/all'
        extraWhitespace
      />
    </>
  );
};

export default LatestProducts;
