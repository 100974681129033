import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import BBTranslate from './Locale/BBTranslate.js';

const useStyles = makeStyles({
  container: {
    textAlign: 'center'
  },
  svgContainer: {
    maxWidth: '16rem',
    maxHeight: '16rem',
    margin: '0 auto'
  },
  svg: {
    enableBackground: 'new 0 0 37 37'
  },
  defaults: {
    fill: 'none',
    stroke: '#68B500',
    strokeWidth: 1,
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    strokeDashoffset: 0,
    animation: '$drawn 1s ease-out forwards'
  },
  circle: {
    strokeDasharray: 130,
    strokeDashoffset: 130,
    transition: 'all 1s'
  },
  tick: {
    strokeDasharray: 50,
    strokeDashoffset: 50
  },
  '@keyframes drawn': {
    '100%': {
      strokeDashoffset: 1
    }
  },
  h3: {
    color: '#68B500'
  },
  text: {
    color: '#666'
  },
  redirect: {
    fontSize: '1.2rem',
    color: '#999',
    display: 'none'
  }
});

const SuccessStep = ({ heading, text, onSuccess }) => {
  useEffect(() => {
    if (onSuccess) {
      setTimeout(() => {
        onSuccess();
      }, 3000);
    }
  }, [onSuccess]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.svgContainer}>
          <svg
            className={classes.svg}
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            viewBox='0 0 37 37'
            xmlSpace='preserve'
          >
            <path
              className={clsx(classes.defaults, classes.circle)}
              d='M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z'
            />
            <polyline
              className={clsx(classes.defaults, classes.tick)}
              points='11.6,20 15.9,24.2 26.4,13.8 '
            />
          </svg>
        </div>
        <h3 className={classes.h3}>
          {heading ? <BBTranslate textId={heading} /> : <BBTranslate textId='success' />}
        </h3>
        {text && <p className={classes.text}><BBTranslate textId={text} /></p>}
        <div className={classes.redirect}><BBTranslate textId='redirecting' />...</div>
      </div>
    </div>
  );
};

SuccessStep.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
};

export default SuccessStep;
