import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { dynamoProductQueries, handQueries } from '../../../../graphql/queries';
import { LocaleContext } from '../../../../Context';
import ErrorNotification from '../../../../Components/Errors/ErrorNotification.js';
import {
  isFinalSetInCollection,
  isFinalHandInSet
} from '../../../../helpers/product.js';
import { convertLangCodeToGraphqlEnum } from '../../../../helpers/locale.js';

const HandDetailsWrapper = (props) => {
  const {
    linkId,
    language,
    children
  } = props;

  let {
    collectionId,
    setId,
    handId
  } = props;

  // as these props originated from the route they are strings, so convert to ints
  collectionId = parseInt(collectionId);
  setId = parseInt(setId);
  handId = parseInt(handId);

  const { locale } = useContext(LocaleContext);

  const productId = collectionId || setId;

  const productQuery = (linkId ? 'DynamoProductByLinkId' : 'DynamoProductById');

  const [getProduct, { data: productData, error: productError }] = useLazyQuery(
    dynamoProductQueries[productQuery]
  );

  const [getHand, { data: handData }] = useLazyQuery(handQueries.HandById);

  useEffect(() => {
    // if locale has been received then execute the queries
    if (locale) {
      let variables = {};
      if (linkId) {
        variables = {
          linkId,
          locale: {
            language: convertLangCodeToGraphqlEnum(language || locale.language),
            currency: locale.currency
          }
        };
      } else {
        variables = {
          id: productId,
          language: convertLangCodeToGraphqlEnum(language || locale.language),
          currency: locale.currency
        };
      }
      getProduct({
        variables
      });
      getHand({
        variables: {
          id: handId
        }
      });
    }
  }, [getProduct, productId, getHand, handId, locale, linkId, language]);

  if (productError) {
    return (
      <ErrorNotification error={productError} />
    );
  }
  if (productData && handData) {
    const product = linkId ? productData.dynamoProductByLinkId : productData.dynamoProductById;

    const hand = handData.handById;

    // get set from collection
    let collection;
    let set = product;
    let finalSetInCollection = true;
    if (product.productType === 'Collection') {
      collection = product;
      set = _.find(collection.sets, { id: setId });
      finalSetInCollection = isFinalSetInCollection(setId, collection.sets);
    }

    const finalHandInSet = isFinalHandInSet(handId, set.hands);
    const finalHandInProduct = finalSetInCollection && finalHandInSet;

    return children({
      collection,
      set,
      hand,
      finalSetInCollection,
      finalHandInSet,
      finalHandInProduct
    });
  }
  return null;
};

HandDetailsWrapper.propTypes = {
  collectionId: PropTypes.string,
  setId: PropTypes.string.isRequired,
  handId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default HandDetailsWrapper;
