import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { mutations } from '../../../../../graphql';
import {
  Alert
} from '@mui/material';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import { useTranslation } from 'react-i18next';
import {
  handlePurchaseFailed
} from '../../../../../helpers/checkout.js';

const Failed = ({ purchase }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [failPurchase] = useMutation(mutations.FailPurchase);

  const handleCheckoutClick = () => {
    navigate('/checkout');
  };

  useEffect(() => {
    async function doMutation () {
      await handlePurchaseFailed(failPurchase, purchase.id, 'iDEAL payment failed');
    }
    doMutation();
  }, [failPurchase, purchase]);

  return (
    <>
      <Alert severity='error' sx={{ m: 3, border: 'solid 1px #C03D36', borderRadius: '2px' }}>
        {t('ideal-payment-failed')}
      </Alert>
      <StoreButton
        label='return-to-checkout'
        onClick={handleCheckoutClick}
      />
    </>
  );
};

export default Failed;
