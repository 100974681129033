import gql from 'graphql-tag';

const productQueries = {
  ProductSearchAdmin: (options) => {
    return gql`
      query {
        productSearchAdmin (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          products {
            id
            type
            images
            title
            description
            dateCreated
            onSale
            promoted
            author {
              id
              firstName
              lastName
              fullName
            }
            collectionSets {
              id
              collectionId
            }
          }
        }
      }
    `;
  },
  ProductByIdAdmin: (id) => {
    return gql`
      query {
        productByIdAdmin (
          id: ${id}
        ) {
          id
          type
          title
          description
          language
          onSale
          productLinksEnabled
          promoted
          images
          priceId
          price {
            id
          }
          dateCreated
          authorId
          author {
            id
          }
          tags {
            id
            name
            tagGroup {
              id
              name
            }
          }
        }
      }
    `;
  },
  ProductSearchCollection: (options) => {
    return gql`
      query {
        productSearchCollection (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          products {
            id
            type
            images
            title
            description
            dateCreated
            onSale
            promoted
            author {
              id
              firstName
              lastName
              fullName
            }
          }
        }
      }
    `;
  },
  ProductSearch: (options) => {
    return gql`
      query {
        productSearch (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
          system: "${options.system}"
          difficulty: "${options.difficulty}"
          type: "${options.type}"
          locale: {
            currency: ${options.currency}
          }
        ) {
          count
          products {
            id
            product {
              id
              type
              images
              title
              description
              dateCreated
              promoted
              language
              priceLocal
              priceLocalFormatted
              tagsCache
              author {
                id
                fullName
                avatarPath
              }
              tagsCache
            }
          }
        }
      }
    `;
  },
  ProductSearchWithHands: gql`
    query (
      $input: SearchInput!
    ) {
      productSearchWithHands (
        input: $input
      ) {
        count
        products {
          id
          type
          title
          hands {
            id
            title
          },
          collection {
            collectionSets {
              product {
                id
                title
                hands {
                  id
                  title
                }
              }
            }
          } 
        }
      }
    }
  `,
  OnSaleProducts: gql`
    query {
      onSaleProducts {
        id
        title
      }
    }
  `
};

export default productQueries;
