import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import validator from 'validator';
import { mutations } from '../graphql';
import StoreForm from './Forms/Store/StoreForm.js';
import StoreInput from './Forms/Store/StoreInput.js';
import FeedbackMessage from './FeedbackMessage.js';
import { useTextTranslation } from '../Hooks';
import NiceError from './Errors/NiceError';

const SendResetPassword = ({ email, onMessageSent }) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const successText = useTextTranslation('email-sent-please-follow-instructions');

  const handleSubmitSuccess = () => {
    setMessage(successText);
    if (onMessageSent) {
      onMessageSent();
    }
  };

  const handleSubmitError = (err) => {
    setError(err);
  };

  return (
    <>
      <FeedbackMessage message={message} type='success' height='5rem' />
      <NiceError err={error} defaultText='finding-your-account' />
      <Mutation
        mutation={mutations.ResetPassword}
      >
        {resetPassword => (
          <StoreForm
            fields={{ email: '' }}
            onSubmit={(options) => {
              setError(null);
              if (email) {
                options = {
                  variables: { email }
                };
              }
              return resetPassword(options);
            }}
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitError={handleSubmitError}
            submitLabel='send-reset-email'
          >
            {
              !email
                ? <StoreInput
                    id='email'
                    label='email-address'
                    autoFocus
                    required
                    maxLength={100}
                    validate={(val) => (validator.isEmail(val) ? '' : 'Invalid Email')}
                  />
                : null
            }
          </StoreForm>
        )}
      </Mutation>
    </>
  );
};

SendResetPassword.propTypes = {
  email: PropTypes.string,
  onMessageSent: PropTypes.func
};

export default SendResetPassword;
