import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  previewButton: {
    padding: 0,
    width: 40,
    minWidth: 40
  }
}));

const PreviewTrickButton = (props) => {
  const { rowIndex, onClick } = props;
  const classes = useStyles();

  return (
    <Tooltip
      title='Preview from this trick'
      placement='top'
    >
      <Button
        className={classes.previewButton}
        variant='contained'
        color='primary'
        size='small'
        onClick={() => onClick(rowIndex)}
      >
        <PlayArrowIcon size='small' />
      </Button>
    </Tooltip>
  );
};

PreviewTrickButton.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default PreviewTrickButton;
