import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import BiddingItem from './BiddingItem.js';
import MiddleMessage from '../../MiddleMessage.js';
import clsx from 'clsx';
import YourTurn from '../YourTurn.js';

import {
  isHigherThanCurrentBid,
  hasEnded,
  doubleOption,
  wereLastThreeBidsAllPass,
  getContract
} from '../../../helpers/auction.js';

const styles = {
  biddingBox: {
    position: 'relative',
    background: '#f5f5f5',
    width: '40em',
    height: '41.4em',
    borderRadius: '0.8em',
    overflow: 'hidden',
    fontWeight: 600,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    zIndex: 1
  },
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  li: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '-1.4em',
    display: 'flex',
    '&:last-child': {
      margin: 0
    }
  },
  pass: {
    position: 'static',
    backgroundImage: 'url("/img/bidding_box/bg_pass.svg")',
    backgroundSize: 'contain',
    width: '16.1em',
    margin: '0 -0.1em 0 0',
    color: '#417505',
    '&:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '2.5em',
      background: 'transparent url("/img/bidding_box/bg_pass_bottom.svg") no-repeat left bottom',
      backgroundSize: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0,
      pointerEvents: 'none',
      zIndex: 101
    },
    '&:hover': {
      backgroundImage: 'url("/img/bidding_box/bg_pass_hover.svg")',
      '&:after': {
        backgroundImage: 'url("/img/bidding_box/bg_pass_bottom_hover.svg")'
      }
    }
  },
  x: {
    backgroundImage: 'url("/img/bidding_box/bg_double.svg")',
    backgroundSize: 'contain',
    width: '12.8em',
    margin: '0 -0.9em 0 0',
    color: '#d0021b',
    '&:hover': {
      backgroundImage: 'url("/img/bidding_box/bg_double_hover.svg")'
    },
    '&$disabled': {
      backgroundImage: 'url("/img/bidding_box/bg_double_disabled.svg") !important'
    }
  },
  xx: {
    backgroundImage: 'url("/img/bidding_box/bg_double_double.svg") !important',
    backgroundSize: 'contain',
    width: '12.1em !important',
    margin: '0 !important',
    color: '#3b76bb !important',
    '&:hover': {
      backgroundImage: 'url("/img/bidding_box/bg_double_double_hover.svg") !important'
    },
    '&$disabled': {
      backgroundImage: 'url("/img/bidding_box/bg_double_double_disabled.svg") !important'
    }
  },
  disabled: {
    color: '#999 !important',
    cursor: 'not-allowed'
  }
};

class BiddingBox extends React.Component {
  isDisabled = (value, isFinalRow) => {
    const { auction, dealer } = this.props;

    // if auction ended then disable
    if (hasEnded(auction)) {
      return true;
    }

    // can we show double or redouble
    if (isFinalRow) {
      const double = doubleOption(auction, dealer);
      switch(value) {
        case 'AP':
          // you can only AP when there is a valid contract
          if (getContract(auction, dealer) !== undefined) {
            return false;
          }
          break;
        case 'Pass':
          // if value is 'Pass' and last 3 bids were also pass
          // we need to disable it
          if (wereLastThreeBidsAllPass(auction)) {
            return true;
          }
          return false;
        case 'X':
          if (double === 'X') {
            return false;
          }
          break;
        case 'XX':
          if (double === 'XX') {
            return false;
          }
          break;
        default:
          break;
      }
      return true;
    }

    // if auction empty then enable
    if (this.props.auction.length === 0) {
      return false;
    }

    if (isHigherThanCurrentBid(auction, 'N', value)) {
      return false;
    }

    return true;
  }

  render () {
    const { classes, auction, clickable, onClick, showTurnHint } = this.props;

    const rowValues = ['1', '2', '3', '4', '5', '6', '7'];
    const rowOptions = ['NT', 'S', 'H', 'D', 'C'];
    const lastRow = ['Pass', 'X', 'XX'];

    let zIndex = 100;

    return (
      <div className={classes.biddingBox}>
        <YourTurn active={clickable && showTurnHint} />
        <ul className={classes.ul}>
          {
            rowValues.map(value => {
              return (
                <li className={classes.li} key={value} style={{zIndex:100-zIndex}}>
                {
                  rowOptions.map((option, index) => {
                    const disabled = this.isDisabled(value + option);
                    if (!disabled) {
                      zIndex--;
                      return (
                        <BiddingItem
                          key={index}
                          suit={option}
                          value={value}
                          onClick={onClick}
                          zIndex={zIndex}
                          clickable={clickable}
                          disabled={false}
                        />
                      );
                    }
                    return null;
                  })
                }
                </li>
              )
            })
          }
          <li className={classes.li} style={{zIndex:100-zIndex}}>
          {
            lastRow.map((value, index) => {
              const disabled = this.isDisabled(value, true);
              // if (!disabled) {
                zIndex--;
                return (
                  <BiddingItem
                    key={index}
                    value={value}
                    onClick={onClick}
                    className={clsx(classes[value.toLowerCase()], disabled ? classes.disabled : null)}
                    zIndex={zIndex}
                    clickable={clickable}
                    disabled={disabled}
                  />
                );
              // }
              // return null;
            })
          }
          </li>
        </ul>
        {
          hasEnded(auction) ?
          <MiddleMessage text='auction-complete' />
          : null
        }
      </div>
    )
  }
}

BiddingBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  dealer: PropTypes.string.isRequired,
  auction: PropTypes.array.isRequired,
  clickable: PropTypes.bool,
  showTurnHint: PropTypes.bool
}

export default withStyles(styles)(BiddingBox);
