import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Toolbar
} from '@mui/material';
import Logo from '../../../../../Components/Layout/AppBar/Logo.js';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'transparent',
    height: '14rem',
    zIndex: 100
  },
  container: {
    width: '118rem',
    margin: '0 auto',
    padding: '3.5rem 0'
  },
  toolbar: {
    padding: 0,
    margin: '0 1rem'
  }
}));

const CheckoutAppBar = () => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} position='relative'>
      <div className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <Logo />
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default CheckoutAppBar;
