import _ from 'lodash';
import { sortCards } from './card.js';

/*
--------------------
play is an 'array of tricks'
play json format is:
--------------------
[
  { step: 0, rank: 'A', suit: 'S', seat: 'N', winner: true, comments: { pre: '', post: '', hint: ''} },
  { step: 1, rank: '9', suit: 'S', seat: 'E' },
  { step: 2, rank: '5', suit: 'S', seat: 'S' },
  { step: 3, rank: '2', suit: 'S', seat: 'W' }
], [
  { step: 4, rank: 'K', suit: 'H', seat: 'N' },
  { step: 5, rank: 'A', suit: 'H', seat: 'E', winner: true },
  { step: 6, rank: '5', suit: 'H', seat: 'S' },
  { step: 7, rank: '2', suit: 'H', seat: 'W' }
];
*/

/* not required
const seatRankings = [
  { value: 'N', rank: 0 },
  { value: 'E', rank: 1 },
  { value: 'S', rank: 2 },
  { value: 'W', rank: 3 }
];
*/

// not required now we store the step in the play item
// for now keep for prosperity
/* export const getStepNumberForPlayItem = (seat, winnerOrFirstToBid, rowIndex) => {
  let stepNumberInRow = 0;
  if (winnerOrFirstToBid === seat) {
    stepNumberInRow = 1;
  } else {
    const winnerOrFirstToBidRank = _.find(
      seatRankings, { value: winnerOrFirstToBid }
    ).rank;

    // rotate the array (in reverse) 'rank' number of times
    let rotatedSeatRankings = _.cloneDeep(seatRankings);
    for (let i = 0; i < winnerOrFirstToBidRank; i++) {
      // rotatedSeatRankings = arrayRotate(rotatedSeatRankings, true);
      arrayRotate(rotatedSeatRankings, true);
    }

    // now get the index in this new array for the seat we want the step for
    const seatIndex = _.findIndex(rotatedSeatRankings, { 'value': seat });

    // add 1 for the step number in this row
    stepNumberInRow = seatIndex + 1;
  }
  return stepNumberInRow + (4 * rowIndex);
}; */

export const convertPlayToTableRows = (play) => {
  const tableRows = [];
  // loop through to create 13 blank rounds
  for (let i = 0; i < 13; i++) {
    const tableRow = ['', '', '', ''];

    const trick = play[i];

    if (trick) {
      // loop through trick items
      for (let j = 0; j < 4; j++) {
        // get card from trick
        const card = trick[j];
        if (card) {
          // get the seat position of the seat (0 to 3)
          const seatPos = _.indexOf(['N', 'E', 'S', 'W'], card.seat);
          // fill this row item with the trick card
          tableRow[seatPos] = card;
        }
      }
    }
    // add the tableRow to the tableRows array
    tableRows.push(tableRow);
  }
  return tableRows;
};

/*
-----------------------------------------------
now obsolete as the play items cotain the seat
-----------------------------------------------
export const getSeatForPlay = (play, playItem) => {
  const suit = playItem.substring(0, 1);
  const rank = playItem.substring(1, playItem.length);
  const playItemObj = _.find(play, { suit: suit, rank: rank });
  if (playItemObj !== undefined) {
    return playItemObj.seat;
  }
  throw new Error('cannot find seat for play item.');
};
-----------------------------------------------
*/

export const winnerOfHand = (hand, trump) => {
  let cardsInPlay = [];

  if (trump && trump !== '' && trump !== 'NT') {
    // if trump set
    // get the seat of highest trump card
    const trumpCards = _.filter(hand, card => {
      return card.suit === trump;
    });
    if (trumpCards.length > 0) {
      cardsInPlay = trumpCards;
    }
  }

  if (cardsInPlay.length < 1) {
    // if no trumps played, get the seat of the highest card of the suit led by the first play
    const firstSuitPlayed = hand[0].suit;
    // get all cards played with this suit
    cardsInPlay = _.filter(hand, card => {
      return card.suit === firstSuitPlayed;
    });
  }

  // sort cards, highest rank comes first
  const sortedCards = sortCards(cardsInPlay);
  if (sortedCards.length > 0) {
    // return first card in list
    return sortedCards[0].seat;
  }
  throw new Error('cant work out who won!');
};

export const flagWinningCard = (hand, winner) => {
  const newHand = hand.slice();
  for (let i = 0; i < newHand.length; i++) {
    if (newHand[i].seat === winner) {
      newHand[i] = {
        ...newHand[i],
        winner: true
      };
    }
  }
  return newHand;
};

export const firstToPlay = (declarer) => {
  // first to play is left of declarer
  switch (declarer) {
    case 'N':
      return 'E';
    case 'E':
      return 'S';
    case 'S':
      return 'W';
    case 'W':
      return 'N';
    default:
      throw new Error('declarer not a valid seat');
  }
};

export const addWinnerToTrickCount = (tricks, winner) => {
  if (_.isEmpty(tricks)) {
    tricks = {
      northSouth: 0,
      eastWest: 0
    };
  } else {
    tricks = { ...tricks };
  }
  if (winner === 'N' || winner === 'S') {
    tricks.northSouth += 1;
  } else {
    tricks.eastWest += 1;
  }
  return tricks;
};

export const removeWinnerFromTrickCount = (tricks, hand, trump) => {
  if (_.isEmpty(tricks)) {
    return {
      northSouth: 0,
      eastWest: 0
    };
  }
  // get winner of undone hand
  const winner = winnerOfHand(hand, trump);
  if (winner === 'N' || winner === 'S') {
    tricks.northSouth -= 1;
  } else {
    tricks.eastWest -= 1;
  }
  return tricks;
};

export const validSuitToPlay = (suit, currentHand, playersCards) => {
  // if it's the first card in the hand then anything can be played
  if (currentHand.length > 0) {
    // if player has cards of the first suit played then they have to play it
    const firstSuitPlayed = currentHand[0].suit;
    const playersCardsInSuit = _.filter(playersCards, card => {
      return card.suit === firstSuitPlayed && !card.played;
    });
    if (playersCardsInSuit.length > 0) {
      // they have to play a suit card
      if (suit === firstSuitPlayed) {
        return true;
      }
      return false;
    }
  }
  return true;
};

export const markDealCardsAsPlayed = (deal, play) => {
  // for each item in play, mark it as played in the deal
  for (let i = 0; i < play.length; i++) {
    const trick = play[i];
    for (let j = 0; j < trick.length; j++) {
      const playItem = trick[j];

      const matchingCard = deal.find(dealItem => {
        return (
          dealItem.suit === playItem.suit &&
          dealItem.rank === playItem.rank
        );
      });

      if (matchingCard) {
        matchingCard.played = true;
      }
    }
  }

  return deal;
};

export const getCardFromTrickBySeat = (trick, seat) => {
  const card = _.find(trick, { seat });
  return card;
};

export const getSeatForPlay = (card, play) => {
  let seat = '';
  play.forEach(trick => {
    const playItem = _.find(trick, { suit: card.suit, rank: card.rank });
    if (playItem) {
      seat = playItem.seat;
    }
  });
  if (seat !== '') {
    return seat;
  }
  throw new Error(`could not find play item for this card: ${card.rank}${card.suit}`);
};

// mark a played card as visible false so it dissapers from the deal once played
export const flagCardAsInvisible = (deal, card) => {
  deal.forEach((dealCard) => {
    if (dealCard.rank === card.rank && dealCard.suit === card.suit) {
      dealCard.visible = false;
    }
  });
};

export const getSuitToPlay = (activeSeat, trick, deal) => {
  if (trick.length > 0) {
    const firstSuitToPlay = trick[0].suit;
    const playersCardsInSuit = _.filter(deal, card => {
      return card.suit === firstSuitToPlay &&
        card.seat === activeSeat &&
        card.visible !== false;
    });
    if (playersCardsInSuit.length > 0) {
      return firstSuitToPlay;
    }
  }
  return 'all';
};

export const setCardToNotPlayedInDeal = (deal, card) => {
  deal = deal.slice();
  const indexOfCard = deal.findIndex((thisCard) => thisCard.rank === card.rank && thisCard.suit === card.suit);
  deal[indexOfCard].played = false;
  return deal;
};
