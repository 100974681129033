import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import MessageGroup from './MessageGroup.js';

const styles = {
  messages: {
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: '2rem',
    borderLeft: 'none',
    borderRight: 'none'
  }
};

class Chat extends React.Component {
  render () {
    const { classes, conversation, ...other } = this.props;
    return (
      <div className={classes.messages}>
        {
          conversation.map((messageGroup, index) => {
            return (
              <MessageGroup
                key={index}
                messages={messageGroup.messages}
                position={messageGroup.position}
                {...other}
              />
            );
          })
        }
      </div>
    );
  }
}

Chat.propTypes = {
  conversation: PropTypes.array.isRequired,
  author: PropTypes.object.isRequired,
  waitingMessageDelay: PropTypes.number.isRequired
};

export default withStyles(styles)(Chat);
