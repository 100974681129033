import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import BBTranslate from '../Locale/BBTranslate.js';
import {
  getMobileOperatingSystem,
  iOSversion,
  getAndroidVersion
} from '../../helpers/pwa.js';
import { isAuthenticated } from '../../helpers/auth.js';
import { DrawerContext } from '../../Context';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#333',
    color: '#F5F2F2',
    fontSize: 16,
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    '& li': {
      display: 'block'
    },
    '& a, span': {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover, &:focus': {
        color: '#ccc',
        textDecoration: 'underline'
      }
    }
  },
  container: {
    maxWidth: '118rem',
    margin: '0 auto',
    padding: '4.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      padding: '4rem 2rem'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  version: {
    color: '#999'
  },
  links: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #A09896',
      marginBottom: '2rem',
      paddingBottom: '2rem'
    }
  },
  loginLink: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const Footer = () => {
  const { openDrawer } = useContext(DrawerContext);
  const loggedIn = isAuthenticated();
  const classes = useStyles();
  const date = new Date();
  const year = date.getFullYear();
  const mobileOS = getMobileOperatingSystem();
  let osVersion;
  if (mobileOS === 'iOS' || mobileOS === 'Android') {
    if (mobileOS === 'iOS') {
      osVersion = iOSversion();
    } else {
      osVersion = getAndroidVersion();
    }
  }

  const handleLoginClick = () => {
    openDrawer('login');
  };

  return (
    <div className={classes.footer}>
      <div className={classes.container}>
        <div>
          <BBTranslate textId='engaging-bridge-lessons-every-level' />
          <br />
          &copy; {year} BridgeBee Ltd.&nbsp;
          <BBTranslate textId='registered-in-eng-and-wales' />&nbsp;
          <br />
          <BBTranslate textId='company-number' /> 12556755.
          &nbsp;
          <br />
          <div className={classes.version}>
            <div>v{global.appVersion}</div>
            <div className={classes.os}>{osVersion ? `${mobileOS} - ${osVersion}` : ''}</div>
          </div>
        </div>
        <div className={classes.links}>
          <ul>
            <li><Link to='/help'><BBTranslate textId='contact-us' /></Link></li>
            <li><Link to='/help'><BBTranslate textId='help' /></Link></li>
            {
              loggedIn
                ? <li><Link to='/logout'><BBTranslate textId='logout' /></Link></li>
                : (
                  <>
                    <li><span onClick={handleLoginClick} className={classes.loginLink}><BBTranslate textId='login' /></span></li>
                    <li><Link to='/register'><BBTranslate textId='register' /></Link></li>
                  </>
                  )
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
