import gql from 'graphql-tag';

const priceQueries = {
  Prices: gql`
    query {
      prices {
        id
        priceUSD
        priceGBP
        priceEUR
        priceCAD
        priceAUD
        priceNZD
      }
    }
  `
};

export default priceQueries;
