import { useContext } from 'react';
import { LocaleContext } from '../Context';
import { useOrientation } from './';

const useCardVariant = () => {
  const { locale } = useContext(LocaleContext);
  const isPortrait = useOrientation();

  let variant = 'normal';

  if (isPortrait) {
    variant = 'simplified';
  }

  if (locale && locale.language === 'nl-nl') {
    variant = 'nl';
  }

  return variant;
};

export default useCardVariant;
