import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import BBTranslate from '../Locale/BBTranslate.js';

class BBSelect extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: props.value,
      error: '',
      labelWidth: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.setSelectState = this.setSelectState.bind(this);
    this.inputLabelRef = React.createRef();
  }

  handleChange (e) {
    const name = this.props.name;
    const value = e.target.value;
    const error = this.props.validate ? this.props.validate(value) : '';

    this.setSelectState(
      {
        value,
        error
      });
    this.props.onChange(
      {
        name,
        value,
        error
      });
  }

  componentDidUpdate (prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setSelectState(
        {
          value: this.props.value
        }
      );
    }
  }

  componentDidMount () {
    this.setSelectState({
      labelWidth: this.inputLabelRef.current.offsetWidth
    });
  }

  setSelectState (newState) {
    let state = this.state;
    state = Object.assign(state, newState);
    this.setState(state);
  }

  render () {
    const { id, label, value, valueField, keyField, name, items, required, showBlankOption, autoWidth, customStyles } = this.props;
    const { labelWidth, error } = this.state;
    const variant = this.props.variant ? this.props.variant : 'outlined';

    return (
      <FormControl
        fullWidth
        variant={variant || 'outlined'}
        margin='normal'
        required={required}
        error={error !== ''}
        style={customStyles}
      >
        <InputLabel
          htmlFor={id}
          ref={this.inputLabelRef}
          style={{ background: 'white', paddingRight: '0.5rem', borderRadius: '3px' }}
        >
          {/* Custom css applied otherwise label appears with strikethrough when passed through BBTranslate */}
          <BBTranslate textId={label} />
        </InputLabel>
        <Select
          variant='standard'
          autoWidth={autoWidth || false}
          style={{ fontSize: '16px' }}
          onChange={this.handleChange}
          value={value || ''}
          name='status'
          input={variant === 'outlined'
            ? <OutlinedInput
                labelWidth={labelWidth}
                name={name}
                id={id}
              />
            : <Input
                name={name}
                id={id}
              />}
        >
          {
          showBlankOption
            ? <MenuItem value=''><em>-</em></MenuItem>
            : null
          }
          {
            items.map((item, index) => {
              return (
                <MenuItem key={index} value={item[valueField]}>
                  {item[keyField]}
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    );
  }
}

BBSelect.propTypes = {
  items: PropTypes.array.isRequired,
  valueField: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  value: PropTypes.any,
  width: PropTypes.number,
  required: PropTypes.bool,
  variant: PropTypes.string,
  showBlankOption: PropTypes.bool,
  autoWidth: PropTypes.bool,
  customStyles: PropTypes.object
};

export default BBSelect;
