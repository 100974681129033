import React from 'react';
import PropTypes from 'prop-types';
import { productQueries } from '../../../../graphql/queries';
import AdminPage from '../../../../Components/AdminPage.js';
import AddIcon from '@mui/icons-material/Add';

const SetSearch = ({ onAddSetClick }) => {
  const columns = [
    { id: 'title', label: 'Title', sortColumn: 'title' },
    { id: 'author.fullName', label: 'Author', sortColumn: 'author.last_name' },
    { id: 'onSale', label: 'On sale', sortColumn: 'onSale', type: 'boolean', width: '8rem' },
    { id: 'add', type: 'button', onClick: onAddSetClick, icon: <AddIcon /> }
  ];
  return (
    <AdminPage
      columns={columns}
      query={productQueries.ProductSearchCollection}
      rowsDataPath='productSearchCollection.products'
      countDataPath='productSearchCollection.count'
      initialOrderBy='id'
      initialOrderByDirection='desc'
      onRowClick={() => {}}
    />
  );
};

SetSearch.propTypes = {
  onAddSetClick: PropTypes.func.isRequired
};

export default SetSearch;
