import _ from 'lodash';

// get the good bits from a grpahQL/API error
// error messages can come n different forms so
// check the 3 most common:
// - graphQLErrors
// - errors
// - networkError
export const parseError = err => {
  let apiError = _.get(err, 'graphQLErrors[0]', null);
  if (!apiError) {
    apiError = _.get(err, 'errors[0]', null);
    if (!apiError) {
      apiError = _.get(err, 'networkError', null);
    }
  }
  if (apiError) {
    // console.log('parseError apiError', apiError);
    return {
      code: apiError.extensions ? apiError.extensions.code : 'INTERNAL_SERVER_ERROR',
      message: apiError.message
    };
  }
  // check if its a local validation error
  if (err.message.indexOf('ValidationError') !== -1) {
    return {
      code: 'VALIDATION_ERROR',
      message: err.message.split(':')[1].trim()
    };
  }
  return {
    code: 'INTERNAL_SERVER_ERROR',
    message: 'Unknown Error'
  };
};
