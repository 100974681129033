import React from 'react';
import PropTypes from 'prop-types';
import { voucherQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class VoucherById extends React.Component {
  render () {
    const { children, id } = this.props;
    return (
      <BBQuery
        query={voucherQueries.VoucherById(id)}
        showLoader
        fetchPolicy='no-cache'
      >
        {data => {
          return children(data.voucherById);
        }}
      </BBQuery>
    );
  }
}

VoucherById.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default VoucherById;
