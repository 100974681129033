// 2 column layout and placeholders for Help, Register, Reset Password, Confirmations etc...
import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  Paper
} from '@mui/material';
import BBTranslate from '../Locale/BBTranslate';

const useStyles = makeStyles((theme) => ({
  item: {
    width: '45%',
    marginRight: '2rem',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  paper: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: 6,
    padding: '4rem'
  }
}));

const TwoColumn = ({ title, description, form }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      sx={{ flexDirection: { sm: 'column', md: 'row' } }}
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <Grid item className={classes.item}>
        <Typography variant='h2'>
          <BBTranslate textId={title} />
        </Typography>
        <Typography variant='body1'>
          {description}
        </Typography>
      </Grid>
      <Grid item className={classes.item}>
        <Paper className={classes.paper}>
          {form}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TwoColumn;
