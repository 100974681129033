import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { mutations } from '../../../../graphql';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CollectionSets from './CollectionSets.js';
import SetSearch from './SetSearch.js';
import { convertCollectionSetsToSets } from '../../../../helpers/product.js';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column'
  }
});

const Collection = (props) => {
  const { classes, collectionId, apolloClient, openSnackbar } = props;
  const [sets, setSets] = useState(props.sets);

  const handleAddSetClick = set => (e) => {
    e.preventDefault();
    const theseSets = _.cloneDeep(sets);
    const existingSet = _.find(theseSets, { id: set.id });
    if (existingSet) {
      openSnackbar('Set already added to collection', 'warning');
    } else {
      theseSets.push(set);
      setSets(theseSets);
      apolloClient.mutate({
        mutation: mutations.AddSetToCollection,
        variables: {
          collectionId,
          setId: set.id
        },
        update: () => {
          openSnackbar('Set added to collection', 'success');
        }
      });
    }
  };

  const handleDeleteClick = (setId) => {
    _.remove(sets, set => {
      return set.id === setId;
    });
    setSets(sets);

    apolloClient.mutate({
      mutation: mutations.DeleteSetFromCollection,
      variables: {
        collectionId,
        setId
      },
      update: () => {
        openSnackbar('Set removed from collection', 'success');
      }
    });
  };

  const handleReorderClick = (setId, direction) => () => {
    apolloClient.mutate({
      mutation: mutations.ReorderCollection,
      variables: {
        collectionId,
        setId,
        direction
      },
      update: (_, response) => {
        const sets = convertCollectionSetsToSets(response.data.reorderCollection.collectionSets);
        setSets(sets);
      }
    });
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography style={{ padding: '2rem 0 0 2rem' }} variant='h6' gutterBottom>
          Sets in this collection
        </Typography>
        <br />
        {
          sets.length > 0
            ? <CollectionSets
                sets={sets}
                onDeleteClick={handleDeleteClick}
                onReorderClick={handleReorderClick}
              />
            : <div style={{ padding: '2rem' }}>There are currently no sets in this collection</div>
        }
      </Paper>
      <SetSearch onAddSetClick={handleAddSetClick} />
    </>
  );
};

Collection.propTypes = {
  classes: PropTypes.object.isRequired,
  collectionId: PropTypes.number.isRequired,
  sets: PropTypes.array.isRequired,
  apolloClient: PropTypes.object.isRequired,
  openSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(Collection);
