import gql from 'graphql-tag';

const pbnQueries = {
  PbnsByProductId: (productId) => {
    return gql`
      query {
        pbnsByProductId (productId: ${productId}) {
          id
          status
        }
      }
    `;
  },
  PbnByFilename: gql`
    query ($filename: String!) {
      pbnByFilename (filename: $filename) {
        id
        status
        error
      }
    }
  `
};

export default pbnQueries;
