import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import uploadHelpers from '../helpers/upload.js';
import { isValidFileExtension, getContentType, getFileExtension } from '../helpers/file.js';

class FileUploader extends React.Component {
  validateFile = (file) => {
    const { fileSpecs, onUploadError } = this.props;
    let message = '';
    // check file type
    if (!isValidFileExtension(file.name, fileSpecs.supportedFileTypes)) {
      message = 'File must be a ';
      for (let i = 0; i < fileSpecs.supportedFileTypes.length; i++) {
        const ext = fileSpecs.supportedFileTypes[i].extension;
        if (i === 0) {
            message += ext;
        } else {
            message += ` or ${ext}`
        }
      }
      onUploadError(message);
      return false;
    }

    // check size
    if (file.size > (1024000 * fileSpecs.maxSizeKb)) {
      message = `File must be a less than ${fileSpecs.maxSizeKb}kb`;
      onUploadError(message);
      return false;
    }
    return true;
  };

  handleUpload = (file) => {
    const { fileSpecs, uploadFolder, onUploadProgress, filePrefix, onUploadError } = this.props;
    file.contentType = getContentType(file.name, fileSpecs.supportedFileTypes);
    file.extension = getFileExtension(file.name);
    const files = [];
    files[0] = file;
    const self = this;
    const callbacks = {
      progress: function (progressValue, data) {
        // progress = progressValue;
        const progress = Math.round(progressValue * 100);
        onUploadProgress(progress);
        console.log(
         'Total Loaded:', data && data.loaded ? data.loaded : '',
         'Speed:', data && data.speed ? data.speed : '',
         'Formatted speed:', data && data.speed ? data.readableSpeed + 's' : '',
         'Minutes left:', data && data.secondsLeft ? Math.round(data.secondsLeft / 60) : '');
        if(data) {
         var xferRate = data.speed ? ' - ' + data.readableSpeed + 's' : '',
             remaining = data.secondsLeft ? ' - ' + Math.round(data.secondsLeft / 60) + 'm left' : '';
         // speed.html(xferRate + remaining);
         console.log(xferRate + remaining);
        }
      },
      started: function (filename) {
        if (self.props.onUploadFilenameGenerated) {
          self.props.onUploadFilenameGenerated(filename);
        }
      },
      complete: function (fileName, folder, _xhr, awsKey, stats) {
        self.props.onUploadComplete(fileName, folder);
      },
      cancelled: function (stuff) {
        console.log('cancelled ' + stuff);
      },
      error: function (message) {
        message = 'upload error: ' + message;
        console.log(message);
        onUploadError(message);
      },
      added: function (upload) {
        console.log('--added upload--', upload);
      }
    }
    uploadHelpers.uploadFiles(files, uploadFolder, filePrefix, callbacks);
  };

  fileChangedHandler = (e) => {
    console.log('fileChangedHandler', e);
    const { onUploadStart } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      var file = e.target.files[0];
      // validate the filetype
      if (this.validateFile(file)) {
        // pass file back to parent
        onUploadStart(file);
        // start the upload
        this.handleUpload(file);
      }
    }
  };

  setFormState = (newState) => {
    let state = this.state;
    state = Object.assign(state, newState);
    this.setState(state);
  };

  render () {
    const { buttonText, buttonIcon, buttonDisabled } = this.props;
    return (
      <React.Fragment>
        <Button
          variant='contained'
          component='label'
          disabled={buttonDisabled}
        >
          {buttonText}
          {buttonIcon}
          <input
            type='file'
            onChange={this.fileChangedHandler}
            style={{ display: 'none' }}
            value=''
          />
        </Button>
      </React.Fragment>
    );
  }
}

FileUploader.protoTypes = {
  fileSpecs: PropTypes.object.isRequired,
  uploadFolder: PropTypes.string.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadFilenameGenerated: PropTypes.func,
  onUploadProgress: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  onUploadError: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  buttonIcon: PropTypes.object,
  filePrefix: PropTypes.string
};

export default FileUploader;
