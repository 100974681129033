import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { userQueries } from '../../../graphql/queries';
import StoreItems from '../../../Components/Store/StoreItems.js';
import StoreSectionContainer from '../../../Components/Store/StoreSectionContainer.js';
import GridViewSkeleton from '../../../Components/Skeletons/GridViewSkeleton.js';
import LoggedOut from './LoggedOut.js';
import Empty from './Empty.js';
import { makeStyles } from '@mui/styles';
import { useTextTranslation } from '../../../Hooks';
import { UserContext, DrawerContext } from '../../../Context';
import { getProductListFromPurchases } from '../../../helpers/purchase';

const useStyles = makeStyles((theme) => ({
  items: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-8rem'
    }
  }
}));

const Library = () => {
  const { setUser, user } = useContext(UserContext);
  const { openDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const classes = useStyles();

  const [purchasedProducts, setPurchasedProducts] = useState(null);
  const [getPurchases, { loading: getPurchasesLoading }] = useLazyQuery(userQueries.PurchasesByUserId, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      // we now get the user and purchases info together to simplify checkouts with new customers
      const user = data.purchasesByUserId;
      // unpack the purchases (and items) to get at the products
      const thePurchasedProducts = getProductListFromPurchases(user.purchases);
      setPurchasedProducts(thePurchasedProducts);
      // remove the purchases and set the user (which is essential when its a new customer)
      delete user.purchases;
      setUser(user);
    },
    onError: (err) => {
      // TODO do something useful with this error
      console.log('PurchasesByUserId err', err);
    }
  });

  const translatedTitle = useTextTranslation('my-library');

  const handleLoginClick = () => {
    openDrawer('login');
  };

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const handleStoreClick = () => {
    navigate('/products');
  };

  const isAuthenticated = !!user;

  // check user is authenticated before fetching purchases
  // retry if the user has just logged in
  useEffect(() => {
    if (isAuthenticated) {
      getPurchases();
    }
  }, [getPurchases, isAuthenticated]);

  if (!isAuthenticated) {
    return (
      <LoggedOut
        onLoginClick={handleLoginClick}
        onRegisterClick={handleRegisterClick}
      />
    );
  } else {
    if (getPurchasesLoading) {
      return <GridViewSkeleton />;
    } else {
      return (
        <>
          <StoreSectionContainer
            heading={translatedTitle}
          >
            {
              purchasedProducts && purchasedProducts.length > 0
                ? (
                  <div className={classes.items}>
                    <StoreItems
                      products={purchasedProducts}
                      hidePrice
                    />
                  </div>
                  )
                : <Empty onStoreClick={handleStoreClick} />
            }
          </StoreSectionContainer>
        </>
      );
    }
  }
};

export default Library;
