import React, { useState, useContext } from 'react';
import validator from 'validator';
import { Typography, Box } from '@mui/material';
import { CartContext, DrawerContext, GiftContext } from '../../../Context';
import StoreInput from '../../Forms/Store/StoreInput.js';
import StoreButton from '../../Forms/Store/StoreButton.js';
import CartItem from '../Cart/CartItem.js';
import GreyLine from '../GreyLine.js';
import BBTranslate from '../../Locale/BBTranslate';

const GiftForm = () => {
  const { addToCart, currency } = useContext(CartContext);
  const { openDrawer } = useContext(DrawerContext);
  const { gift, setGift } = useContext(GiftContext);

  const [fieldErrors, setFieldErrors] = useState({});

  const handleInputChange = ({ id, value, error }) => {
    const newGift = { ...gift };
    newGift[id] = value;
    fieldErrors[id] = error;
    setGift(newGift);
    setFieldErrors(fieldErrors);
  };

  const handleAddToCartClick = () => {
    addToCart(gift.product, gift);
    openDrawer('cart');
  };

  const validate = () => {
    const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
    if (!gift.toFirstName) return false;
    if (!gift.toLastName) return false;
    if (!gift.toEmail) return false;
    if (!gift.message) return false;
    if (errMessages.length) return false;
    return true;
  };

  return (
    <>
      <CartItem
        item={{
          title: gift.product.title,
          images: gift.product.images,
          price: gift.product.price
        }}
        currency={currency}
      />
      <GreyLine />
      <Typography variant='h3'>
        <BBTranslate textId='who-is-it-for' />?
      </Typography>
      <StoreInput
        id='toFirstName'
        label='recipient-first-name'
        autoComplete='given-name'
        required
        maxLength={100}
        value={gift.toFirstName}
        onChange={handleInputChange}
        validate={(val) => (val ? '' : 'Recipient first name is required')}
      />

      <StoreInput
        id='toLastName'
        label='recipient-last-name'
        autoComplete='family-name'
        required
        maxLength={100}
        value={gift.toLastName}
        onChange={handleInputChange}
        validate={(val) => (val ? '' : 'Recipient last name is required')}
      />

      <StoreInput
        id='toEmail'
        label='recipient-email'
        autoComplete='email'
        required
        maxLength={100}
        value={gift.toEmail}
        onChange={handleInputChange}
        validate={(val) => (validator.isEmail(val) ? '' : 'Invalid Email')}
      />

      <StoreInput
        id='message'
        label='message'
        maxLength={500}
        rows={4}
        value={gift.message}
        onChange={handleInputChange}
      />

      <Box sx={{ marginTop: 1 }}>
        <StoreButton
          onClick={handleAddToCartClick}
          label='add-to-cart'
          fullWidth
          disabled={!validate()}
        />
      </Box>
    </>
  );
};

export default GiftForm;
