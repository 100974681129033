import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import { adopt } from 'react-adopt';
import { mutations } from '../../../graphql';
import TagForm from './TagForm.js';
import TagById from '../../../Components/Queries/TagById.js';
import TagGroups from '../../../Components/Queries/TagGroups.js';

const TagDetails = (props) => {
  const { id, ...other } = props;
  const Composed = adopt({
    one: ({ render }) => <TagById id={id}>{render}</TagById>,
    two: ({ render }) => <TagGroups>{render}</TagGroups>
  });
  return (
    <>
      <Composed>
        {({ one, two }) => (
          <Mutation
            mutation={mutations.UpsertTag}
          >
            {upsertTag => (
              <Mutation
                mutation={mutations.DeleteTag}
              >
                {deleteTag => (
                  <>
                    <TagForm
                      upsertTag={upsertTag}
                      deleteTag={deleteTag}
                      tag={one}
                      groups={two}
                      {...other}
                    />
                  </>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Composed>
    </>
  );
};

TagDetails.propTypes = {
  id: PropTypes.number.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default TagDetails;
