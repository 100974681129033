import _ from 'lodash';
import striptags from 'striptags';
import { getCardTextId } from './translation.js';

const getRowOrdinal = (row) => {
  let ordinal = '';
  switch (row) {
    case 1:
      ordinal = '1st';
      break;
    case 2:
      ordinal = '2nd';
      break;
    case 3:
      ordinal = '3rd';
      break;
    default:
      ordinal = row + 'th';
      break;
  }
  return ordinal;
};

const getSeatName = (seat) => {
  let seatName = '';
  switch (seat) {
    case 'N':
      seatName = 'North';
      break;
    case 'E':
      seatName = 'East';
      break;
    case 'S':
      seatName = 'South';
      break;
    case 'W':
      seatName = 'West';
      break;
    default:
      seatName = '?';
      break;
  }
  return seatName;
};

export const getCommentsForCardOrBid = (cardOrBid) => {
  if (cardOrBid.comments === undefined) {
    return {
      pre: '',
      post: '',
      hint: ''
    };
  }
  return cardOrBid.comments;
};

// step is zero based
export const upsertAuctionComments = (auction, step, stepComments) => {
  auction.forEach((bid, index) => {
    if (index === step) {
      bid.comments = stepComments;
    }
  });
  return auction;
};

// step is zero based
export const upsertPlayComments = (play, step, stepComments) => {
  let index = 0;
  play.forEach(row => {
    row.forEach(card => {
      if (index === step) {
        card.comments = stepComments;
      }
      index += 1;
    });
  });
  return play;
};

export const getHeadingForAuction = (bid, row) => {
  return `${getRowOrdinal(row)} ${getSeatName(bid.seat)} bid: ${bid.value}`;
};

export const getHeadingForPlay = (play, row) => {
  return `${getRowOrdinal(row)} ${getSeatName(play.seat)} play: ${play.rank}${play.suit}`;
};

export const isCommentForViewpointOrDummy = (seat, viewpoint, dummy, declarer) => {
  let commentsForViewpointOrDummy = false;
  if (seat === viewpoint ||
    (seat === dummy && viewpoint === declarer)) {
    commentsForViewpointOrDummy = true;
  }
  return commentsForViewpointOrDummy;
};

export const formatComment = (comment, t) => {
  if (comment && comment !== '') {
    comment = highlightBidOrCardInComment(comment, t);
    comment = formatLinkUrl(comment);
  }
  return comment;
};

const getSymbolHtml = (suit, t) => {
  let symbol = '';
  let color = 'black';

  switch (suit.toUpperCase()) {
    case 'S':
      symbol = '&spades;';
      break;
    case 'H':
      symbol = '&hearts;';
      color = 'red';
      break;
    case 'C':
      symbol = '&clubs;';
      break;
    case 'D':
      symbol = '&diams;';
      color = 'red';
      break;
    case 'N':
      symbol = 'N';
      break;
    default:
      throw Error('highlightCardCode - suit not found');
  }
  let symbolHtml = '';
  if (symbol === 'N') {
    symbolHtml = `<strong>${t('n-no-trump')}</strong>`;
  } else {
    symbolHtml = `<span style="font-size: 1.8rem;">${symbol}</span>`;
  }

  return `<span style="color: ${color};">${symbolHtml}</span>`;
};

// suits S H C D
const suitPattern = '[SHCD]';
const suitRegex = new RegExp(suitPattern, 'i');
// card ranks 2-9, TJQKA, also supports ten written as 10
const rankPattern = '([23456789TJQKA]|10)';

// matches any number of ranks chained together with a -
// (e.g. the `T-9-8` in `HT-9-8`)
const rankOrRankSequencePattern = rankPattern + `(-${rankPattern})*`;
const rankOrRankSequenceRegex = new RegExp(rankOrRankSequencePattern, 'i');

// matches a card or card sequence in a comment
// (e.g. `H5` or `HK-Q-J`)
const cardRegex = new RegExp(
  // limit the regex to the beginning of the word
  // (so we match "ct" but not "act")
  '^' +
  suitPattern +
  rankOrRankSequencePattern +
  // limit the regex to the end of the word
  // (so we match "ca" but not "cat")
  '$',
  // case insensitive
  'i'
);

// bid levels 1-7
const bidLevelPattern = '[1234567]';
const bidLevelRegex = new RegExp(bidLevelPattern, 'i');
// card suits + N (no trump) - NT is also supported
const bidDenominationPattern = '([CDHSN]|NT)';
const bidDenominationRegex = new RegExp(bidDenominationPattern, 'i');

// matches a whole bid in a comment
// (e.g. `5D` or `7NT`)
const bidRegex = new RegExp(
  '^' +
  bidLevelPattern +
  bidDenominationPattern +
  '$',
  // case insensitive
  'i'
);

const highlightCardCode = (card, t) => {
  let rank = card.match(rankOrRankSequenceRegex)[0];
  rank = rank.toLowerCase();
  let suit = card.match(suitRegex)[0];
  suit = suit.toLowerCase();

  // if this is a sequence, split it along the -s,
  // translate each individual rank, then put back together
  // (this works for a single rank because the split() returns an array of 1)
  const translatedRankOrSequence = rank
    .split('-')
    .map(rank => t(getCardTextId(rank)))
    .join('-');

  return `${getSymbolHtml(suit, t)}<strong>${translatedRankOrSequence}</strong>`;
};

const highlightBidCode = (bid, t) => {
  const level = bid.match(bidLevelRegex)[0];
  let denomination = bid.match(bidDenominationRegex)[0];
  denomination = denomination.toLowerCase();

  return `<strong>${t(getCardTextId(level))}</strong>${getSymbolHtml(denomination, t)}`;
};

export const highlightBidOrCardInComment = (comment, t) => {
  // replace all &nbsp; with empty spaces
  comment = comment.replace(/(&nbsp;)/gm, ' ');
  // strip the html from the comment
  // replacing any stripped tags with a space
  // in case of a card surrounded fully by spaces
  let strippedComment = striptags(comment, [], ' ');
  // strip line breaks from comment
  strippedComment = strippedComment.replace(/(\r\n|\n|\r|&nbsp;)/gm, ' ');
  // split the strippped comment into words
  const wordsArray = strippedComment.split(' ');
  if (wordsArray.length === 0 && comment.length > 0) {
    wordsArray.push(comment);
  }
  wordsArray.forEach(word => {
    // trim certain characters that might be next to our bid or card
    const trimmedWord = word.trim()
      .replace(/,/g, '')
      .replace(/\./g, '')
      .replace(/!/g, '')
      .replace(/\?/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '');

    if (!trimmedWord) {
      return;
    }

    // see if it's a card
    let matches = trimmedWord.match(cardRegex);
    if (matches) {
      const card = matches[0];
      const highlightedCard = highlightCardCode(card, t);
      comment = comment.replace(trimmedWord, highlightedCard);
    }

    // see if it's a bid
    matches = trimmedWord.match(bidRegex);
    if (matches) {
      const bid = matches[0];
      const highlightedBid = highlightBidCode(bid, t);
      comment = comment.replace(trimmedWord, highlightedBid);
    }
  });
  return comment;
};

// add http to richtext links if its not been added
// e.g. <a href="bbc.co.uk">bbc></a> to <a href="http://bbc.co.uk">bbc></a>
export const formatLinkUrl = (comment) => {
  const wordsArray = comment.split(' ');
  wordsArray.forEach(word => {
    if (word.substring(0, 4) === 'href') {
      const url = word.substring(
        word.indexOf('"') + 1,
        word.lastIndexOf('"')
      );
      if (url.substring(0, 4) !== 'http') {
        const newUrl = `http://${url}`;
        comment = comment.replace(url, newUrl);
      }
    }
  });
  return comment;
};

export const getRandomWrongChoiceAnswer = (section) => {
  const messages = [
    'try-again',
    'try-a-different-{{cardOrBid}}',
    'choose-a-different-{{cardOrBid}}'
  ];

  // get a random number between 0 and messages.length - 1
  const randomNumber = _.random(0, messages.length - 1);

  const randomMessage = messages[randomNumber];

  return randomMessage.replace('{{cardOrBid}}', section === 'play' ? 'card' : 'bid');
};
