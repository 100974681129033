import React from 'react';
import { useParams } from 'react-router-dom';
import HandDetailsWrapper from './HandDetailsWrapper.js';
import HandDetails from './HandDetails.js';
import {
  getNextSetInCollection,
  getNextHandInSet,
  formatPathToHandPlay
} from '../../../../helpers/product.js';

const CollectionHandDetails = () => {
  const {
    collectionId,
    setId,
    handId,
    linkId,
    language,
    ...other
  } = useParams();

  const handlePlayNextClick = (
    collection,
    set,
    hand,
    isFinalHand,
    isFinalSet
  ) => () => {
    let nextSet = set;
    let nextHand;
    if (isFinalHand && !isFinalSet) {
      // is this the final hand in set but NOT the final set in collection
      // ... get next set and get first hand in that set
      nextSet = getNextSetInCollection(set.id, collection.sets);
      nextHand = nextSet.hands[0];
    } else {
      // ... keep the same set but get next hand
      nextHand = getNextHandInSet(hand.id, set.hands);
    }
    const pathToHand = formatPathToHandPlay(collectionId, nextSet, nextHand);
    window.location = pathToHand;
  };

  return (
    <HandDetailsWrapper
      collectionId={collectionId}
      setId={setId}
      handId={handId}
      linkId={linkId}
      language={language}
    >
      {({
        collection,
        set,
        hand,
        finalSetInCollection,
        finalHandInSet,
        finalHandInProduct
      }) => {
        return (
          <HandDetails
            collection={collection}
            set={set}
            hand={hand}
            linkId={linkId}
            isFinalHandInProduct={finalHandInProduct}
            onPlayNextClick={
              handlePlayNextClick(
                collection,
                set,
                hand,
                finalHandInSet,
                finalSetInCollection
              )
            }
            {...other}
          />
        );
      }}
    </HandDetailsWrapper>
  );
};

export default CollectionHandDetails;
