import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AuctionGrid from './AuctionGrid.js';
import { getContract } from '../../../../helpers/auction.js';

class Step1 extends React.Component {
  handlePlayOnlyChange = () => {
    const { hand } = this.props;
    let playOnly = !hand.playOnly;
    let contract = hand.contract;
    // if the there is an auciton (whole or part) work out the contract from that
    if (hand.auction.length > 0) {
      contract = getContract(hand.auction, hand.dealer);
    }
    // if the contract is empty set it to the default (which is shown in the UI anyway)
    if (_.isEmpty(contract)) {
      contract = {
        denomination: 'D',
        level: '1',
        risk: '',
        value: '1D'
      };
    }
    this.props.onChange({
      playOnly: playOnly,
      contract: contract
    });
  };

  handleAuctionOnlyChange = () => {
    this.props.onChange({
      auctionOnly: true
    });
  };

  render () {
    const { hand, ...other } = this.props;
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Switch
              checked={hand.playOnly}
              onChange={this.handlePlayOnlyChange}
              color='primary'
            />
          }
          label='Play only'
        />
        <AuctionGrid
          hand={hand}
          onMakeAuctionOnlyClick={this.handleAuctionOnlyChange}
          {...other}
        />
      </React.Fragment>
    );
  };
}

Step1.propTypes = {
  hand: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onUnavedComment: PropTypes.func.isRequired,
  onCommentsSaved: PropTypes.func.isRequired,
  onChangeViewpointClick: PropTypes.func.isRequired
}

export default Step1;
