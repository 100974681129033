import gql from 'graphql-tag';
import { dynamoProductFragments } from '../fragments';

const dynamoProductQueries = {
  DynamoProductSearch: gql`
    query (
      $orderBy: String!
      $orderByDirection: String!
      $locale: LocaleInput!
      $tags: [[String]]
      $pagination: PaginationInput
    ) {
      dynamoProductSearch (
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        locale: $locale
        tags: $tags
        pagination: $pagination
      ) {
        paginationKey
        products {
          description
          ...ListProduct
        }
      }
    }
    ${dynamoProductFragments.listProduct}
  `,
  DynamoPromotedProducts: gql`
    query (
      $locale: LocaleInput!
    ) {
      dynamoPromotedProducts (
        locale: $locale
      ) {
        products {
          ...ListProduct
        }
      }
    }
    ${dynamoProductFragments.listProduct}
  `,
  DynamoProductById: gql`
    query (
      $id: Int!,
      $language: LanguageEnum,
      $currency: CurrencyEnum
    ) {
      dynamoProductById (
        id: $id
        locale: {
          language: $language
          currency: $currency
        }
      ) {
        ...FullProduct
      }
    }
    ${dynamoProductFragments.fullProduct}
  `,
  DynamoProductByLinkId: gql`
    query (
      $linkId: String!,
      $locale: LocaleInput!
    ) {
      dynamoProductByLinkId (
        linkId: $linkId
        locale: $locale
      ) {
        ...FullProduct
      }
    }
    ${dynamoProductFragments.fullProduct}
  `
};

export default dynamoProductQueries;
