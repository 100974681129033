import React from 'react';
import MetaTags from 'react-meta-tags';
import { build } from '../helpers/env.js';

const RobotsMetaTag = () => {
  if (build === 'LIVE') {
    return (
      <MetaTags>
        <meta name='robots' content='index,follow' />
      </MetaTags>
    );
  } else {
    return (
      <MetaTags>
        <meta name='robots' content='noindex,nofollow' />
      </MetaTags>
    );
  }
};

export default RobotsMetaTag;
