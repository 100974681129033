import _ from 'lodash';

const bidRankings = [
  { value: 'C', rank: 0 },
  { value: 'D', rank: 1 },
  { value: 'H', rank: 2 },
  { value: 'S', rank: 3 },
  { value: 'N', rank: 4 }
];

const seatRankings = [
  { value: 'W', rank: 0 },
  { value: 'N', rank: 1 },
  { value: 'E', rank: 2 },
  { value: 'S', rank: 3 }
];

export const convertSeatCodeToFullWord = (seat) => {
  switch (seat) {
    case 'N':
      return 'North';
    case 'E':
      return 'East';
    case 'S':
      return 'South';
    case 'W':
      return 'West';
    default:
      throw new Error('convertSeatCodeToFullWord - seat must be N, S, E or W. Supplied: ' + seat);
  }
};

export const isHigherThanCurrentBid = (auction, dealer, option) => {
  let currentBid = lastBidInAuction(auction, dealer, ['Pass', 'X', 'XX']);
  if (currentBid !== undefined) {
    currentBid = currentBid.bid.value;
    const optionLevel = parseInt(option.substring(0, 1));
    const currentLevel = parseInt(currentBid.substring(0, 1));
    if (optionLevel > currentLevel) {
      return true;
    } else if (optionLevel === currentLevel) {
      const optionTrump = option.substring(1, 2);
      const currentTrump = currentBid.substring(1, 2);
      const optionRank = _.find(bidRankings, { value: optionTrump });
      const currentRank = _.find(bidRankings, { value: currentTrump });
      if (optionRank.rank > currentRank.rank) {
        return true;
      }
    }
    return false;
  }
  return true;
};

export const convertBidsToTableRows = (bids, startDirection) => {
  const rows = [];
  let row = [];

  // depending on the startdirection we need to fill some blanks
  const rank = _.find(seatRankings, { value: startDirection }).rank;
  // the rank will equal the number of blanks to add
  for (let i = 0; i < rank; i++) {
    row.push('');
  }
  // loop through bids adding them to rows sequentially
  bids.forEach(bid => {
    row.push(bid.value);
    if (row.length === 4) {
      rows.push(row);
      row = [];
    }
  });
  // pad last row with blanks
  const numberOfBidsToPad = (4 - row.length);
  for (let i = 0; i < numberOfBidsToPad; i++) {
    row.push('');
  }
  rows.push(row);

  return rows;
};

// dealer bids first so we can work out seat from that
// note: step IS ZERO BASED
export const getSeatForStep = (step, dealer) => {
  // first make sure step is a number
  step = parseInt(step);

  // then get index of dealer in seatRanks
  const dealerIndex = _.findIndex(seatRankings, { value: dealer });

  // move {step} positions on from dealer
  // so if step = 3 and dealer = W
  // we go W, N, E... so seat is east

  // loop through seatRankings for {step} times
  let seatPos = dealerIndex;
  for (let i = 0; i < step; i++) {
    // add 1 to seatPos
    seatPos = seatPos + 1;
    // if seatPos goes beyond 3 (W), go back to 0 (N)
    if (seatPos > 3) {
      seatPos = 0;
    }
  }
  return seatRankings[seatPos].value;
};

export const getSeatForBid = (auction, bid, dealer) => {
  // THIS ONLY FOR UNIQUE BIDS (not Pass, X, or XX)
  // dealer makes the first bid so we can work out the seat for a given bid
  // bid 1 = dealer

  // first get index of bid in auction
  const bidIndex = _.findIndex(auction, { value: bid });

  // first get index of dealer in seatRanks
  const dealerIndex = _.findIndex(seatRankings, { value: dealer });

  // move {bidIndex} positions on from dealer
  // so if bidIndex = 3 and dealer = switch
  // we go W, N, E... so seat is east

  // loop through seatRankings for {bidIndex} times
  let seatPos = dealerIndex;
  for (let i = 0; i < bidIndex; i++) {
    // add 1 to seatPos
    seatPos = seatPos + 1;
    // if seatPos goes beyond 3 (W), go back to 0 (N)
    if (seatPos > 3) {
      seatPos = 0;
    }
  }
  return seatRankings[seatPos].value;
};

export const lastBidInAuction = (auction, dealer, exclusions) => {
  const bids = auction.filter(bid => {
    // return bid !== 'Pass' && bid !== 'AP';
    return exclusions.indexOf(bid.value) === -1;
  });
  if (bids.length > 0) {
    // return last bid in array
    const lastBid = bids[bids.length - 1];
    const whoMadeLastBid = getSeatForStep(
      auction.lastIndexOf(lastBid),
      dealer
    );
    return {
      bid: lastBid,
      by: whoMadeLastBid
    };
  }
  return undefined;
};

export const getPairForSeat = (seat) => {
  if (seat === 'N' || seat === 'S') {
    return 'N/S';
  }
  return 'E/W';
};

export const doubleOption = (auction, dealer) => {
  // can the next bid be double or redouble?
  const lastBid = lastBidInAuction(auction, dealer, ['Pass']);
  if (lastBid !== undefined) {
    const pairForLastBId = getPairForSeat(lastBid.by);
    const seatOfNextBid = getSeatForStep(auction.length, dealer);
    const pairForNextBid = getPairForSeat(seatOfNextBid);
    const nextBidIsSamePair = pairForLastBId === pairForNextBid;

    // if the last bid was a redouble all double options are off the table
    if (lastBid.bid.value === 'XX') {
      return '';
    }

    // if the last bid was by the same pair as this bid then all double options are off the table
    if (nextBidIsSamePair) {
      return '';
    }

    // you can only redouble if the previous was a double and it wasn't same pair
    if (lastBid.bid.value === 'X' &&
      !nextBidIsSamePair) {
      return 'XX';
    }

    // you can only double if the current high bid is made by your opponents
    if (!nextBidIsSamePair) {
      return 'X';
    }
  }
  return '';
};

export const getContractValue = (level, denomination, risk) => {
  level = level || '';
  denomination = denomination || '';
  risk = risk || '';
  return `${level}${denomination}${risk}`;
};

export const getContract = (auction, dealer) => {
  const finalBid = lastBidInAuction(auction, dealer, ['Pass', 'X', 'XX']);
  if (finalBid !== undefined) {
    const possibleDouble = lastBidInAuction(auction, dealer, ['Pass']);
    let risk = '';
    if (finalBid.bid.value !== possibleDouble.bid.value) {
      risk = possibleDouble.bid.value;
    }
    // return finalBid;
    const contract = {
      value: getContractValue(finalBid.bid.level, finalBid.bid.denomination, risk),
      level: finalBid.bid.level,
      denomination: finalBid.bid.denomination,
      risk
    };
    return contract;
  }
  return undefined;
};

export const whoIsDeclarer = (auction, dealer) => {
  // whoever in that pair first mentions the suit of the final bid
  const finalBid = lastBidInAuction(auction, dealer, ['Pass', 'X', 'XX']);
  if (finalBid !== undefined) {
    const finalSuit = finalBid.bid.denomination;
    const finalPair = getPairForSeat(finalBid.by);

    // get all bids that have that suit
    const bids = auction.filter(bid => {
      return bid.denomination === finalSuit;
    });

    // loop through bids and the maker of the fist bid thats in the finalbids pair will be declarer
    for (let i = 0; i < bids.length; i++) {
      const bidSeat = getSeatForBid(auction, bids[i].value, dealer);
      const bidPair = getPairForSeat(bidSeat);
      if (bidPair === finalPair) {
        return bidSeat;
      }
    }
    throw new Error('Could not work out declarer');
  }
  return undefined;
};

export const whoIsDummy = (declarer) => {
  switch (declarer) {
    case 'N':
      return 'S';
    case 'E':
      return 'W';
    case 'S':
      return 'N';
    case 'W':
      return 'E';
    default:
      throw new Error('whoIsDummy - declarer must be N, S, E or W. Supplied: ' + declarer);
  }
};

export const wereLastThreeBidsAllPass = (auction) => {
  if (auction.length > 2) {
    // get last 3 bids
    const last3 = _.takeRight(auction, 3);
    // get all bids that are not 'Pass'
    const whereNotPass = _.pullAllBy(last3, [{ value: 'Pass' }], 'value');

    // if they are all 'Pass' then its ended
    if (whereNotPass.length === 0) {
      return true;
    }
  }
  return false;
};

export const hasEnded = (auction) => {
  // if the last 3 bids are all 'Pass' then the auction has ended
  // except when they are the first 3 bids of the auction
  // so allow 3 passes if no contract has been made
  const finalBid = lastBidInAuction(auction, 'N', ['Pass', 'X', 'XX']);
  if (finalBid !== undefined) {
    return wereLastThreeBidsAllPass(auction);
  }
  return false;
};

export const noOfRowsInTable = (auction) => {
  return Math.ceil(auction.length / 4);
};

export const getStepNumberFromTable = (rows, rowIndex, cellIndex) => {
  let blankCellsAtStart = 0;
  for (let i = 0; i < rows[0].length; i++) {
    if (rows[0][i] !== '') {
      break;
    }
    blankCellsAtStart += 1;
  }
  return (rowIndex * 4) + (cellIndex - blankCellsAtStart);
};

/* convert bid value to a bid object
i.e. 1C to
{
  "value": "1C",
  "level": "1",
  "denomination": "C"
}
*/
export const convertBidValueToObject = (value) => {
  const bidObject = {
    value
  };
  if (value !== 'Pass' && value !== 'X' && value !== 'XX') {
    bidObject.level = value.substring(0, 1);
    bidObject.denomination = value.replace(bidObject.level, '');
  }
  return bidObject;
};
