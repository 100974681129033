import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { RouterProvider } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { getEnvVar } from './helpers/env.js';
import router from './Router.js';
// importing this kicks off i18next
import './i18next.js';

// set the app version and build as a global vars (can be accessed through console)
global.appVersion = process.env.REACT_APP_VERSION;
global.appBuild = process.env.REACT_APP_ENV;

// init sentry
Sentry.init({
  dsn: getEnvVar('SENTRY_URL'),
  environment: process.env.REACT_APP_ENV.toLowerCase(),
  release: process.env.REACT_APP_VERSION,
  whitelistUrls: [/(?:[a-z]+\.)?bridgebee\.app/],
  ignoreErrors: ['Network error: cancelled'] // 'Network error: Failed to fetch',
});

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
