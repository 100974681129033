import React, { useState } from 'react';
import { ButtonGroup, Popover, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ToolBarButton from './ToolBarButton.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    boxShadow: theme.boxShadow,
    padding: '1rem',
    marginLeft: '-1rem',
    marginTop: '0.5rem'
  },
  inputContainer: {
    marginRight: '1rem'
  },
  input: {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem'
    }
  }
}));

const LinkPopover = (props) => {
  const { anchorEl, initialUrl, onClose, onSave } = props;
  const [url, setUrl] = useState(initialUrl || '');
  const classes = useStyles();

  const handleUrlChange = (e) => {
    setUrl(e.currentTarget.value);
  };

  const handleSave = () => {
    onSave(url);
    setUrl('');
    onClose();
  };

  return (
    <Popover
      classes={{ paper: classes.container }}
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <div className={classes.inputContainer}>
        <TextField
          classes={{ root: classes.input }}
          className={classes.input}
          value={url}
          id='link-url'
          name='link-url'
          aria-label='Link URL'
          size='small'
          hiddenLabel
          placeholder='Please enter the link URL'
          onChange={handleUrlChange}
        />
      </div>
      <ButtonGroup>
        <ToolBarButton
          IconComponent={CheckIcon}
          onClick={handleSave}
          disabled={!url}
        />
        <ToolBarButton
          IconComponent={CloseIcon}
          onClick={onClose}
        />
      </ButtonGroup>
    </Popover>
  );
};

export default LinkPopover;
