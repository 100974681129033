import React, { useState, useEffect, useCallback, useContext } from 'react';
import { isAuthenticated } from '../helpers/auth.js';
import { UserContext } from '../Context/UserContext.js';
import {
  fetchLocaleDataBasedOnIP,
  selectLanguageBasedOnLocation,
  selectCurrencyBasedOnLocation,
  convertGraphQLLanguageName,
  manageLocaleCookie,
  convertLangCodeToGraphqlEnum
} from '../helpers/locale.js';
import { useTranslation } from 'react-i18next';

const LocaleContext = React.createContext();

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(null);
  const { i18n } = useTranslation();
  const [languageLoaded, setLanguageLoaded] = useState(false);

  // getting user from UserContext this way prevents uncaught errors
  let user, setUser, saveUser;
  const userContext = useContext(UserContext);
  if (userContext) {
    user = userContext.user;
    setUser = userContext.setUser;
    saveUser = userContext.saveUser;
  }

  const persist = ({ language, currency }) => {
    setLocale({ language, currency });
    manageLocaleCookie('write', { language, currency });
  };

  useEffect(() => {
    // declare function to check user location
    async function fetchData () {
      let language;
      let currency;
      try {
        // 1. check if there is a locale cookie
        // 2. check if the is user is logged in and the user object has been set
        // 3. neither of the above 2 apply so get locale from IP
        const localeCookie = manageLocaleCookie('read');
        if (localeCookie) {
          ({ language, currency } = localeCookie);
        } else if (isAuthenticated() && user) {
          // get locale info from user
          language = convertGraphQLLanguageName(user.language);
          currency = user.currency;
        } else {
          // not logged in so use location API to get locale data
          const apiResponse = await fetchLocaleDataBasedOnIP();
          language = selectLanguageBasedOnLocation(
            apiResponse.country_code
          );
          currency = selectCurrencyBasedOnLocation(
            apiResponse.country_code,
            apiResponse.continent_code
          );
        }
        // set state if language and currency have been set
        // if any of them weren't set for whatever reason,
        // fall back to these
        language = language || 'en-gb';
        currency = currency || 'USD';
        persist({ language, currency });

        // set UI language
        await i18n.changeLanguage(language);
        setLanguageLoaded(true);
      } catch (err) {
        console.error('error setting locale', err);
        persist({ language: 'en-gb', locale: 'USD' });
        i18n.changeLanguage(language);
      }
    }

    if (!languageLoaded) {
      fetchData();
    }
  }, [user, i18n, languageLoaded]);

  const setLanguage = useCallback(async (language) => {
    // write the locale to the cookie (source of truth)
    persist({ ...locale, language });

    // change the language in i18 so the translations work
    i18n.changeLanguage(language);

    // if logged in call saveUser from UserContext to save language in db
    if (user) {
      const updatedUser = {
        ...user,
        language: convertLangCodeToGraphqlEnum(language)
      };
      setUser(updatedUser);
      await saveUser(updatedUser);
    }
  }, [user, saveUser, setUser, locale, i18n]);

  const setCurrency = useCallback(async (currency) => {
    // write the locale to the cookie (source of truth)
    persist({ ...locale, currency });

    // if logged in call saveUser from UserContext to save language in db
    if (user) {
      const updatedUser = {
        ...user,
        currency
      };
      setUser(updatedUser);
      await saveUser(updatedUser);
    }
  }, [user, setUser, saveUser, locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLanguage, setCurrency }}>
      {languageLoaded && children}
    </LocaleContext.Provider>
  );
};

export { LocaleContext, LocaleProvider };
