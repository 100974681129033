import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import BBTranslate from '../Locale/BBTranslate.js';

const BBSubmit = props => {
  const { disabled, isLoading, label, onClick, className } = props;
  const handleClick = useCallback((e) => {
    e.preventDefault();
    onClick && onClick();
  }, [onClick]);

  return (
    <Button
      type='submit'
      fullWidth
      variant='contained'
      color='secondary'
      className={className}
      disabled={disabled || false}
      onClick={onClick && handleClick}
    >
      {isLoading
        ? <CircularProgress size={24} />
        : <BBTranslate textId={label} />}
    </Button>
  );
};

BBSubmit.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default BBSubmit;
