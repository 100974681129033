import React from 'react';
import PropTypes from 'prop-types';
import { collectionQueries } from '../../graphql/queries';
import { Query } from '@apollo/react-components';
import BBErrorBoundary from '../Errors/BBErrorBoundary.js';

const CollectionById = (props) => {
  const { children, id } = props;
  return (
    <BBErrorBoundary>
      <Query
        query={collectionQueries.CollectionById(id)}
        fetchPolicy='no-cache'
        notifyOnNetworkStatusChange
      >
        {({ data, error, loading, refetch }) => {
          if (error) {
            throw error;
          }
          return children(data, loading, refetch);
        }}
      </Query>
    </BBErrorBoundary>
  );
};

CollectionById.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default CollectionById;
