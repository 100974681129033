import React from 'react';
import PropTypes from 'prop-types';
import { authorQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class Authors extends React.Component {
  render () {
    const { children } = this.props;
    return (
      <BBQuery query={authorQueries.Authors}>
        {data => {
          return children(data.authors);
        }}
      </BBQuery>
    );
  }
}

Authors.propTypes = {
  children: PropTypes.func.isRequired
};

export default Authors;
