import React, { useEffect, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { dynamoProductQueries } from '../../../../graphql/queries';
import { convertLangCodeToGraphqlEnum } from '../../../../helpers/locale.js';
import Product from './Product.js';
import { LocaleContext } from '../../../../Context';
import ProductSkeleton from '../../../../Components/Skeletons/ProductSkeleton';

// need to wrap <Product> so we can conditionally call the query
// i.e. if we try to get locale in <Product> its gonna be null (initially) when
// we try and call the query
const ProductWrapper = () => {
  const { id, language } = useParams();

  const { locale } = useContext(LocaleContext);

  const [product, setProduct] = useState(null);

  const [getProduct] = useLazyQuery(dynamoProductQueries.DynamoProductById);

  useEffect(() => {
    if (locale) {
      const currency = locale.currency;
      // if the language is passed in as a QS param, use that
      // if no QS param, use the locales language
      let theLanguage = language;
      if (!theLanguage) {
        theLanguage = locale.language;
      }
      getProduct({
        variables: {
          id: parseInt(id),
          language: convertLangCodeToGraphqlEnum(theLanguage),
          currency
        },
        onCompleted: (data) => {
          setProduct(data.dynamoProductById);
        }
      });
    }
  }, [getProduct, id, setProduct, locale, language]);

  if (!product) {
    return <ProductSkeleton />;
  }

  return (<Product locale={locale} product={product} />);
};

export default ProductWrapper;
