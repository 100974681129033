import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SearchBar from './SearchBar.js';
import {
  Link
} from 'react-router-dom';
// import LanguageSelector from '../../Locale/LanguageSelector.js';
import clsx from 'clsx';
import BBTranslate from '../../Locale/BBTranslate.js';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    padding: 0
  },
  link: {
    color: theme.palette.styleguide.maroon,
    fontSize: '2.4rem',
    lineHeight: '3.8rem',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    }
  },
  mainNavLink: {
    fontWeight: 700
  }
}));

const HamburgerMenu = (props) => {
  const { tabs, accountTabs, onTabClick, onSearchSubmit } = props;
  const classes = useStyles();

  return (
    <>
      <SearchBar
        onSearchSubmit={onSearchSubmit}
      />
      <ul className={classes.list}>
        {tabs.map((tab, i) => (
          <li key={i}>
            <Link
              className={clsx(classes.link, classes.mainNavLink)}
              to={tab.path}
              onClick={() => { onTabClick(tab, i); }}
            >
              <BBTranslate textId={tab.label} />
            </Link>
          </li>
        ))}
      </ul>
      {/* <LanguageSelector /> */}
      <ul className={classes.list}>
        {accountTabs.map((tab, i) => (
          <li key={i}>
            <Link
              className={clsx(classes.link)}
              to={tab.path}
              onClick={() => { onTabClick(tab); }}
            >
              {tab.label}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

HamburgerMenu.propTypes = {
  tabs: PropTypes.array.isRequired,
  accountTabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired
};

export default HamburgerMenu;
