import React, { useContext } from 'react';
import {
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CartItem from './CartItem.js';
import { CartContext, DrawerContext } from '../../../Context/index.js';
import StoreButton from '../../Forms/Store/StoreButton.js';
import Price from '../Price.js';
import GreyLine from '../GreyLine.js';
import BBTranslate from '../../Locale/BBTranslate.js';

const Cart = () => {
  const { cart, getTotal, removeFromCart, currency } = useContext(CartContext);
  const { closeDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();

  const handleCheckoutClick = () => {
    closeDrawer();
    navigate('/checkout');
  };

  return (
    <>
      {
        cart && cart.items && cart.items.length > 0
          ? (
            <>
              {
                cart.items.map((item, index) => (
                  <span key={index}>
                    <CartItem
                      item={item}
                      onRemoveClick={removeFromCart}
                      currency={currency}
                    />
                    <GreyLine />
                  </span>
                ))
              }
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <Typography variant='body1'>
                  <BBTranslate textId='total' />
                </Typography>
                <Price variant='h4' price={getTotal()} />
              </div>
              <StoreButton onClick={handleCheckoutClick} label='checkout' fullWidth />
            </>
            )
          : (
            <div>
              <BBTranslate textId='basket-is-empty' />
            </div>
            )
      }
    </>
  );
};

export default Cart;
