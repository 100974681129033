import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  '@keyframes dotOne': {
    '0%, 50%': {
      opacity: 0.4
    },
    '25%': {
      opacity: 1
    }
  },
  '@keyframes dotTwo': {
    '25%, 75%': {
      opacity: 0.4
    },
    '50%': {
      opacity: 1
    }
  },
  '@keyframes dotThree': {
    '50%, 100%': {
      opacity: 0.4
    },
    '75%': {
      opacity: 1
    }
  },
  bubble: {
    overflow: 'hidden',
    padding: '0.6rem 0'
  },
  dot: {
    height: '1rem',
    width: '1rem',
    borderRadius: '100%',
    float: 'left',
    margin: '0 0.25rem',
    background: '#999',
    opacity: '0.4',
    animation: '$dotTwo 1.1s linear infinite',
    '&:first-child': {
      animationName: '$dotOne'
    },
    '&:last-child': {
      animationName: '$dotThree'
    }
  }
};

class WaitingMessage extends React.Component {
  state = {
    visible: true
  };

  componentDidMount () {
    const { delayInMS, onTimeoutComplete } = this.props;
    setTimeout(() => {
      this.setState({
        visible: false
      });
      onTimeoutComplete();
    }, delayInMS);
  }

  render () {
    const { classes, position } = this.props;
    const { visible } = this.state;
    if (position === 'left' && visible) {
      return (
        <div className={classes.bubble}>
          <div className={classes.dot}></div>
          <div className={classes.dot}></div>
          <div className={classes.dot}></div>
        </div>
      );
    }
    return null;
  }
}

WaitingMessage.propTypes = {
  delayInMS: PropTypes.number.isRequired,
  position: PropTypes.string.isRequired,
  onTimeoutComplete: PropTypes.func.isRequired
};

export default withStyles(styles)(WaitingMessage);
