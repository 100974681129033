import React from 'react';
import PropTypes from 'prop-types';
import WaitingMessage from './WaitingMessage.js';
import MessageWrapper from './MessageWrapper.js';

class Message extends React.Component {
  state = {
    waiting: true
  };

  // https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react
  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleTimeoutComplete = () => {
    this.setState({
      waiting: false
    });
    this.scrollToBottom();
  }

  componentDidUpdate = () => {
    this.scrollToBottom();
  }

  render () {
    const {
      classes,
      children,
      position,
      waitingMessageDelay,
      ...other
    } = this.props;
    const { waiting } = this.state;
    return (
      <MessageWrapper
        position={position}
        {...other}
      >
        {
          waiting && position === 'left'
          ?
          <WaitingMessage
            delayInMS={waitingMessageDelay}
            position={position}
            onTimeoutComplete={this.handleTimeoutComplete}
          />
          :
          children
        }
        <div ref={(el) => { this.messagesEnd = el; }}></div>
      </MessageWrapper>
    );
  }
};

Message.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired,
  author: PropTypes.object,
  waitingMessageDelay: PropTypes.number.isRequired
};

export default Message;
