const useScrollToTop = () => {
  const scrollToTop = () => {
    if (document.documentElement && document.documentElement.scrollTo) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  };

  return {
    scrollToTop
  };
};

export default useScrollToTop;
