import React from 'react';
import PropTypes from 'prop-types';
import { purchaseStatQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class PurchaseStats extends React.Component {
  render () {
    const { children, dateFrom, dateTo } = this.props;
    const vars = {
      dateFrom,
      dateTo
    };
    return (
      <BBQuery query={purchaseStatQueries.PurchaseStats} variables={vars}>
        {data => {
          return children(data.purchaseStats);
        }}
      </BBQuery>
    );
  }
}

PurchaseStats.propTypes = {
  children: PropTypes.func.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired
};

export default PurchaseStats;
