import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Paper
} from '@mui/material';
import PurchaseDetails from './PurchaseDetails.js';
import PurchaseById from '../../../Components/Queries/PurchaseById.js';
import PurchaseItems from './PurchaseItems.js';

const PurchasePage = () => {
  const { id } = useParams();

  return (
    <PurchaseById id={parseInt(id)}>
      {data => (
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={2}
        >
          <Grid item xs={6}>
            <Paper>
              <PurchaseDetails
                purchase={data}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ p: 2, m: 3 }}>
              <PurchaseItems
                items={data.purchaseItems}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </PurchaseById>
  );
};

export default PurchasePage;
