import useScreenDimensions from './useScreenDimensions.js';

const useAspectRatio = () => {
  // https://28b.co.uk/ios-device-dimensions-reference-table/

  const screenDimensions = useScreenDimensions();
  const width = screenDimensions.width;
  const height = screenDimensions.height;

  let ratio = height / width;
  ratio = ratio.toFixed(2).toString();

  let aspectRatio = '';

  switch (ratio) {
    case '1.33':
      // ipad
      aspectRatio = '3:4';
      break;
    case '2.17':
      // newer iphones, i.e. x, 11, 12
      aspectRatio = '9:19.5';
      break;
    case '1.78':
      // iphone 6, 7, 8
      aspectRatio = '9:16';
      break;
    default:
      break;
  }

  return aspectRatio;
};

export default useAspectRatio;
