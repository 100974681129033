import React, { useState } from 'react';

import {
  Fab
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull.js';

export default function ViewHandsFOB ({ expanded, onClick }) {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    if (onClick) {
      setClicked(true);
      onClick();
      setTimeout(() => {
        setClicked(false);
      }, 500);
    }
  };
  if (clicked) {
    return null;
  }
  return (
    <Fab
      onClick={handleClick}
      variant='extended'
      color='secondary'
      size='small'
      sx={(theme) => ({ 
        width: 150,
        position: 'absolute',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        bottom: -25,
        background: theme.palette.common.grey,
        '&:hover': {
          background: theme.palette.common.lightGrey
        }
      })}
    >
      <OpenInFullIcon />
      <span style={{ marginLeft: 10}}>
        {
          expanded ? 'Hide hands' : 'View hands'
        }
      </span>
    </Fab>
  )
}