import React from 'react';
import ErrorPage from '../../Components/Errors/ErrorPage.js';

const Error401 = ({ location }) => (
  <ErrorPage
    heading={['401', 'session expired']}
    text='Please login again'
  />
);

export default Error401;
