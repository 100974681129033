import React from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
// import { mutations } from '../../../../graphql';
import { BBSnackbarConsumer } from '../../../../Components/BBSnackbar/BBSnackbarContext.js';
import CollectionById from '../../../../Components/Queries/CollectionById.js';
import Collection from './Collection.js';
import { convertCollectionSetsToSets } from '../../../../helpers/product.js';

const CollectionContainer = ({ collectionId, ...other }) => {
  return (
    <CollectionById id={collectionId}>
      {(data) => {
        if (data) {
          return (
            <BBSnackbarConsumer>
              {({ openSnackbar }) => (
                <Collection
                  collectionId={collectionId}
                  sets={data ? convertCollectionSetsToSets(data.collectionById.collectionSets) : []}
                  openSnackbar={openSnackbar}
                  {...other}
                />
              )}
            </BBSnackbarConsumer>
          );
        }
        return null;
      }}
    </CollectionById>
  );
};

CollectionContainer.propTypes = {
  collectionId: PropTypes.number.isRequired,
  apolloClient: PropTypes.object.isRequired
};

export default CollectionContainer;
