import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import BBTranslate from './Locale/BBTranslate.js';

const SearchChip = (props) => {
  const { active, label, name, onDelete, className } = props;
  if (active) {
    return (
      <Chip
        label={<BBTranslate textId={label} />}
        onDelete={() => {
          onDelete(name);
        }}
        className={className}
        color='secondary'
      />
    );
  } else {
    return null;
  }
};

SearchChip.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default SearchChip;
