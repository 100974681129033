import React from 'react';
import { makeStyles } from '@mui/styles';
import Container from '../Container.js';
import { Grid, Typography } from '@mui/material';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';
import HowItWorksItem from './HowItWorksItem.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  howItWorksContainer: {
    paddingBottom: '14rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      paddingBottom: 0
    }
  },
  howItWorksHeading: {
    marginBottom: '4rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem'
    }
  },
  paragraph: {
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem'
    }
  },
  howItWorksImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  itemsContainer: {
    marginBottom: '6rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0
    }
  },
  item: {
    '&:first-child': {
      borderLeft: 0
    },
    paddingRight: '4rem',
    borderLeft: '1px #A09896 solid',
    [theme.breakpoints.down('md')]: {
      borderLeft: 0,
      paddingRight: 16,
      '& > div': {
        paddingBottom: '4rem',
        paddingTop: '4rem',
        borderBottom: '1px #A09896 solid'
      },
      '&:last-child > div': {
        borderBottom: 0,
        paddingBottom: 0
      }
    }
  },
  button: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const HowItWorks = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // TODO verify this behaviour is what we want
  const handleStartClick = () => {
    const elem = document.querySelector('#hands');
    elem && elem.scrollIntoView();
  };

  return (
    <Container>
      <Grid
        container
        spacing={{ xs: 2, md: 0 }}
        className={classes.howItWorksContainer}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant='h1'
            component='h2'
            className={classes.howItWorksHeading}
          >
            {t('how-bridgebee-works')}
          </Typography>
          <Typography variant='body4' component='p' className={classes.paragraph}>
            {t('play-thousands-of-hands')}
          </Typography>
          <Typography variant='body4' component='p' className={classes.paragraph}>
            {t('our-authors-guide-you-through-each-hand')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* TODO link to video */}
          <img
            className={classes.howItWorksImage}
            alt=''
            src='img/components/sales/portrait-iphone.jpg'
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.itemsContainer}
        columnSpacing={{ xs: 2, md: 5 }}
        rowSpacing={0}
      >
        <Grid className={classes.item} item xs={12} md={4}>
          <HowItWorksItem
            heading={t('select-your-level')}
            body={t('bridgebee-grows-with-you')}
            icon='stack'
          />
        </Grid>
        <Grid className={classes.item} item xs={12} md={4}>
          <HowItWorksItem
            heading={t('choose-your-topic')}
            body={t('need-to-work-on-defense')}
            icon='cards'
          />
        </Grid>
        <Grid className={classes.item} item xs={12} md={4}>
          <HowItWorksItem
            heading={t('form-the-right-habits')}
            body={t('bridgebee-authors-guide-you-toward')}
            icon='logo'
          />
        </Grid>
      </Grid>
      <div className={classes.button}>
        <StoreButton
          onClick={handleStartClick}
          label='start-now'
        />
      </div>
    </Container>
  );
};

export default HowItWorks;
