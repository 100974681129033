import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import withStyles from '@mui/styles/withStyles';
import BBTranslate from '../Locale/BBTranslate.js';

const styles = theme => ({
  root: {
    width: '4rem',
    background: 'none',
    userSelect: 'none',
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  table: {
    border: 'none'
  },
  cell: {
    border: 'none',
    padding: 0
  }
});

const TrickCounts = (props) => {
  const { classes, tricks, size } = props;
  const style = {
    color: size && size === 'large' ? '#fff' : '#000'
    // fontSize: size && size === 'large' ? 14 : null
  };
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell align='left' className={classes.cell} style={style}>
              <BBTranslate textId='ns-north-south' />:
            </TableCell>
            <TableCell align='right' className={classes.cell} style={style}>
              {tricks.northSouth ? tricks.northSouth : 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left' className={classes.cell} style={style}>
              <BBTranslate textId='ew-east-west' />:
            </TableCell>
            <TableCell align='right' className={classes.cell} style={style}>
              {tricks.eastWest ? tricks.eastWest : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

TrickCounts.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  tricks: PropTypes.object.isRequired
};

export default withStyles(styles)(TrickCounts);
