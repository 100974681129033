import React from 'react';
import { SvgIcon } from '@mui/material';

const Website = (props) => {
  return (
    <SvgIcon {...props} height='100' viewBox='0 0 100 100' width='100'>
      <path d='m75.14375 46.625-4.89375-4.890375v-11.984625c0-1.85625-1.51875-3.375-3.375-3.375h-3.375c-1.85625 0-3.375 1.51875-3.375 3.375v1.866375l-6.75-6.74325c-.921375-.87075-1.765125-1.873125-3.375-1.873125s-2.453625 1.002375-3.375 1.873125l-21.76875 21.751875c-1.053 1.096875-1.85625 1.89675-1.85625 3.375 0 1.900125 1.458 3.375 3.375 3.375h3.375v20.25c0 1.85625 1.51875 3.375 3.375 3.375h10.125v-16.875c0-1.85625 1.51875-3.375 3.375-3.375h6.75c1.85625 0 3.375 1.51875 3.375 3.375v16.875h10.125c1.85625 0 3.375-1.51875 3.375-3.375v-20.25h3.375c1.917 0 3.375-1.474875 3.375-3.375 0-1.47825-.80325-2.278125-1.85625-3.375z' />
    </SvgIcon>
  );
};

export default Website;
