import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MiniCard from '../../../Components/Hands/MiniCard/MiniCard.js';
import CommentForm from './CommentForm.js';
import { stripHtml } from '../../../helpers/global.js';

const styles = theme => ({
  buttons: {
    marginTop: theme.spacing(2)
  },
  deleteButton: {
    marginRight: theme.spacing(2)
  },
  paper: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    height: 323,
    textAlign: 'center',
    width: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(6),
    outline: 'none'
  },
});

class CommentsModal extends React.Component {
  showDelete = true;
  constructor (props) {
    super(props);
    const { comments } = props;
    if (comments && comments.pre === '' && comments.post === '' && comments.hint === '') {
      this.showDelete = false;
    }
  }

  getCharCount = (value) => {
    return stripHtml(value).length;
  };

  getCharCountForAllComments = (comments) => {
    let totalChars = 0;
    if (comments) {
      totalChars = this.getCharCount(comments.pre) +
        this.getCharCount(comments.post) +
        this.getCharCount(comments.hint);
    }
    return totalChars;
  };

  state = {
    totalCharCount: this.getCharCountForAllComments(this.props.comments)
  };

  handleCommentChange = (input) => {
    const { onCommentChange, comments } = this.props;
    let totalCharCount = 0;

    // get the char count of the comment we are editing
    totalCharCount += this.getCharCount(input.value);

    // loop through each of the comments and add up the char counts of the
    // 2 comments that are not the one we are currently editing
    const keys = Object.keys(comments);
    keys.forEach(key => {
      if (key !== input.name && key !== 'step') {
        // && key !== 'step' is there for legacy hands (v1.0 format)
        // if we don't ignore this attribute it causes an error #416
        if (comments[key]) {
          totalCharCount += this.getCharCount(comments[key]);
        }
      }
    });

    this.setState({
      totalCharCount: totalCharCount
    });
    onCommentChange(input)
  };

  render () {
    const {
      classes,
      commentsForViewpointOrDummy,
      onSubmitClick,
      onCancelClick,
      onDeleteClick,
      comments,
      heading,
      card,
      bid,
      open
    } = this.props;
    const { totalCharCount } = this.state;

    let commentFields = [
      {
        name: 'pre',
        label: `Pre-${card ? 'play' : 'bid'} comment`
      },
      {
        name: 'post',
        label: `Post-${card ? 'play' : 'bid'} comment`
      },
      {
        name: 'hint',
        label: 'Hint comment'
      }
    ];
    if (!commentsForViewpointOrDummy) {
      // remove pre and hint fields
      commentFields.pop();
      commentFields.shift();
    }
    const suit = card ? card.suit :
      bid.denomination ? bid.denomination : undefined
    const value = card ? card.rank :
      bid.level ? bid.level : bid.value
    const paperHeight = commentsForViewpointOrDummy ? 720 : 323;
    return (
      <Modal
        open={open}
      >
        <Paper
          className={classes.paper}
          style={{height: paperHeight}}
        >
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>{heading}</Grid>
            <Grid item>
              <MiniCard
                suit={suit}
                value={value}
                scale='0.7em'
                isBid={true}
              />
            </Grid>
          </Grid>
          {
            commentFields.map((field, index) => (
              <CommentForm
                field={field}
                comments={comments}
                onCommentChange={this.handleCommentChange}
                key={index}
              />
            ))
          }
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            className={classes.buttons}
          >
            <Grid item>
              <Button onClick={onCancelClick} variant='outlined'>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              {
                this.showDelete
                ?
                <Button
                  className={classes.deleteButton}
                  onClick={onDeleteClick}
                  variant='contained'
                  color='secondary'
                >
                  Delete
                </Button>
                :
                null
              }
              <Button
                onClick={onSubmitClick}
                variant='contained'
                color='primary'
                disabled={totalCharCount < 1}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    )
  }
}

CommentsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  commentsForViewpointOrDummy: PropTypes.bool,
  comments: PropTypes.object,
  onCancelClick: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  card: PropTypes.object,
  bid: PropTypes.object
}

export default withStyles(styles)(CommentsModal);
