import _ from 'lodash';

export const getLastMessageGroup = (conversation) => {
  return conversation[conversation.length - 1];
};

export const addNewMessageGroup = (conversation, message, position) => {
  const messageGroup = {
    position,
    messages: [
      message
    ]
  };
  conversation.push(messageGroup);
  return conversation;
};

export const addMessageToLastGroup = (conversation, message) => {
  // get last message group in conversation
  const lastGroup = getLastMessageGroup(conversation);
  lastGroup.messages.push(message);
  return conversation;
};

export const addNewMessage = (conversation, message, position) => {
  const lastGroup = getLastMessageGroup(conversation);
  if (lastGroup.position === position) {
    conversation = addMessageToLastGroup(conversation, message);
  } else {
    conversation = addNewMessageGroup(conversation, message, position);
  }
  return conversation;
};

export const removeHtmlTagsIfComment = (textId) => {
  if (_.includes(textId, '<p>') === true) {
    textId = _.replace(textId, '<p>', '');
    textId = _.replace(textId, '</p>', '');
  }
  return textId;
};

export const getPosition = ({ seat, viewpoint, dummy, declarer, isAuction }) => {
  if (seat === viewpoint ||
    (seat === dummy && declarer === viewpoint && !isAuction)) {
    return 'right';
  }
  return 'left';
};
