import React from 'react';
import PropTypes from 'prop-types';
import { userQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class UserById extends React.Component {
  render () {
    const { children, id } = this.props;
    return (
      <BBQuery
        query={userQueries.UserById(id)}
        showLoader
        fetchPolicy='no-cache'
      >
        {data => {
          return children(data.userById);
        }}
      </BBQuery>
    );
  }
}

UserById.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default UserById;
