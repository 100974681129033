import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import MiniCard from '../MiniCard/MiniCard.js';
import BBTranslate from '../../Locale/BBTranslate.js';
import { useTextTranslation } from '../../../Hooks';

const useStyles = makeStyles(() => ({
  minicard: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '1rem',
    marginLeft: '0.6rem'
  }
}));

const ContractMessage = ({ bidValue, doubleValue, declarer, isAuctionOnly }) => {
  const classes = useStyles();

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: useTextTranslation('all-pass-the-contract-is') }} />
      <span className={classes.minicard}>
        <MiniCard
          suit={bidValue.substring(1)}
          value={bidValue.substring(0, 1)}
          size='small'
          isBid
        />
      </span>
      {
        doubleValue &&
          <span className={classes.minicard}>
            <MiniCard
              value={doubleValue}
              size='small'
            />
          </span>
      }
      <span> {useTextTranslation('by')}</span>
      <span>
        <strong> {declarer}</strong>
      </span>
      {
        !isAuctionOnly &&
          <>
            <span>. </span>
            <BBTranslate textId='lets-move-on-to-the-play' />
          </>
      }
    </>
  );
};

ContractMessage.propTypes = {
  bidValue: PropTypes.string.isRequired,
  doubleValue: PropTypes.string,
  declarer: PropTypes.string.isRequired,
  isAuctionOnly: PropTypes.bool.isRequired
};

export default ContractMessage;
