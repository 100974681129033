const getHandPlayStatus = (hand, purchased) => {
  let status = 'locked';
  if (hand.freeToPlay) {
    status = 'play';
  }
  if (purchased) {
    status = 'play';
    if (hand.userHands && hand.userHands.length > 0) {
      if (hand.userHands[0].status === 'PartPlayed') {
        status = 'resume';
      } else {
        status = 'play-again';
      }
    }
  }
  return status;
};

export {
  getHandPlayStatus
};
