import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem.js';

const ListView = ({ products, ...other }) => {
  return (
    <div style={{ marginBottom: '2rem' }}>
      {
        products.map((product, index) => {
          return (
            <ListItem
              product={product}
              {...other}
              key={index}
            />
          );
        })
      }
    </div>
  );
};

ListView.propTypes = {
  products: PropTypes.array.isRequired,
  hidePrice: PropTypes.bool
};

export default ListView;
