import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import MiniCard from '../MiniCard/MiniCard.js';
import { useTextTranslation } from '../../../Hooks';

const useStyles = makeStyles(() => ({
  minicard: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '0.8rem',
    fontSize: '1rem'
  }
}));

const TextMessage = (props) => {
  const { text, suit, value, isBid } = props;
  const classes = useStyles();

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: useTextTranslation(text) }} />
      {
        value
          ? <span className={classes.minicard}><MiniCard suit={suit} value={value} size='small' isBid={isBid} /></span>
          : null
      }
    </>
  );
};

TextMessage.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  suit: PropTypes.string,
  value: PropTypes.string,
  isBid: PropTypes.bool
};

export default TextMessage;
