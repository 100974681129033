import { getEnvVar } from './env.js';
import shortid from 'shortid';
// import moment from 'moment';
import Evaporate from 'evaporate';

// ---- https://www.javascriptstuff.com/aws-sdk-with-webpack/
require('aws-sdk/dist/aws-sdk');
const AWS = window.AWS;
// ----

const REGION = getEnvVar('REGION');
const UPLOAD_BUCKET = getEnvVar('UPLOAD_BUCKET');
const UPLOAD_API_KEY = getEnvVar('UPLOAD_API_KEY');
const UPLOAD_SIGN_URL = getEnvVar('UPLOAD_SIGN_URL');

const uploadHelpers = {
  uploadFiles: (files, folder, filePrefix, callbacks) => {
    Evaporate.create({
      maxConcurrentParts: 1,
      partSize: 5 * 1024 * 1024,
      aws_key: UPLOAD_API_KEY,
      bucket: UPLOAD_BUCKET,
      awsRegion: REGION,
      signerUrl: UPLOAD_SIGN_URL,
      awsSignatureVersion: '4',
      computeContentMd5: true,
      logging: false,
      cryptoMd5Method: function (data) { return AWS.util.crypto.md5(data, 'base64'); },
      cryptoHexEncodedHash256: function (data) { return AWS.util.crypto.sha256(data, 'hex'); }
    })
      .then(
        // Successfully created evaporate instance `_e_`
        function success (_e_) {
          // var fileInput = document.getElementById('files'),
          const filePromises = [];

          // Start a new evaporate upload anytime new files are added in the file input
          // fileInput.onchange = function(evt) {
          // var files = evt.target.files;
          for (let i = 0; i < files.length; i++) {
            // let filename = getFormattedDate('YYYY-MM-DD') + '_';
            let filename = `${shortid.generate()}.${files[i].extension.toLowerCase()}`;
            if (filePrefix) {
              filename = `${filePrefix}_${filename}`;
            }
            const fileId = `${folder}/${filename}`;

            const promise = _e_.add({
              name: fileId,
              file: files[i],
              progress: (progressValue, data) => {
                callbacks.progress(progressValue, data, fileId);
              },
              started: () => {
                callbacks.started(filename);
              },
              complete: (_xhr, awsKey, stats) => {
                callbacks.complete(filename, folder, _xhr, awsKey, stats);
              },
              cancelled: callbacks.cancelled,
              error: callbacks.error,
              contentType: files[i].contentType
            })
              .then(function (awsKey) {
                console.log(awsKey, 'complete!');
              });
            filePromises.push(promise);
          }

          // Wait until all promises are complete
          Promise.all(filePromises)
            .then(function () {
              console.log('All files were uploaded successfully.');
            }, function (reason) {
              console.log('All files were not uploaded successfully:', reason);
            });

          // Clear out the file picker input
          // evt.target.value = '';
          // };
        }
      );
  }
};

export default uploadHelpers;
