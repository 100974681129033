import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { mutations } from '../../../graphql';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TwoColumn from '../../../Components/Layout/TwoColumn.js';
import StoreForm from '../../../Components/Forms/Store/StoreForm.js';
import StoreInput from '../../../Components/Forms/Store/StoreInput.js';
import NiceError from '../../../Components/Errors/NiceError.js';
import FeedbackMessage from '../../../Components/FeedbackMessage.js';
import Faq from '../../Home/FAQ';
import BBTranslate from '../../../Components/Locale/BBTranslate';

const Help = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const [contactUs] = useMutation(mutations.ContactUs);

  const { t } = useTranslation();

  const handleSubmit = async (formState) => {
    setMessage('');
    setError(null);
    await contactUs(formState);
  };

  const handleSubmitError = (err) => {
    setError(err);
  };

  const handleSubmitSuccess = () => {
    setMessage(t('your-message-has-been-sent'));
  };

  return (
    <>
      <TwoColumn
        title='help'
        description={
          <>
            <p><BBTranslate textId='contact-line-1' /></p>
            <p><BBTranslate textId='contact-line-2' /></p>
            <p><BBTranslate textId='contact-line-3' /></p>
          </>
        }
        form={
          <div>
            <FeedbackMessage message={message} />
            <NiceError err={error} defaultText='sending-your-message' />
            <StoreForm
              fields={{}}
              onSubmit={handleSubmit}
              onSubmitError={handleSubmitError}
              onSubmitSuccess={handleSubmitSuccess}
              submitLabel='send-message'
            >
              <StoreInput
                id='firstName'
                label='first-name'
                autoComplete='given-name'
                autoFocus
                required
                maxLength={100}
                validate={(val) => (val ? '' : 'First name is required')}
              />
              <StoreInput
                id='lastName'
                label='last-name'
                autoComplete='family-name'
                required
                maxLength={100}
                validate={(val) => (val ? '' : 'Last name is required')}
              />
              <StoreInput
                id='email'
                label='email-address'
                autoComplete='email'
                required
                maxLength={100}
                validate={(val) => (validator.isEmail(val) ? '' : 'Invalid Email')}
              />
              <StoreInput
                id='phone'
                label='phone-number'
                autoComplete='phone'
                maxLength={50}
              />
              <StoreInput
                id='message'
                label='message'
                required
                maxLength={500}
              />
            </StoreForm>
          </div>
        }
      />
      <Box sx={{ marginTop: 10 }}>
        <Faq />
      </Box>
    </>
  );
};

export default Help;
