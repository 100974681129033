import { useTranslation } from 'react-i18next';
const useTextTranslation = textId => {
  const { t } = useTranslation();

  if (!textId) {
    return '';
  }

  // if textId is an array,
  // translate each individual element
  // and join them with a space
  if (textId instanceof Array) {
    return textId
      .map(textId => t(textId))
      .join(' ');
  }

  return t(textId);
};

export default useTextTranslation;
