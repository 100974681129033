import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatURLTitle } from '../../../helpers/http';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center'
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover .MuiTypography-root, &:focus .MuiTypography-root': {
      textDecoration: 'underline'
    }
  },
  avatar: {
    marginBottom: '2.5rem',
    // vertical padding is based on parent width
    // so this trick maintains a square aspect ratio
    // the padding is split evenly between top and bottom
    // so a pictureless avatar that falls back on author initials
    // can position them in the middle
    width: '100%',
    paddingTop: '50%',
    paddingBottom: '50%',
    '& img': {
      height: 'auto'
    }
  }
}));

const AuthorListItem = (props) => {
  const { author } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const link = `/authors/${author.id}/${formatURLTitle(author.fullName)}`;

  return (
    <Link to={link} className={classes.clickable}>
      <div
        className={classes.container}
      >
        <Avatar
          alt={author.fullName}
          src={author.avatarPath}
          className={classes.avatar}
        />
        <Typography variant='h3'>{author.fullName}</Typography>
        <Typography variant='body4'>{t('count-products', { count: author.products.length })}</Typography>
      </div>
    </Link>
  );
};

AuthorListItem.propTypes = {
  author: PropTypes.object.isRequired
};

export default AuthorListItem;
