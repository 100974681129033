import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  input: {
    paddingTop: 30
  }
}));

const BBDialog = (props) => {
  const {
    open,
    onCloseClick,
    onSubmitClick,
    primaryHeading,
    secondaryHeading,
    children
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onCloseClick}
      aria-labelledby='form-dialog-title'
      fullWidth
    >
      <DialogTitle id='form-dialog-title'>
        {primaryHeading}
        <Typography variant='caption' gutterBottom align='left'>
          {secondaryHeading || ''}
        </Typography>
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onCloseClick}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {
          children.map((child, index) => {
            return (
              <React.Fragment key={index}>
                {child}
              </React.Fragment>
            );
          })
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClick} color='primary'>
          Cancel
        </Button>
        <Button onClick={onSubmitClick} color='primary'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BBDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  primaryHeading: PropTypes.string.isRequired,
  secondaryHeading: PropTypes.string
};

export default BBDialog;
