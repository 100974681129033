import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '14rem 2rem',
    maxWidth: '118rem',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 2rem'
    }
  }
}));

const Container = (props) => {
  const { className, children } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className || null)}>
      {children}
    </div>
  );
};

export default Container;
