import React from 'react';
import PropTypes from 'prop-types';
import BBAdminForm from '../../../Components/Forms/BBAdminForm.js';
import BBInput from '../../../Components/Forms/BBInput.js';
import BBSelect from '../../../Components/Forms/BBSelect.js';
import { tagQueries } from '../../../graphql/queries';

const TagForm = (props) => {
  const { groups, upsertTag, deleteTag, tag, ...other } = props;
  return (
    <>
      <BBAdminForm
        fields={tag}
        onSubmit={upsertTag}
        onDelete={deleteTag}
        refetchQueries={[
          {
            query: tagQueries.Tags
          }
        ]}
        {...other}
      >
        <BBInput
          id='name'
          name='name'
          type='text'
          label='Name'
          autoFocus
          required
          maxLength={100}
          validate={(val) => (val ? '' : 'Name is required')}
        />
        <BBSelect
          id='group'
          name='tagGroupId'
          label='Group'
          valueField='id'
          keyField='name'
          width={100}
          items={groups}
        />
      </BBAdminForm>
    </>
  );
};

TagForm.propTypes = {
  upsertTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func,
  tag: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired
};

export default TagForm;
