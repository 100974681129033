import React from 'react';
import PropTypes from 'prop-types';
import BBDialog from './BBDialog.js';

class MessageDialog extends React.Component {
  render () {
    const {
      onCloseClick,
      onSubmitClick,
      dialogOpen
    } = this.props;
    return (
      <BBDialog
        open={dialogOpen}
        onCloseClick={onCloseClick}
        onSubmitClick={onSubmitClick}
        primaryHeading='Are you sure?'
        // secondaryHeading='blah blah blah'
      >
        <p>
          Changing this data will clear data from all later steps
        </p>
        <p>
          Are you sure you wish to continue?
        </p>
      </BBDialog>
    );
  }
}

MessageDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired
};

export default MessageDialog;
