import React from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/styles';

const ShadowPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '.7rem',
  boxShadow: theme.boxShadow,
  padding: '7rem'
}));

const BBPaper = ({ children, className }) => {
  return (
    <ShadowPaper className={className}>
      {children}
    </ShadowPaper>
  );
};

export default BBPaper;
