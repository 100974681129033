import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import BBTranslate from '../../Locale/BBTranslate.js';

const styles = {
  icon: {
    background: 'transparent url() no-repeat left 44%',
    display: 'inline-block',
    width: '1.5em',
    fontSize: '0.55em',
    fontStyle: 'inherit'
  },
  a: {
    position: 'relative',
    display: 'block',
    width: '8.9em',
    height: '6.4em',
    textAlign: 'center',
    background: 'transparent url("/img/bidding_box/bg_auction_tab_1.svg") no-repeat left top',
    backgroundSize: '100%',
    marginRight: '-0.9em',
    textDecoration: 'none',
    color: '#4a4a4a',
    '&:hover': {
      backgroundImage: 'url("/img/bidding_box/bg_auction_tab_1_hover.svg")'
    }
  },
  span: {
    display: 'block',
    fontSize: '2.6em',
    lineHeight: '1.95em',
    marginRight: '0.2em',
    overflow: 'hidden'
  },
  icon_c: {
    backgroundImage: 'url("/img/bidding_box/bg_icon_suit_club.svg")',
    backgroundSize: 'contain',
    textIndent: '-20em'
  },
  a_c: {
    color: '#417505',
    width: '8em',
    margin: 0,
    backgroundImage: 'url("/img/bidding_box/bg_auction_tab_2.svg")',
    '&:hover': {
      backgroundImage: 'url("/img/bidding_box/bg_auction_tab_2_hover.svg")'
    },
    '& span': {
      margin: 0
    }
  },
  icon_d: {
    backgroundImage: 'url("/img/bidding_box/bg_icon_suit_diamond.svg")',
    backgroundSize: 'contain',
    textIndent: '-20em'
  },
  a_d: {
    color: '#f5a623'
  },
  icon_h: {
    backgroundImage: 'url("/img/bidding_box/bg_icon_suit_heart.svg")',
    backgroundSize: 'contain',
    textIndent: '-20em'
  },
  a_h: {
    color: '#d0021b'
  },
  icon_s: {
    backgroundImage: 'url("/img/bidding_box/bg_icon_suit_spade.svg")',
    backgroundSize: 'contain',
    textIndent: '-20em'
  },
  a_s: {
    color: '#3b76bb'
  },
  disabled: {
    cursor: 'not-allowed'
  },
  enabled: {
    cursor: 'pointer'
  }
};

const BiddingItem = (props) => {
  const { classes, suit, value, onClick, className, zIndex, clickable, disabled } = props;
  let anchorClass = null;
  if (suit) {
    anchorClass = classes[`a_${suit.toLowerCase()}`];
  }

  return (
    <span
      style={{ zIndex }}
      onClick={() => {
        if (!disabled && clickable) {
          let bid = '';
          if (value === 'Pass' || value === 'X' || value === 'XX') {
            bid = { value };
          } else {
            bid = {
              value: `${value}${suit}`,
              level: value,
              denomination: suit
            };
          }
          onClick(bid);
        }
      }}
      className={
        clsx(
          classes.a,
          className,
          anchorClass,
          clickable ? classes.enabled : classes.disabled
        )
      }
    >
      <span className={classes.span}>
        {value === 'Pass' ? <BBTranslate textId='pass-capitalise' /> : value}
        {
        suit
          ? (
            <i className={clsx(classes.icon, classes[`icon_${suit.toLowerCase()}`])}>
              {suit === 'NT' ? <BBTranslate textId='nt-no-trump' /> : suit}
            </i>
            )
          : null
        }
      </span>
    </span>
  );
};

BiddingItem.propTypes = {
  classes: PropTypes.object.isRequired,
  suit: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  zIndex: PropTypes.number,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool
};

export default withStyles(styles)(BiddingItem);
