import React from 'react';
import PropTypes from 'prop-types';
import DeviceNotSupported from './DeviceNotSupported.js';
import {
  getMobileOperatingSystem,
  getAndroidVersion,
  iOSversion
} from '../helpers/pwa.js';

const DeviceDetector = ({ children }) => {
  const mobileOS = getMobileOperatingSystem();

  let osVersion;
  let osSupported = true;
  if (mobileOS) {
    if (mobileOS.toLowerCase() === 'android') {
      osVersion = parseFloat(getAndroidVersion());
      if (osVersion < 4) {
        osSupported = false;
      }
    } else {
      osVersion = parseFloat(iOSversion());
      if (osVersion < 11) {
        osSupported = false;
      }
    }
  }

  if (osVersion && !osSupported) {
    return <DeviceNotSupported osVersion={osVersion} />;
  }

  return (
    <>
      {children}
    </>
  );
};

DeviceDetector.propTypes = {
  children: PropTypes.node.isRequired
};

export default DeviceDetector;
