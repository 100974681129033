import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';

import _ from 'lodash';

const BBTableRow = (props) => {
  const { columns, item, isItemSelected } = props;
  return columns.map(
    column => {
      const value = _.get(item, column.id);
      const columnType = column.type ? column.type : 'text';
      const linkIdValue = column.linkId ? _.get(item, column.linkId) : '';
      switch (columnType) {
        case 'text':
          return (
            <TableCell key={column.id}>{value && value !== '' ? value : '-'}</TableCell>
          );
        case 'link':
          return (
            <TableCell key={column.id}>
              <Link to={`${column.url}/${linkIdValue}`}>
                {value && value !== '' ? value : '-'}
              </Link>
            </TableCell>
          );
        case 'image':
          return (
            <TableCell key={column.id}>
              <Link to={`${column.url}/${linkIdValue}`}>
                <img alt='table cell' src={value} width={75} />
              </Link>
            </TableCell>
          );
        case 'checkbox':
          return (
            <TableCell key={column.id} padding='checkbox'>
              {
                column.condition(item)
                  ? <Checkbox
                      checked={isItemSelected}
                      onClick={column.onClick(item.id)}
                    />
                  : null
              }
            </TableCell>
          );
        case 'boolean':
          let theValue = value;
          if (column.condition) {
            theValue = column.condition(item);
          }
          return (
            <TableCell key={column.id}>
              {
                theValue
                  ? <DoneIcon />
                  : null
              }
            </TableCell>
          );
        case 'button':
          return (
            <TableCell key={column.id}>
              <IconButton onClick={column.onClick(item)} size='large'>
                {column.icon}
              </IconButton>
            </TableCell>
          );
        default:
          return null;
      }
    }
  );
};

BBTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  isItemSelected: PropTypes.bool.isRequired
};

export default BBTableRow;
