import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'block', // Fix IE 11 issue.
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
  }
}));

const BBFormContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </main>
  );
};

BBFormContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

export default BBFormContainer;
