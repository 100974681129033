import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import StoreInput from '../../../Components/Forms/Store/StoreInput.js';
import Captcha from '../../../Components/Captcha.js';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    '@media all and (max-width: 480px)': {
      flexDirection: 'column',
      width: 'auto',
      '& div:nth-child(1)': {
        display: 'flex!important',
        flexDirection: 'column'
      }
    }
  }
}));

const RegisterForm = ({ onComplete, onError }) => {
  const classes = useStyles();
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    captchaVerificationKey: ''
  });
  const [fieldErrors, setFieldErrors] = useState({});

  const validate = useCallback((newFields, newFieldErrors) => {
    const errMessages = Object.keys(newFieldErrors).filter((k) => newFieldErrors[k]);
    if (!newFields.password) return false;
    if (!newFields.email) return false;
    if (errMessages.length) return false;
    if (!newFields.captchaVerificationKey) return false;
    return true;
  }, []);

  const handleInputChange = ({ id, value, error }) => {
    setFields((state) => {
      const newState = { ...state, [id]: value };
      const isValid = validate(newState, fieldErrors);
      onComplete(isValid, newState);
      return newState;
    });
    setFieldErrors((state) => {
      const newState = { ...state, [id]: error };
      const isValid = validate(fields, newState);
      onComplete(isValid, fields);
      return newState;
    });
  };

  const handleCaptchaSuccess = useCallback((key) => {
    setFields((state) => {
      const newState = { ...state, captchaVerificationKey: key };
      const isValid = validate(newState, fieldErrors);
      onComplete(isValid, newState);
      return newState;
    });
  }, [validate, setFields, onComplete, fieldErrors]);

  const handleCaptchaError = useCallback((error) => {
    setFields((state) => ({ ...state, captchaVerificationKey: '' }));
    if (onError) {
      onError(error);
    }
  }, [onError, setFields]);

  return (
    <form action='' className={classes.form}>
      <FormControl
        variant='standard'
        margin='normal'
        required
        fullWidth
      >
        <StoreInput
          id='firstName'
          label='first-name'
          autoComplete='given-name'
          autoFocus
          required
          maxLength={100}
          value={fields.firstName}
          onChange={handleInputChange}
          validate={(val) => (val ? '' : 'First name is required')}
        />
      </FormControl>
      <FormControl
        variant='standard'
        margin='normal'
        required
        fullWidth
      >
        <StoreInput
          id='lastName'
          label='last-name'
          autoComplete='family-name'
          required
          maxLength={100}
          value={fields.lastName}
          onChange={handleInputChange}
          validate={(val) => (val ? '' : 'Last name is required')}
        />
      </FormControl>
      <FormControl variant='standard' margin='normal' required fullWidth>
        <StoreInput
          id='email'
          label='email-address'
          autoComplete='email'
          required
          maxLength={100}
          value={fields.email}
          onChange={handleInputChange}
          validate={(val) => (validator.isEmail(val) ? '' : 'Invalid Email')}
        />
      </FormControl>
      <FormControl variant='standard' margin='normal' required fullWidth>
        <StoreInput
          id='password'
          name='password'
          type='password'
          label='password'
          autoComplete='current-password'
          required
          maxLength={100}
          value={fields.password}
          onChange={handleInputChange}
          validate={(val) => (val ? '' : 'Password is required')}
        />
      </FormControl>
      <FormControl margin='normal'>
        <Captcha
          action='register'
          onSuccess={handleCaptchaSuccess}
          onError={handleCaptchaError}
        />
      </FormControl>
    </form>
  );
};

RegisterForm.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func
};

export default RegisterForm;
