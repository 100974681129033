import React from 'react';
import PropTypes from 'prop-types';
import RichText from '../../Forms/RichText/RichText.js';

const CommentForm = (props) => {
  const { field, comments, onCommentChange } = props;
  return (
    <>
      <RichText
        name={field.name}
        value={comments[field.name]}
        label={field.label}
        onChange={onCommentChange}
      />
    </>
  );
};

CommentForm.propTypes = {
  field: PropTypes.object.isRequired,
  comments: PropTypes.object,
  onCommentChange: PropTypes.func.isRequired
};

export default CommentForm;
