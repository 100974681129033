import moment from 'moment';

const getQuarterDateRanges = (quartersWithYear) => {
  const quarterDateRanges = {};
  const quarterDates = {
    q1: { start: '-01-01T00:00:00Z', end: '-03-31T23:59:59Z' },
    q2: { start: '-04-01T00:00:00Z', end: '-06-30T23:59:59Z' },
    q3: { start: '-07-01T00:00:00Z', end: '-09-30T23:59:59Z' },
    q4: { start: '-10-01T00:00:00Z', end: '-12-31T23:59:59Z' }
  };

  for (const quarter in quartersWithYear) {
    const range = quartersWithYear[quarter];
    quarterDateRanges[quarter] = {
      from: new Date(`${range.year}${quarterDates[range.quarter].start}`),
      to: new Date(`${range.year}${quarterDates[range.quarter].end}`)
    };
  }

  return quarterDateRanges;
};

export const getDateFilterOptionsByQuarter = (currentDate) => {
  const now = currentDate || new Date();
  const quarter = moment(now).quarter();
  const year = now.getFullYear();
  const prevYear = Number(year) - 1;

  let quartersWithYear;

  switch (quarter) {
    case 1:
      quartersWithYear = {
        current: { year, quarter: 'q1' },
        backdatedOne: { year: prevYear, quarter: 'q4' },
        backdatedTwo: { year: prevYear, quarter: 'q3' },
        backdatedThree: { year: prevYear, quarter: 'q2' }
      };
      break;
    case 2:
      quartersWithYear = {
        current: { year, quarter: 'q2' },
        backdatedOne: { year, quarter: 'q1' },
        backdatedTwo: { year: prevYear, quarter: 'q4' },
        backdatedThree: { year: prevYear, quarter: 'q3' }
      };
      break;
    case 3:
      quartersWithYear = {
        current: { year, quarter: 'q3' },
        backdatedOne: { year, quarter: 'q2' },
        backdatedTwo: { year, quarter: 'q1' },
        backdatedThree: { year: prevYear, quarter: 'q4' }
      };
      break;
    case 4:
      quartersWithYear = {
        current: { year, quarter: 'q4' },
        backdatedOne: { year, quarter: 'q3' },
        backdatedTwo: { year, quarter: 'q2' },
        backdatedThree: { year, quarter: 'q1' }
      };
      break;
    default: throw new Error(`getRollingQuarters error: ${quarter} is not valid quarter.`);
  }

  const quarterDateRanges = getQuarterDateRanges(quartersWithYear);
  const { current, backdatedOne, backdatedTwo, backdatedThree } = quarterDateRanges;

  return [
    { label: 'Current Quarter', unit: null, dateRange: current },
    { label: 'Backdated 1', unit: null, dateRange: backdatedOne },
    { label: 'Backdated 2', unit: null, dateRange: backdatedTwo },
    { label: 'Backdated 3', unit: null, dateRange: backdatedThree },
    { label: 'Custom', unit: null, dateRange: null }
  ];
};
