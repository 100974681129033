import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Auction from '../../../../Components/Hands/Auction.js';

const styles = () => ({
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2
  },
  content: {
    padding: '3rem',
    fontSize: '.6em'
  }
});

class AuctionModal extends React.Component {
  render () {
    const {
      classes,
      open,
      onCloseClick,
      dealer,
      auction
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onCloseClick}
      >
        <div>
          <IconButton
            aria-label='Close'
            className={classes.closeButton}
            onClick={onCloseClick}
            size='large'
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.content}>
            <Auction
              dealer={dealer}
              auction={auction}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

AuctionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  dealer: PropTypes.string.isRequired,
  auction: PropTypes.array.isRequired
};

export default withStyles(styles)(AuctionModal);
