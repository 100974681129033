import React from 'react';
import PropTypes from 'prop-types';
import { userQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';
import { isAuthenticated } from '../../helpers/auth.js';

const CurrentUser = ({ children }) => {
  if (isAuthenticated()) {
    return (
      <BBQuery query={userQueries.CurrentUser}>
        {data => {
          return children(data.currentUser);
        }}
      </BBQuery>
    );
  } else {
    return children(null);
  }
};

CurrentUser.propTypes = {
  children: PropTypes.func.isRequired
};

export default CurrentUser;
