import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { productLinkQueries } from '../../../graphql/queries';
import {
  Modal,
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import BBFormContainer from '../../../Components/Forms/BBFormContainer.js';
import BBSelect from '../../../Components/Forms/BBSelect.js';
import BBInput from '../../../Components/Forms/BBInput.js';
import {
  generateIframeForLinkedProduct,
  buildProductLinksForHand,
  buildProductLinks
} from '../../../helpers/product.js';
import { BBSnackbarContext } from '../../../Components/BBSnackbar/BBSnackbarContext';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  },
  widthHeight: {
    width: 40
  },
  by: {
    margin: 20,
    position: 'relative',
    top: 15
  },
  label: {
    height: 20,
    verticalAlign: 'middle'
  },
  embed: {
    margin: '20px 0'
  },
  closeButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end'
  }
});

const ContentShare = ({ product, hand, open, onModalClose }) => {
  const [selectedLink, setSelectedLink] = useState('');

  const { openSnackbar } = useContext(BBSnackbarContext);
  const classes = useStyles();

  const variables = {
    productId: product.id,
    language: product.language
  };

  const { data } = useQuery(productLinkQueries.ProductLinks, {
    variables
  });

  if (!data) {
    return null;
  }

  let links = [];
  if (hand) {
    links = buildProductLinksForHand(product.language, data.productLinks, hand, product.id, product.title);
  } else {
    links = buildProductLinks(product.language, data.productLinks);
  }

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(selectedLink);
    openSnackbar('Copied!', 'success');
  };

  const handleCopyEmbedClick = () => {
    const iframeCode = getEmbedCode();
    navigator.clipboard.writeText(iframeCode);
    openSnackbar('Copied!', 'success');
  };

  const handleLinksChange = (el) => {
    setSelectedLink(el.value);
  };

  const getEmbedCode = () => {
    if (selectedLink) {
      return generateIframeForLinkedProduct(selectedLink, '100%', '100%');
    }
    return '';
  };

  return (
    <Modal
      open={open}
      onClose={onModalClose}
    >
      <>
        <BBFormContainer>
          <div className={classes.closeButton}>
            <IconButton aria-label='Close' onClick={onModalClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Box sx={{ p: 2, width: '100%', marginBottom: 20 }}>
            <BBSelect
              id='links'
              name='embed'
              items={links}
              valueField='link'
              keyField='link'
              value={selectedLink}
              onChange={handleLinksChange}
              label='Select link'
            />
            <div className={classes.buttonContainer}>
              <Button style={{ marginRight: 20 }} onClick={handleCopyLinkClick} variant='contained'>Copy link</Button>
            </div>
          </Box>
          {
            selectedLink
              ? (
                <Box sx={{ p: 2, width: '100%', borderTop: 'solid 1px grey', paddingTop: '4rem' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className={classes.label}>
                      <Typography variant='h5'>Embed</Typography>
                    </span>
                  </div>
                  <div className={classes.embed}>
                    <BBInput
                      id='embed'
                      name='embed'
                      type='text'
                      label=''
                      maxLength={1000}
                      rows={3}
                      value={getEmbedCode()}
                      disabled
                    />
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button onClick={handleCopyEmbedClick} variant='contained'>Copy embed code</Button>
                  </div>
                </Box>
                )
              : null
          }
        </BBFormContainer>
      </>
    </Modal>
  );
};

ContentShare.propTypes = {
  product: PropTypes.object,
  hand: PropTypes.object,
  open: PropTypes.bool,
  onModalClose: PropTypes.func
};

export default ContentShare;
