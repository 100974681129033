import _ from 'lodash';

export const hasPurchasedProduct = (productId, purchasedProducts) => {
  const purchasedProduct = _.find(purchasedProducts, (p) => {
    return p.id === productId;
  });
  return purchasedProduct !== undefined;
};

export const markPurchasedProducts = (products, user) => {
  if (user && user.products && user.products.length > 0) {
    return products.map(product => ({
      ...product,
      purchased: hasPurchasedProduct(product.id, user.products)
    }));
  }
  return products;
};

export const getProductListFromPurchases = (purchases) => {
  const products = [];
  purchases.forEach(purchase => {
    purchase.purchaseItems.forEach(item => {
      if (!item.giftedToUserId) {
        products.push(item.product);
      }
    });
  });
  return products;
};
