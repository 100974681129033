import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import {
  getShortenedTagName,
  getGroupTagsFromTagsCache,
  getTextIdFromSearchableTag
} from '../../helpers/tag.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    maxHeight: 54,
    overflow: 'hidden'
  },
  chip: {
    borderRadius: 2,
    marginRight: 5,
    fontSize: '1.4rem',
    marginBottom: 5
  }
}));

const Tags = ({ tags }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!tags) {
    return null;
  }

  tags = getGroupTagsFromTagsCache(tags);

  // get system tags and display
  return (
    <div className={classes.chipsContainer}>
      {tags && tags.map(tag => (
        <Chip
          key={tag.name}
          className={classes.chip}
          size='small'
          label={t(getTextIdFromSearchableTag(getShortenedTagName(tag.name)))}
          variant='outlined'
        />
      ))}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.array
};

export default Tags;
