import gql from 'graphql-tag';

const tagQueries = {
  Tags: gql`
    query {
      tags {
        id
        name
        language
        tagGroup {
          id
          name
        }
      }
    }
  `,
  TagGroups: gql`
    query {
      tagGroups {
        id
        name
      }
    }
  `,
  TagById: (id) => {
    return gql`
      query {
        tagById (id: ${id}) {
          id
          name
          language
          tagGroupId
          tagGroup {
            id
            name
          }
        }
      }
    `;
  },
  TagSearch: (options) => {
    return gql`
      query {
        tagSearch (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          tags {
            id
            name
            language
            tagGroup {
              id
              name
            }
          }
        }
      }
    `;
  }
};

export default tagQueries;
