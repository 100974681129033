import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import StoreButton from '../../Components/Forms/Store/StoreButton.js';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 520,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none'
    }
  },
  heading: {
    fontSize: '4.6rem',
    color: '#fff',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.4rem'
    }
  },
  body: {
    color: '#fff'
  },
  buttonContainer: {
    marginTop: '4rem'
  }
}));

const HighlightSection = (props) => {
  const { heading, body, cta, onCtaClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant='h1' component='h2' className={classes.heading}>{heading}</Typography>
      <Typography variant='body4' className={classes.body}>{body}</Typography>
      {cta && (
        <div className={classes.buttonContainer}>
          <StoreButton
            onClick={onCtaClick}
            label={cta}
          />
        </div>
      )}
    </div>
  );
};

HighlightSection.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cta: PropTypes.string,
  onCtaClick: PropTypes.func
};

export default HighlightSection;
