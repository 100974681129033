import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import BBTranslate from './Locale/BBTranslate.js';

const styles = theme => ({
  component: {
    background: '#eee',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    fontSize: '2em',
    lineHeight: '2em',
    textAlign: 'center',
    color: '#999',
    '& span': {
      position: 'relative',
      display: 'block',
      top: '50%',
      marginTop: '-1em'
    }
  }
});

const MiddleMessage = (props) => {
  const { classes, text } = props;

  return (
    <div className={classes.component}>
      <span><BBTranslate textId={text} /></span>
    </div>
  );
};

MiddleMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

export default withStyles(styles)(MiddleMessage);
