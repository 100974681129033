import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import BBTranslate from '../Locale/BBTranslate.js';

const styles = theme => ({
  root: {
    color: '#fff',
  },
  arrow: {
    width: '4rem',
    height: '4rem',
    lineHeight: '3.5rem',
    fontSize: '2.5rem',
    textAlign: 'center',
    cursor: 'help',
    marginRight: '0.5rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    '&.top': {
      backgroundImage: 'url("/img/components/compass/bg_icon_top.svg")'
    },
    '&.right': {
      backgroundImage: 'url("/img/components/compass/bg_icon_right.svg")'
    },
    '&.bottom': {
      backgroundImage: 'url("/img/components/compass/bg_icon_bottom.svg")'
    },
    '&.left': {
      backgroundImage: 'url("/img/components/compass/bg_icon_left.svg")'
    }
  }
});

class Compass extends React.Component {
  returnNorthPosition = (viewpoint) => {
    let obj = {
      tooltipText: '',
      arrowClass: ''
    }
    switch(viewpoint) {
      case 'N':
        obj.tooltipText = 'viewpoint-is-north';
        obj.arrowClass = 'bottom';
        break;
        case 'E':
        obj.tooltipText = 'north-is-right-of-viewpoint';
        obj.arrowClass = 'right';
        break;
      case 'S':
        obj.tooltipText = 'north-is-opposite-viewpoint';
        obj.arrowClass = 'top';
        break;
      case 'W':
        obj.tooltipText = 'north-is-left-of-viewpoint';
        obj.arrowClass = 'left';
        break;
      default:
        break;
    }
    return obj;
  }

  render () {
    const { classes, viewpoint, children } = this.props;
    var northPosition = this.returnNorthPosition(viewpoint);
    return (
      <Grid
        container
        className={classes.root}
        wrap='nowrap'
      >
        <Grid item>
          <Tooltip title={
            <Typography>
              <BBTranslate textId={northPosition.tooltipText} />
            </Typography>
          }>
            <div className={clsx(classes.arrow, northPosition.arrowClass)}></div>
          </Tooltip>
        </Grid>
        {
          children
          ?
          <Grid item>
            {children}
          </Grid>
          :
          null
        }
      </Grid>
    );
  }
};

Compass.propTypes = {
  classes: PropTypes.object.isRequired,
  viewpoint: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

export default withStyles(styles)(Compass);
