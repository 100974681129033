import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledAccordion = styled(Accordion)({
  borderLeft: '1px solid #613344',
  borderRight: '1px solid #613344',
  '&:last-child': {
    borderBottom: '1px solid #613344'
  }
});

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: '#F9E5E1',
  color: '#613344',
  borderTop: '1px solid #613344',
  borderBottom: 'none',
  '.Mui-expanded &': {
    backgroundColor: '#613344',
    color: '#fff'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#613344',
    '.Mui-expanded &': {
      color: '#FFB73B'
    }
  }
});

const StyledAccordionDetails = styled(AccordionDetails)({
  borderTop: '1px solid #613344',
  borderBottom: 'none'
});

const BBAccordion = (props) => {
  const { items } = props;

  return (
    <div>
      {items.map(item => (
        <StyledAccordion
          key={item.key}
          square
          disableGutters
        >
          <StyledAccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`${item.key}-content`}
            id={`${item.key}-header`}
          >
            {item.summary}
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <span
              dangerouslySetInnerHTML={{ __html: item.details }}
            />
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

BBAccordion.propTypes = {
  items: PropTypes.array.isRequired
};

export default BBAccordion;
