import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteButton from './DeleteButton.js';
import BBSubmit from './BBSubmit.js';
import { parseError } from '../../helpers/error.js';
import { stripTypeNameFromMutationVars } from '../../helpers/gql';

class BBForm extends React.Component {
  state = {
    fields: this.props.fields ? this.props.fields : [],
    fieldErrors: {},
    isLoading: false
  };

  handleChange = (input) => {
    const fields = { ...this.state.fields };
    const fieldErrors = { ...this.state.fieldErrors };
    fields[input.name] = input.value;
    fieldErrors[input.name] = input.error;
    this.setFormState({ fields, fieldErrors });
  };

  setFormState = (newState) => {
    let state = this.state;
    state = Object.assign(state, newState);
    this.setState(state);
  };

  handleDelete = () => {
    const { onDelete, onSubmitSuccess, onSubmitError } = this.props;
    const fields = this.state.fields;
    if (onDelete !== null) {
      onDelete(
        {
          variables: { id: fields.id }
        }
      ).then(() => {
        onSubmitSuccess('Deleted successfully');
      }).catch(err => {
        const parsedError = parseError(err);
        onSubmitError(parsedError.message);
      }).finally(() => {
        this.setFormState({
          isLoading: false
        });
      });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      onValidate,
      onSubmit,
      onSubmitSuccess,
      onSubmitError,
      refetchQueries
    } = this.props;

    this.setFormState({
      isLoading: true
    });

    let valMessage = '';

    if (onValidate) {
      valMessage = onValidate(this.state);
      if (valMessage !== '') {
        onSubmitError(valMessage);
        this.setFormState({
          isLoading: false
        });
        return;
      }
    }

    if (valMessage === '' && this.isValid()) {
      if (onSubmit) {
        await onSubmit({
          variables: stripTypeNameFromMutationVars(this.state.fields),
          refetchQueries: refetchQueries
        }).then(() => {
          if (onSubmitSuccess) {
            onSubmitSuccess('Saved successfully');
          }
        }).catch(err => {
          const parsedError = parseError(err);
          if (onSubmitError) {
            onSubmitError('Error submitting form: ' + parsedError.message);
          }
        });

        this.setFormState({
          isLoading: false
        });

      }
    }
  };

  isValid = () => {
    const { children } = this.props;
    const fieldErrors = this.state.fieldErrors;
    const fields = this.state.fields;
    let requiredFields = [];
    if (Array.isArray(children)) {
      requiredFields = children.filter((child) => {
        return child.props.required === true;
      });
    } else {
      if (children && children.props.required) {
        requiredFields.push(children);
      }
    }
    const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
    const blankFields = requiredFields.filter((k) => {
      return fields[k.props.name] === '';
    });
    if (errMessages.length || blankFields.length) return false;
    return true;
  };

  componentDidUpdate (prevProps) {
    // if field values change whilst the form is still rendered then update the state
    // to reflect the new field values
    if (JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
      this.setFormState({ fields: this.props.fields })
    }
  }

  render () {
    const {
      children,
      onDelete,
      onCancelClick,
      cancelLabel,
      submitLabel,
      className
    } = this.props;

    const { isLoading } = this.state;
    let childrenArray = [];

    if (Array.isArray(children)) {
      childrenArray = children;
    } else {
      childrenArray.push(children);
    }

    return (
      <form onSubmit={this.handleSubmit}>
        {
          childrenArray.map(formItem => {
            if (formItem.props.bbFormIgnore) {
              return (formItem);
            }
            const cloned = React.cloneElement(formItem, {
              onChange: this.handleChange,
              value: this.state.fields[formItem.props.name] ? this.state.fields[formItem.props.name] : '',
              className: className ? className : null
            });
            return (
              <FormControl
                variant='standard'
                key={formItem.props.name}
                margin='normal'
                required
                fullWidth>
                {cloned}
              </FormControl>
            );
          })
        }
        <div style={{textAlign: 'right'}}>
          {
            onCancelClick
            ?
            <FormControl variant='standard' margin='normal' style={{marginRight: 10}}>
              <Button onClick={onCancelClick}>
              {
                cancelLabel ? cancelLabel : 'Cancel'
              }
              </Button>
            </FormControl>
            :
            null
          }
          {
            onDelete
            ?
            <FormControl variant='standard' margin='normal' style={{marginRight: 10}}>
              <DeleteButton
                onDeleteOkClick={this.handleDelete}
              />
            </FormControl>
            :
            null
          }
          <FormControl variant='standard' margin='normal'>
            <BBSubmit
              label={submitLabel ? submitLabel : 'save'}
              disabled={isLoading || !this.isValid()}
              isLoading={isLoading}
              className={className ? className : null}
            />
          </FormControl>
        </div>
      </form>
    );
  }
}

BBForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onValidate: PropTypes.func,
  refetchQueries: PropTypes.array,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  className: PropTypes.string
}

export default BBForm;
