import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Grid,
  InputAdornment
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import BBInput from './BBInput.js';
import BBSubmit from './BBSubmit.js';

const useStyles = makeStyles((theme) => ({
  searchButton: {
    top: '1rem'
  }
}));

const AdminSearchBox = ({ onSubmit, isLoading }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = (input) => {
    setSearchTerm(input.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(searchTerm);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <BBInput
            id='search'
            name='search'
            type='text'
            label=''
            maxLength={100}
            value={searchTerm}
            onChange={handleInputChange}
            variant='standard'
            placeholder='Type a product title...'
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search color='action' />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <BBSubmit
            isLoading={isLoading}
            disabled={searchTerm.length === 0 || isLoading}
            className={classes.searchButton}
            label='Search'
          />
        </Grid>
      </Grid>
    </form>
  );
};

AdminSearchBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default AdminSearchBox;
