import {
  writeCookie,
  readCookie,
  deleteCookie
} from './cookie.js';

const env = process.env.REACT_APP_ENV;
const jwtCookieName = `bb-auth-${env}`;

const persistJwt = token => {
  writeCookie(jwtCookieName, token);
};

const getJwt = () => {
  return readCookie(jwtCookieName);
};

const isAuthenticated = () => {
  const token = getJwt();
  return (token !== undefined);
};

const isAuthorised = (authorisedUserTypes, user) => {
  let authorised = true;
  if (authorisedUserTypes && user) {
    authorised = authorisedUserTypes.indexOf(user.type) > -1;
  }
  return authorised;
};

const logout = () => {
  deleteCookie(jwtCookieName);
};

export {
  persistJwt,
  getJwt,
  isAuthenticated,
  isAuthorised,
  logout
};
