import React from 'react';
import PropTypes from 'prop-types';
import BBForm from './BBForm.js';
import BBFormContainer from './BBFormContainer.js';

const BBAdminForm = (props) => {
  return (
    <BBFormContainer>
      <BBForm {...props} cancelLabel='Close' />
    </BBFormContainer>
  );
};

BBAdminForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onValidate: PropTypes.func,
  refetchQueries: PropTypes.array,
  submitLabel: PropTypes.string
};

export default BBAdminForm;
