import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { getEnvVar } from '../helpers/env.js';

const Analytics = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => {
    const id = getEnvVar('GOOGLE_ANALYTICS_KEY');
    ReactGA.initialize(id);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (<>{children}</>);
};

export default Analytics;
