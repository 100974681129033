import Cookies from 'universal-cookie';
const cookies = new Cookies();

const writeCookie = (name, contents, expires) => {
  try {
    if (!expires) {
      expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
    }
    cookies.set(name, contents, {
      path: '/',
      // this caused login loop bug #376 but only on a few peoples machines:
      // secure: env !== 'local' ? true : false,
      // sameSite: 'strict',
      sameSite: 'lax',
      expires
    });
  } catch (err) {
    console.log('set cookie err', err);
  }
};

const readCookie = (name) => {
  return cookies.get(name);
};

const deleteCookie = (name) => {
  cookies.remove(name, { path: '/' });
};

export {
  writeCookie,
  readCookie,
  deleteCookie
};
