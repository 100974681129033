import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import DealDirectionRow from './DealDirectionRow.js';
import { getCardsForSuit } from '../../../helpers/card.js';
import { validSuitToPlay } from '../../../helpers/play.js';

const styles = {
  rootUL: {
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    margin: '0.5em 0 0 0.6em !important'
  },
  rootLI: {
    overflow: 'hidden'
  }
};

const DealDirectionBody = (props) => {
  const { classes, cards, selectable, currentHand, onCardClick, ...other } = props;
  const suits = ['S', 'H', 'D', 'C'];
  return (
    <ul className={classes.rootUL}>
      {
        suits.map((suit, index) => {
          let isSelectable = selectable;
          if (onCardClick && currentHand) {
            isSelectable = selectable && validSuitToPlay(suit, currentHand, cards);
          }
          return (
            <DealDirectionRow
              key={index}
              suit={suit}
              cards={getCardsForSuit(cards, suit)}
              selectable={isSelectable}
              onCardClick={onCardClick}
              {...other}
            />
          );
        })
      }
    </ul>
  );
};

DealDirectionBody.propTypes = {
  classes: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  cards: PropTypes.array.isRequired,
  allPlayed: PropTypes.bool,
  onCardClick: PropTypes.func,
  currentHand: PropTypes.array
};

export default withStyles(styles)(DealDirectionBody);
