import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material';

const FilterPickerLanguage = ({ onContentLanguageChange, contentLanguage, locale }) => {
  if (locale.language !== 'nl-nl') {
    return null;
  }
  const handleContentLanguageChange = (e) => {
    onContentLanguageChange(e.currentTarget.value);
  };
  return (
    <RadioGroup
      aria-labelledby='demo-radio-buttons-group-label'
      value={contentLanguage}
      name='radio-buttons-group'
      onChange={handleContentLanguageChange}
      sx={{ marginBottom: 2 }}
    >
      <FormControlLabel value='nl-nl' control={<Radio />} label='Nederlandse inhoud' />
      <FormControlLabel value='en-gb' control={<Radio />} label='Engelse inhoud' />
    </RadioGroup>
  );
};

export default FilterPickerLanguage;
