import gql from 'graphql-tag';

const userFragments = {
  fullUser: gql`
    fragment FullUser on User {
      id
      firstName
      lastName
      fullName
      email
      language
      type
      dateCreated
      marketingPrefs
      filterSettings
      stripeCustomerId
      stripePaymentMethodId
      confirmed
      currency
      products {
        id
      }
    }
  `
};

export default userFragments;
