import gql from 'graphql-tag';

const collectionQueries = {
  CollectionById: (id) => {
    return gql`
      query {
        collectionById (
          id: ${id}
        ) {
          id
          product {
            id
            type
            title
            description
            dateCreated
            images
          }
          collectionSets {
            id
            product {
              id
              type
              title
              description
              images
              tagsCache
              onSale
              author {
                id
                fullName
                avatarPath
              }
            }
          }
        }
      }
    `;
  }
};

export default collectionQueries;
