import gql from 'graphql-tag';

const introspectionQueries = {
  EnumValues: (enumName) => {
    return gql`
      query {
        __type(name: "${enumName}") {
          name
          enumValues {
            name
          }
        }
      }`;
  }
};

export default introspectionQueries;
