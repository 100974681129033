import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import MiniCard from './MiniCard/MiniCard.js';
import {
  convertPlayToTableRows
} from '../../helpers/play.js';
import BBTranslate from '../Locale/BBTranslate.js';
import { getDirectionTextId } from '../../helpers/translation.js';

const styles = {
  component: {
    textAlign: 'center',
    fontSize: 10,
    position: 'relative',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '0.5em',
    userSelect: 'none',
    '& thead': {
      fontSize: '1.3em',
      '& tr': {
        '& th': {
          padding: '0.5em 0'
        }
      }
    },
    '& tbody': {
      '& tr': {
        background: '#fff',
        '& td': {
          padding: '0.4em 0.2em',
          borderTop: '1px solid #ccc',
          minWidth: '5.7em',
          height: '3.9em',
          textAlign: 'center',
          '&:last-child': {
            paddingRight: '0.4em'
          }
        }
      }
    }
  },
  trickLabel: {
    fontSize: '1.4em'
  },
  rowAction: {
    position: 'absolute',
    left: -50,
    marginTop: -2
  }
};

class Play extends React.Component {
  // not required now we store the step in the play item
  // for now keep for prosperity
  /* getStepNumber = (seat, rowIndex) => {
    const { play, declarer } = this.props;
    let winnerOrFirstToBid = '';
    if (rowIndex === 0) {
      // its the first row so get left of declarer
      winnerOrFirstToBid = firstToPlay(declarer);
    } else {
      const trick = play[rowIndex - 1];
      // winner of last hand is first to play
      winnerOrFirstToBid = _.find(trick, { winner: true }).seat;
    }
    const stepNumber = getStepNumberForPlayItem(
      seat,
      winnerOrFirstToBid,
      rowIndex
    );
    return stepNumber;
  }; */

  render () {
    const { classes, size, play, rowAction, onPlayItemClick } = this.props;
    const directions = ['N', 'E', 'S', 'W'];
    const rows = convertPlayToTableRows(play);
    return (
      <table className={classes.component} cellPadding='0' cellSpacing='0' border='0'>
        <thead>
          <tr>
            <th>&nbsp;</th>
            {
              directions.map((direction, index) => {
                return (
                  <th key={index}><BBTranslate textId={getDirectionTextId(direction)} /></th>
                );
              })
            }
          </tr>
        </thead>
        <tbody>
          {
              rows.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    <td className={classes.trickLabelContainer}>
                      {rowAction && (
                        <div className={classes.rowAction}>
                          {rowAction(rowIndex)}
                        </div>
                      )}
                      <span className={classes.trickLabel}>
                        {rowIndex + 1}
                      </span>
                    </td>
                    {row.map((card, cardIndex) => {
                      if (card !== '') {
                        return (
                          <td key={cardIndex}>
                            <MiniCard
                              size={size}
                              suit={card.suit}
                              value={card.rank}
                              isWinner={card.winner}
                              hasComment={!!card.comments}
                              onClick={() => {
                                if (onPlayItemClick) {
                                  onPlayItemClick(card, rowIndex + 1);
                                }
                              }}
                              tooltipText={onPlayItemClick ? 'Add/Edit play' : null}
                            />
                          </td>
                        );
                      }
                      return (
                        <td key={cardIndex} />
                      );
                    })}
                  </tr>
                );
              })
            }
        </tbody>
      </table>
    );
  }
}

Play.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  play: PropTypes.array.isRequired,
  onPlayItemClick: PropTypes.func,
  rowAction: PropTypes.func,
  declarer: PropTypes.string
};

export default withStyles(styles)(Play);
