import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

const ConfirmModal = (props) => {
  const {
    open,
    title,
    onConfirmClick,
    onCancelClick,
    children
  } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick} color='primary'>{t('cancel')}</Button>
        <Button onClick={onConfirmClick} color='primary'>{t('confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
