import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  FormControl
} from '@mui/material';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import StoreInput from '../../../../../Components/Forms/Store/StoreInput.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  label: {
    fontWeight: 'bold',
    color: '#999',
    '&:focus': {
      color: '#999'
    }
  },
  submit: {
    marginTop: '2.4rem'
  },
  message: {
    padding: '2rem'
  },
  link: {
    float: 'right',
    cursor: 'pointer',
    color: '#999',
    '&:hover': {
      color: '#333'
    }
  },
  formItem: {
    paddingRight: '1rem',
    '@media all and (max-width: 480px)': {
      paddingRight: '.5rem'
    }
  }
}));

const RedeemVoucher = ({ onVoucherApplyClick, isLoading }) => {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [errMessage, setErrMessage] = useState('');

  const handleInputChange = ({ value }) => {
    setCode(value.trim().toUpperCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onVoucherApplyClick(code);
  };

  const isValid = () => {
    if (code && code.length > 1) {
      return true;
    }
    return false;
  };

  const retry = () => {
    setErrMessage('');
    setCode('');
  };

  let message;
  // if (discount > 0 && discount < 100) {
  //   message = `Your discount of ${discount}% has been applied`;
  // } else if (discount === 100) {
  //   message = 'Product discounted by 100% - click below to add to your library.';
  // } else if (errMessage !== '') {
  //   message = errMessage;
  // }
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        {
          message
            ? (
              <>
                <Grid item>
                  <div className={classes.message}>
                    {message}
                  </div>
                </Grid>
                <Grid item>
                  {
                    errMessage
                      ? <span onClick={retry} className={classes.link}>Retry</span>
                      : null
                  }
                </Grid>
              </>
              )
            : (
              <>
                <Grid item xs={6} className={classes.formItem}>
                  <FormControl variant='standard' className={classes.margin}>
                    <StoreInput
                      id='code'
                      label='promo-code'
                      maxLength={20}
                      value={code}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant='standard' className={classes.submit}>
                    <StoreButton
                      type='submit'
                      label='apply-promo'
                      loading={isLoading}
                      disabled={!isValid() || isLoading}
                    />
                  </FormControl>
                </Grid>
              </>
              )
        }
      </Grid>
    </form>
  );
};

RedeemVoucher.propTypes = {
  onVoucherApplyClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default RedeemVoucher;
