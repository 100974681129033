import React from 'react';
import {
  Typography
} from '@mui/material';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import BBTranslate from '../../../../../Components/Locale/BBTranslate.js';

const LeftColumnLogin = ({ user, onLoginClick }) => {
  if (user) {
    return (
      <Typography variant='h5'>
        <BBTranslate textId='logged-in-as' /> {user.firstName} {user.lastName} ({user.email})
      </Typography>
    );
  }
  return (
    <Typography variant='h5'>
      <BBTranslate textId='create-an-account-below-or' />&nbsp;&nbsp;
      <StoreButton
        onClick={onLoginClick}
        color='secondary'
        label='login'
      />
    </Typography>
  );
};

export default LeftColumnLogin;
