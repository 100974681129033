import _ from 'lodash';
import {
  formatPrice
} from './currency.js';
import { writeCookie, readCookie } from './cookie.js';

const env = process.env.REACT_APP_ENV;
const cartCookieName = `bb-cart-${env}`;

const addToCart = (cartItems, newItem) => {
  if (!cartItems) {
    cartItems = [];
  }
  cartItems.push(newItem);
  return cartItems;
};

const removeFromCart = (cartItems, id) => {
  const itemIndex = cartItems.findIndex((item) => item.id === id);
  if (itemIndex > -1) {
    cartItems.splice(itemIndex, 1);
  }
  return cartItems;
};

const persistCart = cart => {
  const cookieString = encodeURIComponent(JSON.stringify(cart));
  writeCookie(cartCookieName, cookieString);
};

const getCart = () => {
  const cookieString = readCookie(cartCookieName);
  if (cookieString) {
    return JSON.parse(decodeURIComponent(cookieString));
  }
  return null;
};

const getPriceAfterDiscount = (price, discount) => {
  let finalPrice = price;
  if (discount && discount > 0) {
    const discountPrice = (discount / 100) * price;
    finalPrice = price - discountPrice;
  }
  return finalPrice;
};

const getProductPriceWithDiscountByCurrency = (currency, price, discount) => {
  return getPriceAfterDiscount(
    price['price' + currency.toUpperCase()],
    discount
  );
};

const getProductPriceByCurrency = (currency, price) => {
  return price['price' + currency.toUpperCase()];
};

const checkIfFreeAfterDiscount = (currency, cartItems) => {
  let total = 0;
  cartItems.forEach(item => {
    total += parseFloat(getProductPriceWithDiscountByCurrency(currency, item.price, item.discount));
  });
  return total === 0;
};

const getTotalBillableAmount = (currency, cartItems) => {
  let total = 0;
  cartItems.forEach(item => {
    total += parseFloat(getProductPriceWithDiscountByCurrency(currency, item.price, item.discount));
  });
  return formatPrice(total, currency);
};

const giftItemsOnly = (cartItems) => {
  const gifts = _.filter(cartItems, (item) => item.gift);
  return gifts.length === cartItems.length;
};

export {
  addToCart,
  removeFromCart,
  persistCart,
  getCart,
  checkIfFreeAfterDiscount,
  getPriceAfterDiscount,
  getProductPriceWithDiscountByCurrency,
  getProductPriceByCurrency,
  getTotalBillableAmount,
  giftItemsOnly
};
