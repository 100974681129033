import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert
} from '@mui/material';

const FeedbackMessage = ({ message }) => {
  if (message === '') {
    return null;
  }
  return (
    <Alert
      severity='success'
      sx={{ m: 3, border: 'solid 1px #2e7d32', borderRadius: '2px' }}
    >
      {message}
    </Alert>
  );
};

FeedbackMessage.protoTypes = {
  type: PropTypes.string.isRequired
};

export default FeedbackMessage;
