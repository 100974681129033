import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import BBTranslate from '../Locale/BBTranslate.js';

const styles = ({
  buttons: {
    paddingTop: 10
  },
  button: {
    marginRight: 10
  }
});

const UndoRedo = (props) => {
  const { classes, onUndoClick, onRedoClick, undoDisabled, redoDisabled } = props;
  return (
    <div className={classes.buttons}>
      <Button
        className={classes.button}
        disabled={undoDisabled}
        variant='outlined'
        onClick={onUndoClick}
      >
        <BBTranslate textId='undo' />
      </Button>
      <Button
        className={classes.button}
        disabled={redoDisabled}
        variant='outlined'
        onClick={onRedoClick}
      >
        <BBTranslate textId='redo' />
      </Button>
    </div>
  );
};

UndoRedo.propTypes = {
  classes: PropTypes.object.isRequired,
  onUndoClick: PropTypes.func.isRequired,
  onRedoClick: PropTypes.func.isRequired,
  undoDisabled: PropTypes.bool.isRequired,
  redoDisabled: PropTypes.bool.isRequired
};

export default withStyles(styles)(UndoRedo);
