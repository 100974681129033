import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { productQueries } from '../../graphql/queries';
import BBQuery from './BBQuery.js';
import { markPurchasedProducts } from '../../helpers/purchase.js';
import { LocaleContext } from '../../Context';

const ProductSearch = ({ children, options, user, noCache, ...other }) => {
  const { locale } = useContext(LocaleContext);
  if (!locale) {
    return null;
  }
  const { currency } = locale;
  options.currency = currency;

  return (
    <BBQuery
      query={productQueries.ProductSearch(options)}
      fetchPolicy='no-cache'
      {...other}
    >
      {(data) => {
        // mark products that have been purchased as 'Purchased'
        let markedProducts = data.productSearch.products.map(product => (
          product.product
        ));
        markedProducts = markPurchasedProducts(markedProducts, user);
        return children(markedProducts);
      }}
    </BBQuery>
  );
};

ProductSearch.propTypes = {
  children: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  user: PropTypes.object,
  loaderComponent: PropTypes.object
};

export default ProductSearch;
