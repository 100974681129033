import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import {
  InputAdornment,
  InputBase,
  InputLabel,
  Box
} from '@mui/material';
import { useTextTranslation } from '../../../Hooks';
import BBTranslate from '../../Locale/BBTranslate';

const useStyles = makeStyles((theme) => ({
  eye: {
    cursor: 'pointer',
    color: theme.palette.error
  },
  hideShow: {
    position: 'absolute',
    right: 20,
    top: 8,
    textDecoration: 'underline',
    fontSize: 16,
    color: 'grey',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const InputBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  marginTop: '2.5rem'
});

const StoreInputLabel = styled(InputLabel)({
  color: '#262626',
  lineHeight: '1em',
  fontSize: 20
});

const StoreInputBase = styled(InputBase)(({ theme, error }) => ({
  'label + &': {
    marginTop: theme.spacing(-0.2)
  },
  '& .MuiInputBase-input': {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: '#fff',
    border: error ? '2px solid' : '1px solid',
    borderColor: error ? theme.palette.error.main : '#E6E6E2',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const StoreInput = (props) => {
  const {
    id,
    label,
    value: propsValue,
    type,
    rows,
    size,
    maxLength,
    inputProps,
    className,
    onChange,
    validate,
    ...rest
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);

  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked);
  };

  const passwordShowHide = () => {
    if (type === 'password') {
      return (
        <InputAdornment position='end' sx={{ marginLeft: 0 }}>
          <span
            className={classes.hideShow}
            onClick={togglePasswordMask}
          >
            <BBTranslate textId={passwordIsMasked ? 'show' : 'hide'} />
          </span>
        </InputAdornment>
      );
    }
    return null;
  };

  const handleChange = e => {
    const thisValue = type === 'number' ? parseInt(e.target.value) : e.target.value;
    const error = validate ? validate(thisValue) : '';
    setValue(thisValue);
    setError(error);
    onChange({
      id,
      value: thisValue,
      error
    });
  };

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  // work out what the type should be
  let thisType = 'text';
  if (type === 'password') {
    thisType = passwordIsMasked ? 'password' : 'text';
  }

  const translatedLabel = useTextTranslation(label);

  return (
    <InputBox>
      <StoreInputLabel shrink htmlFor={id}>
        {translatedLabel}
      </StoreInputLabel>
      <StoreInputBase
        id={id}
        name={id}
        type={thisType}
        value={value}
        error={error !== ''}
        onChange={handleChange}
        onBlur={handleChange}
        onKeyDown={handleChange}
        fullWidth
        multiline={rows && rows > 1}
        inputProps={{
          maxLength
        }}
        endAdornment={passwordShowHide()}
        rows={rows || 1}
        size={size || 'medium'}
        {...rest}
      />
    </InputBox>
  );
};

StoreInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  validate: PropTypes.func,
  onKeyUp: PropTypes.func,
  inputProps: PropTypes.object,
  rows: PropTypes.number,
  size: PropTypes.string,
  placeholder: PropTypes.string
};

export default StoreInput;
