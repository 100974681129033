import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import UnderlineExtension from '@tiptap/extension-underline';
import LinkExtension from '@tiptap/extension-link';
import ToolBar from './ToolBar.js';
import { stripHtml } from '../../../helpers/global.js';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'left',
    position: 'relative',
    marginTop: '1em'
  },
  editor: {
    height: 160,
    borderRadius: 4,
    backgroundColor: '#fff',
    border: '1px solid #ccc'
  },
  editorInvalid: {
    borderColor: theme.palette.error.main
  },
  editorBody: {
    '& .ProseMirror': {
      padding: '1rem',
      height: 109,
      overflow: 'auto',
      fontFamily: 'Roboto',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      '&:focus, &:focus-visible': {
        outline: `2px solid ${theme.palette.styleguide.maroon}`
      }
    }
  },
  caption: {
    color: '#666',
    marginLeft: 3
  },
  captionInvalid: {
    color: theme.palette.error.main,
    marginLeft: 3
  }
}));

const RichText = (props) => {
  const { name, value, label, invalid, onChange } = props;
  const classes = useStyles();

  const getCharCount = (value) => {
    return stripHtml(value).length;
  };

  const handleEditorChange = (html) => {
    const charCount = getCharCount(html);
    setCharCount(charCount);
    onChange({
      name,
      value: html,
      charCount
    });
  };

  let initialValue = '';
  if (value) {
    initialValue = value;
  }

  const [charCount, setCharCount] = useState(getCharCount(initialValue));

  const editor = useEditor({
    extensions: [
      StarterKit,
      UnderlineExtension,
      LinkExtension.configure({
        openOnClick: false
      })
    ],
    onUpdate: ({ editor }) => {
      handleEditorChange(editor.getHTML());
    },
    content: initialValue
  });

  return (
    <div className={classes.container}>
      <Typography
        className={clsx(classes.caption, invalid ? classes.captionInvalid : null)} variant='caption'
      >
        {label}
      </Typography>
      <div className={clsx(classes.editor, invalid && classes.editorInvalid)}>
        <ToolBar editor={editor} charCount={charCount} />
        <div className={classes.editorBody}>
          <EditorContent editor={editor} />
        </div>
      </div>
    </div>
  );
};

RichText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool
};

export default RichText;
