import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#595959',
    borderColor: '#ccc',
    fontSize: '1.8rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    '&:hover, &:focus': {
      zIndex: 1,
      borderColor: '#595959'
    }
  },
  active: {
    color: '#111',
    // !important so it can beat the MUI thing of making
    // one side of the button transparent
    borderColor: '#111 !important',
    zIndex: 2
  }
}));

const ToolBarButton = (props) => {
  const { IconComponent, disabled, onClick, isActive } = props;

  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      size='small'
      className={clsx(classes.button, isActive && classes.active)}
    >
      <IconComponent fontSize='inherit' />
    </Button>
  );
};

export default ToolBarButton;
