import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { getCardTextId } from '../../../helpers/translation.js';

const useStyles = makeStyles({
  component: {
    position: 'relative',
    display: 'block',
    fontSize: '1rem',
    width: '5em',
    height: '3.6em',
    fontWeight: 700,
    borderRadius: '0.5em',
    overflow: 'hidden',
    textTransform: 'uppercase',
    textAlign: 'center',
    background: '#f5f5f5',
    color: '#4a4a4a',
    border: '1px solid #ddd',
    cursor: 'pointer',
    userSelect: 'none',
    '& span:after': {
      display: 'none'
    }
  },
  sizeSmall: {
    height: '3em',
    borderRadius: '0.3em',
    '& span': {
      marginTop: '-0.1em'
    }
  },
  sizeLarge: {
    width: '7.8em',
    height: '5.4em',
    '& span': {
      zoom: 1.5
    }
  },
  isWinner: {
    borderColor: '#f5a623 !important',
    boxShadow: '0 0 5px #f5a623',
    '&:before,&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '2.2em',
      height: '2.2em',
      background: 'transparent url("/img/components/mini_card/bg_overlay_winner.svg") no-repeat right bottom',
      backgroundSize: 'contain'
    }
  },
  hasComment: {
    borderColor: '#bd10e0 !important',
    boxShadow: 'inset 0 0 0 0.1em rgba(255, 255, 255, 0.5), 0 0 0 0.1em #bd10e0',
    '&:before,&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '2.2em',
      height: '2.2em',
      background: 'transparent url("/img/components/mini_card/bg_overlay_has_comment.svg") no-repeat right bottom',
      backgroundSize: 'contain'
    },
    '&:hover': {
      borderColor: '#d616fd !important',
      boxShadow: 'inset 0 0 0 0.1em rgba(255, 255, 255, 0.5), 0 0 0 0.1em #d616fd',
      '&:before,&:after': {
        backgroundImage: 'url("/img/components/mini_card/bg_overlay_has_comment_hover.svg")'
      }
    }
  },
  hasCommentAndIsWinner: {
    borderColor: '#f5a623 !important',
    boxShadow: 'inset 0 0 0 0.1em rgba(255, 255, 255, 0.5), 0 0 0 0.1em #f5a623, 0 0 5px #f5a623',
    '&:before,&:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_overlay_winner_has_comment.svg")'
    },
    '&:hover': {
      borderColor: '#ffbe52 !important',
      boxShadow: 'inset 0 0 0 0.1em rgba(255, 255, 255, 0.5), 0 0 0 0.1em #ffbe52, 0 0 5px #ffbe52',
      '&:after': {
        backgroundImage: 'url("/img/components/mini_card/bg_overlay_winner_has_comment_hover.svg")'
      }
    }
  },
  hasSuit: {
    display: 'inline-block',
    fontSize: '2em',
    lineHeight: '1.7em',
    '&:before,&:after': {
      content: '""',
      display: 'inline',
      padding: '0 0.45em',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center',
      backgroundSize: 'contain'
    }
  },
  textOnly: {
    display: 'inline-block',
    fontSize: '1.5em',
    lineHeight: '2.3em',
    '&:before,&:after': {
      display: 'none'
    }
  },
  isSpade: {
    background: '#ebf1f8',
    color: '#3b76bb',
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_spade.svg")'
    }
  },
  isSpadeAndIsPreview: {
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_spade_preview.svg")'
    }
  },
  isDiamond: {
    background: '#fef6e9',
    color: '#f5a623',
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_diamond.svg")'
    }
  },
  isDiamondAndIsPreview: {
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_diamond_preview.svg")'
    }
  },
  isClub: {
    background: '#ecf1e6',
    color: '#417505',
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_club.svg")'
    }
  },
  isClubAndIsPreview: {
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_club_preview.svg")'
    }
  },
  isHeart: {
    background: '#fcebed',
    color: '#d0021b',
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_heart.svg")'
    }
  },
  isHeartAndIsPreview: {
    '& span:before, & span:after': {
      backgroundImage: 'url("/img/components/mini_card/bg_icon_suit_heart_preview.svg")'
    }
  },
  isNoTrump: {
    '& span': {
      fontSize: '2em',
      lineHeight: '1.7em',
      '&:before,&:after': {
        display: 'none'
      }
    },
    '& i': {
      fontStyle: 'normal',
      fontSize: '0.7em',
      marginLeft: '0.2em'
    }
  },
  isPass: {
    background: '#5d9b19',
    borderColor: '#5d9b19',
    color: '#fff'
  },
  isDouble: {
    background: '#bc1025',
    borderColor: '#bc1025',
    color: '#fff'
  },
  isDoubleDouble: {
    background: '#3367a5',
    borderColor: '#3367a5',
    color: '#fff'
  },
  isBid: {
    '& span:before': {
      display: 'none'
    },
    '& span:after': {
      display: 'inline'
    }
  },
  greyTheme: {
    background: 'transparent',
    border: 'none',
    width: 'auto',
    color: '#fff',
    cursor: 'pointer'
  }
});

const MiniCardBody = ({ size, suit, value, isWinner, hasComment, isBid, theme, scale, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const createConditionalClasses = () => {
    const allClasses = [classes.component];

    if (theme && theme === 'grey') {
      // use grey icons
      allClasses.push(classes.greyTheme);
    }

    switch (size) {
      case 'small':
        allClasses.push(classes.sizeSmall);
        break;
      case 'large':
        allClasses.push(classes.sizeLarge);
        break;
      default:
        allClasses.push(classes.sizeMedium);
        break;
    }

    if (isWinner) {
      allClasses.push(classes.isWinner);
    }

    if (hasComment) {
      allClasses.push(classes.hasComment);
    }

    if (isWinner && hasComment) {
      allClasses.push(classes.hasCommentAndIsWinner);
    }

    if (isBid) {
      allClasses.push(classes.isBid);
    }

    switch (suit) {
      case 'S':
        allClasses.push(classes.isSpade);
        break;
      case 'H':
        allClasses.push(classes.isHeart);
        break;
      case 'D':
        allClasses.push(classes.isDiamond);
        break;
      case 'C':
        allClasses.push(classes.isClub);
        break;
      case 'NT':
        allClasses.push(classes.isNoTrump);
        break;
      default:
        switch (value) {
          case 'Pass':
            allClasses.push(classes.isPass);
            break;
          case 'X':
            allClasses.push(classes.isDouble);
            break;
          case 'XX':
            allClasses.push(classes.isDoubleDouble);
            break;
          default:
            break;
        }
    }

    if (theme && theme === 'grey') {
      // use grey icons
      switch (suit) {
        case 'S':
          allClasses.push(classes.isSpadeAndIsPreview);
          break;
        case 'H':
          allClasses.push(classes.isHeartAndIsPreview);
          break;
        case 'D':
          allClasses.push(classes.isDiamondAndIsPreview);
          break;
        case 'C':
          allClasses.push(classes.isClubAndIsPreview);
          break;
        case 'NT':
          allClasses.push(classes.isNoTrump);
          break;
        default:
          // dont add a class
      }
    }
    return allClasses.join(' ');
  };
  return (
    <span
      style={{ fontSize: scale || '1em' }}
      onClick={() => {
        if (onClick) {
          onClick(value, suit);
        }
      }}
      className={value !== '' && createConditionalClasses()}
    >
      {
        suit
          ? suit === 'NT'
            ? <span className={classes.textOnly}>{t(getCardTextId(value))}<i>{t('nt-no-trump')}</i></span>
            : <span className={classes.hasSuit}>{t(getCardTextId(value))}</span>
          : <span className={classes.textOnly}>{value === 'Pass' ? t('pass') : value}</span>
      }
    </span>
  );
};

MiniCardBody.propTypes = {
  scale: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  size: PropTypes.string,
  isWinner: PropTypes.bool,
  hasComment: PropTypes.bool,
  suit: PropTypes.string,
  value: PropTypes.string,
  isBid: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.string
};

export default MiniCardBody;
