import gql from 'graphql-tag';
import { purchaseFragments } from '../fragments';

const purchaseQueries = {
  PurchaseSearch: (options) => {
    return gql`
      query {
        purchaseSearch (
          input: {
            searchTerm: "${options.searchTerm}"
            dateFrom: "${options.dateFrom}"
            dateTo: "${options.dateTo}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          purchases {
            id
            stripeId
            purchaseDate
            totalPrice
            totalPriceFormatted
            currency
            status
            failureMessage
            paymentType
            purchaseItems {
              id
              product {
                id
                title
              }
            }
            user {
              id
              fullName
            }
          }
        }
      }
    `;
  },
  PurchaseById: (id) => {
    return gql`
      query {
        purchaseById (id: ${id}) {
          ...FullPurchase
        }
      }
      ${purchaseFragments.fullPurchase}
    `;
  },
  PurchaseByStripeId: gql`
    query (
      $stripeId: String!
    ) {
      purchaseByStripeId (stripeId: $stripeId) {
        ...FullPurchase
      }
    }
    ${purchaseFragments.fullPurchase}
  `
};

export default purchaseQueries;
