import React, { useContext } from 'react';
import {
  Link,
  Typography
} from '@mui/material';
import SendResetPassword from '../../../Components/SendResetPassword.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import { DrawerContext } from '../../../Context';

const ForgottenPassword = () => {
  const { openDrawer } = useContext(DrawerContext);
  const handleReturnToLoginClick = () => {
    openDrawer('login');
  };
  return (
    <>
      <Typography variant='body1' sx={{ marginBottom: '2rem' }}>
        <BBTranslate textId='enter-email-address-reset-password' />
      </Typography>
      <SendResetPassword />
      <Link
        component='button'
        variant='body1'
        onClick={handleReturnToLoginClick}
      >
        <BBTranslate textId='return-to-login' />
      </Link>
    </>
  );
};

export default ForgottenPassword;
