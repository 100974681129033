import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PlayGrid from './PlayGrid.js';

class Step2 extends React.Component {
  handleAuctionOnlyChange = () => {
    let auctionOnly = !this.props.hand.auctionOnly;
    this.props.onChange({
      auctionOnly: auctionOnly
    });
  }

  render () {
    const { hand, ...other } = this.props;
    const { auctionOnly } = this.props.hand;
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Switch
              checked={auctionOnly}
              onChange={this.handleAuctionOnlyChange}
              color='primary'
            />
          }
          label='Auction only'
        />
        <PlayGrid
          hand={hand}
          {...other}
        />
      </React.Fragment>
    );
  };
}

Step2.propTypes = {
  hand: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onUnavedComment: PropTypes.func.isRequired,
  onCommentsSaved: PropTypes.func.isRequired,
  showPreview: PropTypes.bool,
  onPreviewClick: PropTypes.func
}

export default Step2;
