import React from 'react';
import { withStyles } from '@mui/styles';

const styles = {
  container: {
    display: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,0.8)',
    zIndex: 100000,
    '@media all and (max-width : 900px)': {
      display: 'block'
    }
  },
  icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '39.3rem',
    height: '35.7rem',
    marginLeft: '-19.65rem',
    marginTop: '-17.85rem',
    background: 'transparent url("/img/components/rotate/rotate.svg") no-repeat left top',
    backgroundSize: 'contain',
  }
};

class Rotate extends React.Component {
  state = {
    orientation: window.orientation
  };

  updateWindowOrientation = () => {
    this.setState({
      orientation: window.orientation
    });
  };

  componentDidMount() {
    this.updateWindowOrientation();
    window.addEventListener('orientationchange', this.updateWindowOrientation);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.updateWindowOrientation);
  }

  render () {
    const { classes } = this.props;
    const { orientation } = this.state;
    return (
      <>
        {
          orientation === 90 || orientation === 270 // it's landscape
          ?
          <div className={classes.container}>
            <div className={classes.icon} />
          </div>
          :
          null
        }
      </>
    );
  }
}

export default withStyles(styles)(Rotate);
