import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ClockwiseIcon from '@mui/icons-material/Refresh';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import BBTranslate from '../Locale/BBTranslate.js';

const styles = {
  middle: {
    paddingTop: 6,
    color: 'black !important'
  }
};

const Viewpoint = (props) => {
  const { classes, onClick } = props;
  return (
    <ButtonGroup size='small' aria-label='Small outlined button group'>
      <Button
        className={classes.middle}
        onClick={() => {
          onClick('cw');
        }}
      >
        <BBTranslate textId='rotate-viewpoint' />
        <ClockwiseIcon />
      </Button>
    </ButtonGroup>
  );
};

Viewpoint.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(Viewpoint);
