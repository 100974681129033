import React from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

const BBTableHeadCell = ({ column, ...other }) => {
  const { order, orderBy, numSelected, rowCount, onColumnSort } = { ...other };
  const createSortHandler = property => event => {
    onColumnSort(event, property);
  };

  if (column.type === 'checkbox' && column.selectAll === true) {
    return (
      <TableCell padding='checkbox'>
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={numSelected === rowCount}
        />
      </TableCell>
    );
  } else {
    return (
      <TableCell
        style={{ width: column.width ? column.width : null }}
        key={column.id}
        align='left'
        sortDirection={orderBy === column.sortColumn ? order : false}
      >
        {
          column.sortColumn
            ? (
              <Tooltip
                title='Sort'
                placement='bottom-end'
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === column.sortColumn}
                  direction={order}
                  onClick={createSortHandler(column.sortColumn)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
              )
            : column.label
        }
      </TableCell>
    );
  }
};

BBTableHeadCell.propTypes = {
  column: PropTypes.object.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onColumnSort: PropTypes.func.isRequired
};

export default BBTableHeadCell;
