import React, { useContext, useEffect } from 'react';
import {
  Link
} from '@mui/material';
// import { ReactTitle } from 'react-meta-tags';
import LoginForm from './LoginForm.js';
import TwoColumn from '../../../Components/Layout/TwoColumn.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import { DrawerContext } from '../../../Context';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../../helpers/auth.js';

const Login = () => {
  const navigate = useNavigate();
  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const handleForgottenPasswordClick = () => {
    openDrawer('forgotten');
  };
  const handleRegisterClick = () => {
    closeDrawer();
    navigate('/register');
  };

  useEffect(() => {
    const loggedIn = isAuthenticated();
    if (loggedIn) {
      navigate('/library');
    }
  }, [navigate]);

  return (
    <TwoColumn
      title='login'
      description={
        <>
          <p>
            <BBTranslate textId='please-login-to-view-content' />
          </p>
          <p>
            <BBTranslate textId='forgotten-your-password' />&nbsp;
            <Link
              style={{ paddingBottom: 3 }}
              component='button'
              variant='body1'
              onClick={handleForgottenPasswordClick}
            >
              <BBTranslate textId='reset-it-here' />
            </Link>
          </p>
          <p>
            <BBTranslate textId='new-customer' />&nbsp;
            <Link
              style={{ paddingBottom: 3 }}
              component='button'
              variant='body1'
              onClick={handleRegisterClick}
            >
              <BBTranslate textId='register-here' />
            </Link>
            <br />
            (<BBTranslate textId='or-you-can-register-when-you-buy' />)
          </p>
        </>
      }
      form={
        <LoginForm />
      }
    />
  );
};

export default Login;
