import React from 'react';
import { adopt } from 'react-adopt';
import { useNavigate, useParams } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../graphql';
import ProductForm from './ProductForm.js';
import { BBSnackbarConsumer } from '../../../Components/BBSnackbar/BBSnackbarContext.js';
import EnumValues from '../../../Components/Queries/EnumValues.js';
import Tags from '../../../Components/Queries/Tags.js';
import Prices from '../../../Components/Queries/Prices.js';
import Authors from '../../../Components/Queries/Authors.js';

const AddProduct = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const Composed = adopt({
    one: ({ render }) => <Authors>{render}</Authors>,
    two: ({ render }) => <Tags>{render}</Tags>,
    three: ({ render }) => <EnumValues name='LanguageEnum'>{render}</EnumValues>,
    four: ({ render }) => <Prices>{render}</Prices>
  });
  return (
    <Composed>
      {({ one, two, three, four }) => (
        <Mutation
          mutation={mutations.UpsertProduct}
        >
          {upsertProduct => (
            <>
              <BBSnackbarConsumer>
                {({ openSnackbar }) => (
                  <ProductForm
                    upsertProduct={upsertProduct}
                    product={{
                      title: '',
                      description: '',
                      authorId: null,
                      priceId: null,
                      tags: [],
                      language: '',
                      onSale: false,
                      promoted: false,
                      images: null
                    }}
                    type={type}
                    authors={one}
                    tags={two}
                    languageEnum={three}
                    prices={four}
                    onFormSubmitted={(productType, productId, productTitle) => {
                      return navigate(`/admin/products/${productType.toLowerCase()}/${productId}/${productTitle}`);
                    }}
                    openSnackbarYouGibbon={openSnackbar}
                  />
                )}
              </BBSnackbarConsumer>
            </>
          )}
        </Mutation>
      )}
    </Composed>
  );
};

export default AddProduct;
