import React from 'react';
import PropTypes from 'prop-types';
import GridView from './GridView.js';
import ListView from './ListView.js';
// import { useOrientation } from '../../Hooks';

const StoreItems = (props) => {
  // const isPortrait = useOrientation();
  const isPortrait = false;
  if (isPortrait) {
    return <ListView {...props} />;
  } else {
    return <GridView {...props} />;
  }
};

StoreItems.propTypes = {
  products: PropTypes.array,
  count: PropTypes.number,
  onClick: PropTypes.func,
  onColumnSort: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  limit: PropTypes.number,
  offset: PropTypes.number,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  cssClasses: PropTypes.array,
  hidePrice: PropTypes.bool
};

export default StoreItems;
