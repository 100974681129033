import React from 'react';
import { SvgIcon } from '@mui/material';

const Twitter = (props) => {
  return (
    <SvgIcon {...props} height='100' viewBox='0 0 100 100' width='100'>
      <path d='m42.62875 74c20.01333 0 30.95624-16.5472076 30.95624-30.8879446 0-.4727774 0-.9455548-.0226133-1.3959448 2.1208833-1.5307974 3.9711133-3.4444704 5.4376233-5.6282012-1.94033.8554767-4.0387767 1.4408956-6.2499367 1.7109534 2.2563867-1.3508176 3.9711134-3.467034 4.7834267-6.0109005-2.0984467 1.238176-4.42232 2.1386036-6.90431 2.6339445-1.9855567-2.1162163-4.8058633-3.4219068-7.94205-3.4219068-6.00172 0-10.8752467 4.8628025-10.8752467 10.8513157 0 .8554767.0902767 1.6883899.2932667 2.4763521-9.04763-.4502137-17.05752-4.7727244-22.4273033-11.3466566-.9252034 1.5984881-1.4666867 3.467034-1.4666867 5.4482214 0 3.7596553 1.9178933 7.0916604 4.85109 9.0277207-1.7825667-.0449508-3.4522433-.5402918-4.9187533-1.3506413v.1350289c0 5.2680654 3.7453333 9.6355269 8.73175 10.6487724-.9024134.2474942-1.8726667.3826993-2.8653567.3826993-.6996 0-1.37641-.0675144-2.05322-.2027196 1.3762333 4.3225107 5.3923967 7.4519725 10.15321 7.5418742-3.7228967 2.9043549-8.41587 4.6378719-13.5151767 4.6378719-.8799766 0-1.73734-.0451272-2.5947033-.1575925 4.7608133 3.106722 10.4917033 4.9077534 16.62875 4.9077534z' />
    </SvgIcon>
  );
};

export default Twitter;
