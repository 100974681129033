import React from 'react';
import {
  Container,
  Typography
} from '@mui/material';
import GridView from '../Components/Store/GridView';

const TestGrid = () => {
  const products = [
    {
      'description': 'Excitement rivaled only by all the beauty nature is presenting this Spring. Insights into life in general, defense, declaring and bidding. Go for it!',
      'id': 993,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/Kr3YLpRCe.jpg'
      },
      'title': 'Martis Mutterings - May 2023',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Intermediate',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'Exciting hands, Life Lessons, perfect for improving our games.',
      'id': 979,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/4Cf8jl-n6.jpg'
      },
      'title': 'Martis Mutterings June 23',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Intermediate',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'Another month of the thrill of victory and the agony of defeat. A months worth of hands to stimulate discussion between partners and trigger some new thoughts. Enjoy!!',
      'id': 973,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/Ajt_YM_L1.jpg'
      },
      'title': 'Martis Mutterings - April 2023',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Intermediate',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'A compendium of The Good, The Bad and The Ugly. A mixture of declarer play where we pull tricks out of thin air, and defender play where we are determined to stomp them like grapes. All in good fun, and we wont take ourselves too seriously. By the end of the month, well be a little better and more reliable in our game. Our partners will appreciate that!',
      'id': 925,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/Qbfg-qTus.jpg'
      },
      'title': 'Martis Mutterings - March 2023',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Improver',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'Its the dead of winter and were bored to death. Never fear! Martis Mutterings will help our day sparkle. We begin with a quirky essay and finish with a hand designed to elucidate and advance a LIFE LESSON. And cheer up. Winter hardly ever lasts forever. It just seems that way.',
      'id': 899,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/5awR9soSp.jpg'
      },
      'title': 'Martis Mutterings - February 2023',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Improver',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'We start the new year with a commitment to improving our game, little by little. These hands are filled with Life Lessons for Faithful Readers. And well have some laughs along the way.',
      'id': 888,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/751huOWaR.jpg'
      },
      'title': 'Martis Mutterings - January 2023',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Improver',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'A hand for every weekday to get the ole noggin working at full-speed. We enjoy the thrill of victory, the agony of defeat, learn a little, enjoy a lot. ',
      'id': 879,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/BQNlJXKSv.jpg'
      },
      'title': 'Martis Mutterings - December 2022',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Improver',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'An exciting, dare we say \'THRILLING!\' mish-mash of hands, all geared to illuminate, confuse, educate, and start our brains seeing things a wee bit differently.',
      'id': 861,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/lUEU_FfLY.jpg'
      },
      'title': 'Martis Mutterings - November 2022',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Improver',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'A wide-ranging sample of what can go wrong... and right! ... in bridge. ',
      'id': 844,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/8aasJeG2z.jpg'
      },
      'title': 'Martis Mutterings: October 2022',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Intermediate',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    },
    {
      'description': 'The \'Back to School\' edition of fun and frolic. A month of learning, laughing.',
      'id': 835,
      'productType': 'Set',
      'images': {
        'cover': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/artwork/HxPfGASv7.jpg'
      },
      'title': 'Martis Mutterings - September 2022',
      'priceLocal': 12.99,
      'priceLocalFormatted': '£12.99',
      'author': {
        'id': 3,
        'fullName': 'Marti Ronemus',
        'avatarPath': 'https://s3.us-east-2.amazonaws.com/live-uploads.bridgebee.app/avatars/smaP14vam.jpg',
        '__typename': 'Author'
      },
      'tagsCache': [
        {
          'name': 'Standard American',
          'group': 'System'
        },
        {
          'name': 'Intermediate',
          'group': 'Difficulty'
        },
        {
          'name': 'Mixed',
          'group': 'Type'
        }
      ],
      '__typename': 'DynamoProduct'
    }
  ];

  return (
    <>
      <Typography>NO WRAP</Typography>
      <Container sx={{ maxWidth: 1180 }}>
        <GridView
          nowrap
          products={products}
        />
      </Container>
      <Typography>WRAP</Typography>
      <Container sx={{ maxWidth: 1180 }}>
        <GridView
          products={products}
        />
      </Container>
    </>
  );
};

export default TestGrid;
