import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    maxWidth: '128rem',
    margin: '0 auto'
  }
}));

const Container = (props) => {
  const { className, children } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className || null)}>
      {children}
    </div>
  );
};

export default Container;
