import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import AuthorListItem from './AuthorListItem.js';

const AuthorList = ({ authors }) => {
  let filteredAuthors = _.filter(authors, (author) => (
    author.products.length > 0
  ));

  filteredAuthors = filteredAuthors.slice(0, 5);

  return (
    <Grid
      container
      columns={10}
      spacing={4}
    >
      {filteredAuthors.map(author => (
        <Grid
          item
          key={author.id}
          md={2}
          sm={3}
          xs={5}
        >
          <AuthorListItem
            author={author}
          />
        </Grid>
      ))}
    </Grid>
  );
};

AuthorList.propTypes = {
  authors: PropTypes.array.isRequired
};

export default AuthorList;
