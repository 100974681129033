import {
  useOrientation,
  useComponentScale,
  useScreenDimensions,
  useAspectRatio
} from '../Hooks';

const HooksWrapper = (props) => {
  const { children } = props;
  const isPortrait = useOrientation();
  const componentScale = useComponentScale();
  const screenDimensions = useScreenDimensions();
  const aspectRatio = useAspectRatio();
  return children({ isPortrait, componentScale, screenDimensions, aspectRatio });
};

export default HooksWrapper;
