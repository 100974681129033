import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#592F3F'
  },
  itemContainer: {
    maxWidth: '118rem',
    margin: '0 auto',
    padding: '6.5rem 0'
  },
  item: {
    '&:first-child': {
      borderLeft: 0,
      paddingLeft: 0
    },
    '&:last-child': {
      paddingRight: 0
    },
    paddingRight: '4rem',
    borderLeft: '1px #FFFFFF solid',
    color: '#FFFFFF',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      borderLeft: 0,
      margin: '0 1rem',
      padding: '3rem 0',
      borderBottom: '1px #fff solid',
      '&:first-child': {
        paddingTop: 0
      },
      '&:last-child': {
        paddingBottom: 0,
        borderBottom: 0
      }
    }
  },
  heading: {
    color: '#FFB73B',
    fontSize: '3rem',
    marginBottom: '0.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.4rem'
    }
  },
  body: {
    color: '#fff',
    marginBottom: 0
  }
}));

const FeaturesBrief = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Grid
        className={classes.itemContainer}
        container
        // 0 prevents the borders from extending past the top of the columns
        rowSpacing={{ md: 0, xs: '3rem' }}
        columnSpacing={{ xs: 0, md: 5 }}
      >
        <Grid item xs={12} md={4} className={classes.item}>
          <Typography
            variant='h3'
            className={classes.heading}
          >
            {t('intuitive-to-use')}
          </Typography>
          <Typography
            variant='body1'
            className={classes.body}
          >
            {t('from-purchasing-to-playing')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.item}>
          <Typography
            variant='h3'
            className={classes.heading}
          >
            {t('interactive-learning')}
          </Typography>
          <Typography
            variant='body1'
            className={classes.body}
          >
            {t('improve-your-game-quickly')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.item}>
          <Typography
            variant='h3'
            className={classes.heading}
          >
            {t('personal-library')}
          </Typography>
          <Typography
            variant='body1'
            className={classes.body}
          >
            {t('for-easy-access-on-the-go')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default FeaturesBrief;
