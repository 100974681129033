export const stripTypeNameFromMutationVars = (vars) => {
  // having the "__typename":"Tag" appera in nested variables e.g. tags in a a product
  // causes error when running mutation through apollo server
  // (can be recreated in playground)
  // error: 'mutation field "__typename" is not defined by type'
  // this is a known issue with apollo and details can be found here:
  // https://github.com/apollographql/apollo-feature-requests/issues/6
  return removeTypename(vars);
};

const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeTypename(v));
  } else if (typeof value === 'object') {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};
