import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import DealerDirection from './DealerDirection.js';

const styles = {
  component: {
    position: 'relative',
    fontSize: 10,
    userSelect: 'none',
    width: '11.6em',
    height: '11.6em'
  }
};

class Dealer extends React.Component {
  render () {
    const { classes, ...other } = this.props;
    const directions = ['N', 'E', 'S', 'W'];
    return (
      <div className={classes.component}>
        {
          directions.map((direction, index) => {
            return (
              <DealerDirection
                key={index}
                direction={direction}
                {...other}
              />
            );
          })
      }
      </div>
    );
  }
}

Dealer.propTypes = {
  classes: PropTypes.object.isRequired,
  dealer: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(Dealer);
