import React from 'react';
import { useNavigate } from 'react-router-dom';
import { purchaseQueries } from '../../../graphql/queries';
import AdminPage from '../../../Components/AdminPage.js';

const Purchases = () => {
  const naviagte = useNavigate();
  const columns = [
    { id: 'id', label: 'Id', sortColumn: 'id' },
    { id: 'user.fullName', label: 'Customer', sortColumn: 'user.last_name' },
    { id: 'purchaseItems.product.title', label: 'Title', sortColumn: 'purchaseItems->product.title' },
    { id: 'purchaseDate', label: 'Date', sortColumn: 'purchase_date' },
    { id: 'totalPriceFormatted', label: 'Price', sortColumn: 'price' },
    { id: 'status', label: 'Status', sortColumn: 'status' }
  ];
  const dateFilterOptions = [
    { label: 'Today', unit: 'day', dateRange: { from: 0, to: 0 } },
    { label: 'Yesterday', unit: 'day', dateRange: { from: 1, to: 1 } },
    { label: 'Last 7 days', unit: 'day', dateRange: { from: 7, to: 1 } },
    { label: 'Last 30 days', unit: 'day', dateRange: { from: 30, to: 1 } },
    { label: 'Custom', unit: null, dateRange: null }
  ];
  
  const handleRowClick = (item) => {
    naviagte(`/admin/sales/${item.id}`);
  };

  return (
    <>
      <AdminPage
        columns={columns}
        query={purchaseQueries.PurchaseSearch}
        initialOrderBy='id'
        rowsDataPath='purchaseSearch.purchases'
        countDataPath='purchaseSearch.count'
        initialOrderByDirection='desc'
        showDates
        dateFilterOptions={dateFilterOptions}
        sectionName='sales'
        onRowClick={handleRowClick}
      />
    </>
  );
}

export default Purchases;
