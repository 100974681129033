import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useOrientation } from '../../Hooks';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      fontSize: 10,
      lineHeight: 10,
      overflowX: 'hidden',
      '@media all and (min-width: 1680px)': {
        fontSize: 11
      }
    },
    body: {
      fontSize: '1.6rem',
      fontFamily: '"Roboto", "helvetica neue", helvetica, arial, sans-serif',
      lineHeight: '2rem',
      background: theme.palette.styleguide.beige
    }
  },
  header: {
    position: 'relative',
    height: '7rem',
    paddingRight: '2rem',
    background: '#232a2c',
    color: '#fff',
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: '-1rem',
      right: 0,
      height: '1rem',
      background: 'radial-gradient(farthest-side at 50% 0, rgba(255,255,255,0.1), transparent)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 1rem',
      pointerEvents: 'none'
    }
  },
  headerMobile: {
    height: '5rem',
    padding: 0
  },
  logo: {
    width: '15rem',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      verticalAlign: 'middle'
    }
  },
  logoMobile: {
    width: '5rem',
    padding: '0 1rem',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      verticalAlign: 'middle'
    }
  },
  grid: {
    marginLeft: 20,
    height: '100%'
  }
}));

const EmbedAppBar = ({ children }) => {
  const classes = useStyles();
  const isPortrait = useOrientation();
  return (
    <div
      className={clsx(classes.header, isPortrait && classes.headerMobile)}
      id='playHeader'
    >
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        className={classes.grid}
      >
        {
        isPortrait
          ? (
            <Grid item className={classes.logoMobile}>
              <img src='/img/components/header/logo_icon.svg' alt='bridgebee logo' />
            </Grid>
            )
          : (
            <Grid item className={classes.logo}>
              <img src='/img/components/footer/logo.svg' alt='bridgebee logo' />
            </Grid>
            )
        }
      </Grid>
    </div>
  );
};

export default EmbedAppBar;
