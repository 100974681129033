import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { ReactTitle } from 'react-meta-tags';
import { useParams } from 'react-router-dom';
import { Avatar, Grid, Typography } from '@mui/material';
import AuthorLink from './Components/AuthorLink.js';
import AuthorById from '../../../Components/Queries/AuthorById.js';
import DynamoProductSearch from '../../../Components/Queries/DynamoProductSearch.js';
import GridViewSkeleton from '../../../Components/Skeletons/GridViewSkeleton.js';
import Container from '../../../Components/Store/Container.js';
import GridView from '../../../Components/Store/GridView.js';
import SuitPattern from '../../../Components/SuitPattern.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import { LocaleContext } from '../../../Context';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#4788B1',
    ...theme.extendableBackground,
    display: 'flex',
    // move up the header and allow it to appear behind the breadcrumbs
    top: -91,
    paddingTop: 91,
    zIndex: -1,
    // shorten it, allowing for the author avatar to stick out
    height: 247,
    overflow: 'visible',
    marginBottom: '4rem',
    [theme.breakpoints.down('md')]: {
      // slightly hacky way of doing all of the above _and_ pulling the content up a little
      marginBottom: '-61px'
    }
  },
  fullNameHeading: {
    fontSize: '3.8rem',
    fontWeight: 600,
    color: '#fff',
    marginTop: 0,
    marginLeft: '4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.4rem'
    }
  },
  suitPatternContainer: {
    height: '100%',
    width: 500,
    position: 'absolute',
    overflowY: 'hidden',
    top: 0,
    right: -50,
    [theme.breakpoints.down('md')]: {
      right: -300
    }
  },
  suitPattern: {
    left: 0
  },
  avatar: {
    width: 219,
    height: 219,
    marginBottom: '2.5rem',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: 124,
      height: 124
    }
  },
  bioContainer: {
    marginTop: '4rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  linksList: {
    float: 'left',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  linksListItem: {
    margin: 0,
    padding: 0
  },
  bio: {
    fontSize: '2rem',
    lineHeight: '2.8rem',
    paddingTop: '1rem',
    paddingRight: '12rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: '8rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: '3.5rem'
    }
  },
  groupHeading: {
    color: '#613344',
    margin: '5rem 0 4rem 0'
  },
  products: {
    // close up the gap between the products and the footer
    // otherwise there's a weird line of coloured background between them
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem'
    }
  }
}));

const Author = () => {
  const classes = useStyles();
  const params = useParams();
  const { authorId } = params;

  const { locale } = useContext(LocaleContext);

  if (!locale) {
    return null;
  }

  const getAuthorTag = (authorName) => {
    return [[], [], [], [`${authorName}`]];
  };

  return (
    <AuthorById id={parseInt(authorId)}>
      {
        data => {
          return (
            <div>
              <ReactTitle title={data.fullName} />
              <Container>
                <div className={classes.header}>
                  <Avatar
                    alt={data.fullName}
                    src={data.avatarPath}
                    className={classes.avatar}
                  />
                  <Typography variant='h1' className={classes.fullNameHeading}>
                    {data.fullName}
                  </Typography>
                  <div className={classes.suitPatternContainer}>
                    <SuitPattern
                      className={classes.suitPattern}
                      opacity={0.1}
                    />
                  </div>
                </div>
                <Grid container className={classes.bioContainer}>
                  <Grid item xs={12} sm={9} className={classes.bio}>
                    <Typography variant='body1'>
                      {data.bio}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ul className={classes.linksList}>
                      {
                        data.authorLinks.map((link, index) => {
                          return (
                            <li key={index} className={classes.linksListItem}>
                              <AuthorLink link={link} />
                            </li>
                          );
                        })
                      }
                    </ul>
                  </Grid>
                </Grid>

                <div className={classes.products}>
                  <Typography variant='h1' className={classes.groupHeading}>
                    <BBTranslate textId={['products-by', data.fullName]} />
                  </Typography>
                  <DynamoProductSearch
                    options={{
                      orderBy: 'id',
                      // order in ascending order unless nothing ("id") is picked
                      // in which case this shows latest products first
                      orderByDirection: 'desc',
                      tags: getAuthorTag(data.fullName),
                      pagination: {
                        pageSize: 10000
                      },
                      language: data.language,
                      currency: locale.currency
                    }}
                  >
                    {({ products, loading }) => (
                      <>
                        {
                        loading
                          ? (
                            <GridViewSkeleton />
                            )
                          : (
                            <GridView
                              loading
                              products={products}
                            />
                            )
                        }
                      </>
                    )}
                  </DynamoProductSearch>
                </div>
              </Container>
            </div>
          );
        }
      }
    </AuthorById>
  );
};

export default Author;
