import React from 'react';
import {
  Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cardIcon: {
    height: '20px',
    margin: '6px 2px',
    width: '33px',
    '@media all and (max-width: 480px)': {
      height: '14px',
      width: '22px',
      margin: '5px 0'
    }
  },
  iconRow: {
    justifyContent: 'right',
    width: 120,
    flexWrap: 'nowrap',
    '@media all and (max-width: 480px)': {
      width: 66
    }
  }
});

const AcceptedCards = () => {
  const classes = useStyles();
  const imagePath = '/img/components/card_details/icon_';
  const cards = ['visa', 'mastercard', 'amex'];

  return (
    <Grid
      container
      item
      direction='row'
      className={classes.iconRow}
    >
      {cards.map((card, index) =>
        <Grid key={index} item>
          <img
            src={`${imagePath}${card}.png`}
            alt={card}
            className={classes.cardIcon}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AcceptedCards;
