import React from 'react';
import PropTypes from 'prop-types';
import BBTranslate from '../../Locale/BBTranslate.js';

const AttachmentMessage = (props) => {
  const { text, onClick } = props;

  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a href='/reveal' onClick={handleClick}><BBTranslate textId={text} /></a>
  );
};

AttachmentMessage.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AttachmentMessage;
