import React, { useState } from 'react';

const DrawerContext = React.createContext();

const DrawerProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [componentProps, setComponentProps] = useState({});
  const openDrawer = (type, componentProps) => {
    setType(type);
    setOpen(true);
    setComponentProps(componentProps || {});
  };
  const closeDrawer = () => {
    setOpen(false);
  };
  return (
    <DrawerContext.Provider value={{
      open,
      type,
      componentProps,
      openDrawer,
      closeDrawer
    }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export { DrawerContext, DrawerProvider };
