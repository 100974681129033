import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';
import { isAuthenticated, isAuthorised } from '../helpers/auth.js';

const RequireAuth = ({ authorisedUserTypes, children }) => {
  const { user } = useContext(UserContext);
  const canViewRoute = isAuthenticated() && isAuthorised(authorisedUserTypes, user);
  return canViewRoute ? children : <Navigate to='/login' />;
};

export default RequireAuth;
