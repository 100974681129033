import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import BBDateRange from './BBDateRange.js';
import BBDatePicker from './BBDatePicker.js';

const useStyles = makeStyles({
  grid: {
    width: '100%',
    justifyContent: 'flex-start'
  },
  dates: {
    marginBottom: 30,
    marginLeft: 5
  }
});

const BBDateFilters = (props) => {
  const {
    from,
    to,
    dateFilterOptions,
    onChange,
    range
  } = props;

  const classes = useStyles();

  const handleDateFromChange = date => {
    handleDateChanges({ from: moment(date).startOf('day') });
  };

  const handleDateToChange = date => {
    handleDateChanges({ to: moment(date).endOf('day') });
  };

  const handleDateRangeChange = range => {
    let newFrom = moment(from);
    let newTo = moment(to);

    if (dateFilterOptions) {
      const selectedRange = dateFilterOptions.find(option => option.label === range.value);
      const { dateRange, unit } = selectedRange;

      if (dateRange) {
        // absolute date, e.g. 2022-09-10 -> 2022-09-22
        // it's possible these will already come in a moment format but that's fine:
        // moment(moment(a)) == moment(a)
        if ((typeof dateRange.from === 'object' && typeof dateRange.to === 'object') ||
          (typeof dateRange.from === 'string' && typeof dateRange.to === 'string')) {
          newFrom = moment(dateRange.from);
          newTo = moment(dateRange.to);
        } else if (typeof dateRange.from === 'number' && typeof dateRange.to === 'number' && unit) {
          // relative date, e.g. 7 days ago -> yesterday
          newFrom = moment().subtract(dateRange.from, unit);
          newTo = moment().subtract(dateRange.to, unit);
        } else {
          // something went weird or no selection - default to today so at least things don't break
          newFrom = moment();
          newTo = moment();
        }
      }
    }

    newFrom = newFrom || moment();
    newTo = newTo || moment();

    handleDateChanges({
      from: newFrom.startOf('day'),
      to: newTo.endOf('day'),
      range: range.value
    });
  };

  const handleDateChanges = (changes) => {
    onChange(changes);
  };

  // ---------------------------------------------------------------
  // weird ass bug where sometimes the dates are coming is a strings
  let newFrom, newTo;
  if (typeof from === 'string') {
    newFrom = moment(from);
  }
  if (typeof to === 'string') {
    newTo = moment(to);
  }
  // ---------------------------------------------------------------
  return (
    <Grid className={classes.dates} container spacing={3}>
      {
        range
          ? (
            <Grid item xs={2} sx={{ margin: '0 !important', padding: '0 !important' }}>
              <BBDateRange
                onChange={handleDateRangeChange}
                selectedRange={range}
                dateFilterOptions={dateFilterOptions}
              />
            </Grid>
            )
          : null
      }
      <Grid item xs={2}>
        <BBDatePicker
          label='Date from'
          date={newFrom}
          onChange={handleDateFromChange}
        />
      </Grid>
      <Grid item xs={2}>
        <BBDatePicker
          label='Date to'
          date={newTo}
          onChange={handleDateToChange}
        />
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
};

BBDateFilters.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  from: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  range: PropTypes.string,
  dateFilterOptions: PropTypes.array
};

export default BBDateFilters;
