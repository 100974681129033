import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteButton from '../DeleteButton.js';
import StoreButton from './StoreButton.js';
import { stripTypeNameFromMutationVars } from '../../../helpers/gql';

const StoreForm = (props) => {
  const {
    fields: propFields,
    children,
    onDelete,
    onCancelClick,
    cancelLabel,
    onValidate,
    onSubmit,
    onSubmitError,
    onSubmitSuccess,
    refetchQueries,
    submitLabel,
    className
  } = props;

  const { t } = useTranslation();

  const [fields, setFields] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = ({ id, value, error }) => {
    const newFields = { ...fields };
    newFields[id] = value;
    setFields(newFields);
    const newFieldErrors = { ...fieldErrors };
    newFieldErrors[id] = error;
    setFieldErrors(newFieldErrors);
  };

  const handleDelete = () => {
    if (onDelete !== null) {
      onDelete(
        {
          variables: { id: fields.id }
        }
      ).then(() => {
        onSubmitSuccess(t('deleted-successfully'));
      }).catch(err => {
        onSubmitError(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let valMessage = '';

    if (onValidate) {
      valMessage = onValidate(fields);
      if (valMessage !== '') {
        onSubmitError(new Error(`ValidationError: ${valMessage}`));
        setLoading(false);
        return;
      }
    }

    if (valMessage === '' && isValid()) {
      if (onSubmit) {
        try {
          await onSubmit({
            variables: stripTypeNameFromMutationVars(fields),
            refetchQueries
          });
          if (onSubmitSuccess) {
            onSubmitSuccess(t('saved-successfully'));
          }
        } catch (err) {
          if (onSubmitError) {
            onSubmitError(err);
          } else {
            throw err;
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const isValid = () => {
    let requiredFields = [];
    if (Array.isArray(children)) {
      requiredFields = children.filter((child) => {
        return child.props.required === true;
      });
    } else {
      if (children && children.props.required) {
        requiredFields.push(children);
      }
    }
    const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
    const blankFields = requiredFields.filter((k) => {
      return fields[k.props.id] === '' || fields[k.props.id] === undefined;
    });
    if (errMessages.length || blankFields.length) return false;
    return true;
  };

  useEffect(() => {
    setFields(propFields);
  }, [propFields]);

  let childrenArray = [];

  if (Array.isArray(children)) {
    childrenArray = children;
  } else {
    childrenArray.push(children);
  }

  return (
    <form onSubmit={handleSubmit}>
      {
        childrenArray.map(formItem => {
          if (formItem.props.bbFormIgnore) {
            return (formItem);
          }
          const cloned = React.cloneElement(formItem, {
            onChange: handleChange,
            value: fields[formItem.props.id] ? fields[formItem.props.id] : '',
            className: className || null
          });
          return (
            <FormControl
              variant='standard'
              key={formItem.props.id}
              margin='normal'
              required={formItem.props.required}
              fullWidth
            >
              {cloned}
            </FormControl>
          );
        })
      }
      <div style={{ textAlign: 'right' }}>
        {
          onCancelClick &&
            <FormControl variant='standard' margin='normal' style={{ marginRight: 10 }}>
              <Button onClick={onCancelClick}>
                {cancelLabel || t('cancel')}
              </Button>
            </FormControl>
        }
        {
          onDelete &&
            <FormControl variant='standard' margin='normal' style={{ marginRight: 10 }}>
              <DeleteButton
                onDeleteOkClick={handleDelete}
              />
            </FormControl>
        }
        <FormControl variant='standard' margin='normal'>
          <StoreButton
            type='submit'
            label={submitLabel || 'save'}
            disabled={loading || !isValid()}
            loading={loading}
            className={className || null}
          />
        </FormControl>
      </div>
    </form>
  );
};

StoreForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onValidate: PropTypes.func,
  refetchQueries: PropTypes.array,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  className: PropTypes.string
};

export default StoreForm;
