import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Menu from './Menu.js';
import MiniCard from '../MiniCard/MiniCard.js';

const styles = {
  button: {
    padding: 2,
    minWidth: 52
  }
};

const Value = (props) => {
  const { classes, onClick, ...other } = props;
  const levels = ['1', '2', '3', '4', '5', '6', '7'];
  const suits = ['NT', 'S', 'H', 'C', 'D'];

  return (
    <Menu
      {...other}
    >
      <table>
        <tbody>
          {
            levels.map((level, levelIndex) => {
              return (
                <tr key={levelIndex}>
                  {
                    suits.map((suit, suitIndex) => {
                      return (
                        <td key={suitIndex}>
                          <Button className={classes.button}>
                            <MiniCard
                              value={level}
                              suit={suit}
                              onClick={onClick}
                              isBid
                            />
                          </Button>
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </Menu>
  );
};

Value.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func
};

export default withStyles(styles)(Value);
