const formatURLTitle = (title) => {
  let newString = title.split(' ').join('-');
  // eslint-disable-next-line
  newString = newString.replace(/[^0-9a-z\-]/gi, '')
  return newString.toLowerCase();
};

const revertURLTitle = (title) => {
  if (title.indexOf('-') > -1) {
    title = title.split('-').join(' ');
  }
  // return capitalized title;
  return title.charAt(0).toUpperCase() + title.slice(1);
};

const getBreadcrumbDataFromPath = (path) => {
  // we need to turn this:
  // /admin/products/set/123/handy-hands-vol1/hands/321/hand-1
  // into this:
  /* [
    { to: '/admin', title: 'Dashboard' },
    { to: '/admin/products', title: 'Products' },
    { to: '/admin/products/set/123/handy-hands-vol1', title: 'Handy Hands vol1' },
    { to: '', title: 'Hand 1'}
  ] */

  const parts = path.split('/');
  const breadcrumbs = [];

  if (parts.length > 2) {
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (part !== '') {
        if (part === 'admin') {
          breadcrumbs.push(
            { to: '/admin', title: 'Dashboard' }
          );
        } else {
          switch (i) {
            case 2:
              breadcrumbs.push(
                { to: `/admin/${parts[2]}`, title: revertURLTitle(parts[2]) }
              );
              break;
            case 4:
              if (parts.length > 5) {
                breadcrumbs.push(
                  { to: `/admin/${parts[2]}/${parts[3]}/${parts[4]}/${parts[5]}`, title: revertURLTitle(parts[5]) }
                );
              } else if (parts.length === 5 && parts[2] === 'royalties') {
                breadcrumbs.push(
                  { to: '', title: revertURLTitle(parts[4]) }
                );
              } else {
                breadcrumbs.push(
                  { to: '', title: revertURLTitle(parts[4] + ' ' + parts[3]) }
                );
              }
              break;
            case 7:
              breadcrumbs.push(
                { to: '', title: revertURLTitle(parts[8] ? parts[8] : parts[7]) }
              );
              break;
            default:
              break;
          }
        }
      }
    }
    // make the last item's 'to' blank
    breadcrumbs[breadcrumbs.length - 1].to = '';
  }
  return breadcrumbs;
};

export {
  formatURLTitle,
  revertURLTitle,
  getBreadcrumbDataFromPath
};
